import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import MyProfileNav from 'components/ecom/MyProfile/MyProfileNav/MyProfileNav';
import ChangePassword from 'components/ecom/MyProfile/ChangePassword/ChangePassword';
import PersonalInfo from 'components/ecom/MyProfile/PersonalInfo/PersonalInfo';
import GppRegister from 'components/ecom/MyProfile/GppRegister/GppRegister';
import PhoneAuthentication from 'components/ecom/MyProfile/PhoneAuthentication/PhoneAuthentication';
import { withTranslation } from "react-i18next";

import classes from './MyProfile.module.scss';

class MyProfile extends PureComponent {

  render() {
    const { tabActive, t } = this.props;

    return (
      <div className={classes.PageWrap}>
        <div className={classes.PageHeader}>
          <h1 className="PageTitle">{t('Hồ sơ của tôi')}</h1>
        </div>

        <div className={`${classes.PageContent}`}>
          <div className={classes.PageSide}>
            <MyProfileNav/>
          </div>
          <div className={classes.PageMain}>
            { tabActive === 'personalinfo' ? <PersonalInfo/> : ``}
            { tabActive === 'changepassword' ? <ChangePassword/> : ``}
            { tabActive === 'gppregister' ? <GppRegister/> : ``}
            { tabActive === 'phone-authentication' ? <PhoneAuthentication/> : ``}
          </div>
        </div>
      </div>
    );
  }
}

MyProfile.propTypes = {
  tabActive: PropTypes.oneOf(['personalinfo', 'changepassword', 'gppregister']),
};

MyProfile.defaultProps = {
  tabActive: 'personalinfo',
};

export default withTranslation()(MyProfile);
