import React, { PureComponent } from 'react';
import { withTranslation } from "react-i18next";

import { 
  Grid,
} from '@material-ui/core';

import StyledAutocomplete from 'components/Styled/Autocomplete/Autocomplete';
import StyledTextField from 'components/Styled/TextField';
import Location from 'components/Location/Location';

import stylesLayoutMainEcom from 'assets/styles/layouts/LayoutMainEcom.module.scss';

class FormAddress extends PureComponent {
  componentDidUpdate(prevProps, prevState) {
    const { values, setFieldValue } = this.props;
    if (values.getcare_province_id !== prevProps.values.getcare_province_id) {
      setFieldValue('getcare_district_id','',false)
      setFieldValue('getcare_ward_id','',false)
    }
    if (values.getcare_district_id !== prevProps.values.getcare_district_id) {
      setFieldValue('getcare_ward_id','',false)
    }
  }

  render() {
    const { 
      values,
      errors,
      handleChange,
      setFieldValue,
      t,
    } = this.props;

    return (
      <Location
        provinceId={values.getcare_province_id}
        districtId={values.getcare_district_id}
        wardId={values.getcare_ward_id}
      >
        {
          (props) => {
            return (
              <Grid container spacing={2} justifyContent="space-between">
                <Grid item xs={12} md={3}>
                  <div className={`${stylesLayoutMainEcom.FormControl} ${stylesLayoutMainEcom.Margin__0}`}>
                    <label htmlFor="support_address" className={`${!!errors.address && stylesLayoutMainEcom['FormLabel--error']}`}>{t('Địa chỉ')} <span>*</span></label>
                    <StyledTextField
                      placeholder={t('Số nhà và tên đường')}
                      size="medium"
                      fullWidth
                      variant="outlined"
                      name="address"
                      id="support_address"
                      error={!!errors.address}
                      helperText={errors.address}
                      value={values.address}
                      onChange={handleChange}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={3}>
                  <div className={`${stylesLayoutMainEcom.FormControl} ${stylesLayoutMainEcom.Margin__0}`}>
                    <label htmlFor="support_getcare_province_id" className={`${!!errors.getcare_province_id && stylesLayoutMainEcom['FormLabel--error']}`}>{t('Thành phố')} <span>*</span></label>
                    <StyledAutocomplete
                      name="getcare_province_id"
                      size="medium"
                      value={props.getProvince(values.getcare_province_id) || ''}
                      options={props.loadingProvinceList ? [] : ( props.provinceList || [] )}
                      getOptionLabel={(option) => option?.name || ''}
                      renderOption={(option) => option?.name}
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      }
                      loading={props.loadingProvinceList}
                      loadingText={`${t('common:Đang tải')}...`}
                      id="support_getcare_province_id"
                      renderInput={(params) => (
                        <StyledTextField
                          {...params}
                          variant="outlined"
                          autoComplete="off"
                          size="medium"
                          placeholder={t('Chọn thành phố')}
                          error={!!errors.getcare_province_id}
                          helperText={errors.getcare_province_id}
                        />
                      )}
                      onChange={(e,newValue) => {
                        setFieldValue('getcare_province_id',newValue?.id || '') 
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={3}>
                  <div className={`${stylesLayoutMainEcom.FormControl} ${stylesLayoutMainEcom.Margin__0}`}>
                    <label htmlFor="support_getcare_district_id" className={`${!!errors.getcare_district_id && stylesLayoutMainEcom['FormLabel--error']}`}>{t('Quận/Huyện')} <span>*</span></label>
                    <StyledAutocomplete
                      name="getcare_district_id"
                      size="medium"
                      noOptionsText={t('Vui lòng chọn thành phố')}
                      value={props.getDistrict(values.getcare_district_id) || ''}
                      options={props.districtList || []}
                      getOptionLabel={(option) => option?.name || ''}
                      renderOption={(option) => option?.name}
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      }
                      loading={props.loadingDistrictList}
                      loadingText={`${t('common:Đang tải')}...`}
                      id="support_getcare_district_id"
                      renderInput={(params) => (
                        <StyledTextField
                          {...params}
                          variant="outlined"
                          autoComplete="off"
                          size="medium"
                          placeholder={t('Quận/Huyện')}
                          error={!!errors.getcare_district_id}
                          helperText={errors.getcare_district_id}
                        />
                      )}
                      onChange={(e,newValue) => {
                        setFieldValue('getcare_district_id',newValue?.id || '') 
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={3}>
                  <div className={`${stylesLayoutMainEcom.FormControl} ${stylesLayoutMainEcom.Margin__0}`}>
                    <label htmlFor="support_getcare_ward_id" className={`${!!errors.getcare_ward_id && stylesLayoutMainEcom['FormLabel--error']}`}>{t('Phường/Xã')} <span>*</span></label>
                    <StyledAutocomplete
                      name="getcare_ward_id"
                      size="medium"
                      noOptionsText={t('Vui lòng chọn quận/huyện')}
                      value={props.getWard(values.getcare_ward_id) || ''}
                      options={props.loadingWardList ? [] : ( props.wardList || [] )}
                      getOptionLabel={(option) => option?.name || ''}
                      renderOption={(option) => option?.name}
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      }
                      loading={props.loadingWardList}
                      loadingText={`${t('common:Đang tải')}...`}
                      id="support_getcare_ward_id"
                      renderInput={(params) => (
                        <StyledTextField
                          {...params}
                          variant="outlined"
                          autoComplete="off"
                          size="medium"
                          placeholder={t('Phường/Xã')}
                          error={!!errors.getcare_ward_id}
                          helperText={errors.getcare_ward_id}
                        />
                      )}
                      onChange={(e,newValue) => {
                        setFieldValue('getcare_ward_id',newValue?.id || '') 
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
            )
          }
        }
      </Location>
    );
  }
};

export default withTranslation(['componentConnectSupportSupportForm','common'],{ withRef: true })(FormAddress);
