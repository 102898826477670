import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Formik , Field, Form} from 'formik';
import { currencyFormatVN } from 'utils/helper';
import { sortBy, isEqual } from 'lodash';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectFastOrderLogistic,
  makeSelectFastOrderDetails,
  makeSelectDeliveryLogisticList,
  makeSelectFastOrderDelivery,
  makeSelectLogisticListLoading,
} from 'redux/selectors';
import { savePRDraftLogistic,getPurchaseRequestDraft } from 'redux/actions/ecom/fastOrderActions';
import {
  getDeliveryLogisticList,
  getVendorDeliveryFee,
  saveVendorDeliveryFees,
  saveSelectedPayment,
} from 'redux/actions/logisticActions';
import { withTranslation } from "react-i18next";
import {
  isChanhXeLogisticProvider,
  isThirdPartyLogisticProvider,
  isThirdPartyLogisticProviderId,
  isSplitOrderByVendor,
  getSelectedService,
  LOGISTIC_OTHER_TYPE,
  getProvidersGroups,
  getGroup,
} from 'utils/constanst/common';
import { savePRDetails } from 'redux/actions/ecom/fastOrderActions';

import {
  getAvailableLogisticProviders,
  isOrderExceedMaximumCOD,
} from 'utils/constanst/ecomFastOrderConstants';
import * as QueryString from 'query-string';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ChanhXeDialog from './ChanhXeDialog/ChanhXeDialog';
import OtherLogisticDialog from './OtherLogisticDialog/OtherLogisticDialog';
import CustomerDeliveryFormDialog from 'components/ecom/FastOrder/FastOrderCustomerDelivery/CustomerDeliveryFormDialog/CustomerDeliveryFormDialog';
import classes from './LogisticProvider.module.scss';
import { ContactlessOutlined } from '@material-ui/icons';

class LogisticProvider extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedTypeAndIdMap: {},
      selectedTypeAndServiceIdMap: {},
      error: this.props.error,
      pending :this.props.pending,
      isChanhXeDialogOpen: false,
      isOtherProviderDialogOpen: false,
      isCustomerDeliveryDialogOpen: false,
    };
  }
 async componentDidMount() {
    const { prDelivery, prLogistic } = this.props;
    if (prLogistic && prLogistic.id && isThirdPartyLogisticProviderId(prLogistic.id)) {
      this.setState({
        selectedTypeAndIdMap: {
          [LOGISTIC_OTHER_TYPE]: prLogistic.id,
        },
        selectedTypeAndServiceIdMap: {
          [LOGISTIC_OTHER_TYPE]: prLogistic.service_id,
        }
      });
    }
    if (prDelivery) {
      this._loadLogisticList();
      this._resetDefaultProvider();

    }
  }
  async componentDidUpdate(prevProps, prevState) {
    const { prDelivery, deliveryLogisticList, prLogistic,prDetails } = this.props;
    const changeDeliveryLogisticList = deliveryLogisticList && deliveryLogisticList.length > 0 && !isEqual(sortBy(deliveryLogisticList), sortBy(prevProps.deliveryLogisticList));
    const changeDeliveryAwardId = prDelivery && prevProps.prDelivery && prDelivery.getcare_ward?.id !== prevProps.prDelivery.getcare_ward?.id;
    if ( changeDeliveryAwardId ) {
     await this._loadLogisticList();
     await this._resetDefaultProvider();
    }

  
    const serviceSelected = this._getSelectedService(prLogistic?.id,prLogistic?.service_id);
    const prevServiceSelected = this._getSelectedService(prevProps.prLogistic?.id,prevProps.prLogistic?.service_id);
    const changeServiceTypeId = serviceSelected?.service_type_id !== prevServiceSelected?.service_type_id;
    const changePrLogistic = prLogistic?.id !==  prevProps.prLogistic?.id;
    if ( changeDeliveryAwardId || changePrLogistic || changeServiceTypeId ) {
      if ( !changeDeliveryLogisticList && !isEqual(sortBy(prLogistic), sortBy(prevProps.prLogistic)) ) {
        await this._resetDefaultProvider();
      }
    }
    if ( changeDeliveryLogisticList 
      || changeDeliveryAwardId 
      || changePrLogistic 
      || changeServiceTypeId 
      || !isEqual(sortBy(prLogistic), sortBy(prevProps.prLogistic)) ) {
        await this._resetVendorDeliveryFee();
        // await this._resetDefaultProvider();
    }
  }

  _loadLogisticList = async () => {
    const { prDelivery, prLogistic, prDetails } = this.props;
    const vendorIds = prDetails?.vendor_items && prDetails.vendor_items.map( item => item.id );
  
    await this.props.getDeliveryLogisticList({
      params: {
        getcare_vendor_ids: vendorIds || [],
        getcare_customer_code: prDetails?.customer_code,
        getcare_ward_id: prDelivery?.getcare_ward?.id,
        insurance_value: prLogistic?.insurance_value || 0,
        weight: prLogistic?.weight || 0,
      },
    });
  };
  _resetVendorDeliveryFee = () => {
    const { prDetails, prLogistic, prDelivery, deliveryLogisticList } = this.props;
    if ( prLogistic?.service_id ) {

      let serviceTypeId = prLogistic?.service_type_id;
      if ( !serviceTypeId ) {
        const { selectedTypeAndIdMap, selectedTypeAndServiceIdMap } = this.state;
        const availablesProviders = getAvailableLogisticProviders(deliveryLogisticList, (prDetails?.amount + prDetails?.amount_vat));
        const providersGroups = deliveryLogisticList ? [...getProvidersGroups(availablesProviders)] : [];
        const otherProviders= getGroup(LOGISTIC_OTHER_TYPE, providersGroups)?.providers;
        const providerId= selectedTypeAndIdMap[LOGISTIC_OTHER_TYPE];
        const serviceId= selectedTypeAndServiceIdMap[LOGISTIC_OTHER_TYPE];
        if (otherProviders){
          const otherProvider = otherProviders.find( item => item.id === providerId );
          if ( Array.isArray(otherProvider?.services) ) {
            const service = otherProvider.services.find( item => item.service_id === serviceId );
            serviceTypeId = service?.service_type_id;
            if (!service ) {
              this.handleSaveLogisticProvider({
                id:  0,
                service_id: 0,
                total_fee: 0,
              });
            }
          }
        }
      }

      if (serviceTypeId && prDetails?.vendor_items) {
        let params = prDetails.vendor_items.map( item => {
          return {
            getcare_ward_id: prDelivery?.getcare_ward?.id,
            getcare_vendor_id: item.id,
            getcare_logistic_provider_id: prLogistic.id,
            service_type_id: serviceTypeId ? serviceTypeId : 1,
            weight: item.weight
          }
        })
        this.props.getVendorDeliveryFee({ params: params });
      }
  
    } else {
      this.props.saveVendorDeliveryFees([])
    }
  }

  _resetDefaultProvider =  ()=> {
    const { deliveryLogisticList, prDetails, prLogistic ,location} = this.props;
    var isExceedMaxCOD = isOrderExceedMaximumCOD(deliveryLogisticList, (prDetails?.amount + prDetails?.amount_vat  ));
    if ((prDetails?.amount + prDetails?.amount_vat) > 15000000){
         isExceedMaxCOD = true;
    }
    const availablesProviders = getAvailableLogisticProviders(deliveryLogisticList,  (prDetails?.amount + prDetails?.amount_vat  ));
   
    const willSetDefaultProvider = (isExceedMaxCOD && availablesProviders && availablesProviders.length) || availablesProviders.length === 1;
    const willReset = !this._getSelectedProvider(prLogistic?.id);
    if ( isExceedMaxCOD === true ||  prLogistic?.id !== 1 && prDetails?.amount === 0) {
      if (prLogistic?.id !== 1 && prDetails?.amount === 0) {
        this.props.saveSelectedPayment(1);
      this.props.onUpdatePRDraft({
      prPayment: 1,
    });
      }
     if (willReset && location.pathname === 'fastorder' ) {
        this.handleSaveLogisticProvider({
          id: 0,
          service_id: 0,
          total_fee: 0,
        });
      }
    }
    // this._resetVendorDeliveryFee();
  }

  _getSelectedProvider = (providerId) => {
    const { deliveryLogisticList, prDetails } = this.props;

    if (!deliveryLogisticList || !providerId) return null;

    const availablesProviders = getAvailableLogisticProviders(deliveryLogisticList,  (prDetails?.amount + prDetails?.amount_vat ));
    return availablesProviders.find((item) => item.id === providerId);
  };
  _getSelectedService = (providerId, serviceId) => {
    const provider = this._getSelectedProvider(providerId);
    if (!provider || !provider.services) return null;
    return provider.services.find(
      (service) => service.service_id === serviceId
    );
  };

  handleOpenChanhXeDialog = () => {
    this.setState({
      isChanhXeDialogOpen: true,
    });
  };
  handleCloseChanhXeDialog = () => {
    this.setState({
      isChanhXeDialogOpen: false,
    });
  };
  handleOpenOtherProviderDialog = () => {
    this.setState({
      isOtherProviderDialogOpen: true,
    });
  };
  handleCloseOtherProviderDialog = () => {
    this.setState({
      isOtherProviderDialogOpen: false,
    });
  };

  handleOpenCustomerDeliveryDialog = () => {
    this.setState({
      isCustomerDeliveryDialogOpen: true,
    });
  };
  handleCloseCustomerDeliveryDialog = () => {
    this.setState({
      isCustomerDeliveryDialogOpen: false,
    });
  };

  handleSelectionClick = (e) => {
    if (isChanhXeLogisticProvider(parseFloat(e.target.value))) {
      this.setState({
        isChanhXeDialogOpen: true,
      });
    }
    if (isThirdPartyLogisticProvider(parseFloat(e.target.value))) {
      this.setState({
        isOtherProviderDialogOpen: true,
      });
    }
  };
  handleSaveLogisticProvider = (fieldMap) => {
    this.props.onUpdatePRDraft({
      prLogisticData: {
        ...this.props.prLogistic,
        ...fieldMap,
      },
      willUpdatePRDetails: true,
    });
  };

  render() {
    const { deliveryLogisticList, prLogistic, prDetails, logisticListLoading, disabled, t } = this.props;
    const {
      selectedTypeAndIdMap,
      selectedTypeAndServiceIdMap,
      isChanhXeDialogOpen,
      isOtherProviderDialogOpen,
      error,
      pending,
    } = this.state;
    const availablesProviders = getAvailableLogisticProviders(deliveryLogisticList, (prDetails?.amount + prDetails?.amount_vat));
    const isExceedMaxCOD = isOrderExceedMaximumCOD(deliveryLogisticList,  (prDetails?.amount + prDetails?.amount_vat ));
    const providersGroups = deliveryLogisticList ? [...getProvidersGroups(availablesProviders)] : [];

    return (
      <section className={`${classes.Wrap} ${logisticListLoading ? 'OverlayLoading' : ''}`}>
        <h3 className="SectionTitle">{t('Đơn vị vận chuyển')}</h3>
        {!deliveryLogisticList || !deliveryLogisticList.length || !availablesProviders.length ? (
          <p className="NoData">
            {t('Hiện không có đơn vị vận chuyển nào thích hợp trong khu vực nhận hàng của bạn.')}<br />
            {t('Hãy')}{` `}
            <Button
              size="small"
              color="secondary"
              onClick={this.handleOpenCustomerDeliveryDialog}
            >
              {t('thay đổi địa chỉ nhận hàng')}
            </Button>
            .
          </p>
        ) : (<>
          { isExceedMaxCOD &&
            <p className={classes.WarningInfo}>
             {t('Đơn hàng giá trị cao không hỗ trợ COD. Vui lòng liên hệ Phahub để chuyển khoản thanh toán trước khi giao hàng.')}
            </p>
          }
          <Formik
            initialValues={{
              typeId: prLogistic?.type || null,
              id: prLogistic?.id || null,
              service_id: prLogistic?.service_id || null,
              note: prLogistic?.note || '',
            }}
            enableReinitialize
          >
            {(props) => {
              const { setFieldValue, values } = props;
              const showDeliveryFee= !isSplitOrderByVendor(values.typeId);
              return (
                <form noValidate autoComplete="off" className={classes.Dialog}>
                  <RadioGroup
                    aria-label="logistic provider"
                    name="typeId"
                    value={values.id ? values.typeId + `` : ``}
                    className={classes.SelectionGroup}
                    onChange={(e) => {
                      const typeId = e.target.value;
                     
                      const selectedGroup = getGroup(typeId, providersGroups);
                      const willSelectProviderId = selectedGroup?.providers?.length === 1;
                      const newProviderId = willSelectProviderId ? selectedGroup.providers[0].id : selectedTypeAndIdMap[typeId];
                      const newServiceId = willSelectProviderId ? 0 : selectedTypeAndServiceIdMap[typeId];

                      setFieldValue('typeId', typeId);
                      this.setState({
                        selectedTypeAndIdMap: {
                          ...selectedTypeAndIdMap,
                          [typeId]: newProviderId,
                        },
                        selectedTypeAndServiceIdMap: {
                          ...selectedTypeAndServiceIdMap,
                          [typeId]: newServiceId,
                        },
                      });
                      this.handleSaveLogisticProvider({
                        id: newProviderId,
                        service_id: newServiceId,
                        total_fee: 0,
                        service_name: e.target.value.service_name
                      });
                    }}
                    onClick={this.handleSelectionClick}
                  >
                    { providersGroups.map((group) => {
                      const provider = this._getSelectedProvider(selectedTypeAndIdMap[group.typeId]);
                      const service = this._getSelectedService(selectedTypeAndIdMap[group.typeId], selectedTypeAndServiceIdMap[group.typeId]);
                      return <div key={`provider-${group.typeId}`}>
                        <FormControlLabel
                          value={group.typeId}
                          disabled={error === true || pending === true || group.typeId === "3" && pending === true ? true : false}
                          control={
                            <Radio
                              size="small"
                              style={{
                                padding: '0.08rem 0',
                                marginRight: '0.5rem',
                              }}
                            />
                          }
                          fontSize="small"
                          className={classes.SelectionItem}
                          label={
                            <div className={`${classes.Item} PreWrap`}>
                              {!isThirdPartyLogisticProvider(group.typeId) && (<>
                                <p className={classes.ItemName}>{group.typeLabel}</p>
                                <p className={classes.TextExplained}>({t('Phí vận chuyển sẽ được chỉ định sau')})</p>
                                <p className={classes.ItemName}>
                                  {isChanhXeLogisticProvider(group.typeId) ? values.note : ``}
                                </p>
                                {isChanhXeLogisticProvider(group.typeId) &&
                                  !!values.note && (
                                    <Button
                                      style={{
                                        padding: 0,
                                        textDecoration: 'underline',
                                      }}
                                      fontSize="small"
                                      disabled={error === true || pending === true ? true : false}
                                      color="secondary"
                                      onClick={this.handleOpenChanhXeDialog}
                                    >{t('Thay đổi')}</Button>
                                  )}
                                </>
                              )}
                              {isThirdPartyLogisticProvider(group.typeId) && (<>

                                <p className={classes.ItemName}>
                                  { provider?.name || group.typeLabel }
                                </p>
                                <p className={classes.TextExplained}>
                                  { service ? (
                                    <span className={classes.ServiceSelected}>
                                      <span>{ service.service_name }</span>
                                    </span>
                                  ) : (
                                      t('Chọn đơn vị vận chuyển')
                                  ) }
                                </p>
                                { service && (
                                  <Button
                                    style={{
                                      padding: 0,
                                      textDecoration: 'underline',
                                    }}
                                    fontSize="small"
                                    disabled={error === true || pending === true ? true : false}
                                    color="secondary"
                                    onClick={this.handleOpenOtherProviderDialog}
                                  >{t('Thay đổi')}</Button>
                                ) }
                              </>)}
                            </div>
                          }
                        />
                      </div>
                    })}
                  </RadioGroup>
                  {isChanhXeDialogOpen && (
                    <ChanhXeDialog
                      isOpen={isChanhXeDialogOpen}
                      note={values.note}
                      onClose={this.handleCloseChanhXeDialog}
                      onSave={(note) => {
                        setFieldValue('note', note);
                        this.handleSaveLogisticProvider({
                          note: note,
                          total_fee: 0,
                        });
                      }}
                    />
                  )}
                  {isOtherProviderDialogOpen && (
                    <OtherLogisticDialog
                      otherProviders={getGroup(LOGISTIC_OTHER_TYPE, providersGroups)?.providers}
                      providerId={selectedTypeAndIdMap[LOGISTIC_OTHER_TYPE]}
                      serviceId={selectedTypeAndServiceIdMap[LOGISTIC_OTHER_TYPE]}
                      showDeliveryFee={showDeliveryFee}
                      isOpen={isOtherProviderDialogOpen}
                      onClose={this.handleCloseOtherProviderDialog}
                      onSave={({ providerId, serviceId }) => {
                        this.setState({
                          selectedTypeAndIdMap: {
                            ...selectedTypeAndIdMap,
                            [LOGISTIC_OTHER_TYPE]: providerId,
                          },
                          selectedTypeAndServiceIdMap: {
                            ...selectedTypeAndServiceIdMap,
                            [LOGISTIC_OTHER_TYPE]: serviceId,
                          },
                        });
                        this.handleSaveLogisticProvider({
                          id: providerId,
                          service_id: serviceId,
                          total_fee: this._getSelectedService(providerId, serviceId)?.fee?.total || 0,
                          service_name: this._getSelectedService(providerId, serviceId)?.service_name
                        });
                      }}
                    />
                  )}
                </form>
              );
            }}
          </Formik>
        </>)}

        {this.state.isCustomerDeliveryDialogOpen && (
          <CustomerDeliveryFormDialog
            isOpen={this.state.isCustomerDeliveryDialogOpen}
            onClose={this.handleCloseCustomerDeliveryDialog}
            onUpdatePRDraft={this.props.onUpdatePRDraft}
          />
        )}
      </section>
    );
  }
}

LogisticProvider.propTypes = {
  prLogistic: PropTypes.object,
};

LogisticProvider.defaultProps = {
  prLogistic: null,
};

const mapStateToProps = createStructuredSelector({
  prLogistic: makeSelectFastOrderLogistic(),
  prDetails: makeSelectFastOrderDetails(),
  deliveryLogisticList: makeSelectDeliveryLogisticList(),
  logisticListLoading: makeSelectLogisticListLoading(),
  prDelivery: makeSelectFastOrderDelivery(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getPurchaseRequestDraft: (payload) => dispatch(getPurchaseRequestDraft(payload)),
    savePRDetails: (payload) => dispatch(savePRDetails(payload)),
    savePRDraftLogistic: (payload) => dispatch(savePRDraftLogistic(payload)),
    getDeliveryLogisticList: (payload) => dispatch(getDeliveryLogisticList(payload)),
    getVendorDeliveryFee: (payload) => dispatch(getVendorDeliveryFee(payload)),
    saveVendorDeliveryFees: (payload) => dispatch(saveVendorDeliveryFees(payload)),
    saveSelectedPayment: (payload) => dispatch(saveSelectedPayment(payload)),

  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default withTranslation()(compose(withConnect)(LogisticProvider));
