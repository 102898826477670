import {
  SET_STATE_CATEGORY,
} from 'redux/constants/categoryConstants';

const initialState = {
  categoryEcomList: [],
  loadingCategoryEcomList: false,
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (action.type) {
    case SET_STATE_CATEGORY: {
      return {
        ...state,
        ...payload,
      };
    }
    default:
      return state;
  }
}

export default reducer;
