import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectSalesOrder, makeSelectSalesOrderDelivery } from 'redux/selectors';
import { withTranslation } from "react-i18next";

import Panel from 'components/Panel/Panel';
import DeliveryView from './DeliveryView/DeliveryView';

import classes from './SalesOrderDeliveryInfo.module.scss';

class SalesOrderDeliveryInfo extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isDialogOpen: false,
    }
  }

  handleOpenDialog = () => {
    this.setState({
      isDialogOpen: true,
    });
  }
  handleCloseDialog = () => {
    this.setState({
      isDialogOpen: false,
    });
  }
  handleSaveDeliverySuccess = (newSalesOrderData) => {
    this.props.onSaveDeliveryInfoSuccess(newSalesOrderData);
  }

  render() {
    const { t } = this.props;
    return (<Panel
      title={t('Thông tin nhận hàng')}
      panelClassName={classes.Panel}
      content={<DeliveryView
        salesOrder={{...this.props.salesOrder}}
        salesOrderDelivery={{...this.props.salesOrderDelivery}}
      />}
    />)
  }

}

SalesOrderDeliveryInfo.propTypes = {
};
SalesOrderDeliveryInfo.defaultProps = {
};

const mapStateToProps = createStructuredSelector({
  salesOrder: makeSelectSalesOrder(),
  salesOrderDelivery: makeSelectSalesOrderDelivery(),
});
const withConnect = connect(mapStateToProps, null);
export default withTranslation()(compose(withConnect)(SalesOrderDeliveryInfo));
