import axiosAuth from 'utils/axios/axiosAuth';

export default {
  fetchVendorList: ({ params, cancelToken }) => {
    return axiosAuth.get(`/ecom/common/vendor`, {
      params,
      cancelToken
    });
  },
};
