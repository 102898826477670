import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Panel from 'components/Panel/Panel';
import Button from '@material-ui/core/Button';
import CustomerDeliveryView from './CustomerDeliveryView/CustomerDeliveryView';
import { withTranslation } from "react-i18next";

import classes from './FastOrderCustomerDelivery.module.scss';

class FastOrderCustomerDelivery extends PureComponent {
  render() {
    const {t} = this.props;
    return (
      <>
        <Panel
          title={t('Địa chỉ nhận hàng')} 
          titleAction={
            <Button
              size="small"
              color="secondary"
              onClick={this.props.onOpenCustomerDeliveryDialog}
            >
              {t('Thay đổi')}
            </Button>
          }
          panelClassName={classes.Panel}
          content={<CustomerDeliveryView taxInfoFormRef={this.props.taxInfoFormRef} onUpdatePRDraft={this.props.onUpdatePRDraft} />}
        />
      </>
    );
  }
}

FastOrderCustomerDelivery.propTypes = {
  taxInfoFormRef: PropTypes.func,
};
FastOrderCustomerDelivery.defaultProps = {};

export default withTranslation()(FastOrderCustomerDelivery);
