import { takeLatest, debounce } from 'redux-saga/effects';

// auth
import {
  CHECK_AUTH,
  SIGN_OUT,
  GET_PROFILE,
  UPDATE_PROFILE,
  GET_USER_PROVINCE_LIST,
} from 'redux/constants/authConstants';
import {
  checkAuthentication,
  signMeOut,
  getProfile,
  updateProfile,
  loadUserProvinceList,
} from 'saga/authSaga';

// logistic
import {
  GET_DELIVERY_LOGISTIC_LIST,
  GET_LOGISTIC_LIST,
  GET_VENDOR_DELIVERY_FEE,
} from 'redux/constants/logisticConstants';
import {
  loadDeliveryLogisticList,
  loadLogisticList,
  loadVendorDeliveryFees,
} from 'saga/logisticSaga';

import {
  GET_COUNTRY_LIST,
  GET_PROVINCE_LIST,
  GET_DISTRICT_LIST,
  GET_WARD_LIST,
} from 'redux/constants/locationConstants';
import {
  loadCountryList,
  loadProvinceList,
  loadDistrictList,
  loadWardList,
} from 'saga/locationSaga';

import {
  GET_PR_DRAFT,
  CREATE_PR_DRAFT,
  CHECK_PROMOTION,
  CREATE_PR,
  RATE_ORDERING,
} from 'redux/constants/ecom/fastOrderConstants';
import {
  loadPRDraft,
  updatePRDraft,
  testPromotion,
  createPR,
  rateOrdering,
} from 'saga/ecom/fastOrderSaga';

import {
  GET_PHARMACY_LIST,
  GET_PHARMACY,
} from 'redux/constants/ecom/pharmacyConstants';
import {
  loadPharmacyList,
  loadPharmacy,
} from 'saga/ecom/pharmacySaga';

import {
  GET_SALES_ORDER_LIST,
  GET_SALES_ORDER,
  CONFIRM_PURCHASING,
  CONFIRM_SUCCESS,
  GET_SALES_ORDER_STATUSES,
} from 'redux/constants/oms/salesOrderConstants';
import {
  loadSalesOrderList,
  loadSalesOrder,
  confirmSalesOrderPurchasing,
  confirmSalesOrderSuccess,
  loadSalesOrderStatuses,
} from 'saga/oms/salesOrderSaga';

// // product
import {
  GET_PRODUCT_LIST,
  DEBOUNCE_GET_PRODUCT_LIST,
  FETCH_PRODUCT_LIST,
  FETCH_PRODUCT_DETAILS,
  FETCH_PRODUCT_DESCRIPTIONS,
  FETCH_PRODUCT_RATINGS,
  FETCH_PRODUCT_PRICE_WHOLESALES,
  FETCH_PRODUCT_DETAILS_INFO,
  FETCH_PRODUCT_RATING_DETAIL,
  FETCH_PRODUCT_RELATED_LIST,
  // GET_PRODUCT_DETAILS,
  // GET_PRODUCT_BRANDS,
  // GET_PRODUCT_INDUSTRIES,
  // GET_PRODUCT_MANUFACTURES,
} from 'redux/constants/productConstants';
import {
  // loadProductDetails,
  loadProductList,
  fetchProductList,
  fetchProductDetails,
  fetchProductDescriptions,
  fetchProductRatings,
  fetchProductPriceWholesales,
  fetchProductDetailsInfo,
  fetchProductRatingDetail,
  fetchProductRelatedList,
  // loadIndustries,
  // loadProductManufactures,
} from 'saga/productSaga';

// banner 
import bannerConstants from 'redux/constants/bannerConstants';
import bannerSaga from 'saga/bannerSaga';
// category 
import categoryConstants from 'redux/constants/categoryConstants';
import categorySaga from 'saga/categorySaga';
// vendor
import vendorConstants from 'redux/constants/vendorConstants';
import vendorSaga from 'saga/vendorSaga';
/**
 * Root saga manages watcher lifecycle
 */
export default function* defaultSaga() {
  // Watches for LOAD_REPOS actions and calls getRepos when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount

  // auth
  yield takeLatest(CHECK_AUTH, checkAuthentication);
  yield takeLatest(SIGN_OUT, signMeOut);
  yield takeLatest(GET_PROFILE, getProfile);
  yield takeLatest(UPDATE_PROFILE, updateProfile);
  yield takeLatest(GET_USER_PROVINCE_LIST, loadUserProvinceList);

  // logistic
  yield takeLatest(GET_DELIVERY_LOGISTIC_LIST, loadDeliveryLogisticList);
  yield takeLatest(GET_LOGISTIC_LIST, loadLogisticList);

  // location
  yield takeLatest(GET_COUNTRY_LIST, loadCountryList);
  yield takeLatest(GET_PROVINCE_LIST, loadProvinceList);
  yield takeLatest(GET_DISTRICT_LIST, loadDistrictList);
  yield takeLatest(GET_WARD_LIST, loadWardList);

  // fastOrder
  yield takeLatest(GET_PR_DRAFT, loadPRDraft);
  yield takeLatest(CREATE_PR_DRAFT, updatePRDraft);
  yield takeLatest(CHECK_PROMOTION, testPromotion);
  yield takeLatest(CREATE_PR, createPR);
  yield takeLatest(RATE_ORDERING, rateOrdering);
  yield takeLatest(GET_VENDOR_DELIVERY_FEE, loadVendorDeliveryFees);

  // pharmacy
  yield takeLatest(GET_PHARMACY_LIST, loadPharmacyList);
  yield takeLatest(GET_PHARMACY, loadPharmacy);

  // sales order
  yield takeLatest(GET_SALES_ORDER_STATUSES, loadSalesOrderStatuses);
  yield takeLatest(GET_SALES_ORDER_LIST, loadSalesOrderList);
  yield takeLatest(GET_SALES_ORDER, loadSalesOrder);
  yield takeLatest(CONFIRM_PURCHASING, confirmSalesOrderPurchasing);
  yield takeLatest(CONFIRM_SUCCESS, confirmSalesOrderSuccess);

  // product
  // yield takeLatest(GET_PRODUCT_DETAILS, loadProductDetails);
  yield takeLatest(GET_PRODUCT_LIST, loadProductList);
  yield takeLatest(FETCH_PRODUCT_LIST, fetchProductList);
  yield takeLatest(FETCH_PRODUCT_DETAILS, fetchProductDetails);
  yield takeLatest(FETCH_PRODUCT_DESCRIPTIONS, fetchProductDescriptions);
  yield takeLatest(FETCH_PRODUCT_RATINGS, fetchProductRatings);
  yield takeLatest(FETCH_PRODUCT_PRICE_WHOLESALES, fetchProductPriceWholesales);
  yield takeLatest(FETCH_PRODUCT_RATING_DETAIL, fetchProductRatingDetail);
  yield takeLatest(FETCH_PRODUCT_DETAILS_INFO, fetchProductDetailsInfo);
  yield takeLatest(FETCH_PRODUCT_RELATED_LIST, fetchProductRelatedList);
  yield debounce(500,DEBOUNCE_GET_PRODUCT_LIST, loadProductList);
  
  // yield takeLatest(GET_PRODUCT_BRANDS, loadBrands);
  // yield takeLatest(GET_PRODUCT_INDUSTRIES, loadIndustries);
  // yield takeLatest(GET_PRODUCT_MANUFACTURES, loadProductManufactures);

  // banner
  yield takeLatest(bannerConstants.LOAD_BANNERS, bannerSaga.loadBanners);

  // category
  yield takeLatest(categoryConstants.GET_CATEGORY_LIST, categorySaga.loadCategoryList);

  // vendor
  yield takeLatest(vendorConstants.GET_VENDOR_LIST, vendorSaga.loadVendorList);
  yield debounce(500,vendorConstants.DEBOUNCE_GET_VENDOR_LIST, vendorSaga.loadVendorList);
}
