import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectFastOrderNotes } from 'redux/selectors';
import { orderBy } from 'lodash';
import { withTranslation } from "react-i18next";
import { withRouter } from 'react-router-dom';
import FastOrderNoteItem from './FastOrderNoteItem/FastOrderNoteItem';
import FastOrderNoteNewForm from './FastOrderNoteNewForm/FastOrderNoteNewForm';

import classes from './FastOrderNotes.module.scss';

class FastOrderNotes extends PureComponent {
  _getOrderedFastOrderNotes = () => {
    return orderBy(this.props.prNotes, 'created_at', 'desc');
  }

  render() {
    const { t } = this.props;

    const orderedNotes = this._getOrderedFastOrderNotes();

    return (<section className={classes.Wrap}>

      <h3 className="SectionTitle">{t('Ghi chú')}</h3>

      <FastOrderNoteNewForm onUpdatePRDraft={this.props.onUpdatePRDraft} />
      { orderedNotes && orderedNotes.length > 0 ? (<>
          { this.props.prNotes.map((note,index) => (
            <FastOrderNoteItem key={`note-${index}`} noteItem={note}  onUpdatePRDraft={this.props.onUpdatePRDraft}  />
          )) }
        </>) : ''
      }
    </section>)
  }
}

FastOrderNotes.propTypes = {
  prNotes: PropTypes.array,
};

FastOrderNotes.defaultProps = {
  prNotes: [],
};

const mapStateToProps = createStructuredSelector({
  prNotes: makeSelectFastOrderNotes(),
});
const withConnect = connect(mapStateToProps, null);
export default withTranslation()(compose(withConnect, withRouter)(FastOrderNotes));
