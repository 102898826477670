// reducer
export const SET_STATE_VENDOR = 'getcare/vendor/SET_STATE_VENDOR';
export const SET_VENDOR_LIST_PARAMS = 'getcare/vendor/SET_VENDOR_LIST_PARAMS';

// Saga
export const GET_VENDOR_LIST = 'getcare/vendor/GET_VENDOR_LIST';
export const DEBOUNCE_GET_VENDOR_LIST = 'getcare/vendor/DEBOUNCE_GET_VENDOR_LIST';

export default {
  SET_VENDOR_LIST_PARAMS,
  SET_STATE_VENDOR,
  GET_VENDOR_LIST,
  DEBOUNCE_GET_VENDOR_LIST,
}