import React from 'react';
import { withStyles  } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';

const styles = (theme) => {
  return {
    root: (props) => {
      const types = {
        0: 'none',
        1: '0 1px 1px 0 rgba(0,0,0,.05)'
      }
      return {
        boxShadow: types[props.boxShadowType],
        padding: theme.spacing(props.spacing || 0),
        color: theme.palette.text.primary,
        '& .Paper-title': {
          marginBottom: theme.spacing(2)
        }
      }
    },
    header: {
      overflow: 'hidden',
      borderTopLeftRadius: theme.shape.borderRadius,
      borderTopRightRadius: theme.shape.borderRadius,
    },
    footer: {
      overflow: 'hidden',
      borderBottomLeftRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,
    }
  }
};

export default withStyles(styles)(
  React.forwardRef( ({ 
    className = '', 
    classes, 
    boxShadowType,  
    header,
    footer,
    children,
    spacing,
    ...other 
  }, ref) => {
    return <Paper classes={{ root: `${classes.root} ${className}`}} {...other}>
      {header && <Box className={`${classes.header} StyledPaper-header`}>
        {header}
      </Box>}
      {children}
      {footer && <Box className={`${classes.footer} StyledPaper-footer`}>
        {footer}
      </Box>}
    </Paper>
}));