import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from "react-i18next";
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { 
  makeSelectLoginUser 
} from 'redux/selectors';
import { 
  CONNECT_SUPPORT_PROJECT,
} from 'utils/constanst/connectSupportConstants';

import { withStyles  } from '@material-ui/core/styles';

import ImageGallery from 'react-image-gallery';
import ShareIcon from '@material-ui/icons/Share';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { 
  Link,
  Grid,
  Box,
  LinearProgress,
  Avatar,
  ButtonBase,
} from '@material-ui/core';
import StyledButton from 'components/Styled/Button/Button';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import Photo from 'components/Photo/Photo';

import moduleStyles from './ProjectItem.module.scss';
import '../CardList.scss';

const StyledLinearProgress = withStyles((theme) => ({
  root: {
    height: 9,
    borderRadius: 4,
    maxWidth: '66%',
  },
  colorPrimary: {
    backgroundColor: '#A0B9D2',
  },
  bar: {
    borderRadius: 4,
    backgroundColor: theme.palette.success.main,
  },
}))(LinearProgress);

class CardListProjectItem extends PureComponent {
  getLinearProgressValue = ({ value, max, min }) => {
    const MIN = min || 0;
    const result =  (value - MIN) * 100 / (max - MIN);
    return result > 100 ? 100 : result;
  }
  goLink = (e,url) => {
    e.preventDefault();
    // console.log(e.target.classList)
    if ( !e.target.classList.contains('image-gallery-nav-icon') 
      && !e.target.classList.contains('image-gallery-bullet')
      && !e.target.classList.contains('image-gallery-bullets-container')
    ) this.props.history.push(url);
  }
  stopGoToPage = (e) => {
    e.stopPropagation();
    e.preventDefault();
  }

  render() {
    const { user, t } = this.props;
    const imageGalleryConfig = {
      showBullets: this.props.images?.length && this.props.images?.length > 1,
      showNav: true,
      showPlayButton: false,
      showFullscreenButton: false,
      
      items: this.props.images?.length ? this.props.images.map( image => ({
        original: image,
        renderItem: (props) => {
          return <Photo className="image-gallery-image" src={props.original}/>
        }
      })) : [{
        renderItem: (props) => {
          return <Photo className="image-gallery-image"/>
        }
      }],
      renderLeftNav: (onClick, disabled) => (
        <ButtonBase className="image-gallery-nav-button image-gallery-nav-button-left" onClick={onClick} disabled={disabled}>
          <KeyboardArrowLeftIcon className="image-gallery-nav-icon"/>
        </ButtonBase>
      ),
      renderRightNav: (onClick, disabled) => (
        <ButtonBase className="image-gallery-nav-button image-gallery-nav-button-right" onClick={onClick} disabled={disabled}>
          <KeyboardArrowRightIcon className="image-gallery-nav-icon"/>
        </ButtonBase>
      )
    }
    
    return (
      <Link 
        className={moduleStyles.Wrap}
        onClick={(e) => this.goLink(e,`/community/connect-support/project/detail/${this.props.id}`)} 
        href={`/community/connect-support/project/detail/${this.props.id}`}
        underline="none"
        color="inherit"
      >
        <Grid container spacing={1}>
          <Grid item >
            <div className={`${moduleStyles.Image} custom-gallery`}>
              <ImageGallery 
                {...imageGalleryConfig}
              />
            </div>
          </Grid>
          <Grid item xs>
            <Box 
              height="100%"
              display="flex"
              flexDirection="column"
              className={moduleStyles.Content}
            >
              <Box flexGrow="1" display="flex">
                <Box flexGrow="1" position="relative" minHeight="107px">
                  <div className={moduleStyles.Info}>
                    <div className={moduleStyles.Info__Title}>
                      {this.props.name}
                    </div>
                    <div className={moduleStyles.Info__Description}>
                      <p><span>{t('Cần trước ngày')}:</span> {moment(this.props.deadline).format('DD/MM/YYYY')}</p>
                      <p><span>{t('Địa chỉ')}:</span> {this.props.address}</p>
                      <p><span>{t('Số lượng đóng góp')}:</span> <b>{this.props.quantity_number_donate}/{this.props.quantity_number}</b></p>
                    </div>
                    <StyledLinearProgress variant="determinate" value={this.getLinearProgressValue({ value: this.props.quantity_number_donate, max: this.props.quantity_number })}/>
                  </div>
                </Box>
                <Box className={moduleStyles.Controls} display="flex" flexDirection="column">
                  <ButtonBase onClick={(e) => {
                    this.stopGoToPage(e);
                    alert(t(`Tính năng này sẽ phát triển trong tương lại bạn nhé :)`,{ nsSeparator: '::' }))
                  }}>
                    <ShareIcon/>
                  </ButtonBase>
                  {
                    user?.id && 
                    <ButtonBase onClick={(e) => {
                      this.stopGoToPage(e);
                      this.props.onSelectItem('FAVOURITE',{ 
                        favourite: !this.props.favourite, 
                        name: CONNECT_SUPPORT_PROJECT,
                        request_key: 'object_id',
                        request_value_key: 'id',
                    })}}>
                      {
                        this.props.favourite ? <FavoriteIcon/> : <FavoriteBorderIcon/>
                      }
                    </ButtonBase>
                  }
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <div className={moduleStyles.Description}>
          {this.props.description}
        </div>
        <Box display="flex" flexDirection="column" style={{ gap: '.5rem' }}>
          <Box className={moduleStyles.Actions} display="flex" justifyContent="center">
            <StyledButton variant="contained" onClick={(e) => {
              this.stopGoToPage(e);
              alert(t(`Tính năng này sẽ phát triển trong tương lại bạn nhé :)`,{ nsSeparator: '::' }))
            }} color="secondary">
              {t('Nhắn tin')}
            </StyledButton>
            <StyledButton variant="contained" onClick={(e) => {
              this.stopGoToPage(e);
              this.props.onSelectItem('PROJECT_DONATE_NEW')
            }} color="primary">
              {t('Đóng góp')}
            </StyledButton>
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <Box className={moduleStyles.UserInfo} display="flex" gap={.5} alignItems="center" justifyContent="center">
              {t('interpolation.postDated',{
                defaultValue: `Đăng ngày {{date}} bởi {{name}}`, 
                date: moment(this.props.created_at).format('DD/MM/YYYY'),
                name: this.props.customer_name,
              })}
              <Avatar className={moduleStyles.UserInfo__avatar} alt={this.props.customer_name} src={this.props.customer_avatar}>
                {this.props.customer_name ? this.props.customer_name.charAt(0) : 'G'}
              </Avatar>
            </Box>
          </Box>
        </Box>
      </Link>
    );
  }
};

CardListProjectItem.propTypes = {
  className: PropTypes.string,
};

CardListProjectItem.defaultProps = {
  className: '',
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectLoginUser(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect, withRouter,withTranslation(['viewMainEcomConnectSupport']))(CardListProjectItem);
