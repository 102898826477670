import {
  SET_STATE_VENDOR,
  SET_VENDOR_LIST_PARAMS,
} from 'redux/constants/vendorConstants';

const initialState = {
  vendorList: [],
  vendorListParams: {
    page: 1,
    page_size: 10,
    ranking_gt: undefined,
    order: undefined,
    keyword: '',
    getcare_vendor_id: [],
  },
  loadingVendorList: false,
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_STATE_VENDOR: {
      return {
        ...state,
        ...payload,
      };
    }
    case SET_VENDOR_LIST_PARAMS: {
      return {
        ...state,
        vendorListParams: {
          ...state.vendorListParams,
          ...payload,
        },
      };
    }
    default:
      return state;
  }
}

export default reducer;
