import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectFastOrderSelectedPayment,
  makeSelectFastOrderDetails,
} from 'redux/selectors';
import {
  saveSelectedPayment,
} from 'redux/actions/logisticActions';
import { savePRDraftLogistic } from 'redux/actions/ecom/fastOrderActions';
import { withTranslation } from "react-i18next";
import * as QueryString from 'query-string';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import authApi from 'utils/api/authApi';
import classes from './Payment.module.scss';

class PaymentProvider extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: 1,
    };
  }
 async componentDidMount() {
    const { location } = this.props;
    const params = QueryString.parse(location.search);

    const { data: response } = await authApi.vnPayLink(params);
    if (!response?.data) return;
    const dataPayment = response.data;
    this.setState({
      selectedPayment: dataPayment,
      value: this.props.selectedPayment ? this.props.selectedPayment  : response.data.filter(x=>x.active === 1)[0]?.id
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const {selectedPayment} = this.props;
    if (selectedPayment !== prevProps.selectedPayment && selectedPayment !== undefined) {
      this.setState({
        value: selectedPayment
      });
    }
  }

  handleChange = (e) => {
    this.setState({
      value:parseInt(e.target.value),
    });
    this.props.saveSelectedPayment(parseInt(e.target.value));
    this.props.onUpdatePRDraft({
      prPayment: parseInt(e.target.value),
    });
  }
 
  render() {
    const { logisticListLoading, t ,prDetails} = this.props;
    const {
  
      value
    } = this.state;
  

    return (
      <section className={`${classes.Wrap} ${logisticListLoading ? 'OverlayLoading' : ''}`}>
       <h3 className="SectionTitle">{t('Phương thức thanh toán')}</h3>

        <RadioGroup aria-label="Payment" name="payment" value={value} onChange={e => this.handleChange(e)}   className={classes.SelectionGroup}>
        { 
         this.state.selectedPayment? 
         this.state.selectedPayment.map((item, i) => {
            
          return item.active ===  0 || ( prDetails?.amount_total === 0 && prDetails && item.id !== 1 && prDetails?.logistic_provider?.id !== null )?
            <FormControlLabel key={item.id} value={item.id}    control={
              <Radio
                size="small"
                style={{
                  padding: '0.08rem 0',
                  marginRight: '0.5rem',
                }}
              />
            }  disabled   className={classes.SelectionItem} label={
              <div  className={`${classes.Item} PreWrap`}>
             <p className={classes.ItemName}>{item.name}</p>
                                <p className={classes.ItemName}></p>
                                <p className={classes.TextExplained}>({t('Tính năng đang được phát triển')})</p>

              </div>
            } />
            : 
            <FormControlLabel key={item.id} value={item.id}    control={
              <Radio
                size="small"
                style={{
                  padding: '0.08rem 0',
                  marginRight: '0.5rem',
                }}
              />
            } className={classes.SelectionItem} label={
              <div  className={`${classes.Item} PreWrap`}>
             <p className={classes.ItemName}>{item.name}</p>
                                <p className={classes.ItemName}></p>
              </div>
            } />
        })


        :null
        }
        </RadioGroup>
      </section>
    );
  }
}

PaymentProvider.propTypes = {
  prLogistic: PropTypes.object,
};

PaymentProvider.defaultProps = {
  prLogistic: null,
};

const mapStateToProps = createStructuredSelector({
  selectedPayment: makeSelectFastOrderSelectedPayment(),
  prDetails: makeSelectFastOrderDetails(),

});
const mapDispatchToProps = (dispatch) => {
  return {
    savePRDraftLogistic: (payload) => dispatch(savePRDraftLogistic(payload)),
    saveSelectedPayment: (payload) => dispatch(saveSelectedPayment(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default withTranslation()(compose(withConnect)(PaymentProvider));
