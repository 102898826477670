import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { debounce } from 'lodash';
import { 
  getSalesOrderList, 
  saveSalesOrderListQueryString, 
  saveSalesOrderListOrderSelected,
} from 'redux/actions/oms/salesOrderActions';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectSalesOrderLoading,
  makeSelectSalesOrderList,
  makeSelectSalesOrderListTotal,
  makeSelectLoginUser,
  makeSelectSalesOrderListOrderSelected,
} from 'redux/selectors';
import { withTranslation } from "react-i18next";
import {
  listParamsMap,
  listDisplayFields,
  filterFields,
} from 'utils/constanst/omsSalesOrderConstants';
import { getSortsString, getDisplayFields, getQueryString, getQueryParams } from 'utils/helper';
import { withRouter, Link } from 'react-router-dom';
import { isRouteEnabled } from 'utils/constanst/authConstants';
import salesOrderApi from 'utils/api/oms/salesOrderApi';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import SalesOrderList from 'components/ecom/SalesOrderList/SalesOrderList';
import SalesOrderListFilters from 'components/ecom/SalesOrderList/SalesOrderListFilters/SalesOrderListFilters';
import ListPagination from 'components/ListPagination/ListPagination';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';

import Button from '@material-ui/core/Button';
import { toast } from 'react-toastify';

import classes from './SalesOrders.module.scss';

class SalesOrders extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      listParams: { ...listParamsMap },
      confirmDiglogOpen: false,
      confirmDiglogTitle: "",
      confirmDiglogMessage: "",
      isReloading: false
    };
  }
  componentDidMount() {
    this.props.saveSalesOrderListOrderSelected([]);
    this.initUrlSync();
  }
  componentDidUpdate(prevProps, prevState) {
    const { location } = this.props;
    if (location.search !== prevProps.location.search) {
      if (['', null, undefined].includes(location.search)) {
        this.initUrlSync();
        return;
      }
      this.reinitListData();
    }
  }

  initUrlSync = () => {
    const { location } = this.props;
    const initalQueryStringFromDefaultParams = getQueryString(listParamsMap);
    // check if default listParams is different from inital location search
    if (['', null, undefined].includes(location.search) && initalQueryStringFromDefaultParams !== location.search) {
      this.props.history.replace({ search: initalQueryStringFromDefaultParams});
      return;
    }
    this.reinitListData();
  }
  reinitListData = () => {
    const { location } = this.props;
    const newParams = getQueryParams(location.search);
    this.setState({
      listParams: newParams,
    });
    this.props.getSalesOrderList({
      params: newParams,
    });
    this.props.saveSalesOrderListQueryString(location.search);
  }
  synUrl = debounce((newParams) => {
    this.props.history.push({ search: getQueryString(newParams)});
  }, 500);

  handleSortChange = ({ sortBy, sortDir }) => {
    const newSortString = getSortsString({
      [sortBy]: sortDir,
    });
    this.handleFilterChange({ order: newSortString });
  };
  handleFilterChange = (filterParams, forceResetPage = false) => {
    const newParams = forceResetPage ? {
      ...this.state.listParams,
      ...filterParams,
      page: 1,
    } : {
      ...this.state.listParams,
      ...filterParams,
    };
    this.setState({
      listParams: newParams,
    }, () => {
      this.synUrl(newParams);
    });
  };
  handleReOrders = async () => {
    if ( this.props.orderSelected.length < 1 ) {
      toast.error("Vui lòng chọn ít nhất 1 đơn hàng để đặt lại đơn hàng");
      return
    }
    this.setState({
      isReloading: true
    })
    const { data: response } = await salesOrderApi.confirmOrCreateReorders({
      params: { ids: this.props.orderSelected  }
    });
    if ( !response.result && response.conflict ) {
      this.setState({
        confirmDiglogOpen: true,
        confirmDiglogTitle: "Thông báo",
        confirmDiglogMessage: response.message,
      })
    } else {
      const {t} = this.props;
      toast.success(t('Đã đặt lại đơn hàng thành công!'));
      this.props.saveSalesOrderListOrderSelected([]);
    }
    
    this.setState({
      isReloading: false
    })
  }

  handleFilterMine = () => {
    const newQueryParams = {
      ...this.state.listParams,
      role:'customer'
    }

      this.setState({
        listParams: newQueryParams,
      }, () => {
        this.synUrl(newQueryParams);
      });
  }

  handleFilterCustomer = () => {
    const newQueryParams = {
      ...this.state.listParams,
      role:'sales'
    }
      this.setState({
        listParams: newQueryParams,
      }, () => {
        this.synUrl(newQueryParams);
      });
  }


  handleReorders = async (type) => {
    this.setState({
      isReloading: false
    })
    const { data: response } = await salesOrderApi.confirmOrCreateReorders({
      params: { ids: this.props.orderSelected, force_type: type  }
    });
    this.props.saveSalesOrderListOrderSelected([]);
    this.setState({
      isReloading: false,
      confirmDiglogOpen: false
    })
    toast.success(`Đã ${ type === "OVERRIDE" ? "thay thế" : "gộp" } đơn hàng thành công!`)
  }

  render() {
    const { salesOrderList, salesOrderListTotal, loading ,t, user } = this.props;
    const { listParams } = this.state;
    const isListLoading = loading;

    return (
      <React.Fragment>
        <div className={classes.PageWrap}>
          { isRouteEnabled(this.props.user, 'customer') &&
            <Breadcrumbs
              separator={
                <NavigateNextIcon
                  className="BreadscrumbSeparator"
                  fontSize="small"
                />
              }
              className="Breadscrumbs"
              aria-label="breadcrumb"
            >
              <Link color="inherit" to="/customer">{t('Trang chủ')}</Link>
              <span className="BreadscrumbActive">{t('Danh Sách Đơn Hàng')}</span>
            </Breadcrumbs>
          }
          <div className={classes.PageHeader}>
            <h1 className="PageTitle">{t('Danh Sách Đơn Hàng')}</h1>
            <Button
              type="button"
              variant="contained"
              color="secondary"
              onClick={this.handleReOrders}
              disabled={this.props.orderSelected.length < 1 || this.state.isReloading }
              // className={`ExtraPrimaryBtn ${classes.RegisterBtn} ${this.getNavLinkClass(['/myprofile/gppregister'])}`}
            >
              { this.state.isReloading ? t('Đang thiết lập lại đơn hàng...') : t('Đặt lại đơn hàng') }
            </Button>
          </div>
          <div className={classes.ButtonGroup}>
          <ButtonGroup
        orientation="horizontal"
      >
         <Button  type="button"
              variant="contained"
              color="secondary"
              className={classes.ButtonMine}
              disabled={listParams.role === 'customer' ? true : false}
              onClick={this.handleFilterMine}>{t('Của tôi')}</Button>
              {
                user.getcare_sales_id  !== null ?
              <Button variant="contained"
              className={classes.ButtonMine}
               disabled={listParams.role === 'sales' ? true : false}
              color="primary" onClick={this.handleFilterCustomer}>{t('Của khách hàng')}</Button>
                : null
              }

      </ButtonGroup>
      </div>
          <div className={classes.PageMain}>
            <SalesOrderListFilters
              filterFields={filterFields}
              queryParamsMap={listParams}
              onFilterChange={this.handleFilterChange}
            />
            <SalesOrderList
              displayFields={getDisplayFields(
                listParams,
                listDisplayFields
              )}
              salesOrderList={salesOrderList}
              isLoading={isListLoading}
              onSortChange={this.handleSortChange}
            />
          </div>
          <div className={classes.PageFooter}>
            <ListPagination
              page={Number(listParams.page)}
              pageSize={Number(listParams.page_size)}
              total={salesOrderListTotal}
              listName="đơn hàng"
              onFilterChange={this.handleFilterChange}
            />
          </div>
        </div>
        <ConfirmationDialog
          isLoading={this.state.isReloading}
          isOpen={this.state.confirmDiglogOpen}
          title={this.state.confirmDiglogTitle}
          type="info"
          message={this.state.confirmDiglogMessage}
          dialogAction={
            <>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => this.handleReorders("OVERRIDE")}
              >
                {t('Thay thế')}
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => this.handleReorders("MERGE")}
              >
                {t('Gộp')}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={ () => this.setState({confirmDiglogOpen: false})}
              >
                {t('Hủy')}
              </Button>
            </>
          }
        />
      </React.Fragment>
    );
  }
}

SalesOrders.propTypes = {
  orderSelected: PropTypes.array,
}

SalesOrders.defaultProps = {
  orderSelected: [],
};

const mapStateToProps = createStructuredSelector({
  salesOrderList: makeSelectSalesOrderList(),
  salesOrderListTotal: makeSelectSalesOrderListTotal(),
  loading: makeSelectSalesOrderLoading(),
  user: makeSelectLoginUser(),
  orderSelected: makeSelectSalesOrderListOrderSelected(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getSalesOrderList: (payload) => dispatch(getSalesOrderList(payload)),
    saveSalesOrderListQueryString: (payload) => dispatch(saveSalesOrderListQueryString(payload)),
    saveSalesOrderListOrderSelected: (payload) => dispatch(saveSalesOrderListOrderSelected(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default withTranslation()(compose(withConnect,withRouter)(SalesOrders));
