import {
  SET_STATE,
} from 'redux/constants/bannerConstants';

const initialState = {
  banners: [],
  loadingBanners: false,
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  
  switch (type) {
    case SET_STATE: {
      return {
        ...state,
        ...payload,
      }
    }
    default:
      return state;
  }
}

export default reducer;
