import React, { PureComponent } from 'react';
import { currencyFormatVN } from 'utils/helper';
import { withTranslation } from "react-i18next";
import classes from './PromotionActive.module.scss';
import gridClasses from '../PromotionActivesGrid.module.scss';

class PromotionActive extends PureComponent {
  render() {
    const {
      product_bonus_name,
      quantity_number,
      amount,
      uom_base_name,
      t,
    } = this.props;

    return (
      <div className={`${gridClasses.Row} ${classes.Item}`}>
        <div className={`${gridClasses.Col}`}>
          { !quantity_number ? t('Chiết khấu')  : product_bonus_name }
        </div>
        <div className={`${gridClasses.Col}`}>
          { uom_base_name }
        </div>
        <div className={`${gridClasses.Col}`}>
          { !quantity_number ? '' : quantity_number }
        </div>
        <div className={`${gridClasses.Col}`}>
          { ['', null, undefined].includes(amount) ? `` : currencyFormatVN(amount) }
        </div>
      </div>
    );
  }
}

export default withTranslation()(PromotionActive);

