import React from 'react';
// Views
// import Products from 'views/MainEcom/Products/Products';
import ConnectSupport from 'views/MainEcom/ConnectSupport/ConnectSupport';
import ConnectSupportSupportNew from 'views/MainEcom/ConnectSupportSupportNew/ConnectSupportSupportNew';
import ConnectSupportDonateNew from 'views/MainEcom/ConnectSupportDonateNew/ConnectSupportDonateNew';
// import ConnectSupportDonateCharityRecipients from 'views/MainEcom/ConnectSupportDonateCharityRecipients/ConnectSupportDonateCharityRecipients';
import ConnectSupportProjectDetail from 'views/MainEcom/ConnectSupportProjectDetail/ConnectSupportProjectDetail';
import ConnectSupportProjectEdit from 'views/MainEcom/ConnectSupportProjectEdit/ConnectSupportProjectEdit';
import BusinessSolution from 'views/MainEcom/BusinessSolution';
// Layout
const LayoutMainEcom = React.lazy(() => import('layouts/LayoutMainEcom/LayoutMainEcom'));
// Products
const Products = React.lazy(() => import('views/MainEcom/Products') );
const ProductDetails = React.lazy(() => import('views/MainEcom/ProductDetails') );

export default [
  {
    path: '/',
    component: LayoutMainEcom,
    routes: [
      // {
      //   path: "/",
      //   exact: true,
      //   component: Productss,
      // },
      // {
      //   path: "/products",
      //   exact: true,
      //   component: Products,
      //   _name: 'Sản phẩm',
      // },
      {
        path: "/products",
        exact: true,
        component: Products,
        _name: 'Sản phẩm & Dịch vụ',
        // _containerDisableGutters: true,
      },
      {
        path: "/products/:product_id",
        exact: true,
        component: ProductDetails,
      },
      {
        path: "/community/connect-support",
        exact: true,
        component: ConnectSupport,
        _name: 'Kết nối hỗ trợ',
      },
      {
        path: "/community/connect-support/support/new",
        exact: true,
        component: ConnectSupportSupportNew,
        auth: true,
        _name: 'Thêm đề nghị hỗ trợ',
      },
      {
        path: "/community/connect-support/donate/new",
        exact: true,
        component: ConnectSupportDonateNew,
        auth: true,
        _name: 'Thêm đóng góp',
      },
      // {
      //   path: "/community/connect-support/donate/:donate_id/charity_recipients",
      //   exact: true,
      //   component: ConnectSupportDonateCharityRecipients,
      // },
      {
        path: "/community/connect-support/project/detail/:project_id",
        exact: true,
        component: ConnectSupportProjectDetail,
      },
      {
        path: "/community/connect-support/project/edit/:project_id",
        exact: true,
        component: ConnectSupportProjectEdit,
        auth: true,
      },
      // {
      //   path: "/business-solution",
      //   exact: true,
      //   component: BusinessSolution,
      //   _name: 'Giải pháp kinh doanh',
      // }
    ]
  }
]