import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { getDisplayFields, groupByVendorPrProducts, currencyFormatVN } from 'utils/helper';
import {
  isSplitOrderByVendor,
} from 'utils/constanst/common';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectSalesOrderProducts, makeSelectSalesOrderDiscounts, makeSelectSalesOrderLogistic, makeSelectDeliveryLogisticList, makeSelectSalesOrder } from 'redux/selectors';
import { withRouter } from 'react-router-dom';
import {
  soProductsParamsMap,
  soProductsDisplayFields,
} from 'utils/constanst/omsSalesOrderConstants';
import { withTranslation } from "react-i18next";
import StorefrontIcon from '@material-ui/icons/Storefront';
import SalesOrderProduct from './SalesOrderProduct/SalesOrderProduct';
import SalesOrderDiscount from './SalesOrderDiscount/SalesOrderDiscount';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';
import SalesOrderShippingFeeByVendor from './SalesOrderShippingFeeByVendor/SalesOrderShippingFeeByVendor';

import classes from './SalesOrderProducts.module.scss';
import gridClasses from './SalesOrderProductsGrid.module.scss';

class SalesOrderProducts extends PureComponent {
  _availableProducts = () => {
    return this.props.salesOrderProducts.filter(item => !item.willRemove);
  }

  _getSelectedProvider = (providerId) => {
    const { deliveryLogisticList } = this.props;
    if (!deliveryLogisticList || !providerId) return null;
    return deliveryLogisticList.find((item) => item.id === providerId);
  };


  render() {
    const { salesOrderStatusId, salesOrderLogistic, salesOrderDiscounts, salesOrder, t } = this.props;
    const displayFields = getDisplayFields(soProductsParamsMap, soProductsDisplayFields);
    const availableProducts = this._availableProducts();
    
    const logisticProviderType = this._getSelectedProvider(salesOrderLogistic?.id)?.type || null;
    const isGroupType = isSplitOrderByVendor(logisticProviderType);
    const vendorPrProducts = isGroupType && groupByVendorPrProducts(availableProducts);
    // const vendorPrProducts = groupByVendorPrProducts(availableProducts);
    return (
      <div className={`${classes.Wrap}`}>
        <h4 className={classes.SectionTitle}>{t('Danh sách hàng đặt')}</h4>
        {
          !isGroupType || !vendorPrProducts?.length || vendorPrProducts.length < 1 ? 
          <div className={`${classes.List}`}>
            <div className={`${gridClasses.Row} ${classes.Header}`}>
              {displayFields.map((item) => (
                <ListHeaderCol
                  key={item.name}
                  {...item}
                  className={`${gridClasses.Col} ${item.className}`}
                />
              ))}
              <ListHeaderCol label=""/>
            </div>
            <div className={`${classes.Body}`}>
              {availableProducts.length ? (<>
                { availableProducts.map((item, index) => (
                  <SalesOrderProduct
                    key={`pr-product-${item.id}`}
                    {...item}
                    index={index}
                    disabledRemoving={availableProducts.length === 1}
                    salesOrderStatusId={salesOrderStatusId}
                    onRemoveProduct={this.props.saveSalesOrderProductItem}
                  />
                )) }
                { salesOrderDiscounts.map((item, index) => (
                  <SalesOrderDiscount
                    key={`pr-discount-${item.id}`}
                    {...item}
                  />
                )) }
              </>) : (
                <p className="NoData">{t('Không có sản phẩm nào')}</p>
              )}
            </div>
          </div> :
          <>
            { 
              vendorPrProducts.map( gbvpp => {
                const { vendor_items } = salesOrder;
                let vendorItem = vendor_items && vendor_items.find( vi => vi.code === gbvpp.vendor_code );
                const amountVendorDeliveryFee = vendorItem?.amount_delivery || 0;
                return (
                  <div key={gbvpp.vendor_code} style={{ marginTop: "1rem" }}>
                    <div className={classes.HeaderVendor}> 
                      <div className={classes.HeaderVendorName}><StorefrontIcon/> {gbvpp.vendor_name}</div>
                      <div className={classes.HeaderVendorLabel}>{t('Tổng tiền')}</div>
                      <div className={classes.HeaderVendorAmountTotal}>{currencyFormatVN(gbvpp.amount_total + amountVendorDeliveryFee)}</div>
                    </div>
                    <div className={`${classes.List}`}>
                      <div className={`${gridClasses.Row} ${classes.Header}`}>
                        {displayFields.map((item) => (
                          <ListHeaderCol
                            key={item.name}
                            {...item}
                            className={`${gridClasses.Col} ${item.className}`}
                          />
                        ))}
                        <ListHeaderCol label=""/>
                      </div>
                      <div className={`${classes.Body}`}>
                        {gbvpp.prProducts.length ? (<>
                          { gbvpp.prProducts.map((item, index) => (
                            <SalesOrderProduct
                              key={`pr-product-${item.id}`}
                              {...item}
                              index={index}
                              disabledRemoving={availableProducts.length === 1}
                              salesOrderStatusId={salesOrderStatusId}
                              onRemoveProduct={this.props.saveSalesOrderProductItem}
                            />
                          )) }
                          <SalesOrderShippingFeeByVendor
                            amount={amountVendorDeliveryFee}
                          />
                        </>) : (
                          <p className="NoData">{t('Không có sản phẩm nào')}</p>
                        )}
                      </div>
                    </div>
                  </div>
                )
              })
            }
            { salesOrderDiscounts.length > 0 &&
              <>
                <div style={{ marginTop: "1rem" }}>
                  <div className={classes.HeaderVendor}> 
                    <div className={classes.HeaderVendorName}>{t('Khuyến mãi')}</div>
                  </div>
                  <div className={`${classes.List}`}>
                    <div className={`${classes.DiscountBox}`}>
                      { salesOrderDiscounts.map((item, index) => (
                        <SalesOrderDiscount
                          key={`pr-discount-${item.id}`}
                          {...item}
                        />
                      )) }
                    </div>
                  </div>
                </div>
              </>
            }
          </>
        }
      </div>
    );
  }
}

SalesOrderProducts.propTypes = {
  salesOrderProducts: PropTypes.array,
  salesOrderLogistic: PropTypes.object,
  salesOrderDiscounts: PropTypes.array,
  salesOrderStatusId: PropTypes.number,
  onCancelSalesOrder: PropTypes.func,
};

SalesOrderProducts.defaultProps = {
  salesOrderProducts: [],
  salesOrderDiscounts: [],
  salesOrderLogistic: null,
};

const mapStateToProps = createStructuredSelector({
  salesOrder: makeSelectSalesOrder(),
  deliveryLogisticList: makeSelectDeliveryLogisticList(),
  salesOrderLogistic: makeSelectSalesOrderLogistic(),
  salesOrderProducts: makeSelectSalesOrderProducts(),
  salesOrderDiscounts: makeSelectSalesOrderDiscounts(),
});
const withConnect = connect(mapStateToProps, null);
export default withTranslation()(compose(withConnect,withRouter)(SalesOrderProducts));
