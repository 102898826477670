import {
  SET_STATE_FAST_ORDER,

  SAVE_SELECTED_CUSTOMER_CODE,
  SAVE_LOADING,
  SAVE_ACTION_LOADING,
  SAVE_PR_DRAFT_DETAILS,
  SAVE_PR_DRAFT_PRODUCTS,
  SAVE_PR_DRAFT_PRODUCT_ITEM,
  REMOVE_PR_DRAFT_PRODUCT_ITEMS,
  SAVE_PR_DRAFT_BONUSES,
  SAVE_PR_DRAFT_DISCOUNTS,
  SAVE_PR_DRAFT_NOTES,
  SAVE_PR_DRAFT_NOTE_ITEM,
  REMOVE_PR_DRAFT_NOTE_ITEM,
  SAVE_PR_DRAFT_LOGISTIC,
  SAVE_PR_DRAFT_DELIVERY,
  SAVE_PROMOTION_AVAILABLES,
  SAVE_PROMOTION_ACTIVES,
  SAVE_PROMOTION_INACTIVES,
  ADD_PROMOTION_INACTIVE_ITEMS,
  REMOVE_PROMOTION_INACTIVE_ITEMS,
  SAVE_USED_PROMOTIONS,
  SAVE_USED_PROMOTION_ITEM,
  REMOVE_USED_PROMOTION_ITEMS,
} from 'redux/constants/ecom/fastOrderConstants';
import { genID } from 'utils/helper';
import { uniqBy } from 'lodash';

const initialState = {
  selectedCustomerCode: undefined,

  loading: false,
  actionLoading: false,

  prDetails: undefined,

  prProducts: [],

  prBonuses: [],
  prDiscounts: [],

  prNotes: [],

  prLogistic: null,

  prDelivery: null,

  promotionAvailables: null,
  promotionActives: [],
  promotionInactives: [],
  usedPromotions: [],

  loadingFirstPRDraft: true,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_STATE_FAST_ORDER: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case SAVE_SELECTED_CUSTOMER_CODE: {
      return {
        ...state,
        selectedCustomerCode: action.selectedCustomerCode,
      };
    }

    case SAVE_LOADING: {
      return {
        ...state,
        loading: action.loading,
      };
    }
    case SAVE_ACTION_LOADING: {
      return {
        ...state,
        actionLoading: action.actionLoading,
      };
    }

    case SAVE_PR_DRAFT_DETAILS: {
      return {
        ...state,
        prDetails: action.prDetails,
      };
    }

    case SAVE_PR_DRAFT_PRODUCTS: {
      const newList = action.prProducts.map((item) => ({
        ...item,
        idStr: genID(),
      }));
      return {
        ...state,
        prProducts: newList,
      };
    }
    case SAVE_PR_DRAFT_PRODUCT_ITEM: {
      const prProducts = [...state.prProducts];
      const {
        _action,
        ...prProductItem
      } = action.prProductItem;
      const indexKey = action.prProductItem.idStr ? 'idStr' : null;
      let prProductsIndex = -1;

      if ( indexKey ) {
       prProductsIndex = prProducts.findIndex((item) => item[indexKey] === prProductItem[indexKey]);
      } else {
        prProductsIndex = prProducts.findIndex( item => (
          item.getcare_product_id === prProductItem.getcare_product_id &&
          item.getcare_vendor_id === prProductItem.getcare_vendor_id &&
          item.getcare_uom_base_id === prProductItem.getcare_uom_base_id  
        ))
      }
      if (prProductItem?.getcare_vendor) {
        if ( prProductsIndex > -1 ) {
          const tempItem = { ...prProducts[prProductsIndex] };
          if ( _action === "ADD_ON" ) {
            prProducts[prProductsIndex] = { ...tempItem, quantity_number: Number(tempItem.quantity_number) + Number(prProductItem.quantity_number) };
          } else prProducts[prProductsIndex] = { ...tempItem, ...prProductItem,vendor_name: prProductItem.getcare_vendor.name};
        } else {
          prProducts.push({ ...prProductItem,vendor_name:prProductItem.getcare_vendor.name, })
        }
      }else{
        if ( prProductsIndex > -1 ) {
          const tempItem = { ...prProducts[prProductsIndex] };
          if ( _action === "ADD_ON" ) {
            prProducts[prProductsIndex] = { ...tempItem, quantity_number: Number(tempItem.quantity_number) + Number(prProductItem.quantity_number) };
          } else prProducts[prProductsIndex] = { ...tempItem, ...prProductItem};
        } else {
          prProducts.push({ ...prProductItem})
        }
      }
      // const list = [...state.prProducts];
      // const comparedField = action.prProductItem.idStr ? 'idStr' : 'id';
      // const index = list.findIndex((p) => p[comparedField] === action.prProductItem[comparedField]);
      // if (index > -1) {
      //   const tempItem = { ...list[index] };
      //   list[index] = { ...tempItem, ...action.prProductItem };
      // } else {
      //   list.push({ ...action.prProductItem });
      // }
      return {
        ...state,
        prProducts,
      };
    }
    case REMOVE_PR_DRAFT_PRODUCT_ITEMS: {
      if (action.prProductItems.filter(x => x.getcare_product_id).length > 0){
        const dataFilter = action.prProductItems.map(x => x.getcare_product_id);
        const filter = state.prProducts.filter(ar => !dataFilter.find(rm => (rm === ar.getcare_product_id) ))
        return {
          ...state,
          prProducts: filter,
        };
      }else{
        const list = state.prProducts.filter(
          (p) =>
            !action.prProductItems.some((item) => {
              const comparedField = item.idStr ? 'idStr' : 'id';
              return item[comparedField] === p[comparedField];
            })
        );
        return {
          ...state,
          prProducts: list,
        };
      }  
    }

    case SAVE_PR_DRAFT_BONUSES: {
      return {
        ...state,
        prBonuses: action.prBonuses,
      };
    }
    case SAVE_PR_DRAFT_DISCOUNTS: {
      return {
        ...state,
        prDiscounts: action.prDiscounts,
      };
    }

    case SAVE_PR_DRAFT_NOTES: {
      return {
        ...state,
        prNotes: action.prNotes,
      };
    }
    case SAVE_PR_DRAFT_NOTE_ITEM: {
      const list = [...state.prNotes];
      const comparedField = action.prNoteItem.idStr ? 'idStr' : 'id';
      const index = list.findIndex((noteItem) => noteItem[comparedField] === action.prNoteItem[comparedField]);
      if (index > -1) {
        const tempItem = { ...list[index] };
        list[index] = { ...tempItem, ...action.prNoteItem };
      } else {
        list.push({ ...action.prNoteItem });
      }
      return {
        ...state,
        prNotes: list,
      };
    }
    case REMOVE_PR_DRAFT_NOTE_ITEM: {
      const list = state.prNotes.filter((noteItem) => noteItem.note !== action.prNoteItem.note);
      return {
        ...state,
        prNotes: list,
      };
    }

    case SAVE_PR_DRAFT_LOGISTIC: {
      return {
        ...state,
        prLogistic: action.prLogistic,
      };
    }

    case SAVE_PR_DRAFT_DELIVERY: {
      return {
        ...state,
        prDelivery: action.prDelivery,
      };
    }

    case SAVE_PROMOTION_AVAILABLES: {
      const list = action.promotionAvailables;
      if (list === 2) {
        return {
          ...state,
          promotionAvailables: list ? list : null,
        };
      }else{
        return {
          ...state,
          promotionAvailables: list ? [...list] : null,
        };
      }
    }
    case SAVE_PROMOTION_ACTIVES: {
      const list = action.promotionActives;
      return {
        ...state,
        promotionActives: list ? [...list] : [],
      };
    }
    case SAVE_PROMOTION_INACTIVES: {
      const list = action.promotionInactives;
      return {
        ...state,
        promotionInactives: list ? [...list] : [],
      };
    }
    case ADD_PROMOTION_INACTIVE_ITEMS: {
      const list = [
        ...state.promotionInactives,
        ...action.promotionInactiveItems,
      ]
      return {
        ...state,
        promotionInactives: list ? uniqBy(list, `id`) : [],
      };
    }
    case REMOVE_PROMOTION_INACTIVE_ITEMS: {
      const list = state.promotionInactives.filter((p) => {
        return !action.promotionInactiveItems.some((item) => {
          const comparedField = item.idStr ? 'idStr' : 'id';
          return p[comparedField] === item[comparedField];
        });
      });
      return {
        ...state,
        promotionInactives: list,
      };
    }
    case SAVE_USED_PROMOTIONS: {
      const list = action.usedPromotions;
      return {
        ...state,
        usedPromotions: list ? [...list] : [],
      };
    }
    case SAVE_USED_PROMOTION_ITEM: {
      const list = [...state.usedPromotions];
      const comparedField = action.usedPromotionItem.idStr ? 'idStr' : 'id';
      const index = list.findIndex((p) => p[comparedField] === action.usedPromotionItem[comparedField]);
      if (index > -1) {
        const tempItem = { ...list[index] };
        list[index] = { ...tempItem, ...action.usedPromotionItem };
      } else {
        list.push({ ...action.usedPromotionItem });
      }
      return {
        ...state,
        usedPromotions: list,
      };
    }
    case REMOVE_USED_PROMOTION_ITEMS: {
      const list = state.usedPromotions.filter((p) => {
        return !action.usedPromotionItems.some((item) => {
          const comparedField = item.idStr ? 'idStr' : 'id';
          return p[comparedField] === item[comparedField];
        });
      });
      return {
        ...state,
        usedPromotions: list,
      };
    }

    default:
      return state;
  }
};

export default reducer;
