import React from 'react';
import PropTypes from 'prop-types';

import MaskedInput from 'react-text-mask';
import {
  SvgIcon,
  Typography,
  Popper,
  Grow,
  Paper,
  MenuList,
  MenuItem,
  ButtonBase,
  InputAdornment,
  withStyles,
} from '@material-ui/core'
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import CheckIcon from '@material-ui/icons/Check';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { ReactComponent as ViFlagIcon } from 'assets/images/icons/ViFlagIcon.svg'
import StyledBox from 'components/Styled/Box/Box';
import StyledTextField from 'components/Styled/TextField/TextField';

import styles from './phoneInputStyles'

const PhoneMask = (props) => {
  const { inputRef, name, value, onChange, ...otherProps } = props;
  const phoneNumberMask = [/\d/, /\d/, ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
  const [phoneNumberValue,setPhoneNumberValue] = React.useState(value);
  React.useEffect(() => {
    if ( value !== phoneNumberValue.replace(/ |-/g,'') ) {
      setPhoneNumberValue(value)
    }
  },[value])
  return (
    <MaskedInput
      {...otherProps}
      value={phoneNumberValue}
      onChange={(e) => {
        onChange({
          target: {
            value: e.target.value.replace(/ |-/g,''),
            name: name,
          }
        })
        setPhoneNumberValue(e.target.value)
      }}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={(value) => {
        let newPhoneNumberMask = [...phoneNumberMask];
        if ( String(value.charAt(0)) === '0' )
          newPhoneNumberMask = [/\d/, ...newPhoneNumberMask];
        return newPhoneNumberMask
      }}
      placeholderChar={'\u2000'}
      showMask
      guide={false}
    />
  );
}

const PhoneInput = ({ 
  value,
  classes,
  className, 
  InputProps,
  onChange,
  onPhoneCodeChange,
  ...otherProps 
}) => {
  const phoneCodeList = [{
    Icon: ViFlagIcon,
    label: "Vietnam",
    phoneCode: "+84",
    viewBox: "0 0 30 20"
  }]
  const [openPhoneCodeList,setOpenPhoneCodeList] = React.useState(false);
  const [phoneCodeSelected,setPhoneCodeSelected] = React.useState(phoneCodeList[0]);
  const textFieldRef = React.useRef(null);
  const anchorRef = React.useRef(null);

  React.useEffect(() => {
    const inputBaseEl = textFieldRef.current.querySelector(".MuiInputBase-root");
    anchorRef.current = inputBaseEl;
  },[])
  React.useEffect(() => {
    if ( value ) {
      const phoneCode = phoneCodeList.find( item => item.phoneCode === value.code );
      if ( phoneCode ) setPhoneCodeSelected(phoneCode);
    }
  },[value])

  const handlePhoneCodeListToggle = () => {
    setOpenPhoneCodeList((prevOpenPhoneCodeList) => !prevOpenPhoneCodeList);
  }

  const handlePhoneNumberChange = (e) => {
    onChange && onChange(e,{
      code: value?.code || phoneCodeSelected.phoneCode,
      number: e.target.value,
    },"change_phone_number")
  }

  const handlePhoneCodeSelect = (code) => {
    onChange && onChange(null,{
      code,
      number: value?.number
    },"change_phone_code")
    handlePhoneCodeListClose()
  }

  const handlePhoneCodeListClose = () => {
    setOpenPhoneCodeList(false);
  }

  const fixedWidth = (data) => {
    const newData = {...data};
    const clientWidth = textFieldRef.current?.clientWidth;

    if ( clientWidth ) {
      newData.styles.width = clientWidth;
    } 
    return newData
  }

  return (<>
    <StyledTextField 
      ref={textFieldRef}
      className={`${classes.root} ${className}`}
      {...otherProps}
      value={value?.number}
      onChange={handlePhoneNumberChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" >
            <ButtonBase 
              onClick={handlePhoneCodeListToggle} 
              className={classes.btnPhoneCode}
            >
              <SvgIcon component={phoneCodeSelected.Icon} viewBox={phoneCodeSelected.viewBox} />
              <ArrowDropDownIcon className={classes.arrowIcon} style={{ transform: `rotate(${openPhoneCodeList ? '180' : '0'}deg)` }}/>  
              ({phoneCodeSelected.phoneCode})
            </ButtonBase>
          </InputAdornment>
        ),
        inputComponent: PhoneMask,
      }}
    />
    <Popper 
      open={openPhoneCodeList} 
      anchorEl={anchorRef.current} 
      disablePortal={true}
      transition 
      disablePortal
      placement="bottom-start"
      className={classes.dropdownPhoneCode}
      modifiers={{
        flip: {
          enabled: true,
        },
        preventOverflow: {
          enabled: true,
          boundariesElement: 'scrollParent',
        },
        offset: {
          offset: '0 4'
        },
        fixedWidth: {
          enabled: true,
          fn: fixedWidth,
          order: 840,
        },
      }}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{ transformOrigin: placement }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handlePhoneCodeListClose}>
              <MenuList id="menu-list-grow">
                {
                  phoneCodeList.map( (item,index) => (
                    <MenuItem className={`${classes.phoneCodeItem} ${phoneCodeSelected.phoneCode === item.phoneCode && classes.phoneCodeItemActive}`} key={index} onClick={() => handlePhoneCodeSelect(item.phoneCode)}>
                      <item.Icon className={classes.flagIcon}/>
                      <Typography className={classes.phoneCodeItemLabel}>{item.label}</Typography>
                      <Typography className={classes.phoneCodeItemCode} variant="body2">({item.phoneCode})</Typography>
                      <StyledBox flexGrow={1}/>
                      <CheckIcon className={classes.checkIcon}/>
                    </MenuItem>
                  ))
                }
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  </>)
}

PhoneInput.propTypes = {
  className: PropTypes.string,
  value: PropTypes.object,
};
PhoneInput.defaultProps = {
  className: '',
};

export default withStyles(styles)(PhoneInput);