import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { withTranslation } from "react-i18next";

import classes from './Tabs.module.scss';

class ConnectSupportTabs extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [{
        id: 0,
        label: 'Kết nối hỗ trợ'
      },{
        id: 1,
        label: 'Diễn đàn'
      },{
        id: 2,
        label: 'Bảng tin'
      }]
    }
  }

  render() {
    const { className, tabActive, t } = this.props;
    const { tabs } = this.state;

    return (
      <div className={`${classes.Tabs} ${className}`}>
        {
          tabs.map( tab => {
            return (
              <div key={tab.id} className={`${classes.Tabs__tab} ${tabActive === tab.id && classes['Tabs__tab--active']}`}>{t(tab.label)}</div>
            )
          })
        }
      </div>
    );
  }
};

ConnectSupportTabs.propTypes = {
  className: PropTypes.string,
  tabActive: PropTypes.number,
};

ConnectSupportTabs.defaultProps = {
  className: '',
  tabActive: 0,
};

export default compose(withRouter, withTranslation(['viewMainEcomConnectSupport']))(ConnectSupportTabs);
