import {
  SAVE_CONNECT_SUPPORT_DONATE,
  SAVE_SAVING_CONNECT_SUPPORT_DONATE,
  SAVE_SAVING_OPEN_PRODUCT_INFO_FORM_INPUT,
} from 'redux/constants/connectSupportDonateConstants';

const initialState = {
  connectSupportDonate: {},
  savingConnectSupportDonate: false,
  openProductInfoFormInput: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_CONNECT_SUPPORT_DONATE: {
      return {
        ...state,
        connectSupportDonate: { ...action.payload },
      }
    }
    case SAVE_SAVING_CONNECT_SUPPORT_DONATE: {
      return {
        ...state,
        savingConnectSupportDonate: action.payload,
      }
    }
    case SAVE_SAVING_OPEN_PRODUCT_INFO_FORM_INPUT: {
      return {
        ...state,
        openProductInfoFormInput: action.payload,
      }
    }
    default:
      return state;
  }
}

export default reducer;
