import {
  SAVE_CONNECT_SUPPORT_DONATE,
  SAVE_SAVING_CONNECT_SUPPORT_DONATE,
  SAVE_SAVING_OPEN_PRODUCT_INFO_FORM_INPUT,
} from 'redux/constants/connectSupportDonateConstants';

export function saveConnectSupportDonate(payload) {
  return {
    type: SAVE_CONNECT_SUPPORT_DONATE,
    payload
  };
}

export function saveSavingConnectSupportDonate(payload) {
  return {
    type: SAVE_SAVING_CONNECT_SUPPORT_DONATE,
    payload
  };
}

export function saveOpenProductInfoFormInput(payload) {
  return {
    type: SAVE_SAVING_OPEN_PRODUCT_INFO_FORM_INPUT,
    payload
  };
}

