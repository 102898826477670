export const CONNECT_SUPPORT_PRODUCT = 'product';
export const CONNECT_SUPPORT_DONATE_PRODUCT = 'donate_product';
export const CONNECT_SUPPORT_PROJECT = 'project';
export const CONNECT_SUPPORT_MAP = 'map';

export const stateDisplayOptions = [{
  label: 'Dự án',
  value: [{
    pageTitle: 'Danh sách dự án',
    btnGoToPageLabel: 'Xem danh sách dự án',
    value: CONNECT_SUPPORT_PROJECT,
    orders: [{
      label: 'Thứ tự A-Z',
      value: 'name ASC'
    },{
      label: 'Thứ tự Z-A',
      value: 'name DESC'
    },{
      label: 'Số lượng tăng dần',
      value: 'total_quantity_number ASC'
    },{
      label: 'Số lượng giảm dần',
      value: 'total_quantity_number DESC'
    }]
  }],
},{
  label: 'Sản phẩm',
  value: [{
    pageTitle: 'Danh sách cộng đồng cần hỗ trợ y tế',
    btnGoToPageLabel: 'Xem danh sách cần hỗ trợ',
    value: CONNECT_SUPPORT_PRODUCT,
    orders: [{
      label: 'Thứ tự A-Z',
      value: 'product_name ASC'
    },{
      label: 'Thứ tự Z-A',
      value: 'product_name DESC'
    },{
      label: 'Số lượng tăng dần',
      value: 'total_quantity_number ASC'
    },{
      label: 'Số lượng giảm dần',
      value: 'total_quantity_number DESC'
    }]
  },{
    pageTitle: 'Danh sách cộng đồng đóng góp',
    btnGoToPageLabel: 'Xem danh sách đóng góp',
    value: CONNECT_SUPPORT_DONATE_PRODUCT,
    orders: [{
      label: 'Thứ tự A-Z',
      value: 'product_name ASC'
    },{
      label: 'Thứ tự Z-A',
      value: 'product_name DESC'
    },{
      label: 'Số lượng tăng dần',
      value: 'quantity_number ASC'
    },{
      label: 'Số lượng giảm dần',
      value: 'quantity_number DESC'
    }]
  }]
},{
  label: 'Bản đồ',
  value: [{
    pageTitle: 'Bản đồ',
    btnGoToPageLabel: 'Xem bản đồ',
    value: CONNECT_SUPPORT_MAP,
  }]
},]
