import React, { PureComponent } from 'react';

import { Formik } from 'formik';

import { genID } from 'utils/helper';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectLoginUser, makeSelectFastOrderNotes,makeSelectFastOrderLogistic} from 'redux/selectors';
import { savePRDraftNoteItem } from 'redux/actions/ecom/fastOrderActions';
import { withTranslation } from "react-i18next";
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import classes from './FastOrderNoteNewForm.module.scss';

class FastOrderNoteNewForm extends PureComponent {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
  }

  handleSubmit = async (values) => {
    if (!values.note || values.note.trim() === '') return;

    const { user} = this.props;
    await this.props.savePRDraftNoteItem({
      idStr: genID(),
      user_name: user?.name,
      created_at: (new Date()).toISOString(),
      note: values.note,
    });
    

    await this.formRef.current.resetForm();

    await  this.props.onUpdatePRDraft({
      prLogisticData: {
        ...this.props.prLogistic,
        ...this.props.prNotes,
      },
      willUpdatePRDetails: true,
    });
  };
  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      this.formRef.current.submitForm();
    }
  }

  render() {
    const { t } = this.props;
    return (
      <Formik
        innerRef={this.formRef}
        initialValues={{
          note: '',
        }}
        validateOnChange={false}
        validateOnBlur={true}
        onSubmit={(values) => {
          this.handleSubmit(values);
        }}
      >
        {(props) => {
          const {
            values,
            errors,
            handleSubmit,
            setFieldValue,
          } = props;
          return (
            <form noValidate autoComplete="off">
              <div className={`${classes.FieldControlWrap}`}>
                <TextField
                  variant="outlined"
                  className={classes.Field}
                  autoComplete="off"
                  value={values.note}
                  placeholder={t('Nhập ghi chú')}
                  name="note"
                  size="small"
                  error={!!errors.note}
                  helperText={errors.note}
                  onKeyPress={this.handleKeyPress}
                  onChange={(e) => {
                    setFieldValue(e.target.name, e.target.value.slice(0, 255));
                  }}
                />
                <div className={classes.Actions}>
                  <Button
                    type="button"
                    variant="outlined"
                    onClick={handleSubmit}
                  >{t('Lưu')}</Button>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  user: makeSelectLoginUser(),
  prLogistic: makeSelectFastOrderLogistic(),
  prNotes: makeSelectFastOrderNotes(),

});
const mapDispatchToProps = (dispatch) => {
  return {
    savePRDraftNoteItem: (payload) => dispatch(savePRDraftNoteItem(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default withTranslation()(compose(withConnect, withRouter)(FastOrderNoteNewForm));
