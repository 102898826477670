import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from "react-i18next";

import { 
  makeSelectLoginUser 
} from 'redux/selectors';
import { numberFormat } from 'utils/helper';

import { 
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
} from '@material-ui/core';
import StyledCheckbox from 'components/Styled/Checkbox/Checkbox';
import StyledButton from 'components/Styled/Button/Button';
import { ReactComponent as EmptyIcon } from 'assets/images/icons/Empty.svg';

import stylesLayoutMainEcom from 'assets/styles/layouts/LayoutMainEcom.module.scss';

class TabSupport extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedItems: []
    }
  }

  isSelectedItem = (item) => {
    return this.state.selectedItems.some( id => id === item.item_id );
  }
  serviceRequestItemCanDonates = () => {
    const service_request_items = this.props.values?.service_request_items || [];
    return service_request_items.filter( item => item.quantity_number_donate > 0);
  }
  canCreateCharityOrders = () => {
    const serviceRequestItemCanDonates = this.serviceRequestItemCanDonates();
    return serviceRequestItemCanDonates.length > 0 && this.props.user.getcare_customer?.code === this.props.values?.customer_code;
  }

  handleSelectItem = (item) => {
    this.setState((state) => {
      if ( state.selectedItems.some( id => id === item.item_id ) ) return {
        selectedItems: [...state.selectedItems.filter( id => id !== item.item_id )]
      }
      return { selectedItems: [...state.selectedItems].concat([item.item_id]) }
    })
  }
  handleSelectAllItem = () => {
    const serviceRequestItem = this.serviceRequestItemCanDonates();
    this.setState((state) => {
      if ( state.selectedItems.length !==  serviceRequestItem.length ) return {
        selectedItems: [...serviceRequestItem.map(item => item.item_id )]
      }
      return { selectedItems: [] }
    })
  }

  render() {
    const { t } = this.props;
    const service_request_items = this.props.values?.service_request_items || [];
    const {
      getcare_service_request_status
    } = this.props.values || {};
    const serviceRequestItemCanDonates = this.serviceRequestItemCanDonates();
    const canCreateCharityOrders = this.canCreateCharityOrders();
    
    return (
      <div>
        <TableContainer className={`${stylesLayoutMainEcom.TableContainer} ${stylesLayoutMainEcom.TableStriped}`}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {
                  canCreateCharityOrders &&
                  <TableCell padding="checkbox">
                    <StyledCheckbox
                      indeterminate={this.state.selectedItems.length > 0 && this.state.selectedItems.length < serviceRequestItemCanDonates.length}
                      checked={this.state.selectedItems.length > 0 && serviceRequestItemCanDonates.length === this.state.selectedItems.length}
                      onChange={this.handleSelectAllItem}
                    />
                  </TableCell>
                }
                <TableCell>{t('STT')}</TableCell>
                <TableCell>{t('Tên sản phẩm')}</TableCell>
                <TableCell>{t('Danh mục')}</TableCell>
                <TableCell>{t('Đơn vị')}</TableCell>
                <TableCell>{t('Số lượng cần')}</TableCell>
                <TableCell align="center">{t('Số lượng nhận')}</TableCell>
              </TableRow> 
            </TableHead>
            <TableBody>
              {
                service_request_items.length > 0 ? 
                service_request_items.map( (item,index) => {
                  return (
                    <TableRow key={index}>
                      {
                        canCreateCharityOrders && 
                        <TableCell padding="checkbox">
                          {
                            item.quantity_number_donate > 0 &&
                            <StyledCheckbox
                              checked={this.isSelectedItem(item)}
                              onChange={() => this.handleSelectItem(item)}
                            />
                          }
                        </TableCell>
                      }
                      <TableCell>
                        {index + 1}
                      </TableCell>
                      <TableCell>
                        {item.name}
                      </TableCell>
                      <TableCell>
                        {item.category_ecom_name}
                      </TableCell>
                      <TableCell>
                        {item.uom_base_name}
                      </TableCell>
                      <TableCell>
                        {numberFormat(item.quantity_number)}
                      </TableCell>
                      <TableCell align="center">
                        {numberFormat(item.quantity_number_donate)}
                      </TableCell>
                    </TableRow>
                  )
                }) :
                <TableRow>
                  <TableCell align="center" className={stylesLayoutMainEcom.TableCellEmpty} colSpan="6">
                    <EmptyIcon className={stylesLayoutMainEcom.TableCellEmpty__icon}/>
                    <p>{t('Chưa có sản phẩm nào')}</p>
                  </TableCell>
                </TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>
        {
          (canCreateCharityOrders && getcare_service_request_status?.id !== 2 ) && 
          <Box display="flex" justifyContent="center" marginTop="1rem">
            <StyledButton disabled={this.state.selectedItems.length === 0} variant="contained" onClick={() => this.state.selectedItems.length > 0 && this.props.onSubmitCharityOrders(this.state.selectedItems)} color="primary" style={{ fontSize: '24px'}}>
              {t('Đã nhận đủ')}
            </StyledButton>
          </Box>
        }
      </div>
    );
  }
};

TabSupport.propTypes = {
  values: PropTypes.object,
};

TabSupport.defaultProps = {
  values: {
    service_request_items: [],
  },
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectLoginUser(),
});

const withConnect = connect(mapStateToProps);
export default compose(withConnect, withTranslation(['viewMainEcomConnectSupportProjectDetail']))(TabSupport);
