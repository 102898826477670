export const GET_SALES_ORDER_STATUSES = 'getcare/oms/so/GET_SALES_ORDER_STATUSES';

export const GET_SALES_ORDER_LIST = 'getcare/oms/so/GET_SALES_ORDER_LIST';
export const GET_SALES_ORDER = 'getcare/oms/so/GET_SALES_ORDER';

// mutation
export const SAVE_SALES_ORDER_STATUSES = 'getcare/oms/so/SAVE_SALES_ORDER_STATUSES';

export const SAVE_LOADING = 'getcare/oms/so/SAVE_LOADING';

export const SAVE_SALES_ORDER_LIST = 'getcare/oms/so/SAVE_SALES_ORDER_LIST';
export const SAVE_SALES_ORDER_LIST_TOTAL = 'getcare/oms/so/SAVE_SALES_ORDER_LIST_TOTAL';
export const SAVE_SALES_ORDER_LIST_QUERY_STRING = 'getcare/oms/so/SAVE_SALES_ORDER_LIST_QUERY_STRING';
export const SAVE_SALES_ORDER_LIST_ORDER_SELECTED = 'getcare/oms/so/SAVE_SALES_ORDER_LIST_ORDER_SELECTED';

export const SAVE_SALES_ORDER = 'getcare/oms/so/SAVE_SALES_ORDER';
export const SAVE_SALES_ORDER_PRODUCTS = 'getcare/oms/so/SAVE_SALES_ORDER_PRODUCTS';
export const SAVE_SALES_ORDER_PRODUCT_ITEM = 'getcare/oms/so/SAVE_SALES_ORDER_PRODUCT_ITEM';
export const SAVE_SALES_ORDER_BONUSES = 'getcare/oms/so/SAVE_SALES_ORDER_BONUSES';
export const SAVE_SALES_ORDER_DISCOUNTS = 'getcare/oms/so/SAVE_SALES_ORDER_DISCOUNTS';

export const SAVE_SALES_ORDER_NOTES = 'getcare/oms/so/SAVE_SALES_ORDER_NOTES';

export const SAVE_SALES_ORDER_LOGISTIC = 'getcare/oms/so/SAVE_SALES_ORDER_LOGISTIC';
export const SAVE_SALES_ORDER_SALES_USER = 'getcare/oms/so/SAVE_SALES_ORDER_SALES_USER';
export const SAVE_SALES_ORDER_DELIVERY = 'getcare/oms/so/SAVE_SALES_ORDER_DELIVERY';
export const CONFIRM_PURCHASING = 'getcare/oms/so/CONFIRM_PURCHASING';

export const CONFIRM_SUCCESS = 'getcare/oms/so/CONFIRM_SUCCESS';

export const SAVE_SALES_ORDER_ALL_INFO = 'getcare/oms/so/SAVE_SALES_ORDER_ALL_INFO';
