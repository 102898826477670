import vendorApi from 'utils/api/app/vendorApi';
import { put, call } from 'redux-saga/effects';
import { vendorActions } from 'redux/actions';
import axios from 'axios';
import { toast } from 'react-toastify';

let vendorListSource = null;

export function* loadVendorList(action) {
  try {
    const { params = {} } = action.payload || {};
    let cancelToken = action.payload?.cancelToken;
    if ( !cancelToken ) {
      vendorListSource && vendorListSource.cancel();
      vendorListSource = axios.CancelToken.source();
      cancelToken = vendorListSource.token;
    }

    yield put(vendorActions.setStateVendor({ loadingVendorList: true })); 
    
    const { data: response } = yield call(vendorApi.fetchVendorList,{ params, cancelToken });

    if (!response?.result) {
      yield put(vendorActions.setStateVendor({ loadingVendorList: false })); 
      toast.error(response.message);
      return;
    }
    yield put(vendorActions.setStateVendor({ 
      loadingVendorList: false,
      vendorList: response.data || [],
    })); 
    return response.data
  } catch (err) {
    console.log("loadVendorList",err);
    yield put(vendorActions.setStateVendor({ loadingVendorList: false })); 
  }
}

export default {
  loadVendorList,
}