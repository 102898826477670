import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import { 
  CONNECT_SUPPORT_PRODUCT,
  CONNECT_SUPPORT_PROJECT,
  CONNECT_SUPPORT_DONATE_PRODUCT,
} from 'utils/constanst/connectSupportConstants';

import { withStyles  } from '@material-ui/core/styles';

import { 
  Grid,
  Paper,
  Box,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import CardListProductItem from './ProductItem/ProductItem';
import CardListProjectItem from './ProjectItem/ProjectItem';
import DonateProductItem from './DonateProductItem/DonateProductItem';

const StyledPaper = withStyles(theme => ({
  root: {
    padding: '.75rem',
    boxShadow: '0px 2px 4.10127px rgba(0, 0, 0, 0.25)',
    height: '100%'
  }
}))(Paper);

class ConnectSupportCardList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }
  componentDidUpdate(prevProps, prevState) {

  }
  
  goLink = (e,url) => {
    e.preventDefault();
    this.props.history.push(url)
  }

  render() {
    // const { className, loading, data } = this.props;
    // const { isOpenAddMenu } = this.state;
    return (
      <div className={`${this.props.className}`}>
        <Grid container spacing={2}>
          {
            !this.props.loading ? this.props.data.map( (item,key) => {
              return (
                <Grid key={key} item xs={12} sm={4}>
                  <Box>
                    <StyledPaper>
                      {
                        this.props.name === CONNECT_SUPPORT_PRODUCT &&
                        <CardListProductItem
                          {...item}
                          onSelectItem={(action,params = {}) => this.props.onSelectItem({ action, item, index: key, params })}
                        />
                      }
                      {
                        this.props.name === CONNECT_SUPPORT_PROJECT && 
                        <CardListProjectItem
                          {...item}
                          onSelectItem={(action,params = {}) => this.props.onSelectItem({ action, item, index: key, params })}
                        />
                      }
                      {
                        this.props.name === CONNECT_SUPPORT_DONATE_PRODUCT && 
                        <DonateProductItem
                          {...item}
                          onSelectItem={(action,params = {}) => this.props.onSelectItem({ action, item, index: key, params })}
                        />
                      }
                    </StyledPaper>
                  </Box>
                </Grid>
              )
            }) : [...Array(15).keys()].map( (key) => (
              <Grid key={key} item xs={12} sm={4}>
                <Box>
                  <StyledPaper>
                    <Grid container spacing={1}>
                      <Grid item >
                        <Skeleton variant="rect" width={152} height={152}/>
                      </Grid>
                      <Grid item xs>
                        <Skeleton/>
                        <Skeleton/>
                        <Skeleton/>
                      </Grid>
                    </Grid>
                    {
                      this.props.name === CONNECT_SUPPORT_PROJECT && 
                      <><Skeleton/>
                      <Skeleton/>
                      <Skeleton/>
                      <Skeleton/>
                      <Skeleton/></>
                    }
                  </StyledPaper>
                </Box>
              </Grid>
            ))
          }
        </Grid>
      </div>
    );
  }
};

ConnectSupportCardList.propTypes = {
  className: PropTypes.string,
};

ConnectSupportCardList.defaultProps = {
  className: '',
};

// const useStyles = theme => ({
//   textFieldClasses: stylesTextField(theme)
// })
// export default compose(withRouter,withStyles(useStyles))(ConnectSupportToolbar);
export default compose(withRouter)(ConnectSupportCardList);
