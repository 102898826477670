import React, { PureComponent } from 'react';
import { maxBy, isEqual } from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from "react-i18next";

import { 
  makeSelectOpenProductInfoFormInput,
} from 'redux/selectors/connectSupportDonateSelector';
import {
  saveOpenProductInfoFormInput,
} from 'redux/actions/connectSupportDonateActions';
import { numberFormat, currencyFormatVN } from 'utils/helper';

import { 
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
} from '@material-ui/core';

import { ReactComponent as EmptyIcon } from 'assets/images/icons/Empty.svg';
import AddIcon from '@material-ui/icons/Add';
import StyledCheckbox from 'components/Styled/Checkbox/Checkbox';
import StyledButton from 'components/Styled/Button/Button';
import FormDialog from './FormDialog/FormDialog';
import ImagesDialog from 'components/ImagesDialog/ImagesDialog';

import styles from './ContributionProductInfo.module.scss';
import stylesLayoutMainEcom from 'assets/styles/layouts/LayoutMainEcom.module.scss';

class ContributionProductInfo extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      formDialogParams: {
        open: false,
        data: undefined,
      },
      imagesDialogParams: {
        open: false,
        data: [],
      },
      selectedProduct: [],
    }
  }
  componentDidMount() {
    if ( this.props.openProductInfoFormInput ) {
      this.handleToggleFormDialog(this.props.values.items[0])
      this.props.saveOpenProductInfoFormInput(false);
    }
  }
  componentDidUpdate(prevProps) {
    if ( !isEqual(prevProps.values.items,this.props.values.items) ) {
      this.setState((state) =>({
        selectedProduct: state.selectedProduct.filter(item => { 
          return this.props.values.items.map( i => i._index ).includes(item._index);
        })
      }))
    }
  }
  
  updateFormDialogState = (params) => {
    this.setState((state) => ({ formDialogParams: { 
      ...state.formDialogParams,
      ...params 
    }}))
  }
  updateImagesDialogState = (params) => {
    this.setState((state) => ({ imagesDialogParams: { 
      ...state.imagesDialogParams,
      ...params 
    }}))
  }
  isProductSelected = (product) => {
    return this.state.selectedProduct.some( id => id === product._index );
  }

  handleToggleImagesDialog = (data) => {
    this.updateImagesDialogState({ open: true, data })
  }
  handleCloseImagesDialog = () => {
    this.updateImagesDialogState({ open: false })
  }
  handleSelectAllProduct = () => {
    const { items } = this.props.values;
    this.setState((state) => {
      if ( state.selectedProduct.length !==  items.length ) return {
        selectedProduct: [...items.map(item => item._index )]
      }
      return { selectedProduct: [] }
    })
  }
  handleSelectProduct = (product) => {
    this.setState((state) => {
      if ( state.selectedProduct.some( id => id === product._index ) ) return {
        selectedProduct: [...state.selectedProduct.filter( id => id !== product._index )]
      }
      return { selectedProduct: [...state.selectedProduct].concat([product._index]) }
    })
  }
  handleSelectAllProduct = () => {
    const { items } = this.props.values;
    this.setState((state) => {
      if ( state.selectedProduct.length !==  items.length ) return {
        selectedProduct: [...items.map(item => item._index )]
      }
      return { selectedProduct: [] }
    })
  }
  handleSelectedProduct = (action) => {
    if ( action === 'REMOVE' ) {
      this.props.setFieldValue('items',[...this.props.values.items.filter( item => !this.state.selectedProduct.includes(item._index))])
    }
  }
  handleToggleFormDialog = (data) => {
    this.updateFormDialogState({ open: true, data })
  }
  handleCloseFormDialog = () => {
    this.updateFormDialogState({ open: false })
  }
  handleSubmitFormDialog = (values) => {
    const { items } = this.props.values;
    if ( Array.isArray(values) ) {
      let maxById = maxBy(items,'_index');
      let nextId = maxById ? maxById._index + 1 : 1;
      const mapValues = values.map( item => {
        const newItem = {
          ...item,
          _index: nextId,
        }
        nextId += 1;
        return newItem;
      })
      this.props.setFieldValue('items',items.concat(mapValues),false)
    } else {
      if ( ![null,undefined].includes(values._index) ) {
        const itemIndex = items.findIndex( (item) => item._index === values._index );
        if ( itemIndex > - 1 ) items[itemIndex] = {...values};
        this.props.setFieldValue('items',[...items],false);
      } else {
        const maxById = maxBy(items,'_index');
        this.props.setFieldValue('items',items.concat([{...values, _index: maxById ? maxById._index + 1 : 1 }]),false)
      };
    }
    this.handleCloseFormDialog();
  }

  render() {
    const { 
      values,
      t,
    } = this.props;

    return (
      <>
        <div className={styles.Toolbar}>
          <div className={stylesLayoutMainEcom.FormLabel}>Thông tin sản phẩm bạn đóng góp</div>
          <div className={styles.GroupActions}>
            {
              this.state.selectedProduct.length > 0 &&
              <StyledButton variant="contained" onClick={() => this.handleSelectedProduct("REMOVE")} color="secondary">
                {t('common:Xóa')}
              </StyledButton>
            }
            <StyledButton variant="contained" onClick={() => this.handleToggleFormDialog()} color="primary">
              {t('common:Thêm')}
            </StyledButton>
          </div>
        </div>
        <TableContainer className={`${stylesLayoutMainEcom.TableContainer} ${stylesLayoutMainEcom.TableStriped}`}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {
                  values.items.length > 0 && 
                  <TableCell padding="checkbox">
                    <StyledCheckbox
                      indeterminate={this.state.selectedProduct.length > 0 && this.state.selectedProduct.length < values.items.length}
                      checked={this.state.selectedProduct.length > 0 && this.state.selectedProduct.length === values.items.length}
                      onChange={this.handleSelectAllProduct}
                    />
                  </TableCell>
                }
                <TableCell>{t('common:STT')}</TableCell>
                <TableCell>{t('Tên sản phẩm')}</TableCell>
                <TableCell>{t('Nhà sản xuất')}</TableCell>
                <TableCell>{t('Đơn vị')}</TableCell>
                <TableCell>{t('Số lượng')}</TableCell>
                <TableCell>{t('Giá bán')}</TableCell>
                <TableCell>{t('Giá trị tặng')}</TableCell>
                <TableCell>{t('Mô tả sản phẩm')}</TableCell>
                <TableCell>{t('Hình ảnh')}</TableCell>
                {
                  values.items.length > 0 && 
                  <TableCell></TableCell>
                }
              </TableRow> 
            </TableHead>
            <TableBody>
              {
                values.items.length > 0 ? 
                values.items.map( (item,index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell padding="checkbox">
                        <StyledCheckbox
                          checked={this.isProductSelected(item)}
                          onChange={() => this.handleSelectProduct(item)}
                        />
                      </TableCell>
                      <TableCell>
                        {index + 1}
                      </TableCell>
                      <TableCell>
                        {item.product_name}
                      </TableCell>
                      <TableCell>
                        {item.manufacturer_name}
                      </TableCell>
                      <TableCell>
                        {item.uom_base_name}
                      </TableCell>
                      <TableCell>
                        {numberFormat(item.quantity_number)}
                      </TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap' }}>
                        {currencyFormatVN(item.price_sales)}
                      </TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap' }}>
                        {currencyFormatVN(item.amount)}
                      </TableCell>
                      <TableCell>
                        <Box className={styles.LineClamp2} maxWidth="120px">
                          {item.product_description}
                        </Box>
                      </TableCell>
                      <TableCell>
                        {
                          item.images && item.images.length > 0 &&
                          <div onClick={() => this.handleToggleImagesDialog(item.images)} className={`BgImage BgImage--rounded ${styles.Images} ${item.images.length > 1 && styles['Images--dark']}`} style={{ backgroundImage: `url(${item.images[0]})`}} >
                            { item.images.length > 1 && <Box className={styles.Images__content}>
                                <AddIcon/> {item.images.length - 1}
                              </Box>
                            }
                          </div>
                        }
                      </TableCell>
                      <TableCell align="right">
                        <StyledButton variant="contained" size="small" onClick={() => this.handleToggleFormDialog({ ...item, index }) } color="secondary">
                          {t('common:Sửa')}
                        </StyledButton>
                      </TableCell>
                    </TableRow>
                  )
                }) :
                <TableRow>
                  <TableCell align="center" className={stylesLayoutMainEcom.TableCellEmpty} colSpan="9">
                    <EmptyIcon className={stylesLayoutMainEcom.TableCellEmpty__icon}/>
                    <p>{t('Chưa có sản phẩm nào')}</p>
                  </TableCell>
                </TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>
        {
          this.state.formDialogParams.open &&
          <FormDialog
            onSubmit={this.handleSubmitFormDialog}
            open={this.state.formDialogParams.open}
            onClose={this.handleCloseFormDialog}
            data={this.state.formDialogParams.data}
            params={values}
          />
        }
        {
          this.state.imagesDialogParams.open &&
          <ImagesDialog
            open={this.state.imagesDialogParams.open}
            onClose={this.handleCloseImagesDialog}
            data={this.state.imagesDialogParams.data}
          />
        }
      </>
    );
  }
};

const mapStateToProps = createStructuredSelector({
  openProductInfoFormInput: makeSelectOpenProductInfoFormInput(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    saveOpenProductInfoFormInput: (payload) => dispatch(saveOpenProductInfoFormInput(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect,withTranslation(['componentConnectSupportDonateForm','common']))(ContributionProductInfo);
