import React, { PureComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router';
import UserDropdown from './UserDropdown/UserDropdown';
import ServicesPanel from 'components/PageHeader/ServicesPanel/ServicesPanel';
import { withTranslation } from "react-i18next";
import { compose } from 'redux';
import FormControl from "@material-ui/core/FormControl";
import {
  MenuItem,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ReactComponent as LogoPhahubSvg } from 'assets/images/LogoPhahub.svg';

import Select from "@material-ui/core/Select";
import PublicIcon from '@material-ui/icons/Public';
import classes from './PageHeader.module.scss';

class PageHeader extends PureComponent {
  getNavLinkClass = (pathArr) => {
    return pathArr.some((path) => this.props.location.pathname.split('/').includes(path.replace('/', '')))
      ? classes.Active
      : '';
  };

  handleClickBrand = () => {
    window.location.href = process.env.REACT_APP_PATH_ECOM;
  }
  handleChangeLanguage = (e) => {
    const { i18n } = this.props;
    this.setState({
      language: e.target.value ,
    })
    i18n.changeLanguage(e.target.value);
  }

  render() {
    const { t ,i18n} = this.props;

    return (<header className={classes.Header}>
      <div className={classes.PreHeader}>
      <nav className={classes.PreHeader__Nav}>
                <ul>
                <li>
                  <FormControl className={classes.formControl}>
          <Select
            labelId="demo-simple-select-label-themeicon"
            id="demo-simple-select-themeicon"
            className={classes.ChangeLanguage}
            value={i18n.language === 'vi-VN' || i18n.language === 'en-CA' ? i18n.language :'vi-VN'}
            onChange={this.handleChangeLanguage}
            disableUnderline
            IconComponent={ExpandMoreIcon}
          >
            <MenuItem value={'vi-VN'}><PublicIcon />Tiếng Việt</MenuItem>
            <MenuItem value={'en-CA'}><PublicIcon />English (US)</MenuItem>
          </Select>
        </FormControl>
                    </li>
                  {
                    this.props.user?.user_type === 2 &&
                    <li>
                      <a target="_blank" href={process.env.REACT_APP_PATH_VENDOR}>{t('Quản lý gian hàng')}</a>
                    </li>
                  }
                </ul>
              </nav>
        <nav className={classes.PreHeaderNav}>
          <ul>
            <li>
              Hotline 0933900360
            </li>
            <li className={classes.Divider}>|</li>
            <li>
              <ServicesPanel iconSize="default" />
            </li>
            <li className={classes.Divider}>|</li>
          </ul>
          <UserDropdown />
        </nav>

      </div>
      <div className={classes.HeaderMain}>
        <div className={classes.Brand} onClick={this.handleClickBrand}>
          {/* <img className={classes.Logo} src={`${process.env.REACT_APP_PATH}static/logo/logo-ecom.svg`} alt="Getcare 247" /> */}
          {/* <img className={classes.Logo} src={LogoIcon} alt="Phahub" /> */}
          <img src={`${process.env.REACT_APP_PATH}static/logo/logo-phahub.svg`} alt="Phahub" height={80}/>
          {/* <LogoPhahubSvg height={60}/> */}
        </div>
        <nav className={classes.HeaderNav}>
          <ul>
            <li>
              <NavLink
                to="/fastorder"
                className={this.getNavLinkClass(['/fastorder', '/customer'])}
              >
                {t('Đặt hàng nhanh')}
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </header>);
  }
}
export default withTranslation()(compose(withRouter)(PageHeader));

