import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { debounce } from 'lodash';
import { listParamsMap } from 'utils/constanst/ecomPharmacyConstants';
import { getPharmacyList } from 'redux/actions/ecom/pharmacyActions';
import { saveAuth, getUserProvinceList } from 'redux/actions/authActions';
import { createStructuredSelector } from "reselect";
import {
  makeSelectPharmacyList,
  makeSelectPharmacyListTotal,
  makeSelectPharmacyLoading,
  makeSelectLoginUser,
  makeSelectUserProvinceList,
} from 'redux/selectors';
import { getQueryString, getQueryParams } from 'utils/helper';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { withTranslation } from "react-i18next";

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import PharmacyList from 'components/ecom/PharmacyList/PharmacyList';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ListPagination from 'components/ListPagination/ListPagination';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import ClearIcon from '@material-ui/icons/Clear';
import Search from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';

import classes from './Pharmacies.module.scss';

class Pharmacies extends PureComponent {
  constructor(props) {
    super(props);

    this.defaultListParams = {
      ...listParamsMap,
      getcare_province_id: props.user?.search_province_id || '',
    };

    this.state = {
      listParams: { ...this.defaultListParams },
    }
  }
  componentDidMount() {
    this.initUrlSync();

    this.props.getUserProvinceList();
  }
  componentDidUpdate(prevProps, prevState) {
    const { location } = this.props;
    if (location.search !== prevProps.location.search) {
      if (['', null, undefined].includes(location.search)) {
        this.initUrlSync();
        return;
      }
      this.reinitListData();
    }
  }

  initUrlSync = () => {
    const { location } = this.props;
    const initalQueryStringFromDefaultParams = getQueryString(this.defaultListParams);
    // check if default listParams is different from inital location search
    if (['', null, undefined].includes(location.search) && initalQueryStringFromDefaultParams !== location.search) {
      this.props.history.replace({ search: initalQueryStringFromDefaultParams});
      return;
    }
    this.reinitListData();
  }
  reinitListData = () => {
    const { location } = this.props;
    const newParams = getQueryParams(location.search);
    this.setState({
      listParams: newParams,
    });
    this.loadCustomers({
      params: newParams,
    });
  }
  synUrl = debounce((newParams) => {
    this.props.history.push({ search: getQueryString(newParams)});
  }, 500);

  loadCustomers = ({ params }) => {
    this.source && this.source.cancel();
    
    this.source = axios.CancelToken.source();
    this.props.getPharmacyList({ params, cancelToken: this.source.token });
  }

  getSelectedProvince = (provinceId) => {
    const { userProvinceList } = this.props;
    if (!userProvinceList || !provinceId) return null;
    return userProvinceList.find(item => item.id === Number(provinceId));
  }
  handleKeywordChange = (e) => {
    this.handleFilterChange({ keyword: e.target.value }, true);
  }
  handleClearKeyword = () => {
    this.handleFilterChange({ keyword: '' }, true);
  }
  handleProvinceChange = (e, newValue) => {
    this.handleFilterChange({ getcare_province_id: newValue?.id }, true);
    // save the province search to user
    this.props.saveAuth({
      ...this.props.user,
      search_province_id: newValue?.id || 0,
    });
  }
  handleFilterChange = (filterParams, forceResetPage: false) => {
    const newParams = forceResetPage ? {
      ...this.state.listParams,
      ...filterParams,
      page: 1,
    } : {
      ...this.state.listParams,
      ...filterParams,
    };
    this.setState({
      listParams: newParams,
    }, () => {
      this.synUrl(newParams);
    });
  }

  render() {
    const { pharmacyList, loading, pharmacyListTotal, userProvinceList, t} = this.props;
    const { listParams } = this.state;

    return (<React.Fragment>
      <div className={classes.PageWrap}>
        <Breadcrumbs
          separator={
            <NavigateNextIcon
              className="BreadscrumbSeparator"
              fontSize="small"
            />
          }
          className="Breadscrumbs"
          aria-label="breadcrumb"
        >
          <span className="BreadscrumbActive">{t('Chọn Khách Hàng')}</span>
          <span>{t('Đặt Hàng Nhanh')}</span>
          <span>{t('Xác Nhận Đơn Hàng')}</span>
        </Breadcrumbs>

        <div className={classes.PageHeader}>
          <h1 className="PageTitle">{t('Danh Sách Khách Hàng')}</h1>
        </div>
        <div className={`${classes.PageContent}`}>
          <div className={classes.PageFilter}>
            <Autocomplete
              fullWidth
              openOnFocus
              size="small"
              handleHomeEndKeys={false}
              value={this.getSelectedProvince(listParams.getcare_province_id) || null}
              options={userProvinceList || []}
              onChange={this.handleProvinceChange}
              renderOption={(option) => `${option.name}`}
              getOptionLabel={(option) => `${option.name}`}
              getOptionSelected={(option, value) =>
                value?.id && option ? option.id === value.id : null
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={t('Tỉnh/Thành phố')}
                  variant="outlined"
                />
              )}
            />
            <TextField
              fullWidth
              value={listParams.keyword}
              autoComplete="off"
              name="keyword"
              variant="outlined"
              size="small"
              placeholder={t('Nhập tên khách hàng tìm kiếm')}
              onChange={this.handleKeywordChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    { !listParams.keyword ? <Search fontSize="small" /> : <IconButton size="small" onClick={this.handleClearKeyword}><ClearIcon fontSize="small" /></IconButton> }
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className={classes.PageMain}>
            <PharmacyList pharmacyList={pharmacyList} isLoading={loading}/>
          </div>
        </div>
        <div className={classes.PageFooter}>
          <ListPagination
            page={Number(listParams.page)}
            pageSize={Number(listParams.page_size)}
            total={pharmacyListTotal}
            listName="khách hàng"
            onFilterChange={this.handleFilterChange}
          />
        </div>
      </div>
    </React.Fragment>);
  }
}

const mapStateToProps = createStructuredSelector({
  pharmacyList: makeSelectPharmacyList(),
  pharmacyListTotal: makeSelectPharmacyListTotal(),
  loading: makeSelectPharmacyLoading(),
  user: makeSelectLoginUser(),
  userProvinceList: makeSelectUserProvinceList(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getPharmacyList: (payload) => dispatch(getPharmacyList(payload)),
    getUserProvinceList: () => dispatch(getUserProvinceList()),
    saveAuth: (payload) => dispatch(saveAuth(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default withTranslation()(compose(withConnect, withRouter)(Pharmacies));
