import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import moment from 'moment';
import { withTranslation } from "react-i18next";

import { numberFormat } from 'utils/helper'
import donateApi from 'utils/api/ecom/serviceRequest/connectSupport/donate/donateApi';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Grid,
  Paper,
  Avatar,
  withStyles,
} from '@material-ui/core';
import StyledButton from 'components/Styled/Button/Button';
import { ReactComponent as EmptyIcon } from 'assets/images/icons/Empty.svg';
import Skeleton from '@material-ui/lab/Skeleton';

import styles from './CharityRecipientListDialog.module.scss';

const StyledPaper = withStyles(theme => ({
  root: {
    boxShadow: '0px 2px 4.10127px rgba(0, 0, 0, 0.25)',
    height: '100%',
    padding: '.75rem',
  }
}))(Paper);

class CharityRecipientListDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      charityRecipientList: [],
      loadingCharityRecipientList: false,
    }
  }
  componentDidMount() {
    // this.loadCharityRecipientList()
  }
  componentDidUpdate(prevProps,prevState) {
    if ( prevProps.open !== this.props.open && this.props.open ) {
      this.loadCharityRecipientList()
    }
  }
  
  loadCharityRecipientList = async () => {
    const { donate_id } = this.props.params;
    this.setState({ loadingCharityRecipientList: true });
    try {
      const { data: response } = await donateApi.getCharityRecipientList({ params: { donate_id }});
      if (!response.result) {
        toast.error(response.message);
        return;
      }
      this.setState({ loadingCharityRecipientList: false, charityRecipientList: response.data });
    } catch (error) {
      toast.error("Đang xảy ra lỗi vui lòng thử lại");
    }
  }
  
  handleCloseDialog = () => {
    this.props.onClose();
  }

  render() {
    const { open, t } = this.props;

    return (
      <Dialog
        open={open}
        maxWidth="md"
        scroll='paper'
        onClose={this.handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        // classes={{ root: `${this.state.loadingCharityRecipientList && 'OverlayLoading'}` }}
        fullWidth
      >
        <DialogTitle id="scroll-dialog-title">{t('Danh người nhận hỗ trợ')}</DialogTitle>
        <DialogContent dividers>
          {
            this.state.loadingCharityRecipientList ? 
            <Grid container spacing={2}> {
              [...Array(15).keys()].map( (key) => (
                <Grid key={key} item xs={12} sm={4}>
                  <Box>
                    <StyledPaper>
                      <Grid container spacing={1}>
                        <Grid item >
                          <Skeleton variant="circle" width={64} height={64}/>
                        </Grid>
                        <Grid item xs>
                          <Skeleton/>
                          <Skeleton/>
                          <Skeleton/>
                        </Grid>
                      </Grid>
                    </StyledPaper>
                  </Box>
                </Grid>
              ))}
            </Grid> : 
            <>
              {
                this.state.charityRecipientList.length > 0 ?
                <Grid container spacing={2}>
                  {
                    this.state.charityRecipientList.map( item => {
                      return (
                        <Grid item xs={12} sm={4}>
                          <Box>
                            <StyledPaper>
                              <Box display="flex" style={{ gap: '.5rem' }}>
                                <Box flexShrink={1}>
                                  <Avatar
                                    className={styles.Avatar} alt={item.name} src={item.avatar}
                                  >
                                    {item.name ? item.name.charAt(0) : 'G'}
                                  </Avatar>
                                </Box>
                                <Box className={styles.UserInfo} display="flex" flexDirection="column" overflow="auto" flexGrow={1}>
                                  <p className={styles.UserName}>{item.name}</p>
                                  <p><span className={styles.SubTitle}>{t('Đã nhận')}:</span> {numberFormat(item.quantity_number)}</p>
                                  <p><span className={styles.SubTitle}>{t('Ngày nhận')}:</span> {moment(item.created_at).format('DD/MM/YYYY')}</p>
                                </Box>
                                {/* <Box flexShrink={1}>

                                </Box> */}
                              </Box>
                            </StyledPaper>
                          </Box>
                        </Grid>
                      )
                    })
                  }
                </Grid> : 
                <> 
                  <div className={styles.Empty}>
                    <EmptyIcon className={styles.EmptyIcon}/>
                    <p>{t('Chưa có danh sách người nhận từ thiện')}</p>
                  </div>
                </>
              }
            </>
          }
        </DialogContent>
        <DialogActions disableSpacing>
          <StyledButton
              color="default"
              onClick={() => this.handleCloseDialog()}
            >
              {t('Đóng')}
            </StyledButton>
        </DialogActions>
      </Dialog>
    );
  }
}

CharityRecipientListDialog.propTypes = {
  params: PropTypes.object
};

CharityRecipientListDialog.defaultProps = {
  params: {},
  open: true
}
export default withTranslation(['componentCharityRecipientListDialog'])(CharityRecipientListDialog);
