import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

const NumberFormatCustom = React.memo((props) => {
  const { inputRef, onChange, allowNegative, ...other } = props;
  const [event,setEvent] = React.useState(null);
  const numberFormatEl = React.useRef(null)
  const changeStateEvent = (event) => {
    setEvent(event)
  }
  React.useEffect(() => {
    numberFormatEl.current && numberFormatEl.current.addEventListener("blur",() => changeStateEvent("blur"))
    numberFormatEl.current && numberFormatEl.current.addEventListener("focus",() => changeStateEvent("focus"))
  },[]);

  return (
    <NumberFormat
      {...other}
      getInputRef={(ref) => {
        inputRef && inputRef(ref);
        numberFormatEl.current = ref;
      }}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
            floatValue: values.floatValue,
          },
        });
      }}
      thousandSeparator
      isNumericString
      allowNegative={allowNegative}
    />
  );
})

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  allowNegative: PropTypes.bool,
};
NumberFormatCustom.defaultProps = {
  allowNegative: false,
};

export default NumberFormatCustom;