import axiosAuth from 'utils/axios/axiosAuth';

const customerApi = {
  getList: ({ params, cancelToken }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_customer/`, {
      params: queryParams,
      cancelToken,
    });
  },
  get: (id) => {
    return axiosAuth.get(`getcare_customer/${id}`);
  },

  getCustomerTypes: () => {
    return axiosAuth.get(`getcare_customer_type/`);
  },
  getAddressBook: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_customer_delivery/`, {
      params: queryParams,
    });
  },
  // add or update
  postAddress: ({ params }) => {
    return axiosAuth.post(`getcare_customer_delivery`, params);
  },
};

export default customerApi;
