import { withTranslation } from "react-i18next";
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types';
import { compose } from 'redux';
import * as Yup from 'yup';
import parsePhoneNumber from 'libphonenumber-js'

import { formatPhoneNumbers } from 'utils/helper'
import authApi from 'utils/api/authApi';

import MaskedInput from 'react-text-mask';
import { Formik } from 'formik';
import {
  FormHelperText,
  Link,
  Dialog,
  DialogContent,
  DialogActions,  
  Typography,
  CircularProgress,
  withStyles,
} from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckIcon from '@material-ui/icons/Check';
import StyledTextField from 'components/Styled/TextField/TextField';
import StyledCircularProgress from 'components/Styled/CircularProgress/CircularProgress'
import StyledBox from 'components/Styled/Box/Box';
import StyledButton from 'components/Styled/Button/Button';
import LoadingOverlay from 'components/LoadingOverlay/LoadingOverlay'

import styles from './otpVerificationDialogStyles';

const PAGE_CHECK_VALID_PHONE = 'check_valid_phone';
const PAGE_VERIFY_PHONE = 'verify_phone';
const PAGE_VERIFY_SUCCESSFUL = 'verify_successful';
const PAGE_CHECK_VALID_PHONE_FAILED = 'check_valid_phone_failed';

const CodeMask = React.memo((props) => {
  const { inputRef, name, value, onChange, ...otherProps } = props;
  const codeMask = [/\d/, ' ', /\d/, ' ', /\d/, ' ', /\d/, ' ', /\d/, ' ', /\d/];
  const [codeValue,setCodeValue] = React.useState(value);
  React.useEffect(() => {
    if ( value !== codeValue.replace(/ /g,'') ) {
      setCodeValue(value)
    }
  },[value])

  return (
    <MaskedInput
      {...otherProps}
      value={codeValue}
      onChange={(e) => {
        onChange({
          target: {
            value: e.target.value.replace(/ /g,''),
            name: name,
          }
        })
        setCodeValue(e.target.value)
      }}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={codeMask}
      placeholderChar={'\u2000'}
      showMask
      guide={false}
    />
  );
})

const CodeInput = React.memo((props) => (
  <StyledTextField
    {...props}
    InputProps={{
      inputComponent: CodeMask,
    }}
  />
))

export class OtpVerificationDialog extends PureComponent { 
  constructor(props) {
    super(props);
    this.state = {
      codeResendMaxTime: 60000,
      codeResendTime: 60000,
      errorMessage: "",
      verifyingOtp: false,
      verifyOtpData: null,
      page: PAGE_CHECK_VALID_PHONE,
    }
    this.setIntervalCodeResendTime = null;
    this.formikRef = React.createRef();
  }
  componentDidMount() {
    this.setIntervalCodeResendTime = setInterval(() =>{
      this.setState((state) => ({ codeResendTime: state.codeResendTime ? state.codeResendTime - 150 : 0 }))
    },150)
  }
  componentDidUpdate(prevProps) {
    const { open } = this.props;
    if ( open !== prevProps.open ) {
      this.handleDialogOpen();
    }
  }
  componentWillUnmount() {
    clearInterval(this.setIntervalCodeResendTime)
  }

  getInitialValues = () => {
    const { values } = this.props;
    return {
      code: "",
      phone: values.phone,
    }
  }
  getValidationSchema = () => {
    return Yup.object().shape({
      code: Yup.string().required('Vui lòng nhập mã xác thực').test(
        'code',
        'Mã xác thực chưa hợp lệ',
        (value,{ parent }) => {
          return String(value).length >= 6
        }
      ),
    })
  }
  
  requestOTP = async ({ params }) => {
    try {
      return await authApi.requestOtp({ params })
    } catch (error) {}
    return 
  }
  verifyOtp = async ({ params }) => {
    try {
      return authApi.verifyOtp({ params })
    } catch (error) {}
    return 
  }

  handleDialogOpen = () => {
    const { open, autoVerify, values } = this.props;

    if ( open ) {
      const { phone } = this.formikRef.current?.values || {};
      if ( this.state.page === PAGE_CHECK_VALID_PHONE_FAILED || phone !== values.phone || autoVerify ) {
        this.setState({ 
          errorMessage: "", 
          codeResendTime: this.state.codeResendMaxTime,
          page: PAGE_CHECK_VALID_PHONE,
        });

        this.formikRef.current?.resetForm && this.formikRef.current.resetForm({
          values: this.getInitialValues()
        });
        this.handleOtpRequest(values.phone)
      }
    }
  }
  handleOtpVerify = async ({ validateForm, values }) => {
    const validate = await validateForm();
    if ( validate.code ) {
      this.setState({ errorMessage: validate.code })
      return;
    }
    this.setState({ verifyingOtp: true });
    const { data: response } = await this.verifyOtp({ params: {
      phone: values.phone,
      code: values.code,
    }})
    
    if ( !response.result ) {
      this.setState({ 
        verifyingOtp: false,
        errorMessage: "Mã xác thực chưa đúng, vui lòng thử lại"
      });
      return
    } 
    this.setState({ 
      verifyingOtp: false,
      verifyOtpData: response.data,
      page: PAGE_VERIFY_SUCCESSFUL
    })
  }
  handleOtpRequest = async (phone) => {
    const { data: response } = await this.requestOTP({ params: {
      phone,
      is_register: this.props.values?.isRegister || false,
    }})
    if ( !response?.result ) {
      this.setState({
        page: PAGE_CHECK_VALID_PHONE_FAILED,
        errorMessage: !this.props.values?.isRegister ? 
          "Không tìm thấy số điện thoại trên hệ thống, vui lòng kiểm tra lại" : 
            "Số điện thoại đã được đăng ký, vui lòng đăng ký số điện thoại khác"
      })
      return
    }
    this.setState({
      codeResendTime: this.state.codeResendMaxTime,
      page: PAGE_VERIFY_PHONE,
      errorMessage: ""
    })
  }
  handleCodeChange = (e) => {
    this.setState({ code: e.target.value });
  }
  handleDialogClose = () => {
    if ( this.state.page ===  PAGE_VERIFY_SUCCESSFUL ) {
      this.props.onClose && this.props.onComplete(this.state.verifyOtpData) 
    }
    this.props.onClose && this.props.onClose();
  }

  render() {
    const { classes, open } = this.props;
    const { 
      codeResendTime, 
      codeResendMaxTime, 
      errorMessage, 
      verifyingOtp,
      page
    } = this.state;
    const validationSchema = this.getValidationSchema();
    const initialValues = this.getInitialValues();

    return (
      <Formik
        innerRef={this.formikRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
      {(props) => {
        const {
          validateForm,
          values,
          handleChange,
        } = props;
        const phoneNumberConfig = parsePhoneNumber(values.phone);
        const displayPhoneNumber = phoneNumberConfig ?  
          formatPhoneNumbers({ 
            code: `+${phoneNumberConfig.countryCallingCode}`, 
            number: phoneNumberConfig.nationalNumber, 
            options: { withhold: { count: 4 } } 
          }) : `*******${values.phone.substr(-4)}`;
          
        return (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              this.handleOtpVerify({ validateForm, values })
            }} 
            noValidate 
            autoComplete="off"
          >
            <Dialog  
              disableBackdropClick={verifyingOtp}
              disableEscapeKeyDown={verifyingOtp}
              open={open} 
              fullWidth
              maxWidth="xs"
              onClose={this.handleDialogClose}
            >
              <DialogContent>
                {
                  page === PAGE_CHECK_VALID_PHONE && (
                    <StyledBox display="flex" alignItems="center" justifyContent="center" flexDirection="column" gap={1}>  
                      <CircularProgress className={classes.checkValidPhoneColor} />
                      <Typography align="center" variant="h6">Đang kiểm tra số điện thoại...</Typography>
                    </StyledBox>
                  )
                }
                {
                  page === PAGE_VERIFY_PHONE && (
                    <LoadingOverlay loading={verifyingOtp}>
                      <StyledBox color="primary.main" fontWeight={600} component={Typography} gutterBottom variant="h6" align="center">
                        Xác thực OTP
                      </StyledBox>
                      <Typography align="center" gutterBottom>
                        Nhập mã xác thực gởi đến <StyledBox fontWeight={600} component="span">{displayPhoneNumber}</StyledBox>
                      </Typography>
                      <StyledBox marginBottom={1}>
                        <StyledBox display="flex" justifyContent="center">
                          <CodeInput
                            className={classes.codeInput}
                            type="tel"
                            name="code"
                            autoComplete="off"
                            value={values.code}
                            onChange={(e) => {
                              handleChange(e)
                              this.setState({ errorMessage: "" })
                            }}
                            variant="outlined"
                            error={!!errorMessage}
                            size="small"
                          />
                        </StyledBox>
                        <FormHelperText className={classes.errorMessage} error>{errorMessage}</FormHelperText>
                      </StyledBox>
                      {
                        codeResendTime > 0 ? <>
                          <StyledBox display="flex" alignItems="center" justifyContent="center" gap={1}>
                            <Typography variant="body2" component="div" color="textSecondary">
                              Gửi lại code trong vòng 
                            </Typography>
                            <StyledCircularProgress
                              progressMax={codeResendMaxTime}
                              progressValue={codeResendTime}
                            >
                              <StyledBox color="primary.main" fontWeight={600} component={Typography} variant="body2">
                                {Math.round(codeResendTime/1000)}
                              </StyledBox>
                            </StyledCircularProgress>
                            <Typography variant="body2" component="div" color="textSecondary">
                              giây
                            </Typography>
                          </StyledBox>
                        </> : <>
                          <StyledBox align="center" component={Typography} variant="body2" >
                            Bạn chưa nhận được mã! 
                            <Link className={classes.btnResend} onClick={() => this.handleOtpRequest(values.phone)} component="button" variant="body2" underline="none">
                              <StyledBox fontWeight={600} component="span">Gửi lại</StyledBox>
                            </Link>
                          </StyledBox>
                        </>
                      }
                    </LoadingOverlay>
                  )
                }
                {
                  page === PAGE_VERIFY_SUCCESSFUL && (
                    <StyledBox display="flex" alignItems="center" justifyContent="center" flexDirection="column" gap={1}>  
                      <CheckIcon className={classes.successIcon}/>
                      <Typography align="center" variant="h6">Xác thực thành công</Typography>
                    </StyledBox>
                  )
                }
                {
                  page === PAGE_CHECK_VALID_PHONE_FAILED && (
                    <StyledBox display="flex" alignItems="center" justifyContent="center" flexDirection="column" gap={1}>  
                      <ErrorOutlineIcon className={classes.errorIcon}/>
                      <Typography align="center" variant="h6">{errorMessage}</Typography>
                    </StyledBox>
                  )
                }
              </DialogContent>
              <DialogActions className={classes.dialogActions}>
                {
                  page === PAGE_VERIFY_PHONE && <>
                    <StyledButton onClick={this.handleDialogClose}>
                      Đóng
                    </StyledButton>
                    <StyledButton disabled={verifyingOtp} onClick={() => this.handleOtpVerify({ validateForm, values })} variant="contained" color="primary">
                      Xác thực
                    </StyledButton>
                  </>
                }
                {
                  [PAGE_CHECK_VALID_PHONE_FAILED,PAGE_VERIFY_SUCCESSFUL].includes(page) && (
                    <StyledButton onClick={this.handleDialogClose} variant="contained" color="primary">
                      OK
                    </StyledButton>
                  )
                }
                {
                  page === PAGE_CHECK_VALID_PHONE && (
                    <StyledButton onClick={this.handleDialogClose} variant="contained" color="primary">
                      Đóng
                    </StyledButton>
                  )
                }
              </DialogActions>
            </Dialog>
          </form>
        )
      }}
      </Formik>
    )
  }
}

OtpVerificationDialog.propTypes = {
  open: PropTypes.bool,
  value: PropTypes.object,
  onClose: PropTypes.func,
  onComplete: PropTypes.func,
};
OtpVerificationDialog.defaultProps = {
  open: false,
  value: {
    phone: "",
    isRegister: false,
  },
  autoVerify: false, // Tự động xác thực không cần so sánh số đã truyền vào
  onClose: () => {},
  onComplete: () => {},
};

export default compose(withStyles(styles), withTranslation(['componentOtpVerificationDialog']))(OtpVerificationDialog);
