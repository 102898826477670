import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectFastOrderDelivery,
  makeSelectFastOrderDetails,
} from 'redux/selectors';
import { getFullAddressStr } from 'utils/helper';
import { savePRDetails } from 'redux/actions/ecom/fastOrderActions';
import Button from '@material-ui/core/Button';
import { withTranslation } from "react-i18next";
import { withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';

import classes from '../FastOrderCustomerDelivery.module.scss';

class CustomerDeliveryView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      hasTaxCode: false,
    };
  }
  componentDidMount(prevProps) {
    const { prDetails } = this.props;
    if (!!prDetails) {
      this.setState({
        hasTaxCode: prDetails.is_taxcode,
      });
    }
  }

  handleChange = (fieldMap) => {
    this.props.savePRDetails({
      ...this.props.prDetails,
      ...fieldMap,
    });
  };

  handleSubmit = (values) => {
    const prDeliveryData = {
      ...this.props.prDetails,
      tax_address: values.tax_address,
      tax_name: values.tax_name ,
      tax_code: values.tax_code,
    
    };
    this.props.onUpdatePRDraft({
      prDeliveryData: { ...prDeliveryData },
      willSaveLoading: true,
      willUpdatePRDetails: true,
    });
  }

  render() {
    const { prDelivery, prDetails ,t} = this.props;

    return (
      <div className={classes.ViewWrap}>
        {prDelivery && (
          <>
            {(prDelivery.label || prDelivery.customer_name) && (
              <p className={classes.CustomerName}>{prDelivery.label || prDelivery.customer_name}</p>
            )}

            {(!!prDelivery.name || !!prDelivery.phone) && (
              <div className={`${classes.GroupInfo} ${classes.Info}`}>
                {!!prDelivery.name && (
                  <span className={classes.Info}>
                    <PersonOutlineIcon
                      fontSize="small"
                      className={classes.Icon}
                    />
                    {prDelivery.name}
                  </span>
                )}
                {!!prDelivery.name && !!prDelivery.phone && (
                  <span className={classes.InfoDivider}></span>
                )}
                {!!prDelivery.phone && (
                  <span className={classes.Info}>
                    <PhoneOutlinedIcon
                      fontSize="small"
                      className={classes.Icon}
                    />
                    {prDelivery.phone}
                  </span>
                )}
              </div>
            )}
            <div className={classes.Info}>
              <RoomOutlinedIcon fontSize="small" className={classes.Icon} />
              {getFullAddressStr(
                prDelivery.address,
                prDelivery.getcare_ward,
                prDelivery.getcare_district,
                prDelivery.getcare_province
              )}
            </div>
            <div className={`${classes.Info} ${classes.VATOption}`}>
              <Formik
                innerRef={this.props.taxInfoFormRef}
                initialValues={{
                  is_taxcode: !!prDetails?.is_taxcode,
                  tax_name: prDetails?.tax_name || '',
                  taxcode: prDetails?.taxcode || prDetails?.customer_taxcode || '',
                  tax_address: prDetails?.tax_address || '',
                }}
                validationSchema={Yup.object().shape({
                  tax_name: this.state.hasTaxCode ? Yup.string().required(t('Vui lòng nhập')).max(255, t('Tối đa 20 ký tự')) : undefined,
                  taxcode: this.state.hasTaxCode ? Yup.string().required(t('Vui lòng nhập')).max(20, t('Tối đa 20 ký tự')) : undefined,
                  tax_address: this.state.hasTaxCode ? Yup.string().required(t('Vui lòng nhập')).max(255, t('Tối đa 255 ký tự')) : undefined,
                })}
                onSubmit={(values) => {
                  this.handleSubmit(values);
                }}
              >
                {(props) => {
                  const { values, errors, handleChange, setFieldValue } = props;
                  return (
                    <form noValidate autoComplete="off">
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            name="is_taxcode"
                            checked={values.is_taxcode}
                            onChange={(e) => {
                              setFieldValue('is_taxcode', e.target.checked);
                              if (e.target.checked) {
                                setFieldValue('taxcode', values.taxcode || prDetails?.customer_taxcode || '');
                              }
                              this.setState({
                                hasTaxCode: e.target.checked,
                              });
                              this.handleChange({
                                [e.target.name]: e.target.checked,
                                taxcode: e.target.checked
                                  ? (values.taxcode || prDetails?.customer_taxcode || '')
                                  : values.taxcode,
                              });
                            }}
                          />
                        }
                        label={t('Xuất hoá đơn GTGT')}
                      />
                      {this.state.hasTaxCode && (<>
                        <div className={classes.FieldControl}>
                          <label>{t('Tên công ty')}</label>
                          <TextField
                            autoFocus
                            className={classes.Field}
                            autoComplete="off"
                            value={values.tax_name}
                            name="tax_name"
                            size="small"
                            variant="outlined"
                            error={!!errors.tax_name}
                            helperText={errors.tax_name}
                            onChange={(e) => {
                              handleChange(e);
                              this.handleChange({
                                [e.target.name]: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className={classes.FieldControl}>
                          <label>{t('Mã số thuế')}</label>
                          <TextField
                            className={classes.Field}
                            autoComplete="off"
                            value={values.taxcode}
                            name="taxcode"
                            size="small"
                            variant="outlined"
                            error={!!errors.taxcode}
                            helperText={errors.taxcode}
                            onChange={(e) => {
                              handleChange(e);
                              this.handleChange({
                                [e.target.name]: e.target.value,
                              });
                            }}
                          />
                        </div>

                        <div className={classes.FieldControl}>
                          <label>{t('Địa chỉ')}</label>
                          <TextField
                            multiline
                            rows={3}
                            rowsMax={3}
                            className={classes.Field}
                            autoComplete="off"
                            value={values.tax_address}
                            name="tax_address"
                            size="small"
                            variant="outlined"
                            error={!!errors.tax_address}
                            helperText={errors.tax_address}
                            onChange={(e) => {
                              handleChange(e);
                              this.handleChange({
                                [e.target.name]: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <Button variant="contained"  className={classes.ButtonSubmit} color="secondary" onClick={this.handleSubmit}>
                          {t('Lưu thông tin')}
                        </Button>
                      </>)}
                    </form>
                  );
                }}
              </Formik>
            </div>
          </>
        )}
      </div>
    );
  }
}

CustomerDeliveryView.propTypes = {
  prDelivery: PropTypes.object,
  prDetails: PropTypes.object,
  taxInfoFormRef: PropTypes.func,
};
CustomerDeliveryView.defaultProps = {};

const mapStateToProps = createStructuredSelector({
  prDetails: makeSelectFastOrderDetails(),
  prDelivery: makeSelectFastOrderDelivery(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    savePRDetails: (payload) => dispatch(savePRDetails(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default withTranslation()(compose(withConnect, withRouter)(CustomerDeliveryView));

