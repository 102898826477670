import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectLoginUser } from 'redux/selectors';
import { withTranslation } from "react-i18next";
import { withRouter } from 'react-router-dom';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

class CharityBreadcrumbs extends PureComponent {
  render() {
    const {  step,t } = this.props;

    return (
      <Breadcrumbs
        separator={
          <NavigateNextIcon
            className="BreadscrumbSeparator"
            fontSize="small"
          />
        }
        className="Breadscrumbs"
        aria-label="breadcrumb"
      >
        {step === 3 && <span className="BreadscrumbActive">{t('Xác Nhận Đơn Hàng')}</span>}
      </Breadcrumbs>
    );
  }
}

CharityBreadcrumbs.propTypes = {
  step: PropTypes.number,
  onReselectProducts: PropTypes.func,
};
CharityBreadcrumbs.defaultProps = {
  step: 1,
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectLoginUser(),
});
const withConnect = connect(mapStateToProps, null);
export default withTranslation()(compose(withConnect, withRouter)(CharityBreadcrumbs));
