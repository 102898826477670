export const MODULE_ERP = 'erp';
export const MODULE_MDM = 'mdm';
export const MODULE_OMS = 'oms';
export const MODULE_TMK = 'tmk';
export const MODULE_ECOM = 'ecom';

export const COMPONENT_LOGIN_AS = 'login_as';

export const GENDER_FEMALE = 'female';
export const GENDER_MALE = 'male';
export const GENDER_OTHER = 'other';
export const userGenderMap = {
  [GENDER_FEMALE]: 'Nữ',
  [GENDER_MALE]: 'Nam',
  [GENDER_OTHER]: 'Khác',
};
export const userGenders = [
  {
    id: GENDER_FEMALE,
    name: userGenderMap[GENDER_FEMALE],
  },
  {
    id: GENDER_MALE,
    name: userGenderMap[GENDER_MALE],
  },
  {
    id: GENDER_OTHER,
    name: userGenderMap[GENDER_OTHER],
  },
];

export const COMPANY_CUSTOMER_TYPE = 1;
export const RETAIL_CUSTOMER_TYPE = 2;
export const DOCTOR_CUSTOMER_TYPE = 3;

/* actions */
export function getActionItem(actionCode, userDetailsActions) {
  if (!userDetailsActions) return;
  return userDetailsActions.find(action => action.code === actionCode);
}
export function getModuleItem(moduleCode, userDetailsModules) {
  if (!userDetailsModules) return;
  return userDetailsModules.includes(moduleCode);
}
export function getComponentItem(componentCode, userDetailsComponents) {
  if (!userDetailsComponents) return;
  return userDetailsComponents.includes(componentCode);
}

export function isCompanyUser(user) {
  return !!(user && user.id && user.getcare_customer?.getcare_customer_type_id === COMPANY_CUSTOMER_TYPE);
}
export function isRouteEnabled(user, path) {
  return (!user || !user.getcare_components) ? true : user.getcare_components.includes(path);
}

export function isCollaborator(user) {
  return user?.getcare_sales_id;
}