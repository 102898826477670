import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from "react-i18next";

import supportProductApi from 'utils/api/ecom/serviceRequest/connectSupport/support/productApi';
import supportProjectApi from 'utils/api/ecom/serviceRequest/connectSupport/support/projectApi';
import donateProductApi from 'utils/api/ecom/serviceRequest/connectSupport/donate/productApi';
import donateApi from 'utils/api/ecom/serviceRequest/connectSupport/donate/donateApi';
import supportApi from 'utils/api/ecom/serviceRequest/connectSupport/support/supportApi';

import { toast } from 'react-toastify';
import { isEqual, debounce, ceil } from 'lodash';
import axios from 'axios';

import { 
  makeSelectConnectSupportSupport,
} from 'redux/selectors/connectSupportSupportSelector';
import { 
  makeSelectConnectSupportDonate,
} from 'redux/selectors/connectSupportDonateSelector';
import { 
  makeSelectLoginUser 
} from 'redux/selectors';
import {
  saveConnectSupportSupport,
} from 'redux/actions/connectSupportSupportActions';
import {
  saveConnectSupportDonate,
  saveOpenProductInfoFormInput,
} from 'redux/actions/connectSupportDonateActions';
import { 
  CONNECT_SUPPORT_PRODUCT,
  CONNECT_SUPPORT_PROJECT,
  CONNECT_SUPPORT_MAP,
  CONNECT_SUPPORT_DONATE_PRODUCT,
  stateDisplayOptions,
} from 'utils/constanst/connectSupportConstants';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { ReactComponent as EyeIcon } from 'assets/images/icons/Eye.svg';
import { ReactComponent as DisplayIcon } from 'assets/images/icons/Display.svg';
import { 
  Button, 
  Container,
  Paper,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Grow,
  Popper,
  Box,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import ConnectSupportTabs from './components/Tabs/Tabs';
import ConnectSupportToolbar from './components/Toolbar/Toolbar';
import ConnectSupportCardList from './components/CardList/CardList';
import CharityRecipientListDialog from 'components/MainEcom/CharityRecipientListDialog/CharityRecipientListDialog';

import classes from './ConnectSupport.module.scss'

class ConnectSupport extends PureComponent {
  constructor(props) {
    super(props);
    const sessionStorageDataGridParams = this.getSessionStorage('connectSupport_dataGridParams') || {};
    this.state = {
      isOpenFilters: false,
      dataGridLoading: true,
      displayOptions: stateDisplayOptions,
      dataGridParams: {
        name: stateDisplayOptions[0].value[0].value,
        page: 1,
        page_size: 15,
        keyword: '',
        order: stateDisplayOptions[0].value[0].orders[0].value,
        ...sessionStorageDataGridParams
      },
      dataGridData: [],
      dataGridCount: null,
      charityRecipientListDialogOpen: false,
      charityRecipientListDialogParams: {},
    }
    this.sourceDataGrid = null;
    this.anchorElFilters = React.createRef();
  }
  componentDidMount() {
    this.fetchDataGrid();
  }
  componentDidUpdate(prevProps, prevState) {
    if ( !isEqual(prevState.dataGridParams,this.state.dataGridParams) ) {
      this.saveSessionStorage('connectSupport_dataGridParams',this.state.dataGridParams);
      this.setState({ dataGridLoading: true });
      this.debounceFetchDataGrid();  
    }
  }
  
  saveSessionStorage = (name,value) => {
    sessionStorage.setItem(name,JSON.stringify(value));
  }
  getSessionStorage = (name) => {
    const item = sessionStorage.getItem(name);
    return item ? JSON.parse(item) : null;
  }
  fetchDataGrid = async (params) => {
    this.setState({
      dataGridLoading: true,
    })
    this.sourceDataGrid && this.sourceDataGrid.cancel();
    this.sourceDataGrid = axios.CancelToken.source();
    const { data: response } = await this.loadData({ 
      params: params || {},
      cancelToken: this.sourceDataGrid.token
    });
    if (!response?.result) {
      toast.error(response.message);
      this.setState({
        dataGridCount: null,
      })
      return;
    }
    this.setState((state) => ({
      dataGridLoading: false,
      dataGridData: response.data,
      dataGridCount: ceil(response.total_records/state.dataGridParams.page_size),
    }))
  }
  
  debounceFetchDataGrid = debounce(this.fetchDataGrid,500)

  loadData = async ({ params, cancelToken}) => {
    const { page, page_size, keyword, order, name } = this.state.dataGridParams;
    const request = {
      params: {
        page,
        page_size,
        keyword,
        order,
        ...params
      },
      cancelToken: cancelToken,
    }
    if ( name === CONNECT_SUPPORT_PROJECT ) 
      return await supportProjectApi.getList(request);
    if ( name === CONNECT_SUPPORT_DONATE_PRODUCT ) 
      return await donateProductApi.getList(request);
    return await supportProductApi.getList(request)
  }
  updateDataGridParams = async (params = {}) => { 
    this.setState((state) => ({
      dataGridParams: {
        ...state.dataGridParams,
        ...params
      }
    }))
  }
  getDisplayValue = () => {
    const options = this.state.displayOptions;
    const displayPage = this.state.dataGridParams.name;
    const displayOrder = this.state.dataGridParams.order;
    const option = options.find( o => {
      return o.value.some( v => v.value === displayPage );
    })
    const displayPageValue = option?.value ? option.value.find( v => v.value === displayPage) : null;
    const orderPageValue = displayPageValue?.orders ? displayPageValue.orders.find( order => order.value === displayOrder) : null;
    return {
      selectedDisplayOption: option?.value || null,
      selectedOrderOption: orderPageValue?.value || null,
      pageTitle: displayPageValue?.pageTitle || '',
      orders: displayPageValue?.orders || [],
    };
  }
  getToggleDisplay = () => {
    const displayPageSupportProduct = this.state.displayOptions.find( option => (
      option.value.some( v => v.value === CONNECT_SUPPORT_PRODUCT )
    ))
    if ( [CONNECT_SUPPORT_PROJECT,CONNECT_SUPPORT_MAP].includes(this.state.dataGridParams.name) ) {
      return displayPageSupportProduct ? displayPageSupportProduct.value.find( v => v.value === CONNECT_SUPPORT_PRODUCT ) : null;
    }
    const displayPageValue = this.state.displayOptions.find( option => (
      option.value.some( v => v.value === this.state.dataGridParams.name )
    ));
    if ( displayPageValue ) {
      const displayPageValueIndex = displayPageValue.value.findIndex( v => v.value === this.state.dataGridParams.name);
      return displayPageValueIndex > -1 ? 
        displayPageValueIndex ===  displayPageValue.value.length - 1 ? displayPageValue.value[0] : displayPageValue.value[displayPageValueIndex + 1] 
      : null;
    }
    return displayPageSupportProduct ? displayPageSupportProduct.value.find( v => v.value === CONNECT_SUPPORT_PRODUCT ) : null; 
  }
  confirmLogin = () => {
    const { t } = this.props;
    let confirmLogin = true;
    if ( !this.props.user?.id ) {
      confirmLogin = window.confirm(t("common:Bạn phải đăng nhập trước khi thực hiện"));
    }
    return confirmLogin;
  }

  handlePageChange = (e,value) => {
    this.setState((state) => ({
      dataGridParams: {
        ...state.dataGridParams,
        page: value,
      }
    }))
  }
  handleToggleFilters = () => {
    this.setState((state) => ({
      isOpenFilters: !state.isOpenFilters,
    }))
  }
  handleCloseFilters = () => {
    this.setState({
      isOpenFilters: false,
    })
  }
  handleDisplayChange = ({ name, value }) => {
    const params = {};
    if ( name === 'name' ) {
      const newValue = Array.isArray(value) ? value[0] : value;
      if ( newValue.orders ) {
        const displayValue = this.getDisplayValue();
        const displayValueOrderIndex = displayValue?.orders ? displayValue.orders.findIndex( order => order.value === this.state.dataGridParams.order ) : -1;
        if ( displayValueOrderIndex > -1 && newValue.orders[displayValueOrderIndex] ) {
          Object.assign(params,{ order: newValue.orders[displayValueOrderIndex].value });
        } else Object.assign(params,{ order: newValue.orders[0].value });
      }
      Object.assign(params,{ [name]: newValue.value });
    } else Object.assign(params,{ [name]: value });
    this.updateDataGridParams({ 
      ...params,
      page: 1,
    });
  }
  handleToggleDisplay = ({ name, value }) => {
    if ( typeof value === 'string') {
      this.updateDataGridParams({ [name]: value });
    } else {
      this.updateDataGridParams({ [name]: value[0].value });
    }
  }
  handleSelectItem = ({ action, item, index, params }) => {
    if ( action === 'SUPPORT_NEW_FROM_DONATE' ) {
      this.props.saveConnectSupportSupport({
        donate_id: item.donate_id,
        service_request_items: [{
          name: item.name,
          id: item.id,
          getcare_manufacturer_id: item.getcare_manufacturer_id,
          manufacturer_name: item.manufacturer_name,
          getcare_category_ecom_id: item.getcare_category_ecom_id,
          category_ecom_name: item.category_ecom_name,
          getcare_uom_base_id: item.getcare_uom_base_id,
          uom_base_name: item.uom_base_name,
          quantity_number: params.quantity_number,
          images: item.images,
        }]
      })
      this.confirmLogin() && this.props.history.push(`/community/connect-support/support/new`);
    } 
    if ( action === 'SUPPORT_NEW_FROM_PRODUCT' ) {
      this.props.saveConnectSupportSupport({
        service_request_items: [{
          name: item.name,
          id: item.id,
          getcare_manufacturer_id: item.getcare_manufacturer_id,
          manufacturer_name: item.manufacturer_name,
          getcare_category_ecom_id: item.getcare_category_ecom_id,
          category_ecom_name: item.category_ecom_name,
          getcare_uom_base_id: item.getcare_uom_base_id,
          uom_base_name: item.uom_base_name,
          quantity_number: item.quantity_number,
          images: item.images,
        }]
      })
      this.confirmLogin() && this.props.history.push(`/community/connect-support/support/new`);
    }
    if ( action === 'PRODUCT_DONATE_NEW' ) {
      this.props.saveConnectSupportDonate({
        // ...this.props.connectSupportDonate,
        items: [{
          product_name: item.name,
          getcare_product_id: item.id,
          getcare_manufacturer_id: item.getcare_manufacturer_id,
          manufacturer_name: item.manufacturer_name,
          getcare_uom_base_id: item.getcare_uom_base_id,
          uom_base_name: item.uom_base_name,
          quantity_number: item.quantity_number,
          images: item.images || [],
          price_buy: item.price_buy,
          price_sales: item.price_sales || 0,
          amount: item.amount || 0,
          product_description: item.product_description,
        }],
      })
      // this.props.saveOpenProductInfoFormInput(true)
      this.confirmLogin() && this.props.history.push(`/community/connect-support/donate/new`)
    }
    if ( action === 'PROJECT_DONATE_NEW' ) {
      this.props.saveConnectSupportDonate({
        // ...this.props.connectSupportDonate,
        project_id: item.id,
        project_name: item.name,
        npo_name: item.npo_name
      })
      this.confirmLogin() && this.props.history.push(`/community/connect-support/donate/new`)
    }
    if ( action === 'FAVOURITE' ) {
      const dataGridData = [...this.state.dataGridData];
      if ( dataGridData[index] ) {
        dataGridData[index].favourite = params.favourite;
        this.setState({ 
          dataGridData: [...dataGridData] 
        },async () => {
          let pushFavouriteApi;
          if ( [CONNECT_SUPPORT_PROJECT].includes(params.name) ) {
            pushFavouriteApi = supportApi.pushFavourite;
          } else if ( [CONNECT_SUPPORT_PRODUCT].includes(params.name) ) {
            pushFavouriteApi = supportProductApi.pushFavourite;
          } else {
            pushFavouriteApi = donateApi.pushFavourite;
          }
          console.log(item,{
            [params.request_key]: item[params.request_value_key]
          })
          const { data: response } = await pushFavouriteApi({ params: {
            [params.request_key]: item[params.request_value_key]
          }})
          if (!response?.result) {
            toast.error(response.message);
            const dataGridData = [...this.state.dataGridData];
            const dataGridDataIndex = dataGridData.findIndex( data => data[params.request_value_key] === item[params.request_value_key]);
            if ( dataGridDataIndex > -1 ) {
              dataGridData[dataGridDataIndex].favourite = !params.favourite;
              this.setState({
                dataGridData: [...dataGridData],
              })
            }
          }
        });
      }
    }
    if ( action === 'OPEN_CHARITY_RECIPIENT_LIST_DIALOG' ) {
      this.setState({
        charityRecipientListDialogOpen: true,
        charityRecipientListDialogParams: {
          donate_id: item.donate_id,
        },
      })
    }
  }

  render() {
    const { isOpenFilters } = this.state;
    const { t } = this.props;
    const displayValue = this.getDisplayValue();
    const toggleDisplay = this.getToggleDisplay();

    return (
      <>
        <ConnectSupportTabs 
          className={classes.Tabs}
        />
        <ConnectSupportToolbar 
          className={classes.Toolbar}
          keyword={this.state.dataGridParams.keyword}
          oncKeywordChange={(value) => this.updateDataGridParams({ keyword: value, page: 1 })}
        />
        <div className={classes.DataGrid}>
          <div className={classes.DataGrid__header}>
            <h2 className={`${classes.DataGrid__header__title} ${classes['DataGrid__header--primaryColor']}`}>
              { t(displayValue.pageTitle) }
            </h2>
            <div className={classes.DataGrid__header__actions}>
              {
                toggleDisplay?.btnGoToPageLabel && 
                <Button
                  className={`${classes['DataGrid__header--primaryColor']} ${classes['DataGrid__header__button']} Text--uppercase`}
                  onClick={() => this.handleDisplayChange({ name: 'name', value: toggleDisplay })}
                >
                  <EyeIcon style={{width: '20px',height: '20px'}} className={classes['DataGrid__header__icon']}/> {t(toggleDisplay.btnGoToPageLabel)}
                </Button>
              }
              <Button
                className={`${classes['DataGrid__header--primaryColor']} ${classes['DataGrid__header__button']} Text--uppercase`}
                onClick={this.handleToggleFilters}
                ref={this.anchorElFilters}
              >
                <DisplayIcon style={{width: '20px',height: '20px'}} className={classes['DataGrid__header__icon']}/> {t('Hiển thị')}
              </Button>
              <Popper placement="bottom-end" open={isOpenFilters} anchorEl={this.anchorElFilters?.current} role={undefined} transition disablePortal style={{zIndex: 9999}}>
                {({ TransitionProps }) => (
                  <Grow
                    {...TransitionProps}
                    style={{ transformOrigin: 'bottom' }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={this.handleCloseFilters}>
                        <FormControl classes={{ root: classes.Filters }}>
                          <Box>
                            <FormLabel classes={{ root: classes.Filters__label }} component="legend">
                              {t('Hiển thị')}
                            </FormLabel>
                            <RadioGroup name="name" value={JSON.stringify(displayValue.selectedDisplayOption)} aria-label="gender" 
                              onChange={(e) => this.handleDisplayChange({ name: e.target.name, value: JSON.parse(e.target.value) })} 
                            >
                              {
                                this.state.displayOptions.map( (displayOption,displayOptionKey) => (
                                  <FormControlLabel key={displayOptionKey} value={JSON.stringify(displayOption.value)} control={<Radio />} label={t(displayOption.label)} />
                                ))
                              }
                            </RadioGroup>
                          </Box>
                          {
                            (displayValue.orders && displayValue.orders.length > 0) && 
                            <Box>
                              <FormLabel classes={{ root: classes.Filters__label }} component="legend">
                                {t('Sắp xếp')}
                              </FormLabel>
                              <RadioGroup name="order" value={displayValue.selectedOrderOption}
                                onChange={(e) => this.handleDisplayChange({ name: e.target.name, value: e.target.value })} 
                              >
                                {
                                  displayValue.orders.map( (order,orderKey) => (
                                    <FormControlLabel key={orderKey} value={order.value} control={<Radio />} label={t(order.label)} />
                                  ))
                                }
                              </RadioGroup>
                            </Box>
                          }
                        </FormControl>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
          </div>
          <div className={classes.DataGrid__body}>
            {
              [CONNECT_SUPPORT_PROJECT,CONNECT_SUPPORT_PRODUCT,CONNECT_SUPPORT_DONATE_PRODUCT].includes(this.state.dataGridParams.name) ? 
              <ConnectSupportCardList
                name={this.state.dataGridParams.name}
                data={this.state.dataGridData}
                loading={this.state.dataGridLoading}
                onSelectItem={this.handleSelectItem}
              /> : <div className={classes.PageEmpty}>
                Tính năng sẽ phát triển trong tương lai bạn nhé :)
              </div>
            }
          </div>
          <div className={classes.DataGrid__footer}>
            {
              (this.state.dataGridCount && [CONNECT_SUPPORT_PROJECT,CONNECT_SUPPORT_PRODUCT,CONNECT_SUPPORT_DONATE_PRODUCT].includes(this.state.dataGridParams.name)) ? 
              <Pagination count={this.state.dataGridCount} page={this.state.dataGridParams.page} color="primary" shape="rounded" 
                onChange={this.handlePageChange}
              /> : ''
            }
          </div>
        </div>
        <CharityRecipientListDialog
          open={this.state.charityRecipientListDialogOpen}
          params={this.state.charityRecipientListDialogParams}
          onClose={() => this.setState({ charityRecipientListDialogOpen: false })}
        />
      </>
    );
  }
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectLoginUser(),
  connectSupportSupport: makeSelectConnectSupportSupport(),
  connectSupportDonate: makeSelectConnectSupportDonate()
});
const mapDispatchToProps = (dispatch) => {
  return {
    saveConnectSupportSupport: (payload) => dispatch(saveConnectSupportSupport(payload)),
    saveConnectSupportDonate: (payload) => dispatch(saveConnectSupportDonate(payload)),
    saveOpenProductInfoFormInput: (payload) => dispatch(saveOpenProductInfoFormInput(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withRouter, withTranslation(['viewMainEcomConnectSupport','common']))(ConnectSupport);
