import React, { PureComponent } from 'react';
import * as Yup from 'yup';
import axios from 'axios';
import { debounce } from 'lodash';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { withTranslation } from "react-i18next";

import { validNumber } from 'utils/helper';
import ecomCommonApi from 'utils/api/ecom/commonApi';
import authApi from 'utils/api/authApi';
// import { mapTreeCategoriesToList } from 'utils/constanst/ecomCategoryConstants';

import {
  IconButton,
  Grid,
  CircularProgress,
  InputAdornment,
} from '@material-ui/core';
import { Formik } from 'formik';
import NumberFormatCustom from 'components/NumberFormatCustom/NumberFormatCustom';
import StyledTextField from 'components/Styled/TextField';
import StyledAutocomplete from 'components/Styled/Autocomplete/Autocomplete';
import FileUpload from 'components/FileUpload/FileUpload';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import CloseIcon from '@material-ui/icons/Close';

import styles from './FormInput.module.scss';
import stylesLayoutMainEcom from 'assets/styles/layouts/LayoutMainEcom.module.scss';

const PRODUCT_TYPE = 'product';
// const CATEGORY_TYPE = 'category';
const MANUFACTURE_TYPE = 'manufacture';
const UOM_BASE_TYPE = 'uom_base';
const mapOptions = (data,{ labelKey, valueKey }) => data.map( item => ({
  label: item[labelKey],
  value: item[valueKey],
  data: item,
}))
const typeConfig = {
  [PRODUCT_TYPE]: {
    fetchData: ecomCommonApi.getProductList,
    // addParams: ({getcare_manufacturer_id,
    //   getcare_category_ecom_id,
    //   getcare_uom_base_id}) => {
    //   return { getcare_uom_base_id, getcare_category_ecom_id, getcare_manufacturer_id }
    // },
    labelKey: 'name',
    valueKey: 'id',
    idKey: 'getcare_product_id',
  },
  [MANUFACTURE_TYPE]: {
    fetchData: ecomCommonApi.getManufacturerList,
    // addParams: ({getcare_product_id,
    //   getcare_category_ecom_id,
    //   getcare_uom_base_id}) => {
    //   return { getcare_uom_base_id, getcare_category_ecom_id, getcare_product_id }
    // },
    labelKey: 'name',
    valueKey: 'id',
    idKey: 'getcare_manufacturer_id',
  },
  // [CATEGORY_TYPE]: {
  //   fetchData: ecomCommonApi.getCategoryEcomList,
  //   // addParams: ({getcare_product_id,
  //   //   getcare_manufacturer_id,
  //   //   getcare_uom_base_id}) => {
  //   //   return { getcare_uom_base_id, getcare_manufacturer_id, getcare_product_id }
  //   // },
  //   mapResults: (data) => {
  //     return mapTreeCategoriesToList(data || []);
  //   },
  //   labelKey: 'name',
  //   valueKey: 'id',
  //   idKey: 'getcare_category_ecom_id',
  // }, 
  [UOM_BASE_TYPE]: {
    fetchData: ecomCommonApi.getUomBaseList,
    addParams: ({getcare_product_id}) => {
      return { getcare_product_id }
    },
    labelKey: 'name',
    valueKey: 'id',
    idKey: 'getcare_uom_base_id',
  },
}

class FormInput extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loadingOptions: {
        [PRODUCT_TYPE]: false,
        // [CATEGORY_TYPE]: false,
        [MANUFACTURE_TYPE]: false,
        [UOM_BASE_TYPE]: false,
      },
      options: {
        [PRODUCT_TYPE]: [],
        // [CATEGORY_TYPE]: [],
        [MANUFACTURE_TYPE]: [],
        [UOM_BASE_TYPE]: [],
      },
      keywords: {
        [PRODUCT_TYPE]: '',
        // [CATEGORY_TYPE]: '',
        [MANUFACTURE_TYPE]: '',
        [UOM_BASE_TYPE]: '',
      },
      selectedValues: {
        [PRODUCT_TYPE]: '',
        // [CATEGORY_TYPE]: '',
        [MANUFACTURE_TYPE]: '',
        [UOM_BASE_TYPE]: '',
      },
      onFocusInput: '',
    }
    this.source = {
      [PRODUCT_TYPE]: null,
      // [CATEGORY_TYPE]: null,
      [MANUFACTURE_TYPE]: null,
      [UOM_BASE_TYPE]: null,
    }
    this.refProductInfoFormik = React.createRef();
    this.refForm = React.createRef();
  }
  componentDidMount() {
    const {
      product_name,
      getcare_product_id,
      getcare_uom_base_id,
      uom_base_name,
      // getcare_category_ecom_id,
      // category_ecom_name,
      getcare_manufacturer_id,
      manufacturer_name,
    } = this.props.data;
    this.setState((state) => ({
      selectedValues: { 
        ...state.selectedValues, 
        [PRODUCT_TYPE]: product_name ? { label: product_name, value: getcare_product_id || '' } : null,
        // [CATEGORY_TYPE]: category_ecom_name ? { label: category_ecom_name, value: getcare_category_ecom_id || '' } : null,
        [MANUFACTURE_TYPE]: manufacturer_name ? { label: manufacturer_name, value: getcare_manufacturer_id || '' } : null,
        [UOM_BASE_TYPE]: uom_base_name ? { label: uom_base_name, value: getcare_uom_base_id || '' } : null,
      }
    }))
  }
  
  getInitialValues = () => {
    const {
      _index,
      product_name,
      getcare_product_id,
      getcare_uom_base_id,
      uom_base_name,
      getcare_manufacturer_id,
      manufacturer_name,
      quantity_number,
      images,
      price_sales,
      amount,
      product_description,
    } = this.props.data;

    return {
      _index,
      product_name: product_name || '',
      getcare_product_id: getcare_product_id || '',
      getcare_manufacturer_id: getcare_manufacturer_id || '',
      manufacturer_name: manufacturer_name || '',
      getcare_uom_base_id: getcare_uom_base_id || '',
      uom_base_name: uom_base_name || '',
      quantity_number: validNumber(quantity_number),
      images: images || [],
      price_sales: validNumber(price_sales),
      amount: validNumber(amount),
      product_description: product_description || '',
    }
  }
  getValidationSchema = () => {
    const { t } = this.props;
    return Yup.object().shape({
      product_name: Yup.string().required(t('Vui lòng nhập thông tin')).max(255, t('Tối đa 255 ký tự')),
      uom_base_name: Yup.string().required(t('Vui lòng nhập thông tin')).max(255, t('Tối đa 255 ký tự')),
      quantity_number: Yup.number().nullable().required(t('Vui lòng nhập số lượng')).min(1, t('Vui lòng nhập giá trị tối thiểu 1')),
      // price_sales: Yup.number().nullable().required('Vui lòng nhập thông tin'),
    })
  }

  loadOptions = async (type, request = {}) => {
    this.source[type] && this.source[type].cancel();
    this.setState(state => ({
      options: {
        ...state.options,
        [type]: [],
      },
      loadingOptions: {
        ...state.loadingOptions,
        [type]: true,
      }
    }),() => {
      this.debounceFetchOptions(type,request);
    })
  }
  debounceFetchOptions = debounce(async (type,{ params }) => {
    const { 
      getcare_product_id,
      getcare_manufacturer_id,
      // getcare_category_ecom_id,
      getcare_uom_base_id,
    } = this.refProductInfoFormik.current.values;
    const setFieldValue = this.refProductInfoFormik.current.setFieldValue;
    const addParams = typeConfig[type].addParams ? typeConfig[type].addParams({
        getcare_product_id,
        getcare_manufacturer_id,
        // getcare_category_ecom_id,
        getcare_uom_base_id,
      }) : {};
    let options = [];
    this.source[type] = axios.CancelToken.source();
    const { data: response } = await typeConfig[type].fetchData({
      params: {
        page: 1,
        page_size: 10,
        ...addParams,
        ...params,
      },
      cancelToken: this.source[type].token
    });
    if (response?.result) {
      const data = typeConfig[type].mapResults ? typeConfig[type].mapResults(response.data) : response.data;
      options = data ? mapOptions(
        data,{ 
          labelKey: typeConfig[type].labelKey, 
          valueKey: typeConfig[type].valueKey,
        }
      ) : [];
      // matched name and update values
      const option = this.state.keywords[type].trim() && options.find(option => this.state.keywords[type].trim().toLowerCase() === option.label.toLowerCase());
      if ( option ) { 
        setFieldValue(typeConfig[type].idKey,option.value);
        this.state.selectedValues[type] && this.setState((state) => ({
          selectedValues: { ...state.selectedValues, [type]: {...state.selectedValues[type], value: option.value} },
        }))
      }
    }
    this.setState(state => ({
      options: {
        ...state.options,
        [type]: options,
      },
      loadingOptions: {
        ...state.loadingOptions,
        [type]: false,
      }
    }));
  },250)
  getOptions = (type) => {
    if ( this.state.loadingOptions[type] ) return []
    return this.state.options[type]
  }
  prepareData = (data) => {
    return {
      ...data,
      _index: data._index,
      product_name: data.product_name || '',
      getcare_product_id: data.getcare_product_id || null,
      getcare_manufacturer_id: data.getcare_manufacturer_id || null,
      manufacturer_name: data.manufacturer_name || '',
      getcare_uom_base_id: data.getcare_uom_base_id || null,
      uom_base_name: data.uom_base_name || '',
      quantity_number: validNumber(data.quantity_number),
      images: data.images || [],
      price_sales: validNumber(data.price_sales) || 0,
      amount: validNumber(data.amount) || 0,
      product_description: data.product_description || null,
    }
  }
  submitForm = () => {
    this.refProductInfoFormik.current.validateForm().then( error => {
      if ( Object.keys(error).length === 0 ) this.refProductInfoFormik.current.submitForm();
      for ( const err in error ) {
        const el = this.refForm.current.querySelector(`input[name="${err}"]`);
        if ( el ) { el.focus(); break; }
      }
    })
  }

  handleSubmit = (values) => {
    this.props.onSubmit(this.prepareData({...values}))
  }
  handleFilesAdded = async ({ files }) => {
    let { images } = { ...this.refProductInfoFormik.current.values };
    const setFieldValue = this.refProductInfoFormik.current.setFieldValue
    images = [...images,...files.map( file => null )];
    setFieldValue("images",[...images])
    try {
      const { data: response } = await authApi.uploadMultipleFiles({
        params: { file: files },
      });
      if (!response?.result) {
        setFieldValue('images',
          [...images.slice(0,-files.length)]
        );
        toast.error(response.message);
      } else 
      setFieldValue('images',
          [...images.slice(0,-files.length),
          ...response.data.map(item => item.url)]
        );
    } catch(err) {
      toast.error(err);
    }
  }
  handleRemoveImage = (index) => {
    let images = [...this.refProductInfoFormik.current.values.images]
    const setFieldValue = this.refProductInfoFormik.current.setFieldValue
    images.splice(index, 1);
    setFieldValue('images',images);
  }
  
  render() {
    const { t } = this.props;

    return (
      <Formik
        innerRef={this.refProductInfoFormik}
        initialValues={this.getInitialValues()}
        enableReinitialize
        onSubmit={(values) => {
          this.handleSubmit(values);
        }}
        validationSchema={this.getValidationSchema()}
      >
        {
          (props) => {
            const {
              values,
              errors,
              handleChange,
              setFieldValue,
            } = props;

            return (
              <form ref={this.refForm} autoComplete="off">
                <div className={`${stylesLayoutMainEcom.FormControl}`}>
                  <label className={errors.product_name && stylesLayoutMainEcom['FormLabel--error']} htmlFor="product_name">
                    {t('Tên sản phẩm')} <span>*</span>
                  </label>
                  <StyledAutocomplete
                    id="product_name"
                    fullWidth
                    autoComplete
                    filterOptions={(x) => x}
                    value={this.state.selectedValues[PRODUCT_TYPE]}
                    options={this.getOptions(PRODUCT_TYPE)}
                    noOptionsText={t('common:Không tìm thấy kết quả')}
                    getOptionLabel={(option) => option.label || ''}
                    getOptionSelected={(option, value) => option.value === value.value}
                    renderOption={(option) => option.value ? option.label : option.label ? `+ ${option.label}` : ''}
                    onInputChange={(event, newInputValue,reason) => {
                      if ( ['input','clear'].includes(reason) ) {
                        setFieldValue("product_name",newInputValue)
                        setFieldValue("getcare_product_id",'',false)
                        this.setState((state) => ({
                          keywords: { ...state.keywords, [PRODUCT_TYPE]: newInputValue },
                          selectedValues: { ...state.selectedValues, [PRODUCT_TYPE]: newInputValue ? { label: newInputValue , value: '' } : null },
                        }))
                        this.loadOptions(PRODUCT_TYPE,{ params: { keyword: newInputValue }}) 
                      };
                    }}
                    loading={this.state.loadingOptions[PRODUCT_TYPE]}
                    loadingText={`${t('common:Đang tìm kiếm')}...`}
                    renderInput={(params) => (
                        <StyledTextField
                          {...params}
                          placeholder={t('Nhập tên sản phẩm hoặc chọn sản phẩm gợi ý')}
                          variant="outlined"
                          size="medium"
                          name="product_name"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {this.state.loadingOptions[PRODUCT_TYPE] ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                          error={!!errors.product_name}
                          helperText={errors.product_name}
                        />
                    )}
                    onOpen={() => this.loadOptions(PRODUCT_TYPE)}
                    onChange={(e, newValue) => {
                      setFieldValue("product_name",newValue?.label || '')
                      setFieldValue("getcare_product_id",newValue?.value || '',false)
                      this.setState((state) => ({
                        selectedValues: { ...state.selectedValues, [PRODUCT_TYPE]: newValue },
                        keywords: { ...state.keywords, [PRODUCT_TYPE]: '' },
                      }))
                    }}
                  />
                </div>
                <div className={`${stylesLayoutMainEcom.FormControl}`}>
                  <label htmlFor="manufacturer_name">
                    {t('Nhà sản xuất')}
                  </label>
                  <StyledAutocomplete
                    id="manufacturer_name"
                    fullWidth
                    autoComplete
                    filterOptions={(x) => x}
                    value={this.state.selectedValues[MANUFACTURE_TYPE]}
                    options={this.getOptions(MANUFACTURE_TYPE)}
                    getOptionLabel={(option) => option.label || ''}
                    getOptionSelected={(option, value) => option.value === value.value}
                    noOptionsText={t('common:Không tìm thấy kết quả')}
                    renderOption={(option) => option.value ? option.label : option.label ? `+ ${option.label}` : ''}
                    onInputChange={(event, newInputValue,reason) => {
                      if ( ['input','clear'].includes(reason) ) {
                        setFieldValue("manufacturer_name",newInputValue)
                        setFieldValue("getcare_manufacturer_id",'',false)
                        this.setState((state) => ({
                          keywords: { ...state.keywords, [MANUFACTURE_TYPE]: newInputValue },
                          selectedValues: { ...state.selectedValues, [MANUFACTURE_TYPE]: newInputValue ? { label: newInputValue , value: '' } : null },
                        }))
                        this.loadOptions(MANUFACTURE_TYPE,{ params: { name: newInputValue }}) 
                      };
                    }}
                    loading={this.state.loadingOptions[MANUFACTURE_TYPE]}
                    loadingText={`${t('common:Đang tìm kiếm')}...`}
                    renderInput={(params) => (
                        <StyledTextField
                          {...params}
                          placeholder={t('Nhập nhà sản xuất hoặc chọn nhà sản xuất gợi ý')}
                          variant="outlined"
                          size="medium"
                          name="manufacturer_name"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {this.state.loadingOptions[MANUFACTURE_TYPE] ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                    )}
                    onOpen={() => this.loadOptions(MANUFACTURE_TYPE)}
                    onChange={(e, newValue) => {
                      setFieldValue("manufacturer_name",newValue?.label || '', false)
                      setFieldValue("getcare_manufacturer_id",newValue?.value || '', false)
                      this.setState((state) => ({
                        selectedValues: { ...state.selectedValues, [MANUFACTURE_TYPE]: newValue },
                        keywords: { ...state.keywords, [MANUFACTURE_TYPE]: '' },
                      }))
                    }}
                  />
                </div>
                <div className={`${stylesLayoutMainEcom.FormControl}`}>
                  <label className={errors.uom_base_name && stylesLayoutMainEcom['FormLabel--error']} htmlFor="uom_base_name">
                    {t('Đơn vị')} <span>*</span>
                  </label>
                  <StyledAutocomplete
                    id="uom_base_name"
                    fullWidth
                    autoComplete
                    filterOptions={(x) => x}
                    value={this.state.selectedValues[UOM_BASE_TYPE]}
                    options={this.getOptions(UOM_BASE_TYPE)}
                    noOptionsText={t('common:Không tìm thấy kết quả')}
                    getOptionLabel={(option) => option.label || ''}
                    getOptionSelected={(option, value) => option.value === value.value}
                    renderOption={(option) => option.value ? option.label : option.label ? `+ ${option.label}` : ''}
                    onInputChange={(event, newInputValue,reason) => {
                      if ( ['input','clear'].includes(reason) ) {
                        setFieldValue("uom_base_name",newInputValue)
                        setFieldValue("getcare_uom_base_id",'',false)
                        this.setState((state) => ({
                          keywords: { ...state.keywords, [UOM_BASE_TYPE]: newInputValue },
                          selectedValues: { ...state.selectedValues, [UOM_BASE_TYPE]: newInputValue ? { label: newInputValue , value: '' } : null },
                        }))
                        this.loadOptions(UOM_BASE_TYPE,{ params: { name: newInputValue }});
                      }
                    }}
                    loading={this.state.loadingOptions[UOM_BASE_TYPE]}
                    loadingText={`${t('common:Đang tìm kiếm')}...`}
                    renderInput={(params) => (
                        <StyledTextField
                          {...params}
                          placeholder={t('Nhập đơn vị hoặc chọn đơn vị gợi ý')}
                          variant="outlined"
                          size="medium"
                          name="uom_base_name"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {this.state.loadingOptions[UOM_BASE_TYPE] ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                          error={!!errors.uom_base_name}
                          helperText={errors.uom_base_name}
                        />
                    )}
                    onOpen={() => this.loadOptions(UOM_BASE_TYPE)}
                    onChange={(e, newValue) => {
                      setFieldValue("uom_base_name",newValue?.label || '')
                      setFieldValue("getcare_uom_base_id",newValue?.value || '',false)
                      this.setState((state) => ({
                        selectedValues: { ...state.selectedValues, [UOM_BASE_TYPE]: newValue },
                        keywords: { ...state.keywords, [UOM_BASE_TYPE]: '' },
                      }))
                    }}
                  />
                </div>
                <div className={`${stylesLayoutMainEcom.FormControl}`}>
                  <label htmlFor="quantity_number">
                    {t('Số lượng')} <span>*</span>
                  </label>
                  <StyledTextField
                    placeholder={t('Nhập số lượng')}
                    size="medium"
                    fullWidth
                    variant="outlined"
                    name="quantity_number"
                    id="quantity_number"
                    value={values.quantity_number}
                    error={!!errors.quantity_number}
                    helperText={errors.quantity_number}
                    onChange={(e)=> {
                      const quantity_number = validNumber(e.target.value);
                      const price_sales = values.price_sales;
                      const amount = price_sales ? (quantity_number || 0)*price_sales : '';
                      if ( this.state.onFocusInput === e.target.name ) {
                        setFieldValue("quantity_number",quantity_number)
                        // setFieldValue("amount",amount,false);
                      }
                    }}
                    onFocus={(e) => this.setState({ onFocusInput: e.target.name })}
                    size="medium"
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                  />
                </div>
                <div className={`${stylesLayoutMainEcom.FormControl}`}>
                  <label htmlFor="price_sales">
                    {t('Giá bán')} <span>*</span>
                  </label>
                  <StyledTextField
                    placeholder={t('Nhập giá bán')}
                    size="medium"
                    fullWidth
                    variant="outlined"
                    name="price_sales"
                    id="price_sales"
                    value={values.price_sales}
                    error={!!errors.price_sales}
                    helperText={errors.price_sales}
                    onChange={(e)=> {
                      const price_sales = validNumber(e.target.value);
                      const quantity_number = values.quantity_number || 0;
                      const amount = price_sales ? quantity_number*price_sales : '';
                      if ( this.state.onFocusInput === e.target.name ) {
                        setFieldValue("price_sales",price_sales);
                        // setFieldValue("amount",amount,false);
                      }
                    }}
                    onFocus={(e) => this.setState({ onFocusInput: e.target.name })}
                    size="medium"
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                      endAdornment: <InputAdornment position="end">đ</InputAdornment>
                    }}
                  />
                </div>
                <div className={`${stylesLayoutMainEcom.FormControl}`}>
                  <label htmlFor="amount">
                    {t('Giá trị tặng')} <span>*</span>
                  </label>
                  <StyledTextField
                    placeholder={t('Nhập giá trị tặng')}
                    size="medium"
                    fullWidth
                    variant="outlined"
                    name="amount"
                    id="amount"
                    value={values.amount}
                    error={!!errors.amount}
                    helperText={errors.amount}
                    onChange={(e)=> {
                      const quantity_number = values.quantity_number || 0;
                      const amount = validNumber(e.target.value);
                      const price_sales = amount ? amount/quantity_number : 0;
                      if ( this.state.onFocusInput === e.target.name ) {
                        setFieldValue("amount",amount);
                        // setFieldValue("price_sales",price_sales,false);
                      }
                    }}
                    onFocus={(e) => this.setState({ onFocusInput: e.target.name })}
                    size="medium"
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                      endAdornment: <InputAdornment position="end">đ</InputAdornment>
                    }}
                  />
                </div>
                <div className={`${stylesLayoutMainEcom.FormControl}`}>
                  <label htmlFor="product_description">
                    {t('Mô tả sản phẩm')}
                  </label>
                  <StyledTextField
                    placeholder={t('Nhập mô tả sản phẩm')}
                    size="medium"
                    fullWidth
                    multiline
                    variant="outlined"
                    id="product_description"
                    name="product_description"
                    value={values.product_description}
                    onChange={handleChange}
                  />
                </div>
                <div className={`${stylesLayoutMainEcom.FormControl} ${stylesLayoutMainEcom.Margin__0}`}>
                  <label htmlFor="image">
                    {t('Hình ảnh')}
                  </label>
                  <Grid container spacing={2}>
                    <Grid xs={6} sm={3} item>
                      <FileUpload
                        multiple
                        accept="image/*"
                        inputId="image"
                        className={styles.FileUpload}
                        helper={
                          <>
                            <InsertPhotoIcon style={{ fontSize: 64, color: '#808080'}}/>
                            <h3>{t('Tải ảnh')}</h3>
                          </>
                        }
                        onFilesAdded={(files) => this.handleFilesAdded({ files })}
                      />
                    </Grid>
                    {
                      values.images.length > 0 && 
                      values.images.map( (image,index) => (
                        <Grid xs={6} sm={3} key={index} item>
                          <div className={`${styles.Image} ${!image && 'BgImage--loading'} BgImage--rounded BgImage`} style={{ backgroundImage: `url(${image})` }}> 
                            <div className={styles.Image__actions}> 
                              <IconButton onClick={() => this.handleRemoveImage(index)} size="small" color="primary">
                                <CloseIcon />
                              </IconButton>
                            </div>
                          </div> 
                        </Grid>
                      ))
                    }
                  </Grid>
                </div>
              </form>
            )
          }
        }
      </Formik>
    );
  }
}

FormInput.propTypes = {
  data: PropTypes.object,
};

FormInput.defaultProps = {
  data: {}
}
export default withTranslation(['componentConnectSupportDonateForm','common'],{ withRef: true })(FormInput);
