import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const styles = (theme) => ({
    root: {
        borderRadius: 4,
        '& .MuiInputBase-root': {
            backgroundColor: '#EFF7FF',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'transparent',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'transparent',
            },
            '&:hover fieldset': {
                borderColor: theme.palette.primary.main,
            },
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.primary.main,
            },
            '&.Mui-focused:hover fieldset': {
                borderColor: theme.palette.primary.main,
            },
        },
        // '& .MuiOutlinedInput-adornedEnd': {
        //     paddingRight: 0,
        // },
        // '& .MuiOutlinedInput-adornedStart': {
        //     paddingLeft: 10,
        // },
        '& .MuiOutlinedInput-input': {
            paddingTop: 15,
            paddingBottom: 15,
        },
        // '& .MuiButton-root': {
        //     height: theme.defaultHeight || 40,
        //     minWidth: theme.defaultHeight || 40,
        //     backgroundColor: '#ED1C24',
        //     borderTopLeftRadius: 0,
        //     borderBottomLeftRadius: 0,
        // },
        '& .MuiOutlinedInput-inputMultiline': {
            padding: '0 !important',
        }
    },
});

export default withStyles(styles)(
    React.forwardRef( ({ classes, innerRef, color, ...other }, ref) =>
        <TextField ref={ref} className={classes.root} {...other} />
    )
);

// export default withStyles(styles)(TextField);