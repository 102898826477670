import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import filterClasses from './FilterField.module.scss';

class MultiSelect extends PureComponent {
  getValueMaps = (valueArr) => {
    return this.props.options.filter(opt => valueArr.includes(Number(opt.id)) || valueArr.includes(opt.id + ``));
  }

  handleChange = (e, newValue) => {
    this.props.handleFieldChange({
      name: this.props.name,
      value: newValue.map(item => item.id),
    });
  }

  render() {
    const { name, value, placeholder, options, optionLabelName } = this.props;

    return (<Autocomplete
      multiple
      size="small"
      name={name}
      disableClearable
      options={options}
      handleHomeEndKeys={false}
      value={this.getValueMaps(value) || []}
      onChange={this.handleChange}
      getOptionLabel={(option) => option[optionLabelName]}
      getOptionSelected={(option, value) => {
        return value && value.id && option ? String(option.id) === String(value.id) : null
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          className={`${filterClasses.Field} TextFieldOutlined`}
          variant="outlined"
          fullWidth
          placeholder={placeholder}
        />
      )}
    />);
  }
}

MultiSelect.propTypes = {
  placeholder: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.array,
  options: PropTypes.array,
  optionLabelName: PropTypes.string,
  handleFieldChange: PropTypes.func,
};

MultiSelect.defaultProps = {
  value: [],
  options: [],
  optionLabelName: 'name',
};

export default MultiSelect;
