import React, { PureComponent } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from "reselect";
import { makeSelectLoginUser } from 'redux/selectors';
import { isCompanyUser } from 'utils/constanst/authConstants';
import { withTranslation } from "react-i18next";
import { NavLink } from 'react-router-dom';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SmsIcon from '@material-ui/icons/Sms';
import classes from './MyProfileNav.module.scss';

class MyProfileNav extends PureComponent {
  getNavLinkClass = (pathArr) => {
    return pathArr.some((path) => this.props.location.pathname.includes(path))
      ? classes.Active
      : '';
  };
  handleRegisterClick = () => {
    this.props.history.push(`/myprofile/gppregister`);
  }

  render() {
    const { t } = this.props;

    return (<nav className={classes.Nav}>
      <ul>
        <li>
          <NavLink
            to="/myprofile/personalinfo"
            className={this.getNavLinkClass(['/myprofile/personalinfo'])}
          >
            <PermIdentityIcon fontSize="small" className={classes.Icon}/>
            {t('Thông tin cá nhân')}
            <NavigateNextIcon className={`${classes.Icon} ${classes.ArrowIcon}`}/>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/myprofile/changepassword"
            className={this.getNavLinkClass(['/myprofile/changepassword'])}
          >
            <LockOutlinedIcon fontSize="small" className={classes.Icon}/>
            {t('Đổi mật khẩu')}
            <NavigateNextIcon className={`${classes.Icon} ${classes.ArrowIcon}`}/>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/myprofile/phone-authentication"
            className={this.getNavLinkClass(['/myprofile/phone-authentication'])}
          >
            <SmsIcon fontSize="small" className={classes.Icon}/>
            {t('Xác thực qua SMS')}
            <NavigateNextIcon className={`${classes.Icon} ${classes.ArrowIcon}`}/>
          </NavLink>
        </li>
        { isCompanyUser(this.props.user) &&
          <li>
    
            <NavLink
            to="/myprofile/gppregister"  onClick={this.handleRegisterClick}
            className={this.getNavLinkClass(['/myprofile/gppregister'])} >
              <ExitToAppIcon fontSize="small" className={classes.Icon}/>
              {t('Đăng ký phần mềm quản lý')}
              </NavLink>
          </li>
        }
      </ul>
    </nav>);
  }
}

const mapStateToProps = createStructuredSelector({
  user: makeSelectLoginUser(),
});
const withConnect = connect(mapStateToProps, null);
export default withTranslation()(compose(withConnect,withRouter)(MyProfileNav));
