import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectSalesOrder } from 'redux/selectors';
import { withRouter } from 'react-router-dom';
import { isEqual, debounce } from 'lodash';
import salesOrderApi from 'utils/api/oms/salesOrderApi';
import { getStatusClass } from 'utils/constanst/omsSalesOrderConstants';
import { validDate } from 'utils/helper';
import { dateTimeFormat } from 'utils/constanst/dateConstants';
import { format } from 'date-fns';
import { withTranslation } from "react-i18next";

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import StoreOutlinedIcon from '@material-ui/icons/StoreOutlined';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';

import classes from './SalesOrderInfo.module.scss';

class SalesOrderInfo extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      codeSearchInput: '',
      codeOptions: [],
      selectedCode: null,
    }
  }
  componentDidMount() {
    if (this.props.salesOrder) {
      this.setState({
        ...this._initState(this.props.salesOrder),
      });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { salesOrder } = this.props;
    if (salesOrder && !isEqual(salesOrder, prevProps.salesOrder)) {
      this.setState({
        ...this._initState(salesOrder),
      });
    }
    const { codeSearchInput } = this.state;
    if (codeSearchInput && codeSearchInput !== prevState.codeSearchInput) {
      this._loadSuggestedCodes({
        params: {
          code: codeSearchInput,
        }
      });
    }
  }

  _initState = (params) => {
    return {
      codeSearchInput: params.code,
      selectedCode: {
        id: params.id,
        code: params.code,
      },
      codeOptions: [{id: params.id, code: params.code}],
    }
  }
  _loadSuggestedCodes = debounce(async ({ params }) => {
    const { data: response } = await salesOrderApi.getAll({ params });
    if (!response?.result) return;
    this.setState({
      codeOptions: response.data ? response.data : [],
    });
  }, 500)

  handleChangeSelectedCode = (e, newValue) => {
    if (!newValue) return;
    this.props.history.push(`/so/${newValue.code}`);
  }

  render() {
    const { salesOrder, t } = this.props;
    const { selectedCode, codeOptions, codeSearchInput } = this.state;

    return (
      <div className={classes.Wrap}>
        <div className={classes.ColInner}>
          <div className={`${classes.Info} ${classes.CodeInfo}`}>
            <label>{t('Mã đơn hàng')}</label>
            <Autocomplete
              size="small"
              disableClearable
              handleHomeEndKeys={false}
              value={selectedCode || null}
              onChange={this.handleChangeSelectedCode}
              inputValue={codeSearchInput}
              onInputChange={(e, newValue) => {
                this.setState({
                  codeSearchInput: newValue,
                });
              }}
              id="combo-box-codes"
              options={codeOptions}
              filterOptions={(x) => x}
              renderOption={(option) => option.code}
              getOptionLabel={(option) => option?.code || ''}
              getOptionSelected={(option, value) =>
                value && option && value.id && option.id ? option.id === value.id : null
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                />
              )}
              popupIcon={<SearchOutlinedIcon/>}
              className={classes.NoTransformPopupIconAutocomplete}
            />
          </div>
          <div className={`${classes.Info}`}>
            <label>{t('Trạng thái')}</label>
            <span className={getStatusClass(salesOrder?.getcare_order_status?.id)}>
              {salesOrder?.getcare_order_status?.name}
            </span>
          </div>
        </div>

        <div className={classes.ColInner}>
          <div className={`${classes.Info}`}>
            <label>{t('Người tạo')}</label>
            { salesOrder?.user_name_created }
          </div>
          <div className={`${classes.Info}`}>
            <label>
              {validDate(salesOrder?.created_at)
                ? format(
                    validDate(salesOrder?.created_at),
                    dateTimeFormat
                  )
                : ''}
            </label>
          </div>
        </div>

        <div className={classes.CustomerInfo}>
          <div className={`${classes.ColInner}`}>
            <div className={`${classes.Info}`}>
              <label><StoreOutlinedIcon fontSize="small"/></label>
              {salesOrder?.customer_name}
            </div>
            <div className={`${classes.Info}`}>
              <label>{t('Mã khách hàng')}</label>
              <span>{salesOrder?.customer_code}</span>
            </div>
          </div>
          <div className={`${classes.ColInner}`}>
            <div className={`${classes.Info}`}>
              <label><LocationOnOutlinedIcon fontSize="small"/></label>
              {salesOrder?.customer_address}
            </div>
            <div className={`${classes.Info}`}>
              <label>{t('Mã số thuế')}</label>
              {salesOrder?.customer_taxcode || `-`}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SalesOrderInfo.propTypes = {
  salesOrder: PropTypes.object,
};

SalesOrderInfo.defaultProps = {
  salesOrder: null,
};

const mapStateToProps = createStructuredSelector({
  salesOrder: makeSelectSalesOrder(),
});
const withConnect = connect(mapStateToProps, null);
export default withTranslation()(compose(withConnect,withRouter)(SalesOrderInfo));
