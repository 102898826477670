import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import vendorApi from 'utils/api/mdm/vendorApi';
import { debounce } from 'lodash';
import axios from 'axios';

import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import ClearIcon from '@material-ui/icons/Clear';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Search from '@material-ui/icons/Search';
import { withTranslation } from "react-i18next";

import classes from './FastOrderVendorsSelection.module.scss';

class FastOrderVendorsSelection extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      name: '',

      isLoading: true,
      vendorOptions: [],
      isOpen: false,
    }
    this.keywordInputRef = React.createRef();
  }
  componentDidMount() {
    this.loadVendors({ params: { name: this.state.name, } });
  }
  componentDidUpdate(prevProps, prevState) {
    const { name } = this.state;
    if (name !== prevState.name) {
      this.loadVendors({ params: { name: name } });
    }
  }

  loadVendors = ({ params }) => {
    this.source && this.source.cancel();
    this._loadSuggestedVendors({ params });
  }
  _loadSuggestedVendors = debounce(async ({ params }) => {
    this.source = axios.CancelToken.source();
    try {
      this.setState({ isLoading: true });
      const { data: response } = await vendorApi.getAll({ params, cancelToken: this.source.token });
      this.setState({
        isLoading: false,
      });
      if (!response?.result) return;
      this.setState({ vendorOptions: response.data ? response.data : [] })
    } catch(err) {
    }
  }, 200);
  _isVendorSelected = (vendor) => {
    return this.props.selectedVendors.some(item => item.id === vendor.id);
  }

  handleKeywordChange = (e) => {
    this.setState({
      name: e.target.value,
    });
  }
  handleClearKeyword = () => {
    this.setState({
      name: '',
    }, () => {
      this.keywordInputRef && this.keywordInputRef.current && this.keywordInputRef.current.focus();
    });
  }
  handleSelectedChange = (e, vendor) => {
    this.props.onSelectedChange(e, vendor);
  }
  handleToggleVendorsPanel = (e) => {
    e.stopPropagation();
    if (!this.state.isOpen) {
      this.setState({
        isOpen: true,
      });
    }
  }
  handleClickAway = () => {
    this.setState({
      name: '',
      isOpen: false,
    }, () => {
      this.keywordInputRef && this.keywordInputRef.current && this.keywordInputRef.current.blur();
    });
  }


  render() {
    const { vendorOptions, name, isLoading, isOpen } = this.state;
    const { selectedVendors, t } = this.props;

    return (<ClickAwayListener onClickAway={this.handleClickAway}>
      <div className={classes.FilterWrap}>
        <TextField
          inputRef={this.keywordInputRef}
          value={name}
          autoComplete="off"
          name="name"
          variant="outlined"
          size="small"
          fullWidth
          className={`${classes.SearchField} ${isOpen ? classes.Focus : ''}`}
          placeholder={t('Nhập nhà cung cấp')}
          onChange={this.handleKeywordChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                { !name ? <Search fontSize="small" /> : <IconButton size="small" onClick={this.handleClearKeyword}><ClearIcon fontSize="small" /></IconButton> }
                { selectedVendors.length > 0 ? <span className={classes.BadgeWrap}><span className={`Badge ${classes.Badge}`}>{selectedVendors.length}</span></span> : `` }
              </InputAdornment>
            ),
          }}
          onClick={this.handleToggleVendorsPanel}
        />

        { isOpen && <div className={`${classes.SearchWrap} ${isOpen ? classes.Opened : '' }`}>
          <div className={classes.LabelWrap}>
            <label>{t('Nhà cung cấp')}</label>
            <Button color="secondary" fontSize="small" size="small" onClick={this.props.onDeselectAll}>{t('Bỏ chọn tất cả')}</Button>
          </div>
          <div className={classes.ListWrap}>
            <div className={`${classes.Body} ${isLoading ? 'OverlayLoading' : ''}`}>
              {vendorOptions.length ? (
                vendorOptions.map((vendor) => (
                  <FormControlLabel
                    key={`vendor-${vendor.id}`}
                    className={classes.VendorItem}
                    control={<Checkbox
                      checked={this._isVendorSelected(vendor)}
                      className={classes.VendorCheckbox}
                      size="small"
                      name="selectedVendors"
                      onChange={(e) => this.handleSelectedChange(e, vendor)}
                    />}
                    label={vendor.name}
                  />
                ))
              ) : (
                <p className={classes.EmptyLine}>{ isLoading ? '' : t('Không tìm thấy nhà cung cấp')  }</p>
              )}
            </div>
          </div>
        </div> }
      </div>
    </ClickAwayListener>);
  }
}

FastOrderVendorsSelection.propTypes = {
  selectedVendors: PropTypes.array,
  onDeselectAll: PropTypes.func,
  onSelectedChange: PropTypes.func,
};

FastOrderVendorsSelection.defaultProps = {
  selectedVendors: [],
};

export default withTranslation()(FastOrderVendorsSelection);
