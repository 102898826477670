export default (theme) => ({
  '.thin-scroll': {
    '&::-webkit-scrollbar': {
      width: 6,
      backgroundColor: "#F5F5F5",
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': "inset 0 0 6px rgba(0,0,0,0.00)",
      backgroundColor: "#f1f1f1",
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: "#c1c1c1"
    },
  }
})