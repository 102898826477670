import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { isEqual, sortBy, debounce, sumBy } from 'lodash';
import { toast } from 'react-toastify';
import axios from 'axios';
import {
  getPurchaseRequestDraft,
  updatePurchaseRequestDraft,
  savePRDetails,
  saveSelectedCustomerCode,
  checkPromotion,
  savePRDraftDelivery,
  savePromotionAvailables,
  saveUsedPromotions,
  savePromotionInactives,
  savePromotionActives,
  removePRDraftProductItems,
  savePRProducts,
  createPurchaseRequest,
} from 'redux/actions/ecom/fastOrderActions';
import { withTranslation } from "react-i18next";
import { getPharmacy } from 'redux/actions/ecom/pharmacyActions';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectFastOrderLoading,
  makeSelectLoginUser,
  makeSelectFastOrderSelectedCustomerCode,
  makeSelectFastOrderSelectedPayment,
  makeSelectFastOrderProducts,
  makeSelectFastOrderUsedPromotions,
  makeSelectFastOrderPromotionActives,
  makeSelectFastOrderPromotionInactives,
  makeSelectFastOrderPromotionAvailables,
  makeSelectFastOrderDelivery,
  makeSelectFastOrderLogistic,
  makeSelectFastOrderDetails,
  makeSelectDeliveryLogisticList,
  makeSelectFastOrderNotes,
  makeSelectPharmacy,
  makeSelectFastOrderDiscounts,
  makeSelectVendorDeliveryFees,
  makeSelectVendorDeliveryFeesLoading,
  makeSelectSelectCoupon,
} from 'redux/selectors';
import * as QueryString from 'query-string';
import {
  getValidOrderItems,
  prepareUsedPromotionsData,
  getCombinedValidOrderProductsData,
} from 'utils/constanst/ecomFastOrderConstants';
import {
  isChanhXeLogisticProviderId,
  isThirdPartyLogisticProviderId,
  FAST_ORDER_TYPE_ID,
} from 'utils/constanst/common';
import { isRouteEnabled } from 'utils/constanst/authConstants';
import fastOrderApi from 'utils/api/ecom/fastOrderApi';
import FastOrderProducts from 'components/ecom/FastOrder/FastOrderProducts/FastOrderProducts';
import FastOrderAmount from 'components/ecom/FastOrder/FastOrderAmount/FastOrderAmount';
import PromotionAvailablesDialog from 'components/ecom/FastOrder/PromotionAvailablesDialog/PromotionAvailablesDialog';
import FastOrderBonuses from 'components/ecom/FastOrder/FastOrderBonuses/FastOrderBonuses';
import FastOrderPromotions from 'components/ecom/FastOrder/FastOrderPromotions/FastOrderPromotions';
import Button from '@material-ui/core/Button';
import PaymentProvider from 'components/ecom/FastOrder/PaymentProvider/PaymentProvider';
import LogisticProvider from 'components/ecom/FastOrder/LogisticProvider/LogisticProvider';
import FastOrderNotes from 'components/ecom/FastOrder/FastOrderNotes/FastOrderNotes';
import FastOrderCustomerDelivery from 'components/ecom/FastOrder/FastOrderCustomerDelivery/FastOrderCustomerDelivery';
import FastOrderSuccessDialog from 'components/ecom/FastOrder/FastOrderSuccessDialog/FastOrderSuccessDialog';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import PromotionDetailsDialog from 'components/ecom/FastOrder/PromotionDetailsDialog/PromotionDetailsDialog';
import FastOrderBreadcrumbs from 'components/ecom/FastOrder/FastOrderBreadcrumbs/FastOrderBreadcrumbs';
import CustomerDeliveryFormDialog from 'components/ecom/FastOrder/FastOrderCustomerDelivery/CustomerDeliveryFormDialog/CustomerDeliveryFormDialog';
import CharityBreadcrumbs from 'components/ecom/FastOrder/CharityBreadcrumbs/CharityBreadcrumbs';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

import classes from './FastOrder.module.scss';
import { th } from 'date-fns/locale';

class FastOrder extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      selectedPromotionDetails: null,
      isCheckingPromotion: false,
      isChangingProductCart: false,
      charity: null,
      dataRemove: null,
      isPromotionDialogOpen: false,
      isSuccessDialogOpen: false,
      isEditConfirmationDialogOpen: false,
      isSkipPromotionConfirmDialogOpen: false,
      isPromotionDetailsDialogOpen: false,
      isCustomerDeliveryDialogOpen: false,
      unAvailables: false,
      isProductError: false
    };
    this.pageRef = null;
    this.pageSideInnerRef = null;
    this.pageMainInnerRef = null;

    this.taxInfoFormRef = null;
    this.cancelUpdatePurchaseRequestDraft = null;
  }
  async componentDidMount () {
    const { location } = this.props;
    const params = QueryString.parse(location.search);
    if (!params.getcare_customer_code) {
      this._handleOrderRouting();
      return;
    }

    if (params?.is_charity === "1" || params?.is_wholesale === "1") {
      await this._loadDraftPR({
        getcare_customer_code: params.getcare_customer_code,
        is_charity: params?.is_charity === "1" ? 1 : undefined,
        is_wholesale: params?.is_wholesale === "1" ? 1 : undefined
      });

      this.handleSkipPromotion();
      this.setState({
        charity: true
      })
      
    } else {
      this._loadDraftPR({
        getcare_customer_code: params.getcare_customer_code,
      });
    }
  }
  async componentDidUpdate (prevProps, prevState) {
    const { location } = this.props;
    const params = QueryString.parse(location.search);
    if (params.getcare_customer_code !== QueryString.parse(prevProps.location.search).getcare_customer_code) {
      if ( params?.getcare_customer_code ) {
        this._loadDraftPR({
          getcare_customer_code: params.getcare_customer_code,
          is_charity: params?.is_charity === "1" ? 1 : undefined,
          is_wholesale: params?.is_wholesale === "1" ? 1 : undefined
        });
      } else {
        this._handleOrderRouting();
      }
    }
    const { prProducts } = this.props;
    const { unAvailables,dataRemove } = this.state;
    const { step } = this.state;
    // save pr draft on product items change
    if (
      this._isPREditing() 
      && prProducts
      && !isEqual(prProducts, prevProps.prProducts) 
    ) {
      this.changeProductCart({
        prDeliveryData: true,
        prLogisticData: null,
        willSaveLoading: false,
        willUpdatePRDetails: false,
      });
    }


    if (step !== prevState.step) {
      window.scrollTo({ top: 0 });
    }

    const { prDetails } = this.props;

    if (prDetails && !isEqual(prDetails, prevProps.prDetails)) {
        const productError = prDetails?.getcare_purchase_request_items ? prDetails?.getcare_purchase_request_items.filter(x => x.error_status < 0) : null;
        this.setState({ productError });
    }
    

    if (unAvailables   && !isEqual(unAvailables, prevState.unAvailables)) {
        if (unAvailables === true && this.state.productError){
          this.setState({ isProductError :true });
        }
    }


    if (dataRemove   && !isEqual(dataRemove, prevState.dataRemove)) {
      if (dataRemove === true ){
        this._savePRDraft({ prDeliveryData: null, prLogisticData: null, willSaveLoading: false, willUpdatePRDetails: true, });
      }
  }
    

    // order success
    if (prDetails && prDetails.id && !isEqual(prDetails, prevProps.prDetails)) {
      this.handleOpenSuccessDialog();
    }
  }

  _isProductsChanged = (products, comparedProducts) => {
    if (!products || !comparedProducts) return false;
    return !isEqual(
      sortBy(getCombinedValidOrderProductsData(products)),
      sortBy(getCombinedValidOrderProductsData(comparedProducts)),
    );
  }

  _isPREditing = () => {
    return this.state.step === 1;
  }
  _handleOrderRouting = (willRefresh = false) => {
    const { user } = this.props;
    const path = isRouteEnabled(user, 'customer')
      ? `/customer`
      : `/fastorder?getcare_customer_code=${user.getcare_customer?.code}`;
    if (willRefresh) {
      window.location.replace(path);
      return;
    }
    this.props.history.push(path);
  };
  _loadDraftPR = async (params) => {
    await this.props.getPharmacy({
      id: params.getcare_customer_code,
    });
    await this.props.saveSelectedCustomerCode(params.getcare_customer_code);
    await this._resetPromotionData();
    await this.props.getPurchaseRequestDraft({ params });
  };
  _isAbleToCheckPromotion = () => {
    const { selectedCustomerCode, prProducts } = this.props;
    return !!getValidOrderItems(prProducts).length && !!selectedCustomerCode;
  };
  _getSelectedProvider = (providerId) => {
    const { deliveryLogisticList } = this.props;
    if (!deliveryLogisticList || !providerId) return null;
    return deliveryLogisticList.find((item) => item.id === providerId);
  };
  _getSelectedService = (providerId, serviceId) => {
    const provider = this._getSelectedProvider(providerId);
    if (!provider || !provider.services) return null;
    return provider.services.find(
      (service) => service.service_id === serviceId
    );
  };

  _getSubTotal = () => {
    const { prProducts } = this.props;
    return prProducts.reduce((memo, product) => {
      const subTotalLine =
        [null, undefined, ''].includes(product.quantity_number) ||
        [null, undefined, ''].includes(product.price_sales_total)
          ? 0
          : product.price_sales_total * Number(product.quantity_number);
      return (memo += subTotalLine);
    }, 0);
  };
  _getAmountDiscount = () => {
    const { prProducts, prDiscounts, promotionActives } = this.props;

    // calculate promotionActives by top priority
    const discountPromotionActives = promotionActives.filter((item) => !!item.amount);
    if (discountPromotionActives.length > 0) {
      return discountPromotionActives.reduce((memo, discount) => {
        return (memo += discount.amount);
      }, 0);
    }

    // calculate in order items
    const lineDiscountItems = prProducts.filter(
      (product) =>
        product.getcare_purchase_request_item_discounts &&
        product.getcare_purchase_request_item_discounts.length > 0
      ).map((product) => [...product.getcare_purchase_request_item_discounts]);
    const lineDiscountsAmount = lineDiscountItems
      .flatMap((item) => [...item])
      .reduce((memo, discount) => {
        return (memo += discount.amount);
      }, 0);

    // calculate in discounts (not by order items)
    const notLineDiscountsAmount = prDiscounts.reduce((memo, discount) => {
      return (memo += discount.amount);
    }, 0);
    return lineDiscountsAmount + notLineDiscountsAmount;
  };
  _getAmountShippingCoupon = () => {
    const { prDetails } = this.props;
    const isPREditing = this._isPREditing();
    if ( isPREditing ) return 0;
    return prDetails?.amount_shipping_coupon || 0;
  };
  _getAmountDelivery = () => {
    const { vendorDeliveryFees } = this.props;
    const isPREditing = this._isPREditing();
    if ( isPREditing ) return 0;
    if ( vendorDeliveryFees.length > 0 ) {
      const totalVendorAmount = sumBy(vendorDeliveryFees,(v) => { return v?.fee?.total; }); 
      return totalVendorAmount || 0;
    }
    return this.props.prLogistic?.total_fee || 0;
  };
  _getTotal = () => {
    return (
      this._getSubTotal() -
      this._getAmountDiscount() +
      this._getAmountDelivery() -
      this._getAmountShippingCoupon()
    );
  };
  _isLoadingOrder = () => {
    const { vendorDeliveryFeesLoading } = this.props;
    return vendorDeliveryFeesLoading;
  }
  _prepareLogisticProviderData = (logisticProvider, isSubmitPR) => {
    const providerId = logisticProvider?.id;
    const providerNote = isSubmitPR
      ? (isChanhXeLogisticProviderId(providerId) ? logisticProvider?.note : undefined)
      : logisticProvider?.note;
    const serviceSelected = this._getSelectedService(
      logisticProvider?.id,
      logisticProvider?.service_id
    );
    return {
      id: providerId,
      name: this._getSelectedProvider(providerId)?.name,
      service_id: logisticProvider?.service_id,
      service_type_id: serviceSelected?.service_type_id ? serviceSelected?.service_type_id  :logisticProvider?.service_type_id ,
      service_name: logisticProvider?.service_name,
      insurance_value: logisticProvider?.insurance_value,
      note: providerNote,
    };
  };
  _prepareCustomerDeliveryData = (customerDelivery) => {
    return {
      name: customerDelivery.name,
      phone: (/^(84)/).test(customerDelivery.phone) ? customerDelivery.phone.replace(/^(84)/, '+84') : customerDelivery.phone,
      label: customerDelivery.label,
      address: customerDelivery.address,
      getcare_country_id: customerDelivery.getcare_country?.id,
      getcare_province_id: customerDelivery.getcare_province?.id,
      getcare_district_id: customerDelivery.getcare_district?.id,
      getcare_ward_id: customerDelivery.getcare_ward?.id,
      save: customerDelivery.save,
      default: customerDelivery.default,
    };
  };
  _preparePRData = ({ prDeliveryData, prLogisticData, willSaveLoading, willUpdatePRDetails, isSubmitPR, prPayment,vouchers }) => {
    const {
      selectedCustomerCode,
      prProducts,
      prDetails,
      prLogistic,
      prDelivery,
      prNotes,
      selectedPayment,
      selectCoupon,
      usedPromotions,
    } = this.props;
    const {
      charity,
    } = this.state;
    return {
      getcare_customer_code: selectedCustomerCode,
      getcare_order_type_id: prDetails?.getcare_order_type?.id === 7 ? prDetails?.getcare_order_type?.id  : FAST_ORDER_TYPE_ID,
      is_taxcode: prDetails?.is_taxcode,
      tax_name: prDetails?.is_taxcode ? prDetails?.tax_name : undefined,
      taxcode: prDetails?.is_taxcode ? prDetails?.taxcode : undefined,
      tax_address: prDetails?.is_taxcode ? prDetails?.tax_address : undefined,
      order_items: prProducts,
      promotion_actives: usedPromotions
        ? prepareUsedPromotionsData(this.props.usedPromotions)
        : undefined,
      is_check_promotion: this.state.step === 3 ? 1 : undefined, // would get the applied promotions if users had finished the choosing promotions step
      logistic_provider: prLogisticData
        ? this._prepareLogisticProviderData(prLogisticData, isSubmitPR)
        : prLogistic
        ? this._prepareLogisticProviderData(prLogistic, isSubmitPR)
        : undefined,
      customer_delivery: prDeliveryData
        ? this._prepareCustomerDeliveryData(prDeliveryData)
        : prDelivery
        ? this._prepareCustomerDeliveryData(prDelivery)
        : undefined,
      getcare_service_request_id : prDetails?.getcare_service_request_id ? prDetails?.getcare_service_request_id: undefined,
      vouchers :  vouchers? vouchers.map(x => x.code)  : selectCoupon.map(x => x.code) ,
      notes: prNotes ? prNotes.map((note) => note.note) : undefined,
      getcare_payment_method_id: prPayment ? prPayment : selectedPayment,
      willSaveLoading,
      willUpdatePRDetails,
    };
  };
  changeProductCart = async ({ prDeliveryData, prLogisticData, willSaveLoading, willUpdatePRDetails, prPayment,vouchers }) => {
    this.setState({ isChangingProductCart: true });
    this.debounceToSavePRDraft({ prDeliveryData, prLogisticData, willSaveLoading, willUpdatePRDetails, prPayment,vouchers})
  }
  debounceToSavePRDraft = debounce(({ prDeliveryData, prLogisticData, willSaveLoading, willUpdatePRDetails, prPayment,vouchers }) => {
    this._savePRDraft({ prDeliveryData, prLogisticData, willSaveLoading, willUpdatePRDetails, prPayment,vouchers });
    this.setState({ isChangingProductCart: false });
  }, 500);
  _savePRDraft = async ({ prDeliveryData, prLogisticData, willSaveLoading, willUpdatePRDetails, prPayment,vouchers }) => {
    this.cancelUpdatePurchaseRequestDraft && this.cancelUpdatePurchaseRequestDraft.cancel();
    this.cancelUpdatePurchaseRequestDraft = axios.CancelToken.source();
    const { location } = this.props;
    const params = QueryString.parse(location.search);
    if ( params?.is_charity === "1" || params?.is_wholesale === "1") {
      const response = await this.props.updatePurchaseRequestDraft({
        params: this._preparePRData({ prDeliveryData, prLogisticData, willSaveLoading, willUpdatePRDetails, prPayment,vouchers }),
        cancelToken: this.cancelUpdatePurchaseRequestDraft.token,
        isCharity: params?.is_charity === "1" ? 1 : undefined,
        isWholesale: params?.is_wholesale === "1" ? 1 : undefined
      })
      this.props.savePRDetails(response);
    }else{
      const response = await this.props.updatePurchaseRequestDraft({
        params: this._preparePRData({ prDeliveryData, prLogisticData, willSaveLoading, willUpdatePRDetails, prPayment,vouchers}),
        cancelToken: this.cancelUpdatePurchaseRequestDraft.token,
      }); 
      this.props.savePRDetails(response);
    }
  };
  _deliveryErrors = () => {
    let messages = [];
    const { prDelivery } = this.props;
    if (!prDelivery) {
      messages.push('• Vui lòng nhập thông tin nhận hàng.');
      return messages;
    }

    !prDelivery.name && messages.push('• Địa chỉ nhận hàng không hợp lệ (không có tên người nhận hàng). Vui lòng chọn địa chỉ khác.');
    !prDelivery.phone && messages.push('• Địa chỉ nhận hàng không hợp lệ (không có số điện thoại nhận hàng). Vui lòng chọn địa chỉ khác.');
    (!prDelivery.getcare_country
      || !prDelivery.getcare_province
      || !prDelivery.getcare_district
      || !prDelivery.getcare_ward
      || !prDelivery.address
    ) && messages.push('• Địa chỉ nhận hàng không hợp lệ. Vui lòng chọn địa chỉ khác.');
    return messages;
  }
  _logisticErrors = () => {
    let messages = [];
    const { prLogistic } = this.props;
    if (!prLogistic || prLogistic.id === 0) {
      messages.push('• Vui lòng chọn đơn vị vận chuyển.');
      return messages;
    }
    if (isThirdPartyLogisticProviderId(prLogistic.id)) {
      if (!prLogistic.service_id) {
        messages.push('• Vui lòng chọn dịch vụ cho đơn vị vận chuyển.');
        return messages;
      }
      if (!this._getSelectedService(prLogistic.id, prLogistic.service_id)) {
        messages.push('• Bạn đã thay đổi địa chỉ nhận hàng. Vui lòng chọn lại dịch vụ vận chuyển phù hợp với địa chỉ mới.');
        return messages;
      }
      return [];
    }
    if (isChanhXeLogisticProviderId(prLogistic.id) && !prLogistic.note) {
      messages.push('• Vui lòng nhập ghi chú cho đơn vị vận chuyển.');
      return messages;
    }
    return [];
  }
  _hasWarningMessages = () => {
    const messages = [
      ...this._deliveryErrors(),
      ...this._logisticErrors(),
    ];
    if (messages.length) {
      toast.error(messages.join('\n'));
      return messages.join('\n');
    }
    return ``;
  }

  _resetPromotionData = async () => {
    await this.props.savePromotionAvailables(null);
    await this.props.savePromotionActives([]);
    await this.props.saveUsedPromotions([]);
    await this.props.savePromotionInactives([]);
  };
  _preparePromotionData = ({ willResetPromotionActives }) => {
    const { selectedCustomerCode, prProducts } = this.props;
    return {
      getcare_customer_code: selectedCustomerCode,
      order_items: getCombinedValidOrderProductsData(prProducts),
      promotion_actives: willResetPromotionActives
        ? []
        : prepareUsedPromotionsData(this.props.usedPromotions),
      promotion_inactives: willResetPromotionActives
        ? []
        : this.props.promotionInactives,
    };
  };

  handleCheckPromotion = async () => {
    this.setState({
      isCheckingPromotion: true,
    })
    const {prDetails} = this.props;
    if (prDetails?.customer_delivery) {
      this.props.savePRDraftDelivery(prDetails?.customer_delivery);
    }
    const dataResponse = prDetails?.getcare_purchase_request_items && prDetails?.getcare_purchase_request_items.filter((x) => x.error_status < 0);

    const dataResponseRemaining = prDetails?.getcare_purchase_request_items && prDetails?.getcare_purchase_request_items.filter((x) => x.minimum_quantity > x.remaining_quantity && x.minimum_quantity && x.remaining_quantity );

    const dataFilter = this.state.productError?.filter((x) => x.error_status < 0  );
    if (dataResponse && dataResponse.length > 0 || dataFilter && dataFilter.length > 0 || dataResponseRemaining && dataResponseRemaining.length > 0) {
        this.setState({
          unAvailables: true,
          isProductError: true,
        })
      if (dataResponse && dataFilter.length < 1 || dataResponseRemaining && dataFilter.length < 1 ) {
        this.setState({
          productError: dataResponse || dataResponseRemaining ? dataResponseRemaining ? dataResponseRemaining  : dataResponse : null
        })
      }
      this.setState({
        isCheckingPromotion: false,
      });
    }else{
      this._savePRDraft({ prDeliveryData: null, prLogisticData: null, willSaveLoading: true, willUpdatePRDetails: true });

      this.setState({
         isCheckingPromotion: false,
       },  () => {
         this.handleOpenPromotionDialog();
     })
    }
  };

  handleOpenPromotionDialog = () => {
    this.setState({
      step: 1,
      isPromotionDialogOpen: true,
    });
  };
  handleClosePromotionDialog = () => {
    this.setState({
      isPromotionDialogOpen: false,
    });
  };
  handleContinuePurchasing = () => {
    this.setState({
      isPromotionDialogOpen: false,
    }, () => {
      this._resetPromotionData();
    });
  };

  handleOpenSuccessDialog = () => {
    this.setState({
      isSuccessDialogOpen: true,
    });
  };
  handleCloseSuccessDialog = () => {
    this.setState({
      isSuccessDialogOpen: false,
      step: 1,
    }, () => {
      window.location.replace('/');
    });
  };

  handleCompletePromotion = () => {
    this.setState({
      step: 2,
    });
  };
  handleSkipPromotion = () => {
    this.setState({
      step: 3,
      isPromotionDialogOpen: false,
    }, () => {
      this._resetPromotionData();
    });
  };
  handleConfirmPR = () => {
    this.setState({
      step: 3,
    }, () => {
      this._savePRDraft({ prDeliveryData: null, prLogisticData: null, willSaveLoading: true, willUpdatePRDetails: true });
    });
  };
  handleSubmitPR = async () => {
    const { location } = this.props;
    const params = QueryString.parse(location.search);
      if (this.taxInfoFormRef) {
        const orderData = this._preparePRData({
          prDeliveryData: null,
          prLogisticData: null,
          willSaveLoading: true,
          willUpdatePRDetails: true,
          isSubmitPR: true,
        });
  
        await this.taxInfoFormRef.validateForm();
        if (!this.taxInfoFormRef.isValid) {
          fastOrderApi.saveOrderDraft({
            params: {
              error_description: JSON.stringify(this.taxInfoFormRef.errors),
              data: JSON.stringify(orderData),
            }
          });
          return;
        };
  
        const deliveryErrors = this._deliveryErrors();
        if (deliveryErrors.length) {
          fastOrderApi.saveOrderDraft({
            params: {
              error_description: deliveryErrors.join('\n'),
              data: JSON.stringify(orderData),
            }
          });
          toast.error(deliveryErrors.join('\n'));
          this.handleOpenCustomerDeliveryDialog();
          return;
        }
  
        const logisticErrors = this._logisticErrors();
        if (logisticErrors.length) {
          fastOrderApi.saveOrderDraft({
            params: {
              error_description: logisticErrors.join('\n'),
              data: JSON.stringify(orderData),
            }
          });
          toast.error(logisticErrors.join('\n'));
          return;
        }

        if (this.props.selectedPayment === 3){
          if (params?.is_charity === "1") {
            const { data: response } = await fastOrderApi.vnPayLink({
              params: {
                data: this.props.selectedCustomerCode,
              },
              isCharity:1
            });
            if (response?.result ===  false) {
              toast.error(response?.message );
            }else{
              window.location.replace(response.data.url);
            }    
          }else{
            const { data: response } = await fastOrderApi.vnPayLink({
              params: {
                data: this.props.selectedCustomerCode,
              }
            });
            if (response?.result ===  false) {
              toast.error(response?.message );
            }else{
              window.location.replace(response.data.url);
            }    
          }
         
        }else {
          if (this.props.selectedPayment === 2){
            if (params?.is_charity === "1") {
              const { data: response } = await fastOrderApi.getCareLink({
                params: {
                  data: this.props.selectedCustomerCode,
                },
                isCharity:1
              });
              if (response?.result ===  false) {
                toast.error(response?.message );
              }else{
                window.location.replace(response.data.url);
              }    
            }else{
              const { data: response } = await fastOrderApi.getCareLink({
                params: {
                  data: this.props.selectedCustomerCode,
                }
              });
              if (response?.result ===  false) {
                toast.error(response?.message );
              }else{
                window.location.replace(response.data.url);
              }    
            }
      
          }else{
            if (params?.is_charity === "1") {
              this.props.createPurchaseRequest({
                params: orderData,
                isCharity: 1,
              });
            }else{
              this.props.createPurchaseRequest({
                params: orderData,
              });
            }
          }
      }
    }
  };

  handleOpenEditConfirmationDialog = () => {
    this.setState({
      isEditConfirmationDialogOpen: true,
    });
  };
  handleCloseEditConfirmationDialog = () => {
    this.setState({
      isEditConfirmationDialogOpen: false,
    });
  };
  handleSubmitEditConfirmationDialog = () => {
    this.setState({
      step: 1,
    }, () => {
      this.handleCloseEditConfirmationDialog();
      this._resetPromotionData();
    });
  };

  handleOpenSkipPromotionConfirmDialog = () => {
    this.setState({
      isSkipPromotionConfirmDialogOpen: true,
    });
  };
  handleCloseSkipPromotionConfirmDialog = () => {
    this.setState({
      isSkipPromotionConfirmDialogOpen: false,
    });
  };
  handleSubmitSkipPromotionConfirmDialog = () => {
    this.setState({
      isSkipPromotionConfirmDialogOpen: false,
    }, () => {
      this.handleSkipPromotion();
    });
  };

  handleSubmitPromotion = () => {
    this.props.checkPromotion({
      params: this._preparePromotionData({ willResetPromotionActives: false }),
    });
  };
  handleCalculatePromotion = () => {
    this.props.checkPromotion({
      params: this._preparePromotionData({ willResetPromotionActives: true }),
    });
  };

  handlePromotionLinkClick = (promotionItem) => {
    this.setState({
      selectedPromotionDetails: { ...promotionItem },
    }, () => {
      this.handleOpenPromotionDetailsDialog();
    });
  };
  handleOpenPromotionDetailsDialog = () => {
    this.setState({
      isPromotionDetailsDialogOpen: true,
    });
  };
  handleClosePromotionDetailsDialog = () => {
    this.setState({
      isPromotionDetailsDialogOpen: false,
      selectedPromotionDetails: null,
    });
  };

  handleOpenCustomerDeliveryDialog = () => {
    this.setState({
      isCustomerDeliveryDialogOpen: true,
    });
  };
  handleCloseCustomerDeliveryDialog = () => {
    this.setState({
      isCustomerDeliveryDialogOpen: false,
    });
  };


  closeProductError =  () => {
    this.props.removePRDraftProductItems(this.state.productError.map(product => ({
      id:  product.id,
      idStr: product.idStr,
      getcare_product_id: product.getcare_product_id
    })));
    this.setState({
      isProductError: false,
    });

    this.setState({
      dataRemove: true,
    });
  }

  onClose = () => {
    this.setState({
      unAvailables: false,
    });
  }

  render() {
    const { isPromotionDialogOpen,promotionAvailables, isProductError,productError,step,isSuccessDialogOpen, isCheckingPromotion, isChangingProductCart ,charity} = this.state;
    const { loading, selectedCustomerCode, pharmacyDetails, t,prProducts } = this.props;
    return (
      <React.Fragment>
        <div
          ref={(ref) => {
            this.pageRef = ref;
          }}
          className={classes.PageWrap}
        >
          {charity === true?
           <CharityBreadcrumbs
           step={step}
           selectedCustomerCode={selectedCustomerCode}
           onReselectProducts={this.handleOpenEditConfirmationDialog}
         />
         : 
         <FastOrderBreadcrumbs
         step={step}
         selectedCustomerCode={selectedCustomerCode}
         onReselectProducts={this.handleOpenEditConfirmationDialog}
       />
          }
         

          <div className={classes.PageHeader}>
            <h1 className="PageTitle">
              {step === 3 ? t('Xác nhận đơn hàng') : t('Đặt hàng nhanh') }
              <p className={classes.PageSubTitle}>
                {t('Cho khách hàng')}{' '}
                <span className={classes.CustomerName}>
                  {pharmacyDetails?.name || `-`}
                </span>
              </p>
            </h1>
          </div>

          <div className={`${classes.PageContent} ${loading && 'OverlayLoading'}`}>
            <div className={classes.PageMain}>
              <div ref={(ref) => { this.pageMainInnerRef = ref; }}>
                <FastOrderProducts
                  isEditing={this._isPREditing()}
                  step={step}
                  isCharity={charity === true ? true :false}
                  onEditFastOrderProducts={this.handleOpenEditConfirmationDialog}
                />
                {step === 1  && prProducts.length > 0 && (
                  <div className={classes.StepGuideControl}>
                    <p>
                    {t('(Chú ý: sản phẩm sẽ luôn được hiển thị giá mới nhất theo giá thị trường hoặc chính sách công ty hiện tại ngay khi bạn bấm nút \"Áp dụng khuyến mãi\".)',{nsSeparator:"::"})}
                    </p>
                    <Button
                      disabled={isCheckingPromotion && promotionAvailables !== 2 || isChangingProductCart  && promotionAvailables !== 2 }
                      variant="outlined"
                      size="large"
                      onClick={this.handleCheckPromotion}
                    >
                      {t('Áp dụng khuyến mãi')}
                    </Button>
                  </div>
                )}
                {step === 2 && (
                  <>
                    <FastOrderPromotions
                      step={step}
                      onSubmitPromotion={this.handleSubmitPromotion}
                      onRecalculatePromotion={this.handleOpenPromotionDialog}
                      onPromotionLinkClick={this.handlePromotionLinkClick}
                    />
                    <div className={classes.StepGuideControl}>
                      <p>
                        {t('Bạn đã chọn xong khuyến mãi, bạn có muốn đến bước xác nhận đơn hàng?')}
                      </p>
                      <Button
                        variant="outlined"
                        size="large"
                        disabled={isCheckingPromotion || isChangingProductCart}
                        onClick={this.handleConfirmPR}
                      >
                        {t('Tiếp tục')}
                      </Button>
                    </div>
                  </>
                )}
                {step === 3 && (
                  <>
                    <FastOrderBonuses />
                    <LogisticProvider disabled={charity}  onUpdatePRDraft={this._savePRDraft} location={this.props.location} />
                    <PaymentProvider   onUpdatePRDraft={this._savePRDraft} location={this.props.location} />
                    <FastOrderNotes onUpdatePRDraft={this._savePRDraft} />
                  </>
                )}
              </div>
            </div>
            <div className={classes.PageSide}>
              <div
                ref={(ref) => {
                  this.pageSideInnerRef = ref;
                }}
                className={classes.PageSideInner}
              >
                {step === 3 && (
                  <FastOrderCustomerDelivery
                    taxInfoFormRef={(ref) => { this.taxInfoFormRef = ref; }}
                    onOpenCustomerDeliveryDialog={this.handleOpenCustomerDeliveryDialog}
                    onUpdatePRDraft={this._savePRDraft}
                  />
                )}
                <FastOrderAmount
                  isFinalStep={step === 3}
                  disabled={charity}
                  location={this.props.location}
                  subTotal={this._getSubTotal()}
                  onUpdatePRDraft={this._savePRDraft}
                  amountDiscount={this._getAmountDiscount()}
                  amountDelivery={this._getAmountDelivery()}
                  amountShippingCoupon={this._getAmountShippingCoupon()}
                  amountTotal={this._getTotal()}
                />
                {step === 3 && (
                  <Button
                    className={classes.SubmitPRBtn}
                    variant="contained"
                    size="large"
                    color="secondary"
                    onClick={this.handleSubmitPR}
                    disabled={this._isLoadingOrder()}
                  >
                    {t('Gửi đơn đặt hàng')}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>

        {isPromotionDialogOpen  &&(
          <PromotionAvailablesDialog 
            isOpen={isPromotionDialogOpen}
            onClose={this.handleClosePromotionDialog}
            onCompletePromotion={this.handleCompletePromotion}
            onCancelPromotion={this.handleOpenSkipPromotionConfirmDialog}
            onSkipPromotion={this.handleSkipPromotion}
            onSubmitPromotion={this.handleSubmitPromotion}
            onCalculatePromotion={this.handleCalculatePromotion}
            onContinuePurchasing={this.handleContinuePurchasing}
            onPromotionLinkClick={this.handlePromotionLinkClick}
          />
        )}

        {this.state.unAvailables === true &&(
             <>

              <Dialog open={isProductError}  >
        <DialogTitle>{t('Bạn có muốn xoá sản phẩm không?')}</DialogTitle>
        <DialogContent>
          <p>
          {t('Các sản phẩm tạm ngừng cung cấp sẽ được loại bỏ khỏi đơn hàng')}
          </p>
            {productError && productError.length ? (
                  <>
                    {productError.map((item, index) => (
                        <div key={index} className={`${classes.WrapContent}`}>
                        <div className={classes.WrapItem}>
                        <img className={classes.itemImage} src={item.thumbnail? item.thumbnail:'https://www.imagdisplays.co.uk/wp-content/uploads/2020/10/Outdoor-LED-Screen-Hire-Technical-Event-Production-iMAG.jpg'} alt={item.code} />
                      <div className={`${classes.Item}`}>
                        <p
                          className={classes.PromotionLink}
                        >
                          <span>{item.getcare_voucher_program_code}</span>
                        </p>
                        {item.apply === -1 ? <span  className={classes.PromotionError}>({item.error_message})</span>: null}
                        <p>{item.product_name ?item.product_name : ``}</p>
                        <p className={classes.TextExplained}>{item.vendor_name}</p>
                      </div>
                      </div>
                      </div>
                    ))}
                    
                  </>
                ) : (
                  <div className={classes.EmptyBox}>
                    <p className="NoData">{t('Chưa có sản phẩm')}</p>
                  </div>
                )}
        </DialogContent>
        <DialogActions className={classes.DialogInfoActions}>
        <Button variant="outlined" onClick={this.onClose}>{t('Hủy')}</Button>
         <Button variant="contained" color="secondary" onClick={this.closeProductError}>{t('Xác nhận')}</Button>
        </DialogActions>
      </Dialog>
           </>
        )}

        {isSuccessDialogOpen && (
          <FastOrderSuccessDialog
            prDetails={this.props.prDetails}
            isOpen={isSuccessDialogOpen}
            onClose={this.handleCloseSuccessDialog}
          />
        )}

        {this.state.isEditConfirmationDialogOpen && (
          <ConfirmationDialog
            isOpen={this.state.isEditConfirmationDialogOpen}
            title={t('Chú ý')}
            type="info"
            message={t('Chỉnh sửa đơn hàng sẽ làm ảnh hưởng đến khuyến mãi đã tính. Bạn sẽ cần phải chọn lại khuyến mãi sau khi chỉnh sửa. Bạn có chắc muốn chỉnh sửa đơn hàng?')}
            dialogAction={
              <>
                <Button
                  variant="outlined"
                  onClick={this.handleCloseEditConfirmationDialog}
                >
                  {t('Không')}
                </Button>
                <Button
                  onClick={this.handleSubmitEditConfirmationDialog}
                  variant="contained"
                  color="secondary"
                >
                  {t('Sửa đơn hàng')}
                </Button>
              </>
            }
          />
        )}

        {this.state.isSkipPromotionConfirmDialogOpen && (
          <ConfirmationDialog
            isOpen={this.state.isSkipPromotionConfirmDialogOpen}
            title={t('Xác nhận bỏ qua khuyến mãi')}
            type="info"
            message={t('Bạn chưa hoàn tất việc chọn khuyến mãi. Nếu bạn rời đi thì lần sau bạn sẽ phải chọn lại từ đầu và khuyến mãi có thể thay đổi so với lúc này. Bạn có chắc muốn bỏ qua khuyến mãi?')}
            dialogAction={
              <>
                <Button
                  variant="outlined"
                  onClick={this.handleCloseSkipPromotionConfirmDialog}
                >
                  {t('Không')}
                </Button>
                <Button
                  onClick={this.handleSubmitSkipPromotionConfirmDialog}
                  variant="contained"
                  color="secondary"
                >
                  {t('Bỏ qua khuyến mãi')}
                </Button>
              </>
            }
          />
        )}

        {this.state.isPromotionDetailsDialogOpen && (
          <PromotionDetailsDialog
            promotionDetails={this.state.selectedPromotionDetails}
            isOpen={this.state.isPromotionDetailsDialogOpen}
            onClose={this.handleClosePromotionDetailsDialog}
          />
        )}

        {this.state.isCustomerDeliveryDialogOpen && (
          <CustomerDeliveryFormDialog
            isOpen={this.state.isCustomerDeliveryDialogOpen}
            onClose={this.handleCloseCustomerDeliveryDialog}
            onUpdatePRDraft={this._savePRDraft}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  loading: makeSelectFastOrderLoading(),
  user: makeSelectLoginUser(),
  selectedCustomerCode: makeSelectFastOrderSelectedCustomerCode(),
  prProducts: makeSelectFastOrderProducts(),
  prDetails: makeSelectFastOrderDetails(),
  prLogistic: makeSelectFastOrderLogistic(),
  prDelivery: makeSelectFastOrderDelivery(),
  promotionAvailables: makeSelectFastOrderPromotionAvailables(),
  promotionActives: makeSelectFastOrderPromotionActives(),
  promotionInactives: makeSelectFastOrderPromotionInactives(),
  usedPromotions: makeSelectFastOrderUsedPromotions(),
  prDiscounts: makeSelectFastOrderDiscounts(),
  selectCoupon: makeSelectSelectCoupon(),
  deliveryLogisticList: makeSelectDeliveryLogisticList(),
  prNotes: makeSelectFastOrderNotes(),
  pharmacyDetails: makeSelectPharmacy(),
  vendorDeliveryFees: makeSelectVendorDeliveryFees(),
  vendorDeliveryFeesLoading: makeSelectVendorDeliveryFeesLoading(),
  selectedPayment: makeSelectFastOrderSelectedPayment(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    // getPurchaseRequestDraft: (payload) => dispatch(getPurchaseRequestDraft(payload)),
    getPurchaseRequestDraft: (payload) => new Promise((resolve, reject) => dispatch(getPurchaseRequestDraft({ ...payload, resolve, reject }))),
    savePRDraftDelivery: (payload) => dispatch(savePRDraftDelivery(payload)),
    savePRProducts: (payload) => dispatch(savePRProducts(payload)),
    updatePurchaseRequestDraft: (payload) => new Promise((resolve, reject) => dispatch(updatePurchaseRequestDraft({ ...payload, resolve, reject }))),
    savePRDetails:(payload) => dispatch(savePRDetails(payload)),
    saveSelectedCustomerCode: (payload) => dispatch(saveSelectedCustomerCode(payload)),
    checkPromotion: (payload) => dispatch(checkPromotion(payload)),
    savePromotionAvailables: (payload) => dispatch(savePromotionAvailables(payload)),
    saveUsedPromotions: (payload) => dispatch(saveUsedPromotions(payload)),
    savePromotionActives: (payload) => dispatch(savePromotionActives(payload)),
    savePromotionInactives: (payload) => dispatch(savePromotionInactives(payload)),
    createPurchaseRequest: (payload) => dispatch(createPurchaseRequest(payload)),
    removePRDraftProductItems: (payload) => dispatch(removePRDraftProductItems(payload)),
    getPharmacy: (payload) => dispatch(getPharmacy(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default withTranslation()(compose(withConnect,withRouter)(FastOrder));
