import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from "react-i18next";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Box,
} from '@material-ui/core';
import ImageGallery from 'react-image-gallery';
import CloseIcon from '@material-ui/icons/Close';

import styles from './ImagesDialog.module.scss';

class ImagesDialog extends PureComponent {
  handleCloseDialog = () => {
    this.props.onClose();
  }

  render() {
    const { open, data, t } = this.props;
    
    return (
      <Dialog
        ref={this.refEl}
        open={open}
        scroll="paper"
        onClose={this.handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        fullScreen
      >
        <DialogTitle id="scroll-dialog-title">
          <div className={styles.DialogTitleBody}>
            {t('common:Hình ảnh')}
            <IconButton onClick={this.handleCloseDialog} className={styles.IconButtonClose}>
              <CloseIcon/>
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent dividers className={styles.DialogContent}>
          <Box height="100%">
            <ImageGallery 
                showBullets={true}
                showNav={true}
                showPlayButton={false}
                showFullscreenButton={true}
                items={data.map( image => ({
                  original: image,
                }))}
            />
          </Box>
        </DialogContent>
      </Dialog>
    );
  }
}

ImagesDialog.propTypes = {
  open: PropTypes.bool,
  data: PropTypes.array,
};

ImagesDialog.defaultProps = {
  open: false,
  data: [],
}
export default withTranslation(['common'],{ withRef: true })(ImagesDialog);
