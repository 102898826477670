import axiosAuth from 'utils/axios/axiosAuth';
import { listParamsMap ,listParamsMapCountry } from 'utils/constanst/locationConstants';

const locationApi = {
  getCountries: ({ name }) => {
    const params = { ...listParamsMapCountry, name };
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_country/`, {
      params: queryParams,
    });
  },
  getProvinces: ({ name, getcare_country_id }) => {
    const params = { ...listParamsMap, name, getcare_country_id };
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});

    return axiosAuth.get(`getcare_province/`, {
      params: queryParams,
    });
  },
  getDistricts: ({ name, getcare_province_id }) => {
    const params = { ...listParamsMap, name, getcare_province_id };
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_district/`, {
      params: queryParams,
    });
  },
  getWards: ({ name, getcare_district_id }) => {
    const params = { ...listParamsMap, name, getcare_district_id };
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_ward/`, {
      params: queryParams,
    });
  },
};

export default locationApi;
