import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { 
  makeSelectLoginUser 
} from 'redux/selectors';

import CommentForm from './CommentForm/CommentForm';
import CommentList from './CommentList/CommentList';

import styles from './Comments.module.scss';


class Comments extends PureComponent {
  constructor(props) {
    super(props);
    this.refCommentList = React.createRef();
  }
  handleCompleteSubmitComment = (values) => {
    this.refCommentList.current.pushComment({...values})
  }

  render() {
    return (
      <div>
        <CommentForm
          parent_id={this.props.parent_id}
          object_id={this.props.object_id}
          getcare_comment_type_id={this.props.getcare_comment_type_id}
          onComplete={this.handleCompleteSubmitComment}
        />
        <hr className={styles.divider}/>
        <CommentList
          ref={this.refCommentList}
          parent_id={this.props.parent_id}
          object_id={this.props.object_id}
          getcare_comment_type_id={this.props.getcare_comment_type_id}
        />
      </div>
    );
  }
};

Comments.propTypes = {
  parent_id: PropTypes.number,
  object_id: PropTypes.number,
  getcare_comment_type_id: PropTypes.oneOf([
    1, // Yêu cầu dịch vụ
  ]),
};

Comments.defaultProps = {
  parent_id: null,
  object_id: null,
  getcare_comment_type_id: null,
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectLoginUser(),
});
const mapDispatchToProps = (dispatch) => {
  return {

  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(Comments);
