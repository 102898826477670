import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import PharmacyListItem from './PharmacyListItem/PharmacyListItem';
import { withTranslation } from "react-i18next";

import classes from './PharmacyList.module.scss';

class PharmacyList extends PureComponent {
  render() {
    const { pharmacyList, isLoading, t } = this.props;

    return (
      <div className={`${classes.List} ${isLoading && 'OverlayLoading'}`}>
        {pharmacyList && pharmacyList.length ? (
          pharmacyList.map((item) => (
            <PharmacyListItem key={`store-${item.id}`} {...item} />
          ))
        ) : (
          <p className={`${classes.NoData} NoData}`}>{t('Không có khách hàng nào')}</p>
        )}
      </div>
    );
  }
}

PharmacyList.propTypes = {
  displayFields: PropTypes.array,
  pharmacyList: PropTypes.array,
  isLoading: PropTypes.bool,
  onSortChange: PropTypes.func,
};

PharmacyList.defaultProps = {
  displayFields: [],
  pharmacyList: [],
  isLoading: false,
};

export default withTranslation()(PharmacyList);
