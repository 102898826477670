import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from "react-i18next";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
} from '@material-ui/core';
import StyledButton from 'components/Styled/Button/Button';
import CreateIcon from '@material-ui/icons/Create';
import ListAltIcon from '@material-ui/icons/ListAlt';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import FormInput from './FormInput/FormInput';
import TableSelect from './TableSelect/TableSelect'

import styles from './FormDialog.module.scss';

class FormDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      formTypeOptions: [{
        value: 'INPUT',
        label: 'Nhập thông tin sản phẩm',
        icon: <CreateIcon/>
      },{
        value: 'SELECT',
        label: 'Chọn nhiều sản phẩm đang cần hỗ trợ hoặc từ dự án',
        icon: <ListAltIcon/>
      }],
      formTypeValue: props.data?._index ? 'INPUT' : '',
      // formTypeValue: 'SELECT',
    }
    this.refFormInput = React.createRef();
    this.refTableSelect = React.createRef();
  }
  componentDidMount() {
    
  }
  
  handleSubmit = (values) => {
    this.props.onSubmit({ ...values })
  }
  handleSubmitForm = () => {
    if ( this.state.formTypeValue === 'INPUT') {
      this.refFormInput.current.submitForm();
    } 
    if ( this.state.formTypeValue === 'SELECT') {
      this.refTableSelect.current.submitForm();
    }
  }
  handleSubmitFormInput = (values) => {
    this.props.onSubmit( Array.isArray(values) ? [...values] : { ...values });
  }
  handleCloseDialog = () => {
    this.props.onClose();
  }

  render() {
    const { open, loading, t } = this.props;

    return (
      <Dialog
        open={open}
        maxWidth="md"
        scroll='paper'
        onClose={this.handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ root: `${loading && 'OverlayLoading'}`, paperScrollPaper: `${ this.state.formTypeValue === 'SELECT' && styles.DialogTableSelect }` }}
        fullWidth
      >
        <DialogTitle id="scroll-dialog-title">{ this.props.data._index ? `${this.props.data?.product_name}` : t('Thêm sản phẩm')}</DialogTitle>
        <DialogContent dividers>
          {
            !this.state.formTypeValue ? 
            <div className={styles.FormTypeList}>
              {
                this.state.formTypeOptions.map( item => (
                  <div key={item.value} onClick={() => this.setState({ formTypeValue: item.value })} className={styles.FormTypeList__Item}>
                    <div className={styles.FormTypeList__Icon}>
                      <div className={styles.FormTypeList__IconWrap}>
                        {item.icon}
                      </div>
                    </div>
                    <div className={styles.FormTypeList__Content}>
                      <p>{t(item.label)}</p>
                    </div>
                  </div>
                ))
              }
            </div> : 
            <> 
              {
                this.state.formTypeValue === 'INPUT' && <FormInput
                  data={this.props.data}
                  ref={this.refFormInput}
                  onSubmit={this.handleSubmitFormInput}
                />
              }
              {
                this.state.formTypeValue === 'SELECT' && <TableSelect
                  params={this.props.params}
                  ref={this.refTableSelect}
                  onSubmit={this.handleSubmitFormInput}
                />
              }
            </>
          }
        </DialogContent>
        <DialogActions disableSpacing>
          <Box width="100%" display="flex" justifyContent="space-between">
            <Box>
              {
                this.state.formTypeValue && !this.props.data?._index &&
                  <StyledButton
                    color="default"
                    onClick={() => this.setState({ formTypeValue: '' })}
                  >
                    <ArrowBackIcon/> {t('common:Quay lại')}
                  </StyledButton>
              }
            </Box>
            <Box display="flex" style={{ gap: '.5rem' }}>
              <StyledButton
                color="default"
                onClick={() => this.handleCloseDialog()}
              >
                {t('common:Đóng')}
              </StyledButton>
              {
                this.state.formTypeValue && 
                <StyledButton
                  variant="contained"
                  color="primary"
                  onClick={() => this.handleSubmitForm()}
                >
                  { this.state.formTypeValue === 'INPUT' && t('common:Lưu')}
                  { this.state.formTypeValue === 'SELECT' && t('Đưa vào danh sách đóng góp')}
                </StyledButton>
              }
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
    );
  }
}

FormDialog.propTypes = {
  data: PropTypes.object
};

FormDialog.defaultProps = {
  data: {},
  open: true
}
export default withTranslation(['componentConnectSupportDonateForm','common'],{ withRef: true })(FormDialog);
