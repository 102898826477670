import axiosAuth from 'utils/axios/axiosAuth';

const logisticApi = {
  calculateDeliveryFee: ({ params }) => {
    return axiosAuth.post(`delivery/calculate_fee`, params);
  },
  getAllByDelivery: ({ params }) => {
    return axiosAuth.post(`delivery/logistic_provider`, params);
  },
  getAll: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_logistic_provider`, {
      params: queryParams,
    });
  },
};

export default logisticApi;
