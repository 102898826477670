import React, { PureComponent } from 'react';
import { maxBy, isEqual } from 'lodash';
import { withTranslation } from "react-i18next";

import { numberFormat } from 'utils/helper';

import { 
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
} from '@material-ui/core';
import StyledCheckbox from 'components/Styled/Checkbox/Checkbox';
import StyledButton from 'components/Styled/Button/Button';
import FormProductInfoDialog from './FormProductInfoDialog/FormProductInfoDialog';
import ImagesDialog from 'components/ImagesDialog/ImagesDialog';
import { ReactComponent as EmptyIcon } from 'assets/images/icons/Empty.svg';
import AddIcon from '@material-ui/icons/Add';

import stylesLayoutMainEcom from 'assets/styles/layouts/LayoutMainEcom.module.scss';
import styles from './ProductInfo.module.scss';

class ProductInfo extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      formProductInfoDialogParams: {
        open: false,
        data: null,
      },
      imagesDialogParams: {
        open: false,
        data: [],
      },
      selectedProduct: [],
    }
  }
  componentDidUpdate(prevProps) {
    if ( !isEqual(prevProps.values.service_request_items,this.props.values.service_request_items) ) {
      this.setState((state) =>({
        selectedProduct: state.selectedProduct.filter(item => { 
          return this.props.values.service_request_items.map( i => i._index ).includes(item._index);
        })
      }))
    }
  }

  updateFormProductInfoDialogState = (params) => {
    this.setState((state) => ({ formProductInfoDialogParams: { 
      ...state.formProductInfoDialogParams,
      ...params 
    }}))
  }
  updateImagesDialogState = (params) => {
    this.setState((state) => ({ imagesDialogParams: { 
      ...state.imagesDialogParams,
      ...params 
    }}))
  }
  isProductSelected = (product) => {
    return this.state.selectedProduct.some( id => id === product._index );
  }

  handleToggleImagesDialog = (data) => {
    this.updateImagesDialogState({ open: true, data })
  }
  handleCloseImagesDialog = () => {
    this.updateImagesDialogState({ open: false })
  }
  handleToggleFormProductInfoDialog = (data) => {
    this.updateFormProductInfoDialogState({ open: true, data })
  }
  handleCloseFormProductInfoDialog = () => {
    this.updateFormProductInfoDialogState({ open: false })
  }
  handleSubmitFormProductInfoDialog = (serviceRequestItem) => {
    const { service_request_items } = this.props.values;
    if ( ![null,undefined].includes(serviceRequestItem._index) ) {
      const serviceRequestItemIndex = service_request_items.findIndex((item) => item._index === serviceRequestItem._index );
      if ( serviceRequestItemIndex > - 1 ) service_request_items[serviceRequestItemIndex] = {...serviceRequestItem};
      this.props.setFieldValue('service_request_items',[...service_request_items],false);
    } else {
      const maxById = maxBy(service_request_items,'_index');
      this.props.setFieldValue('service_request_items',service_request_items.concat([{...serviceRequestItem, _index: maxById ? maxById._index + 1 : 1 }]),false)
    };
    this.handleCloseFormProductInfoDialog();
  }
  handleSelectAllProduct = () => {
    const { service_request_items } = this.props.values;
    this.setState((state) => {
      if ( state.selectedProduct.length !==  service_request_items.length ) return {
        selectedProduct: [...service_request_items.map(item => item._index )]
      }
      return { selectedProduct: [] }
    })
  }
  handleSelectProduct = (product) => {
    this.setState((state) => {
      if ( state.selectedProduct.some( id => id === product._index ) ) return {
        selectedProduct: [...state.selectedProduct.filter( id => id !== product._index )]
      }
      return { selectedProduct: [...state.selectedProduct].concat([product._index]) }
    })
  }
  handleSelectedProduct = (action) => {
    if ( action === 'REMOVE' ) {
      this.props.setFieldValue('service_request_items',[...this.props.values.service_request_items.filter( item => !this.state.selectedProduct.includes(item._index))])
    }
  }

  render() {
    const { 
      values,
      t,
    } = this.props;
    return (
      <>
        <div className={styles.Toolbar}>
          <div className={stylesLayoutMainEcom.FormLabel}>{t('Thông tin sản phẩm bạn cần hỗ trợ')}</div>
          <div className={styles.GroupActions}>
            {
              this.state.selectedProduct.length > 0 &&
              <StyledButton variant="contained" onClick={() => this.handleSelectedProduct("REMOVE")} color="secondary">
                {t('common:Xóa')}
              </StyledButton>
            }
            <StyledButton variant="contained" onClick={() => this.handleToggleFormProductInfoDialog()} color="primary">
              {t('common:Thêm')}
            </StyledButton>
          </div>
        </div>
        <TableContainer className={styles.TableContainer}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {
                  values.service_request_items.length > 0 &&
                  <TableCell padding="checkbox">
                    <StyledCheckbox
                      indeterminate={this.state.selectedProduct.length > 0 && this.state.selectedProduct.length < values.service_request_items.length}
                      checked={this.state.selectedProduct.length > 0 && this.state.selectedProduct.length === values.service_request_items.length}
                      onChange={this.handleSelectAllProduct}
                    />
                  </TableCell>
                }
                <TableCell>{t('STT')}</TableCell>
                <TableCell>{t('Tên sản phẩm')}</TableCell>
                <TableCell>{t('Nhà sản xuất')}</TableCell>
                <TableCell>{t('Danh mục')}</TableCell>
                <TableCell>{t('Đơn vị')}</TableCell>
                <TableCell>{t('Số lượng cần')}</TableCell>
                <TableCell>{t('Hình ảnh')}</TableCell>
                {
                  values.service_request_items.length > 0 &&
                  <TableCell></TableCell>
                }
              </TableRow> 
            </TableHead>
            <TableBody>
              {
                values.service_request_items.length > 0 ? 
                values.service_request_items.map( (item,index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell padding="checkbox">
                        <StyledCheckbox
                          checked={this.isProductSelected(item)}
                          onChange={() => this.handleSelectProduct(item)}
                        />
                      </TableCell>
                      <TableCell>
                        {index + 1}
                      </TableCell>
                      <TableCell>
                        {item.product_name}
                      </TableCell>
                      <TableCell>
                        {item.manufacturer_name}
                      </TableCell>
                      <TableCell>
                        {item.category_ecom_name}
                      </TableCell>
                      <TableCell>
                        {item.uom_base_name}
                      </TableCell>
                      <TableCell>
                        {numberFormat(item.quantity_number)}
                      </TableCell>
                      <TableCell>
                        {
                          item.images.length > 0 &&
                          <div onClick={() => this.handleToggleImagesDialog(item.images)} className={`BgImage BgImage--rounded ${styles.Images} ${item.images.length > 1 && styles['Images--dark']}`} style={{ backgroundImage: `url(${item.images[0]})`}} >
                            { item.images.length > 1 && <Box className={styles.Images__content}>
                                <AddIcon/> {item.images.length - 1}
                              </Box>
                            }
                          </div>
                        }
                      </TableCell>
                      <TableCell align="right">
                        <StyledButton variant="contained" size="small" onClick={() => this.handleToggleFormProductInfoDialog({ ...item, index }) } color="secondary">
                          {t('common:Sửa')}
                        </StyledButton>
                      </TableCell>
                    </TableRow>
                  )
                }) :
                <TableRow>
                  <TableCell align="center" className={styles.TableEmpty} colSpan="7">
                    <EmptyIcon className={styles.TableEmpty__icon}/>
                    <p>{t('Chưa có sản phẩm nào')}</p>
                  </TableCell>
                </TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>
        {
          this.state.formProductInfoDialogParams.open &&
          <FormProductInfoDialog
            open={this.state.formProductInfoDialogParams.open}
            onSubmit={this.handleSubmitFormProductInfoDialog}
            onClose={this.handleCloseFormProductInfoDialog}
            data={this.state.formProductInfoDialogParams.data}
          />
        }
        {
          this.state.imagesDialogParams.open &&
          <ImagesDialog
            open={this.state.imagesDialogParams.open}
            onClose={this.handleCloseImagesDialog}
            data={this.state.imagesDialogParams.data}
          />
        }
      </>
    );
  }
};

export default withTranslation(['componentConnectSupportSupportForm','common'],{ withRef: true })(ProductInfo);
