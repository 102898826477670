export const LOAD_STATUS_IDLE = 'idle';
export const LOAD_STATUS_LOADING = 'loading';
export const LOAD_STATUS_SUCCEEDED = 'succeeded';
export const LOAD_STATUS_FAILED = 'failed';

export default {
  LOAD_STATUS_IDLE,
  LOAD_STATUS_LOADING,
  LOAD_STATUS_SUCCEEDED,
  LOAD_STATUS_FAILED
}