import {
  CHECK_AUTH,
  SIGN_OUT,
  GET_PROFILE,
  UPDATE_PROFILE,
  SAVE_AUTH,
  REMOVE_AUTH,
  SAVE_AUTH_LOADING,
  SAVE_AUTH_ERROR,
  SAVE_AUTH_FIELDS_MAP,
  SAVE_PROFILE,
  GET_USER_PROVINCE_LIST,
  SAVE_USER_PROVINCE_LIST,
  SAVE_CHECKING_AUTH,
} from 'redux/constants/authConstants';

export function checkAuth(payload) {
  return {
    type: CHECK_AUTH,
    payload
  };
}
export function signOut(payload) {
  return {
    type: SIGN_OUT,
    payload
  };
}
export function getProfile(payload) {
  return {
    type: GET_PROFILE,
    payload
  };
}
export function updateProfile(payload) {
  return {
    type: UPDATE_PROFILE,
    payload
  };
}

export function getUserProvinceList(payload) {
  return {
    type: GET_USER_PROVINCE_LIST,
    payload
  };
}

// mutation
export function saveAuthLoading(loading) {
  return {
    type: SAVE_AUTH_LOADING,
    loading
  };
}
export function saveAuthError(error) {
  return {
    type: SAVE_AUTH_ERROR,
    error
  };
}
export function saveAuth(user) {
  return {
    type: SAVE_AUTH,
    user
  };
}
export function saveCheckingAuth(checkingAuth) {
  return {
    type: SAVE_CHECKING_AUTH,
    checkingAuth
  };
}
export function saveAuthFieldsMap(fieldsMap) {
  return {
    type: SAVE_AUTH_FIELDS_MAP,
    fieldsMap
  };
}
export function removeAuth(payload) {
  return {
    type: REMOVE_AUTH,
    payload
  };
}

export function saveProfile(profile) {
  return {
    type: SAVE_PROFILE,
    profile
  };
}

export function saveUserProvinceList(userProvinceList) {
  return {
    type: SAVE_USER_PROVINCE_LIST,
    userProvinceList
  };
}
