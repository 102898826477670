import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectSalesOrder } from 'redux/selectors';
import { withTranslation } from "react-i18next";

import Panel from 'components/Panel/Panel';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';

import classes from './SalesOrderSalesInfo.module.scss';

class SalesOrderSalesInfo extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      openSalesUserDialog: false,
    }
  }

  handleOpenSalesUserDialog = () => {
    this.setState({
      openSalesUserDialog: true,
    });
  }
  handleCloseSalesUserDialog = () => {
    this.setState({
      openSalesUserDialog: false,
    });
  }
  handleSaveSalesUserSuccess = (newSalesOrderData) => {
    this.props.onSaveSalesInfoSuccess(newSalesOrderData);
  }

  render() {
    const { salesOrder, t } = this.props;

    return (<Panel
      title= {t('Phụ trách bởi')}
      panelClassName={classes.Panel}
      content={<div className={classes.Wrap}>
        { salesOrder && salesOrder?.sales_name && (
          <>
            <p className={classes.CustomerName}>{salesOrder.sales_name}</p>
            { salesOrder?.sales_phone &&
              <div className={classes.Info}>
                <label><PhoneOutlinedIcon fontSize="small"/></label>
                {salesOrder.sales_phone || `-`}
              </div>
            }
          </>)
        }
      </div>}
    />)
  }
}

SalesOrderSalesInfo.propTypes = {
  salesOrder: PropTypes.object,
  onSaveSalesInfoSuccess: PropTypes.func,
};
SalesOrderSalesInfo.defaultProps = {
};

const mapStateToProps = createStructuredSelector({
  salesOrder: makeSelectSalesOrder(),
});
const withConnect = connect(mapStateToProps, null);
export default withTranslation()(compose(withConnect)(SalesOrderSalesInfo));
