import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withTranslation } from "react-i18next";

import classes from './PromotionDetailsDialog.module.scss';

class PromotionDetailsDialog extends PureComponent {
  render() {
    const { isOpen, promotionDetails ,t } = this.props;
    return (
      <Dialog
        open={isOpen}
        onClose={this.props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={false}
        className={classes.Dialog}
      >
        <div className={`${classes.DialogContentWrap}`}>
          <DialogTitle className={`${classes.DialogTitle} PreWrap`}>
            {promotionDetails?.name}
          </DialogTitle>
          <DialogContent className={classes.DialogContent}>
            {promotionDetails?.vendor_name ? (
              <div className={classes.Info}>
                <label>{t('Nhà cung cấp')}</label>
                <p className={`${classes.InfoDisplay} PreWrap`}>
                  {promotionDetails?.vendor_name}
                </p>
              </div>
            ) : (
              ``
            )}
            <div className={classes.Info}>
              <label>{t('Mô tả')}</label>
              <p className={`${classes.InfoDisplay} PreWrap`}>
                {promotionDetails?.description || `-`}
              </p>
            </div>
          </DialogContent>
          <DialogActions className={classes.DialogFooter}>
            <Button variant="outlined" onClick={this.props.onClose}>
              {t('Đóng')}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    );
  }
}

PromotionDetailsDialog.propTypes = {
  isOpen: PropTypes.bool,
  promotionDetails: PropTypes.object,
  onClose: PropTypes.func,
};

PromotionDetailsDialog.defaultProps = {
  isOpen: false,
};

export default withTranslation()(PromotionDetailsDialog);
