import {
  // REDUCER
  SET_STATE_PRODUCT,
  SET_DEFAULT_PRODUCT_LIST_PARAMS,
  SET_PRODUCT_LIST_PARAMS_BY_FILTERS,
  SET_PRODUCT_LIST_PARAMS,
  SET_PRODUCT_FILTER_OPTIONS,
  RESET_PRODUCT_FILTER,

  SET_PRODUCT_DETAILS_STATES,  
  SET_PRODUCT_DETAILS,
  SET_PRODUCT_DETAILS_PARAMS,

  SET_PRODUCT_DESCRIPTIONS,
  SET_PRODUCT_DESCRIPTIONS_PARAMS,

  SET_PRODUCT_RATINGS,
  SET_PRODUCT_RATINGS_PARAMS,

  SET_PRODUCT_PRICE_WHOLESALES,
  SET_PRODUCT_PRICE_WHOLESALES_PARAMS,

  SET_PRODUCT_RATING_DETAIL,
  SET_PRODUCT_RATING_DETAIL_PARAMS,

  SET_PRODUCT_RELATED_LIST_PARAMS,

  SET_STATE_PRODUCT_STATUS,
  // SAGA
  GET_PRODUCT_LIST,
  FETCH_PRODUCT_LIST,
  DEBOUNCE_GET_PRODUCT_LIST,
  FETCH_PRODUCT_DETAILS,
  FETCH_PRODUCT_RATINGS,
  FETCH_PRODUCT_DESCRIPTIONS,
  FETCH_PRODUCT_PRICE_WHOLESALES,
  FETCH_PRODUCT_DETAILS_INFO,
  FETCH_PRODUCT_RATING_DETAIL,
  FETCH_PRODUCT_RELATED_LIST,
} from 'redux/constants/productConstants';

// <------------------------------ REDUCER ----------------------------------> //

export function setStateProduct(payload) {
  return {
    type: SET_STATE_PRODUCT,
    payload,
  };
}
export function resetProductFilter(payload) {
  return {
    type: RESET_PRODUCT_FILTER,
    payload,
  };
}
export function setProductListParamsByFilters(payload) {
  return {
    type: SET_PRODUCT_LIST_PARAMS_BY_FILTERS,
    payload,
  };
}
export function setProductListParams(payload) {
  return {
    type: SET_PRODUCT_LIST_PARAMS,
    payload,
  };
}
export function setDefaultProductListParams(payload) {
  return {
    type: SET_DEFAULT_PRODUCT_LIST_PARAMS,
    payload,
  };
}
export function setProductFilterOptions(payload) {
  return {
    type: SET_PRODUCT_FILTER_OPTIONS,
    payload,
  };
}

export function setProductDetails(payload) {
  return {
    type: SET_PRODUCT_DETAILS,
    payload,
  };
}
export function setProductDetailsStates(payload) {
  return {
    type: SET_PRODUCT_DETAILS_STATES,
    payload,
  };
}
export function setProductDetailsParams(payload) {
  return {
    type: SET_PRODUCT_DETAILS_PARAMS,
    payload,
  };
}

export function setProductRatings(payload) {
  return {
    type: SET_PRODUCT_RATINGS,
    payload,
  };
}

export function setProductRatingsParams(payload) {
  return {
    type: SET_PRODUCT_RATINGS_PARAMS,
    payload,
  };
}

export function setProductDescriptions(payload) {
  return {
    type: SET_PRODUCT_DESCRIPTIONS,
    payload,
  };
}
export function setProductDescriptionsParams(payload) {
  return {
    type: SET_PRODUCT_DESCRIPTIONS_PARAMS,
    payload,
  };
}

export function setProductPriceWholesales(payload) {
  return {
    type: SET_PRODUCT_PRICE_WHOLESALES,
    payload,
  };
}
export function setProductPriceWholesalesParams(payload) {
  return {
    type: SET_PRODUCT_PRICE_WHOLESALES_PARAMS,
    payload,
  };
}

export function setProductRatingDetail(payload) {
  return {
    type: SET_PRODUCT_RATING_DETAIL,
    payload,
  };
}
export function setProductRatingDetailParams(payload) {
  return {
    type: SET_PRODUCT_RATING_DETAIL_PARAMS,
    payload,
  };
}

export function setProductRelatedListParams(payload) {
  return {
    type: SET_PRODUCT_RELATED_LIST_PARAMS,
    payload,
  };
}

export function setStateProductStatus({ scope, value }) {
  return {
    type: SET_STATE_PRODUCT_STATUS,
    payload: { scope, value },
  };
}

// <------------------------------ SAGA ----------------------------------> //

export function fetchProductList({ 
  params, 
  cancelToken, 
  debounce, 
  resetPaging, 
  clearingFilters, 
  resetDefault,
  resolve, 
  reject,
}) {
  return {
    type: FETCH_PRODUCT_LIST,
    payload: {
      params,
      cancelToken,
      debounce,
      resetPaging,
      clearingFilters, 
      resetDefault,
      resolve, 
      reject,
    },
  };
}
export function fetchProductDetails({ 
  params, 
  cancelToken, 
  debounce, 
  resolve, 
  reject,
}) {
  return {
    type: FETCH_PRODUCT_DETAILS,
    payload: {
      params,
      cancelToken,
      debounce,
      resolve, 
      reject,
    },
  };
}
export function fetchProductRatings({ 
  params, 
  cancelToken, 
  debounce, 
  resolve, 
  reject,
}) {
  return {
    type: FETCH_PRODUCT_RATINGS,
    payload: {
      params,
      cancelToken,
      debounce,
      resolve, 
      reject,
    },
  };
}
export function fetchProductDescriptions({ 
  params, 
  cancelToken, 
  debounce, 
  resolve, 
  reject,
}) {
  return {
    type: FETCH_PRODUCT_DESCRIPTIONS,
    payload: {
      params,
      cancelToken,
      debounce,
      resolve, 
      reject,
    },
  };
}
export function fetchProductPriceWholesales({ 
  params, 
  cancelToken, 
  debounce, 
  resolve, 
  reject,
}) {
  return {
    type: FETCH_PRODUCT_PRICE_WHOLESALES,
    payload: {
      params,
      cancelToken,
      debounce,
      resolve, 
      reject,
    },
  };
}

export function fetchProductDetailsInfo({ 
  params, 
  cancelToken,
  debounce, 
  resolve, 
  reject,
}) {
  return {
    type: FETCH_PRODUCT_DETAILS_INFO,
    payload: { 
      params, 
      cancelToken,
      debounce, 
      resolve, 
      reject,
    },
  };
}

export function fetchProductRatingDetail({  
  params, 
  cancelToken,
  debounce, 
  resolve, 
  reject,
}) {
  return {
    type: FETCH_PRODUCT_RATING_DETAIL,
    payload: { 
      params, 
      cancelToken,
      debounce, 
      resolve, 
      reject,
    },
  };
}

export function fetchProductRelatedList({  
  params, 
  cancelToken,
  debounce, 
  resolve, 
  reject,
}) {
  return {
    type: FETCH_PRODUCT_RELATED_LIST,
    payload: { 
      params, 
      cancelToken,
      debounce, 
      resolve, 
      reject,
    },
  };
}

export function getProductList(payload) {
  return {
    type: GET_PRODUCT_LIST,
    payload,
  };
}
export function debounceGetProductList(payload) {
  return {
    type: DEBOUNCE_GET_PRODUCT_LIST,
    payload,
  };
}