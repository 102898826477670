import axiosAuth from 'utils/axios/axiosAuth';

const VOUCHER_API_URL = 'getcare_voucher_action/';
const voucherApi = {

  getVendorSuggestions:({params}) => {
    return axiosAuth.get(`${VOUCHER_API_URL}search_voucher?code=${params.getcare_vendor_suggestion}&getcare_customer_code=`+ params.getcare_customer_code);
  },

};

export default voucherApi;
