import React, { Component } from 'react';
import { withTranslation } from "react-i18next";

import PageHeader from 'components/PageHeader/PageHeader';
import PermissionDenied from 'views/PermissionDenied/PermissionDenied';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { checkAuth ,signOut } from 'redux/actions/authActions';
import { createStructuredSelector } from "reselect";
import { makeSelectLoginUser } from 'redux/selectors';
import { ToastContainer } from 'react-toastify';
import { WEBSOCKET_ENDPOINT, KEEP_CONNECT_MSG, KEEP_CONNECT_RES } from 'utils/constanst/wsConstants';
import { saveGlobalWS, saveWSEvent } from 'redux/actions/wsActions';
import { isValidJSONString } from 'utils/helper';
import jwtService from 'utils/services/jwtService';

import isEqual from 'lodash/isEqual';

import { toast } from 'react-toastify';
import { Helmet } from "react-helmet";

import { CUSTOMER_PAGE } from 'utils/constanst/common';

import 'react-toastify/dist/ReactToastify.css';
import classes from './LayoutEcom.module.scss';

const UpdateTitleBar = ({ title }) => {
  return (
    <Helmet>
      <title>{title ? `${title} - ` : ''}Phahub</title>
    </Helmet>
  );
};

class LayoutEcom extends Component {
  ws = undefined;
  _connectWebsocket = () => {
    this.ws = new WebSocket(`${WEBSOCKET_ENDPOINT}?token=${jwtService.getToken()}`);
    let that = this;
    var connectInterval;
    var connectHoldingInterval;

    this.ws.onopen = () => {
      this.props.saveGlobalWS(this.ws);
      that.timeout = 250;

      clearTimeout(connectInterval);
      clearInterval(connectHoldingInterval);

      connectHoldingInterval = setInterval(this._keepConnectWebsocket, 30000);

      this.ws.onmessage = (e) => {
        if (!e.data || !isValidJSONString(e.data)) return;
        const message = JSON.parse(e.data);
        if (message.result === false) {
          toast.error(message.message);
          return;
        }
        if (![KEEP_CONNECT_MSG, KEEP_CONNECT_RES].includes(message.event)) {
          this.props.saveWSEvent({
            ...message,
            indexField: 'event',
          });
        }
      };
    };
    this.ws.onerror = (err) => {
      console.error(
        "Socket encountered error: ",
        err.message,
        "Closing socket"
      );
      this._closeWebsocket();
    };
    this.ws.onclose = (e) => {
      that.timeout = that.timeout + that.timeout;
      connectInterval = setTimeout(this.check, Math.min(10000, that.timeout));
      clearInterval(connectHoldingInterval);
    };
  };

  _check = () => {
    if (!this.ws || this.ws.readyState === WebSocket.CLOSED)
      this._connectWebsocket();
  };
  _keepConnectWebsocket = () => {
    this.ws &&
      this.ws.send(
        JSON.stringify({
          event: KEEP_CONNECT_MSG,
        })
      );
  };
  _closeWebsocket = () => {
    this.ws && this.ws.close();
  };

  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    const error = params.get('error');
    if (error && this.props.location.pathname === '/user/authentication/pms') {
      console.log('Oauth2 error:', error);
    }
    if ( !this.props.user?.id ) {
      this.props.checkAuth();
    }
  }
  componentDidUpdate(prevProps) {
    const { user } = this.props;
  
    if (user && user !== prevProps.user && !user.id) {
      // check auth failed
      window.location.href = process.env.REACT_APP_PATH_SSO + '?continue=' + window.location.href;
    }
    if (prevProps.user && prevProps.user.id && (!user || !user.id)) {
      // logout success
      window.location.href = process.env.REACT_APP_PATH_ECOM;
    }
    if (prevProps.user && prevProps.user.id && user && user.id && (prevProps.user.id !== user.id)) {
      // the second user logout success
      window.location.href = CUSTOMER_PAGE;
    }
    if (user && user.id && user.token && !isEqual(user, prevProps.user)) {
      this._connectWebsocket();
    }
  }

  componentWillUnmount() {
    this._closeWebsocket();
  }

  render() {
    const { t, user, children, name } = this.props;
    const hasAuth = user && user.id;

    return (<>
      { hasAuth &&
        <div className={classes.Wrap + ' FastOrder'}>
          <PageHeader />
          { user.token ?
            <div className={`${classes.PageMain}`}>
              <UpdateTitleBar title={t(name,{ ns: 'documentTitle' })}/>
              { children }
            </div> : <PermissionDenied/>
          }
        </div>
      }
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        closeOnClick
      />
    </>);
  }
}

const mapStateToProps = createStructuredSelector({
  user: makeSelectLoginUser(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    checkAuth: () => dispatch(checkAuth()),
    signOut: (payload) => dispatch(signOut(payload)),
    saveGlobalWS: (payload) => dispatch(saveGlobalWS(payload)),
    saveWSEvent: (payload) => dispatch(saveWSEvent(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withRouter, withTranslation(['documentTitle']))(LayoutEcom);
