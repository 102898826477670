export const LOGO_IMAGE = `${process.env.REACT_APP_PATH}static/logo/logo.svg`;
export const LOGO_PHAHUB_IMAGE = `${process.env.REACT_APP_PATH}static/logo/logo-phahub.svg`;
export const EMPTY_PICTURE_IMAGE = `${process.env.REACT_APP_PATH}static/empty-picture.svg`;
export const NO_RATING_IMAGE = `${process.env.REACT_APP_PATH}static/no-rating.png`;
export const NO_DATA_IMAGE = `${process.env.REACT_APP_PATH}static/no-data.png`;

export default {
  LOGO_IMAGE,
  LOGO_PHAHUB_IMAGE,
  EMPTY_PICTURE_IMAGE,
  NO_RATING_IMAGE,
  NO_DATA_IMAGE,
}