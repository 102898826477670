import authReducer from './authReducer';
import wsReducer from './wsReducer';
import { combineReducers } from 'redux';
import logisticReducer from './logisticReducer';
import locationReducer from './locationReducer';
import fastOrderReducer from './ecom/fastOrderReducer';
import pharmacyReducer from './ecom/pharmacyReducer';
import salesOrderReducer from './oms/salesOrderReducer';
import couponReducer from './ecom/couponReducer';

// import productReducer from './mdm/productReducer';
import connectSupportSupportReducer from './connectSupportSupportReducer';
import connectSupportDonateReducer from './connectSupportDonateReducer';
import productReducer from './productReducer';
import categoryReducer from './categoryReducer';
import bannerReducer from './bannerReducer';
import vendorReducer from './vendorReducer';
import commonReducer from './commonReducer';

export default combineReducers({
  authReducer,
  wsReducer,
  logisticReducer,
  locationReducer,
  fastOrderReducer,
  pharmacyReducer,
  salesOrderReducer,
  couponReducer,
  productReducer,
  connectSupportSupportReducer,
  connectSupportDonateReducer,
  bannerReducer,
  vendorReducer,
  commonReducer,
  categoryReducer,
});
