import React, { PureComponent } from 'react';

import { compose } from 'redux';
import { withRouter } from 'react-router';
import { validDate } from 'utils/helper';
import { dateFormat } from 'utils/constanst/dateConstants';
import { format } from 'date-fns';
import { withTranslation } from "react-i18next";

import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import CustomerAddressItem from 'components/CustomerAddressItem/CustomerAddressItem';

import classes from './PharmacyListItem.module.scss';

class PharmacyListItem extends PureComponent {
  _goToDetails = (e) => {
    this.props.history.push(`/customer/${this.props.id}`);
  };
  _getCustomerInfo = () => {
    return {
      getcare_customer: {
        name: this.props.name,
      },
      name: this.props.representative_name,
      phone: this.props.phone,
      address: this.props.address,
      getcare_ward: this.props.getcare_ward,
      getcare_district: this.props.getcare_district,
      getcare_province: this.props.getcare_province,
    };
  };

  handleFastOrder = (e) => {
    e.stopPropagation();
  };

  render() {
    const {
      getcare_sales_id,
      code,
      cart_quantity,
      expired_date,
      is_mine,
      t,
    } = this.props;
    const isMine = is_mine;
    const canOrder = isMine || !getcare_sales_id;

    return (
      <Link
        className={`${classes.Item} ${canOrder ? '' : classes.DisabledOrder}`}
        to={`/fastorder?getcare_customer_code=${code}`}
      >
        <CustomerAddressItem
          address={this._getCustomerInfo()}
          titleMaxLine={2}
        />
        <div className={classes.BottomInfo}>
          <div className={classes.MoreInfo}>
            <div className={classes.Info}>
              <label>{t('Mã khách hàng')}</label>
              <span>{code}</span>
            </div>
            <div className={`${classes.Info} ${classes.RightInfo}`}>
              {!!cart_quantity && canOrder && (<>
                <span className={classes.CartQty}>
                  <ShoppingCartOutlinedIcon className={classes.Icon} />
                  <span className={classes.QtyBadge}>{cart_quantity}</span>
                </span>
              </>)}
              {isMine && validDate(expired_date) && !cart_quantity && (<>
                <label>{t('Ngày hết hạn quản lý')}</label>
                <span className={classes.ExpiredDate}>
                  {format(validDate(expired_date), dateFormat)}
                </span>
              </>)}
            </div>
            <div className={classes.Actions}>
              <Button
                variant="contained"
                color="secondary"
                onClick={this.handleFastOrder}
              >
                {t('Đặt hàng')}
              </Button>
            </div>
          </div>
        </div>
      </Link>
    );
  }
}

export default withTranslation()(compose(withRouter)(PharmacyListItem));
