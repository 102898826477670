import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { 
  saveSalesOrderListOrderSelected
} from 'redux/actions/oms/salesOrderActions';
import {
  makeSelectSalesOrderListOrderSelected
} from 'redux/selectors';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';

import { withTranslation } from "react-i18next";
import SalesOrderListItem from './SalesOrderListItem/SalesOrderListItem';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';

import gridClasses from './SalesOrderListGrid.module.scss';
import classes from './SalesOrderList.module.scss';

import Checkbox from '@material-ui/core/Checkbox';

class SalesOrderList extends PureComponent {
  selectAllOrder = () => {
    const { salesOrderList } = this.props;
    let orderSelected = [...this.props.orderSelected];
    let isSelectedAll = salesOrderList.every( sol => orderSelected.includes(sol.id) )
    if ( isSelectedAll ) {
      let solIDs = salesOrderList.map( sol => sol.id ); 
      orderSelected = orderSelected.filter( id => !solIDs.includes(id)  )
    } else {
      salesOrderList.forEach( sol => {
        if  ( !orderSelected.includes(sol.id) ) orderSelected.push(sol.id)
      })
    }
    this.props.saveSalesOrderListOrderSelected(orderSelected)
  }
  
  selectAllOrderChecked = () => {
    const { salesOrderList } = this.props;
    let orderSelected = [...this.props.orderSelected];
    let isSelectedAll = salesOrderList.every( sol => orderSelected.includes(sol.id) )
    if ( isSelectedAll ) return "all";
    let solCheckIndex = salesOrderList.findIndex( sol => {
      return orderSelected.includes(sol.id);
    })
    if ( solCheckIndex > -1 ) return "indeterminate";
    return "none";
  }

  render() {
    const { salesOrderList, isLoading, displayFields, t} = this.props;
    let selectAllOrderChecked = this.selectAllOrderChecked();
    return <div className={`${classes.List} ${isLoading && 'OverlayLoading'}`}>
      <div className={`${classes.Body}`}>
        <div className={`${gridClasses.Row} ${classes.Header}`}>
          { displayFields.map((item) => (
            <ListHeaderCol
              key={item.name}
              {...item}
              className={`${gridClasses.Col} ${item.className}`}
              onSortChange={this.props.onSortChange}
            >
              { item.name === 'select' && 
                <Checkbox
                  style={{ padding: 0 }}
                  checked={selectAllOrderChecked === 'all'}
                  indeterminate={selectAllOrderChecked === 'indeterminate'}
                  onChange={this.selectAllOrder}
                />
              }
            </ListHeaderCol>
          )) }
        </div>
        { !isLoading && salesOrderList.length
          ? salesOrderList.map(item => (
            <SalesOrderListItem
              key={`SalesOrder-${item.id}`}
              {...item}
            />))
          : (<p className="NoData">{t('Không có đơn hàng nào')}</p>)
        }
      </div>
    </div>;
  }
}

SalesOrderList.propTypes = {
  displayFields: PropTypes.array,
  salesOrderList: PropTypes.array,
  orderSelected: PropTypes.array,
  isLoading: PropTypes.bool,
  onSortChange: PropTypes.func,
};

SalesOrderList.defaultProps = {
  displayFields: [],
  salesOrderList: [],
  orderSelected: [],
  isLoading: false,
};

const mapStateToProps = createStructuredSelector({
  orderSelected: makeSelectSalesOrderListOrderSelected(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    saveSalesOrderListOrderSelected: (payload) => dispatch(saveSalesOrderListOrderSelected(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default withTranslation()(compose(withConnect,withRouter)(SalesOrderList));
