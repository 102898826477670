import React, { PureComponent } from 'react';

import FileUpload from 'components/FileUpload/FileUpload';
import AttachFileOutlinedIcon from '@material-ui/icons/AttachFileOutlined';
import WallpaperSharpIcon from '@material-ui/icons/WallpaperSharp';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';

import classes from './DocumentUpload.module.scss';

class DocumentUpload extends PureComponent {
  render() {
    const { loading, type, label, hint, fileName } = this.props;
    const accept = type === 'image'
      ? 'image/jpg,image/jpeg,image/png,image/gif'
      : 'application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const labelIcon = type === 'image'
      ? <WallpaperSharpIcon className={classes.Icon} fontSize="small"/>
      : <FileCopyOutlinedIcon className={classes.Icon} fontSize="small"/>

    return (
      <div className={classes.FieldWrap}>
        <FileUpload
          className={`${classes.FieldUpload} ${loading ? 'OverlayLoading' : ''}`}
          accept={accept}
          helper={
            <div className={classes.FieldHelper}>
              { label }
              { labelIcon }
            </div>
          }
          onFilesAdded={(files) => this.props.onFilesAdded(files)}
        />
        <div className={classes.HelpBlock}>
          <span>{ hint }</span>
          { !!fileName &&
            <span className={classes.FileNameWrap}>
              <span className={classes.FileName}>{ fileName }</span>
              <AttachFileOutlinedIcon className={classes.Icon} fontSize="small"/>
            </span>
          }
        </div>
      </div>
    );
  }
}

export default DocumentUpload;
