import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { ceil, debounce, isEqual } from 'lodash';
import  moment from 'moment';
import axios from 'axios';
import { withTranslation } from "react-i18next";

import commentApi from 'utils/api/commentApi'
import { 
  makeSelectLoginUser 
} from 'redux/selectors';
import { 
  COMMENT_MEDIA_VIDEO_ID,
} from 'utils/constanst/commentConstants'

import { 
  Avatar,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import Pagination from '@material-ui/lab/Pagination';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import CommentForm from '../CommentForm/CommentForm';

import styles from './CommentList.module.scss';

class CommentList extends PureComponent {
  constructor(props) {
    super(props);
    const { object_id, parent_id, getcare_comment_type_id } = props;
    this.state = {
      loadingCommentList: true,
      commentListParams: {
        page: 1, 
        page_size: 10, 
        getcare_comment_type_id: getcare_comment_type_id || null,
        object_id: object_id || null, 
        parent_id: parent_id || null, 
      },
      loadMoreComment: false,
      commentListCount: 0,
      commentList: []
    }
    this.sourceCommentList = null;
    this.refCommentListChilds = {};
    // this.refInputImageFile = React.createRef();
  }
  componentDidMount() {
    if ( !this.props.isReply ) this.fetchCommentList();
    else this.setState({ loadingCommentList: false });
  }
  componentDidUpdate(prevProps, prevState) {
    if ( !isEqual(prevState.commentListParams,this.state.commentListParams) ) {
      this.setState((state) => ({ 
        loadingCommentList: true,
        commentList: !this.props.isReply ? [] : state.commentList,
      }));
      this.debounceFetchCommentList();  
    }
    if ( !isEqual(prevState.loadMoreComment,this.state.loadMoreComment) && !prevState.loadMoreComment ) {
      this.setState({ loadingCommentList: true });
      this.debounceFetchCommentList();  
    }
  }
  
  fetchCommentList = async (params) => {
    this.setState((state) => ({
      loadingCommentList: true,
    }))
    this.sourceCommentList && this.sourceCommentList.cancel();
    this.sourceCommentList = axios.CancelToken.source();
    const { data: response } = await this.loadCommentListData({ 
      params: params || {},
      cancelToken: this.sourceCommentList.token
    });
    if (!response?.result) {
      toast.error(response.message);
      this.setState({
        commentListCount: null,
      })
      return;
    }
    const commentList = this.props.isReply ? [...this.state.commentList] : [];
    const commentListIds = commentList.map( comment => comment.id );
  
    response.data.forEach( comment => {
      const existedComment = commentListIds.includes(comment.id);
      !existedComment && commentList.push({
        ...comment,
        _openReply: false,
        _showLoadMoreCommentText: comment.comment.length > 255 ? true : undefined,
      })
      this.refCommentListChilds[comment.id] = React.createRef();
    })
    this.setState((state) => ({
      loadingCommentList: false,
      commentList: [...commentList],
      commentListCount: ceil(response.total_records/state.commentListParams.page_size),
    }))
  }
  debounceFetchCommentList = debounce(this.fetchCommentList,500)
  loadCommentListData = ({ params, cancelToken }) => {
    const { page, page_size, object_id, parent_id } = this.state.commentListParams;
    const request = {
      params: {
        page,
        page_size,
        object_id,
        parent_id,
        ...params
      },
      cancelToken: cancelToken,
    }
    return commentApi.getCommentList(request);
  }
  updateCommentListParamsState = (params) => {
    this.setState((state) => ({
      commentListParams: {
        ...state.commentListParams,
        ...params,
      }
    }))
  }
  pushComment = (commentValue) => {
    this.refCommentListChilds[commentValue.id] = React.createRef();
    this.setState((state) => ({
      commentList: [
        commentValue,
        ...state.commentList,
      ]
    }))
  }
  formatCommentAt = (date) => {
    const commentAt = moment(date);
    const now = moment();
    const isToday = commentAt.date() === now.date() &&  
      commentAt.month() === now.month() &&
      commentAt.year() === now.year();
    return commentAt.format(`DD/MM/YYYY${ isToday ? ' HH:mm' : ''}`);
  }

  handleOpenFile = (url) => {
    window.open(url);
  }
  handleChangeCommentPage = (e,value) => {
    this.updateCommentListParamsState({ page: value });
  }
  handleToggleReply = ({ index, open }) => {
    const commentList = [...this.state.commentList];
    commentList[index]._openReply = open;
    this.setState({commentList: [...commentList]});
  }
  handleCompleteSubmitComment = ({ index, comment }) => {
    const commentList = [...this.state.commentList];
    const refCommentList = this.refCommentListChilds[commentList[index].id];
    const hasChildren = !!commentList[index].has_children
    if ( hasChildren && refCommentList.current ) {
      refCommentList.current.pushComment({...comment});
    }
    commentList[index].has_children = 1;
    this.setState({
      commentList: [...commentList]
    },() => {
      if ( !hasChildren && refCommentList?.current ) {
        refCommentList.current.handleLoadMoreComment();
      }
      // this.refCommentListChilds[commentList[index].id]
    });
    this.handleToggleReply({ index, open: false })
  }
  handleLoadMoreComment = () => {
    this.setState({ loadMoreComment: true })
  }
  handleLoadMoreCommentText = ({ index, showLoadMoreCommentText }) => {
    const commentList = [...this.state.commentList];
    commentList[index]._showLoadMoreCommentText = showLoadMoreCommentText;
    this.setState({commentList: [...commentList]});
  }

  render() {
    const { isReply, has_children, t } = this.props;

    return (
      <div className={styles.commentsContainer}>
        {
          (this.state.commentList && this.state.commentList.length > 0 ) && 
          <ul className={`${styles.commentsList} ${isReply && styles.commentReply}`}>
            {
              this.state.commentList.map( (item,index) => {
                return (
                  <li key={`item${item.id}`}>
                    <div className={styles.commentMainLevel}>
                      <div className={styles.commentAvatar}>
                        <Avatar
                          className={styles.Avatar} 
                          alt={item.user_name} 
                          src={item.user_avatar}
                        >
                          { item.user_name ? item.user_name.charAt(0) : 'G'}
                        </Avatar>
                      </div>
                      <div className={styles.commentBox}>
                        <div className={styles.commentHead}>
                          <p className={styles.commentName}>
                            {item.user_name}
                          </p>
                          <span className={styles.commentAt}>{this.formatCommentAt(item.created_at)}</span>
                        </div>
                        <div className={styles.commentContent}>
                          { 
                            item._showLoadMoreCommentText ? <>
                            {item.comment.substring(0,255)}...<span onClick={() => this.handleLoadMoreCommentText({ index, showLoadMoreCommentText: false })} className={styles.commentContentLoadMoreText}>Xem thêm</span>
                            </> : item._showLoadMoreCommentText === false ? <>
                            {item.comment} <span onClick={() => this.handleLoadMoreCommentText({ index, showLoadMoreCommentText: true })} className={styles.commentContentLoadMoreText}>{t('Ẩn bớt')}</span>
                            </> : item.comment
                          }
                        </div>
                        {
                          ( item.getcare_comment_attach_items && item.getcare_comment_attach_items.length > 0 ) && 
                          <div className={styles.commentFiles}>
                            {
                              item.getcare_comment_attach_items.map( (attachItem) => (
                                <div key={`attachItem${attachItem.id}`} className={styles.commentFileBox}>
                                  {
                                    attachItem.getcare_comment_media_id === COMMENT_MEDIA_VIDEO_ID ?
                                    <div onClick={() => this.handleOpenFile(attachItem.url)} className={styles.commentFileContent} >
                                      <video>
                                        <source src={attachItem.url}/>
                                      </video>
                                      <PlayCircleOutlineIcon className={styles.commentFilePlayVideoIcon}/>
                                    </div> : 
                                    <div onClick={() => this.handleOpenFile(attachItem.url)} className={`${styles.commentFileContent}`} style={{ backgroundImage: `url("${attachItem.url}")`}}></div>
                                  }
                                  {/* <div onClick={() => this.handleOpenFile(attachItem.url)} className={`${styles.commentFileContent} ${!attachItem._imageUrl && styles.commentFileLoading}`} style={{ backgroundImage: `url("${attachItem._imageUrl}")`}}>
                                    {
                                      (attachItem.getcare_comment_media_id === 2 && attachItem._imageUrl ) &&
                                      <PlayCircleOutlineIcon className={styles.commentFilePlayVideoIcon}/>
                                    }
                                  </div> */}
                                </div>
                              ))
                            }
                          </div>
                        }
                        {
                          (this.props.user?.id ) && (
                            !item._openReply ?
                            <div className={styles.commentActions}>
                              <a onClick={() => this.handleToggleReply({ index , open: true }) } className={styles.commentAction}>{t('Trả lời')}</a>
                            </div> : <>
                              <CommentForm
                                className={styles.commentFormReply}
                                parent_id={item.id}
                                object_id={item.object_id}
                                getcare_comment_type_id={item.getcare_comment_type_id}
                                btnClose
                                onComplete={(comment) => this.handleCompleteSubmitComment({ comment, index })}
                                onClose={() => this.handleToggleReply({ index , open: false })}
                              />
                            </>
                          )
                        }
                      </div>
                    </div>
                    {
                      ( item.has_children && item.has_children > 0 ) ?
                      <CommentList
                        ref={this.refCommentListChilds[item.id]}
                        t={t}
                        isReply
                        parent_id={item.id}
                        object_id={item.object_id}
                        getcare_comment_type_id={item.getcare_comment_type_id}
                        has_children={item.has_children}
                        user={this.props.user}
                      /> : ''
                    }
                  </li>
                )
              })
            }
          </ul> 
        }
        {
          this.state.loadingCommentList &&
          <ul className={`${styles.commentsList} ${isReply && styles.commentReply}`}>
            <li>
              <div className={styles.commentMainLevel}>
                <div className={styles.commentAvatar} style={{ backgroundColor: '#FFF', zIndex: 1 }}>
                  <div className={styles.commentName}>
                    <Skeleton variant="circle" width={40} height={40}/>
                  </div>
                </div>
                <div className={styles.commentBox}>
                  <Skeleton width="33%"/>
                  <div className={styles.commentContent}>
                    <Skeleton/>
                    <Skeleton/>
                    <Skeleton width="75%"/>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        }
        {
          ( has_children && !this.state.loadMoreComment) ? 
          <div className={styles.commentContainerActions}>
            <div className={styles.commentActions}>
              <a onClick={this.handleLoadMoreComment} className={styles.commentAction}>{t('Xem thêm bình luận')}</a>
            </div>
          </div> : ''
        }
        {
          (this.state.commentListCount && !isReply ) ? 
          <div className={styles.commentPagination}>
            <Pagination count={this.state.commentListCount} page={this.state.commentListParams.page} color="primary" shape="rounded" 
              onChange={this.handleChangeCommentPage}
            /> 
          </div>: ''
        }
      </div>
    );
  }
};

CommentList.propTypes = {
  isReply: PropTypes.bool,
  user: PropTypes.object,
  parent_id: PropTypes.number,
  object_id: PropTypes.number,
  getcare_comment_type_id: PropTypes.number,
};

CommentList.defaultProps = {
  user: {},
  parent_id: null,
  object_id: null,
  getcare_comment_type_id: null,
  isReply: false,
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectLoginUser(),
});
const mapDispatchToProps = (dispatch) => {
  return {

  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true });
export default compose(withConnect, withTranslation(['componentComments','common'],{ withRef: true }))(CommentList);
