import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { 
  makeSelectLoginUser 
} from 'redux/selectors';
import StyledTextField from 'components/Styled/TextField';
import StyledButton from 'components/Styled/Button/Button';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from '@material-ui/icons/Add';
import { 
  InputAdornment,
  Paper,
  MenuItem,
  MenuList,
  Grow,
  Popper,
} from '@material-ui/core';

import classes from './Toolbar.module.scss';

class ConnectSupportToolbar extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpenAddMenu: false,
    }
    this.anchorElAddMenu = React.createRef();
  }

  confirmLogin = () => {
    const { t } = this.props;
    let confirmLogin = true;
    if ( !this.props.user?.id ) {
      confirmLogin = window.confirm(t("common:Bạn phải đăng nhập trước khi thực hiện"));
    }
    return confirmLogin;
  }
  goLink = (e,url) => {
    e.preventDefault();
    this.confirmLogin() && this.props.history.push(url)
  }
  
  handleToggleAddMenu = () => {
    this.setState((state) => ({
      isOpenAddMenu: !state.isOpenAddMenu,
    }))
  }
  handleCloseAddMenu = () => {
    this.setState({
      isOpenAddMenu: false,
    })
  }
  render() {
    const { className, user, t } = this.props;
    const { isOpenAddMenu } = this.state;

    return (
      <div className={`${classes.Toolbar} ${className}`}>
        <StyledTextField
          placeholder={`${t('Tìm kiếm')}...`}
          size="medium"
          value={this.props.keyword}
          fullWidth
          variant="outlined"
          InputProps={{
            startAdornment: <InputAdornment position="start">
              <SearchIcon/>
            </InputAdornment>,
          }}
          onChange={(e) => {
            this.props.oncKeywordChange(e.target.value);
          }}
        />
        <StyledButton
          onClick={this.handleToggleAddMenu}
          variant="contained"
          ref={this.anchorElAddMenu}
          color="primary"
        >
          <AddIcon/> {t('Thêm')}
        </StyledButton>
        <Popper placement="bottom-end" open={isOpenAddMenu} anchorEl={this.anchorElAddMenu?.current} role={undefined} transition disablePortal style={{zIndex: 9999}}>
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: 'bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={this.handleCloseAddMenu}>
                  <MenuList>
                    <MenuItem onClick={(e) => this.goLink(e,'/community/connect-support/support/new')}>
                      {t('Cần hỗ trợ')}
                    </MenuItem>
                    <MenuItem onClick={(e) => this.goLink(e,'/community/connect-support/donate/new')}>
                      {t('Muốn đóng góp')}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }
};

ConnectSupportToolbar.propTypes = {
  className: PropTypes.string,
};

ConnectSupportToolbar.defaultProps = {
  className: '',
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectLoginUser(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect, withRouter, withTranslation(['viewMainEcomConnectSupport','common']))(ConnectSupportToolbar);
