import productApi from 'utils/api/app/productApi';
// import vendorApi from 'utils/api/mdm/vendorApi';
import { put, delay, call, select, all } from 'redux-saga/effects';
import { productActions } from 'redux/actions';
import { toast } from 'react-toastify';
import axios from 'axios';

import {
  PRODUCT_LIST_BY_BESTSELLER,
  PRODUCT_LIST_BY_HIGH_PRICE,
  PRODUCT_LIST_BY_HOT_PROMOTION,
  PRODUCT_LIST_BY_LOW_PRICE,
  PRODUCT_LIST_BY_NEW_ARRIVAL,
  PRODUCT_LIST_BY_TOP_SEARCH,
} from 'utils/constanst/productConstants';
import {
  LOAD_STATUS_IDLE,
  LOAD_STATUS_LOADING,
  LOAD_STATUS_SUCCEEDED,
  LOAD_STATUS_FAILED,
} from 'utils/constanst/statusConstants';

let productListSource = null;
let productDetailsSource = null;
let productDescriptionsSource = null;
let productRatingsSource = null;
let productWhosalePricesSource = null;
let productRatingDetailSource = null;
let productRelatedListSource = null;

// export function* loadProductDetails(payload) {
//   const { id, params } = payload.payload;
//   try {
//     yield put(productActions.saveLoading(true));

//     const { data: response } = yield productApi.get(id, params);
//     yield put(productActions.saveLoading(false));

//     if (!response?.result) {
//       return;
//     }
//     yield put(
//       productActions.saveProduct(response.data ? { ...response.data } : {})
//     );
//   } catch (err) {
//     yield put(productActions.saveLoading(false));
//   }
// }

export function* loadProductList(payload) {
  try {
    const {
      _product_list_by,
      ...params
    } = payload.payload.params;
    const productListOptions = {
      [PRODUCT_LIST_BY_HOT_PROMOTION]: {
        fetchProductList: productApi.fetchProductListByHotPromotion,
      },
      [PRODUCT_LIST_BY_TOP_SEARCH]: {
        fetchProductList: productApi.fetchProductListByTopSearch,
      },
      [PRODUCT_LIST_BY_BESTSELLER]: {
        fetchProductList: productApi.fetchProductListByBestseller,
      },
      [PRODUCT_LIST_BY_NEW_ARRIVAL]: {
        fetchProductList: productApi.fetchProductListByNewArrival,
      },
      [PRODUCT_LIST_BY_LOW_PRICE]: {
        fetchProductList: productApi.fetchProductListBySuggest,
        params: {
          order: 'price_sales asc'
        }
      },
      [PRODUCT_LIST_BY_HIGH_PRICE]: {
        fetchProductList: productApi.fetchProductListBySuggest,
        params: {
          order: 'price_sales desc'
        }
      },
    }
    const productListOption = productListOptions[_product_list_by];

    productListSource?.cancel && productListSource.cancel();
    productListSource = axios.CancelToken.source();
    const cancelToken = productListSource.token;

    yield put(productActions.setStateProduct({ loadingProductList: true, productListStatusCode: null })); 
    
    const { data: response } = yield productListOption.fetchProductList({ params: { ...params, ...productListOption.params }, cancelToken });

    if (!response?.result) {
      yield put(productActions.setStateProduct({ loadingProductList: false, productListStatusCode: 500 })); 
      toast.error(response.message);
      return;
    }
    yield put(productActions.setStateProduct({ 
      loadingProductList: false,
      productList: response.data ? [...response.data] : [],
      productListCount: response.total_records,
    })); 
  } catch (err) {
    if ( err.toString() === 'Cancel' ) { return }
    console.log("loadProductList",err.toString());
    yield put(productActions.setStateProduct({ loadingProductList: false, productListStatusCode: 500 })); 
  }
}

export function* fetchProductList(payload) {
  let cancelToken = payload.payload.cancelToken;
  const {
    params = {},
    debounce = 0,
    resetPaging = false,
    clearingFilters = false,
    resetDefault = false,
    resolve = () => {}, 
    reject = () => {},
  } = payload.payload;
  const newParams = { ...params }
  if ( clearingFilters ) yield put(productActions.resetProductFilter()) //  clearing filters
  if ( !newParams.page ) yield put(productActions.setStateProduct({ productListCount: 0 })) // clear count pagination when selecting page
  yield put(productActions.setStateProduct({ loadingProductList: true })) // call loading
  if ( resetPaging ) Object.assign(newParams,{ page: 1 }); // reset pagination
  yield put(productActions.setProductListParams({ ...newParams })) // set params api product list
  if ( resetDefault ) yield put(productActions.setDefaultProductListParams()) // reset default product list
  yield delay(debounce === true ? 500 : debounce) // debounce api

  const { productListParams } = yield select((state) => state.productReducer);
  try {
    const {
      _product_list_by,
      ...params
    } = productListParams;
    const productListOptions = {
      [PRODUCT_LIST_BY_HOT_PROMOTION]: {
        fetchProductList: productApi.fetchProductListByHotPromotion,
      },
      [PRODUCT_LIST_BY_TOP_SEARCH]: {
        fetchProductList: productApi.fetchProductListByTopSearch,
      },
      [PRODUCT_LIST_BY_BESTSELLER]: {
        fetchProductList: productApi.fetchProductListByBestseller,
      },
      [PRODUCT_LIST_BY_NEW_ARRIVAL]: {
        fetchProductList: productApi.fetchProductListByNewArrival,
      },
      [PRODUCT_LIST_BY_LOW_PRICE]: {
        fetchProductList: productApi.fetchProductListBySuggest,
        params: {
          order: 'price_sales asc'
        }
      },
      [PRODUCT_LIST_BY_HIGH_PRICE]: {
        fetchProductList: productApi.fetchProductListBySuggest,
        params: {
          order: 'price_sales desc'
        }
      },
    }
    const productListOption = productListOptions[_product_list_by];
    
    if ( !cancelToken ) {
      productListSource?.cancel && productListSource.cancel();
      productListSource = axios.CancelToken.source();
      cancelToken = productListSource.token;
    }
    
    const { data: response } = yield productListOption.fetchProductList({ params: { ...params, ...productListOption.params }, cancelToken });

    if (!response?.result) {
      yield put(productActions.setStateProduct({ loadingProductList: false, productListStatusCode: 500 })); 
      reject(response)
      return;
    }
    yield put(productActions.setStateProduct({ 
      loadingProductList: false,
      productList: response.data ? [...response.data] : [],
      productListCount: response.total_records,
    })); 
    resolve(response)
  } catch (err) {
    if ( err.toString() === 'Cancel' ) { return }
    console.log("fetchProductList",err.toString());
    yield put(productActions.setStateProduct({ loadingProductList: false }));
    reject(err) 
  }
}

export function* fetchProductRatingDetail(payload) {
  let cancelToken = payload.payload.cancelToken;
  const {
    params = {},
    debounce = 0,
    resolve = () => {}, 
    reject = () => {},
  } = payload.payload;
  const newParams = { ...params }
  yield put(productActions.setStateProduct({ loadingProductRatingDetail: true })) // call loading
  yield put(productActions.setProductRatingDetailParams({ ...newParams })) // set params api product details
  yield delay(debounce === true ? 500 : debounce) // debounce api

  const { productRatingDetailParams } = yield select((state) => state.productReducer);
  try {
    if ( !cancelToken ) {
      productRatingDetailSource?.cancel && productRatingDetailSource.cancel();
      productRatingDetailSource = axios.CancelToken.source();
      cancelToken = productRatingDetailSource.token;
    }
    const { data: response } = yield productApi.fetchProductRatingDetail({ 
      // params: { ...productRatingDetailParams, product_id: 51730, getcare_vendor_id: 1870, getcare_uom_base_id: 9 }, 
      params: { ...productRatingDetailParams }, 
      cancelToken 
    });
    if (!response?.result) {
      yield put(productActions.setStateProduct({ loadingProductRatingDetail: false })); 
      reject(response)
      return;
    }
    yield put(productActions.setStateProduct({ 
      productRatingDetail: { 
        total_rows: response.data?.total_rows || 0,
        items: response.data?.items || []
      },
      loadingProductRatingDetail: false,
    })); 
    resolve(response)
  } catch (err) {
    if ( err.toString() === 'Cancel' ) { return }
    console.log("fetchProductRatings",err.toString());
    yield put(productActions.setStateProduct({ loadingProductRatingDetail: false }));
    reject(err) 
  }
} 

export function* fetchProductRatings(payload) {
  let cancelToken = payload.payload.cancelToken;
  const {
    params = {},
    debounce = 0,
    resolve = () => {}, 
    reject = () => {},
  } = payload.payload;
  const newParams = { ...params }
  yield put(productActions.setStateProduct({ loadingProductRatings: true })) // call loading
  yield put(productActions.setProductRatingsParams({ ...newParams })) // set params api product details
  yield delay(debounce === true ? 500 : debounce) // debounce api

  const { productRatingsParams } = yield select((state) => state.productReducer);
  try {
    if ( !cancelToken ) {
      productRatingsSource?.cancel && productRatingsSource.cancel();
      productRatingsSource = axios.CancelToken.source();
      cancelToken = productRatingsSource.token;
    }
    const { data: response } = yield productApi.fetchProductRatings({ 
      // params: { ...productRatingsParams, product_id: 51730, getcare_vendor_id: 1870, getcare_uom_base_id: 9 }, 
      params: { ...productRatingsParams }, 
      cancelToken 
    });
    if (!response?.result) {
      yield put(productActions.setStateProduct({ loadingProductRatings: false })); 
      reject(response)
      return;
    }
    yield put(productActions.setStateProduct({ 
      loadingProductRatings: false,
      productRatings: response.data ? {...response.data } : null,
    })); 
    resolve(response)
  } catch (err) {
    if ( err.toString() === 'Cancel' ) { return }
    console.log("fetchProductRatings",err.toString());
    yield put(productActions.setStateProduct({ loadingProductRatings: false }));
    reject(err) 
  }
} 

export function* fetchProductDescriptions(payload) {
  let cancelToken = payload.payload.cancelToken;
  const {
    params = {},
    debounce = 0,
    resolve = () => {}, 
    reject = () => {},
  } = payload.payload;
  const newParams = { ...params }
  yield put(productActions.setStateProduct({ loadingProductDescriptions: true })) // call loading
  yield put(productActions.setProductDescriptionsParams({ ...newParams })) // set params api product details
  yield delay(debounce === true ? 500 : debounce) // debounce api

  const { productDescriptionsParams } = yield select((state) => state.productReducer);
  try {
    if ( !cancelToken ) {
      productDescriptionsSource?.cancel && productDescriptionsSource.cancel();
      productDescriptionsSource = axios.CancelToken.source();
      cancelToken = productDescriptionsSource.token;
    }
    const { data: response } = yield productApi.fetchProductDescriptions({ 
      // params: { ...productDescriptionsParams, product_id: 96957, getcare_vendor_id: 1910, getcare_uom_base_id: 9 },
      params: { ...productDescriptionsParams }, 
      cancelToken 
    });
    if (!response?.result) {
      yield put(productActions.setStateProduct({ loadingProductDescriptions: false })); 
      reject(response)
      return;
    }
    yield put(productActions.setStateProduct({ 
      loadingProductDescriptions: false,
      productDescriptions: response.data ? {...response.data } : null,
    })); 
    // productDescriptionsSource
    resolve(response)
  } catch (err) {
    if ( err.toString() === 'Cancel' ) { return }
    console.log("fetchProductDescriptions",err.toString());
    yield put(productActions.setStateProduct({ loadingProductDescriptions: false }));
    reject(err) 
  }
} 

export function* fetchProductDetails(payload) {
  let cancelToken = payload.payload.cancelToken;
  const {
    params = {},
    debounce = 0,
    resolve = () => {}, 
    reject = () => {},
  } = payload.payload;
  const newParams = { ...params }
  yield put(productActions.setStateProduct({ loadingProductDetails: true })) // call loading
  yield put(productActions.setProductDetailsParams({ ...newParams })) // set params api product details
  yield put(productActions.setStateProductStatus({ scope: "productDetails", value: LOAD_STATUS_LOADING }))
  yield delay(debounce === true ? 500 : debounce) // debounce api

  const { productDetailsParams } = yield select((state) => state.productReducer);
  try {
    if ( !cancelToken ) {
      productDetailsSource?.cancel && productDetailsSource.cancel();
      productDetailsSource = axios.CancelToken.source();
      cancelToken = productDetailsSource.token;
    }
    const { data: response } = yield productApi.fetchProductDetails({ params: { ...productDetailsParams }, cancelToken });
    if (!response?.result) {
      yield put(productActions.setStateProduct({ loadingProductDetails: false })); 
      yield put(productActions.setStateProductStatus({ scope: "productDetails", value: LOAD_STATUS_FAILED }))
      reject(response)
      return;
    }
    const vendorSelected = response.data?.vendor_items ? response.data?.vendor_items.find( vendorItem => vendorItem.default ) : null;
    yield put(productActions.setProductDetailsStates({ 
      quantityAddedToCart: 1,
      vendorSelected,
    })); 
    yield put(productActions.setStateProduct({ 
      loadingProductDetails: false,
      productDetails: response.data ? {...response.data } : null,
    })); 
    yield put(productActions.setStateProductStatus({ scope: "productDetails", value: LOAD_STATUS_SUCCEEDED }))
    resolve(response)
  } catch (err) {
    if ( err.toString() === 'Cancel' ) { return }
    console.log("fetchProductDetails",err.toString());
    yield put(productActions.setStateProduct({ loadingProductDetails: false }));
    yield put(productActions.setStateProductStatus({ scope: "productDetails", value: LOAD_STATUS_FAILED }))
    reject(err) 
  }
}

export function* fetchProductPriceWholesales(payload) {
  let cancelToken = payload.payload.cancelToken;
  const {
    params = {},
    debounce = 0,
    resolve = () => {}, 
    reject = () => {},
  } = payload.payload;
  const newParams = { ...params }
  yield put(productActions.setStateProduct({ loadingProductPriceWholesales: true })) // call loading
  yield put(productActions.setProductPriceWholesalesParams({ ...newParams })) // set params api product details
  yield delay(debounce === true ? 500 : debounce) // debounce api

  const { productPriceWholesalesParams } = yield select((state) => state.productReducer);
  try {
    if ( !cancelToken ) {
      productWhosalePricesSource?.cancel && productWhosalePricesSource.cancel();
      productWhosalePricesSource = axios.CancelToken.source();
      productWhosalePricesSource = productWhosalePricesSource.token;
    }
    const { data: response } = yield productApi.fetchProductPriceWholesales({ 
      // params: { ...productPriceWholesalesParams, getcare_vendor_id: 1866, getcare_uom_base_id: 11 }, 
      params: { ...productPriceWholesalesParams }, 
      cancelToken 
    });
    if (!response?.result) {
      yield put(productActions.setStateProduct({ loadingProductPriceWholesales: false })); 
      reject(response)
      return;
    }
    yield put(productActions.setStateProduct({ 
      loadingProductPriceWholesales: false,
      productPriceWholesales: response.data ? [...response.data ] : null,
    })); 
    resolve(response)
  } catch (err) {
    if ( err.toString() === 'Cancel' ) { return }
    console.log("fetchProductPriceWholesales",err.toString());
    yield put(productActions.setStateProduct({ loadingProductPriceWholesales: false }));
    reject(err) 
  }
}

export function* fetchProductDetailsInfo(payload) {
  let cancelToken = payload.payload.cancelToken;
  const {
    params = {},
    debounce = 0,
    resolve = () => {}, 
    reject = () => {},
  } = payload.payload;
  const { productDetails } = yield select((state) => state.productReducer);
  const vendorSelected = productDetails?.vendor_items ? 
    productDetails?.vendor_items.find( vendorItem => ( 
      vendorItem.id === params?.getcare_vendor_id && 
      vendorItem.getcare_uom_base_id === params?.getcare_uom_base_id 
    )) : null;

  yield put(productActions.setStateProduct({
    loadingProductDescriptions: true,
    loadingProductRatings: true,
    loadingProductPriceWholesales: true,
  }))
  yield put(productActions.setProductDetailsStates({
    vendorSelected,
  }))
  yield delay(debounce === true ? 500 : debounce)

  const commonParams = {
    product_id: params?.product_id,
    getcare_vendor_id: vendorSelected?.id, 
    getcare_uom_base_id: vendorSelected?.getcare_uom_base_id, 
  }
  try {
    yield all([
      put(productActions.fetchProductRatings({ 
        params: { ...commonParams },
        cancelToken
      })),
      put(productActions.fetchProductDescriptions({ 
        params: { ...commonParams },
        cancelToken
      })),
      put(productActions.fetchProductPriceWholesales({ 
        params: { ...commonParams },
        cancelToken
      })),
      put(productActions.fetchProductRatingDetail({ 
        params: { ...commonParams, page: 1 },
        cancelToken
      })),
    ])
    resolve()
  } catch (error) {
    reject()
  }
}

export function* fetchProductRelatedList(payload) {
  let cancelToken = payload.payload.cancelToken;
  const {
    params = {},
    debounce = 0,
    resolve = () => {}, 
    reject = () => {},
  } = payload.payload;
  const newParams = { ...params }
  yield put(productActions.setStateProduct({ loadingProductRelatedList: true })) // call loading
  yield put(productActions.setProductRelatedListParams({ ...newParams })) // set params api product details
  yield delay(debounce === true ? 500 : debounce) // debounce api

  const { productRelatedListParams } = yield select((state) => state.productReducer);
  try {
    if ( !cancelToken ) {
      productRelatedListSource?.cancel && productRelatedListSource.cancel();
      productRelatedListSource = axios.CancelToken.source();
      cancelToken = productRelatedListSource.token;
    }
    const { data: response } = yield productApi.fetchProductRelatedList({ 
      params: { ...productRelatedListParams }, 
      cancelToken 
    });
    if (!response?.result) {
      yield put(productActions.setStateProduct({ loadingProductRelatedList: false })); 
      reject(response)
      return;
    }
    yield put(productActions.setStateProduct({ 
      productRelatedList: response.data ? [...response.data] : [],
      loadingProductRelatedList: false,
    })); 
    resolve(response)
  } catch (err) {
    if ( err.toString() === 'Cancel' ) { return }
    console.log("fetchProductRelatedList",err.toString());
    yield put(productActions.setStateProduct({ loadingProductRelatedList: false }));
    reject(err) 
  }
} 


// export function* loadIndustries(payload) {
//   const { params } = payload.payload;
//   try {
//     const { data: response } = yield productApi.getProductIndustries({
//       params,
//     });

//     if (!response?.result) {
//       yield put(productActions.saveProductIndustries([]));
//       return;
//     }
//     yield put(
//       productActions.saveProductIndustries(
//         response.data ? [...response.data] : []
//       )
//     );
//   } catch (err) {
//     console.log(err);
//   }
// }

// export function* loadProductManufactures(payload) {
//   const { params } = payload.payload;
//   try {
//     // const { data: response } = yield productApi.getProductManufactures({
//     const { data: response } = yield vendorApi.getAll({
//       params,
//     });
//     if (!response?.result) {
//       return;
//     }
//     yield put(
//       productActions.saveProductManufactures(
//         response.data ? [...response.data] : []
//       )
//     );
//   } catch (err) {
//     console.log(err);
//   }
// }
