import axiosCommon from 'utils/axios/axiosCommon';
import axiosAuth from 'utils/axios/axiosAuth';

export default {
  getProductList: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosCommon.get(`ecom/common/product`, {
      params: {
        ...queryParams,
      }
    });
  },
  getBannerList: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`/mobile/banner`,{
      params: {
        ...queryParams,
      }
    });
  },
  getManufacturerList: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosCommon.get(`ecom/common/manufacturer`, {
      params: {
        ...queryParams,
      }
    });
  },
  getUomBaseList: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosCommon.get(`ecom/common/uom_base`, {
      params: {
        ...queryParams,
      }
    });
  },
  getCategoryEcomList: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosCommon.get(`ecom/common/category_ecom`, {
      params: {
        ...queryParams,
      }
    });
  },
  getNPOList: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosCommon.get(`ecom/common/npo`, {
      params: {
        ...queryParams,
      },
    });
  }
};
