import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getSalesOrder, confirmPurchasing, saveSalesOrderAllInfo } from 'redux/actions/oms/salesOrderActions';
import { createStructuredSelector } from "reselect";
import {
  makeSelectSalesOrder,
  makeSelectDeliveryLogisticList,
  makeSelectSalesOrderLoading,
  makeSelectSalesOrderLogistic,
  makeSelectSalesOrderProducts,
  makeSelectSalesOrderListQueryString,
  makeSelectLoginUser,
} from 'redux/selectors';
import { withTranslation } from "react-i18next";
import { withRouter, Link } from 'react-router-dom';
import { isRouteEnabled } from 'utils/constanst/authConstants';
import salesOrderApi from 'utils/api/oms/salesOrderApi';
import FastOrderSuccessDialog from 'components/ecom/FastOrder/FastOrderSuccessDialog/FastOrderSuccessDialog';

import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button';
import ArrowBack from '@material-ui/icons/ArrowBack';
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import SalesOrderInfo from 'components/ecom/SalesOrderInfo/SalesOrderInfo';
import SalesOrderProducts from 'components/ecom/SalesOrderProducts/SalesOrderProducts';
import SalesOrderBonuses from 'components/ecom/SalesOrderBonuses/SalesOrderBonuses';
import SalesOrderLogisticProvider from 'components/ecom/SalesOrderLogisticProvider/SalesOrderLogisticProvider';
import SalesOrderNotes from 'components/ecom/SalesOrderNotes/SalesOrderNotes';
import SalesOrderSalesInfo from 'components/ecom/SalesOrderSalesInfo/SalesOrderSalesInfo';
import SalesOrderDeliveryInfo from 'components/ecom/SalesOrderDeliveryInfo/SalesOrderDeliveryInfo';
import SalesOrderAmountInfo from 'components/ecom/SalesOrderAmountInfo/SalesOrderAmountInfo';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import SalesOrderPaymentInfo from 'components/ecom/SalesOrderPaymentInfo/SalesOrderPaymentInfo';
import SalesOrderCancelFormDialog from 'components/ecom/SalesOrderCancelFormDialog/SalesOrderCancelFormDialog';

import { toast } from 'react-toastify';
import * as QueryString from 'query-string';

import classes from './SalesOrder.module.scss';

class SalesOrder extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      nextSalesOrderId: null,
      confirmDiglogOpen: false,
      confirmDiglogTitle: "",
      confirmDiglogMessage: "",
      confirmDiglogSubmit: null,
      isLoadingConfirmDialog: false,
      confirmDiglogCancel: false,
      confirmDiglogBuy: false,
      isSuccessDialogOpen: false,
    }
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    const { location } = this.props;
    const token = QueryString.parse(location.search);
    if (token?.so_code) {
      this._loadData(token?.so_code);
      this.setState({
        isSuccessDialogOpen: true
      })
    } else {
      this._loadData(id);
    }
  }
  componentDidUpdate(prevProps) {
    const { id } = this.props.match.params;
    if (id && id !== prevProps.match.params.id) {
      this._loadData(id);
    }
  }

  _loadData = (id) => {
    this.props.getSalesOrder(id);
  }
  _backToList = () => {
    this.props.history.push(`/so${this.props.salesOrderListQueryString}`);
  }

  _loadSalesOrderHistory = () => {
    this.props.getSalesOrder(this.props.salesOrder?.id);
  }

  _goToNextSalesOrder = () => {
    this.props.history.push(`/so/${this.state.nextSalesOrderId}`);
  }
  _goToAfterItem = () => {
    this.props.history.push(`/so/${this.props.salesOrder?.next_code}`);
  }
  _goToBeforeItem = () => {
    this.props.history.push(`/so/${this.props.salesOrder?.prev_code}`);
  }

  submitReorders = async (type) => {
    const { salesOrder } = this.props;
    this.setState({
      isLoadingConfirmDialog: false
    })
    const { data: response } = await salesOrderApi.confirmOrCreateReorders({
      params: { ids: [salesOrder.id], force_type: type }
    });
    this.setState({
      isLoadingConfirmDialog: false,
      confirmDiglogOpen: false
    })
    toast.success(`Đã ${type === "OVERRIDE" ? "thay thế" : "gộp"} đơn hàng thành công!`)
  }
  handleConfirmReorders = async () => {
    const { salesOrder } = this.props;
    this.setState({
      isLoadingConfirmDialog: true
    })
    const { data: response } = await salesOrderApi.confirmOrCreateReorders({
      params: { ids: [salesOrder.id] }
    });
    if (!response.result && response.conflict) {
      this.setState({
        confirmDiglogOpen: true,
        confirmDiglogTitle: "Thông báo",
        confirmDiglogMessage: response.message,
      })
    } else {
      toast.success(`Đã đặt lại đơn hàng thành công!`);
    }

    this.setState({
      isLoadingConfirmDialog: false
    })
  }

  handleCloseSuccessDialog = () => {
    this.setState({
      isSuccessDialogOpen: false,
    }, () => {
      window.location.replace('/');
    });
  };

  handleConfirmCancel = () => {
    this.setState({
      confirmDiglogCancel: true,
    })
  }
  handleCloseCancelDialog = () => {
    this.setState({
      confirmDiglogCancel: false,
    })
  }

  handleSaveSalesOrderAllInfo = (newSalesOrderData) => {
    this.props.saveSalesOrderAllInfo(newSalesOrderData);
    this._loadSalesOrderHistory();
  }

 

  handleConfirmBuy = () => {
      this.props.confirmPurchasing({
        params: {
          id: this.props.salesOrder?.id,
        }
      });
  }


  render() {
    const { salesOrder, deliveryLogisticList, loading, t } = this.props;
    const { isSuccessDialogOpen } = this.state;
    const statusId = salesOrder?.getcare_order_status?.id;

    return (<>
      <div className={`${classes.PageWrap} ${loading ? 'OverlayLoading' : ''}`}>
        <Breadcrumbs
          separator={
            <NavigateNextIcon
              className="BreadscrumbSeparator"
              fontSize="small"
            />
          }
          className={`${classes.PageBreadscrumbs} Breadscrumbs`}
          aria-label="breadcrumb"
        >
          {isRouteEnabled(this.props.user, 'customer') && <Link color="inherit" to="/customer">{t('Trang chủ')}</Link>}
          <Link color="inherit" to="/so">{t('Danh Sách Đơn Hàng')}</Link>
          <span className="BreadscrumbActive">{t('Chi Tiết Đơn Hàng')}</span>
        </Breadcrumbs>

        <div className={classes.PageHeader}>
          <h1 className={`${classes.PageTitle} PageTitle`}>
            {t('Chi Tiết Đơn Hàng')}
            {salesOrder?.is_taxcode && (
              <Tooltip title={`Xuất hoá đơn GTGT`} arrow placement="top">
                <LocalOfferOutlinedIcon className={classes.InvoiceIcon} />
              </Tooltip>
            )}
          </h1>
          <Button
            variant="outlined"
            startIcon={<ArrowBack />}
            onClick={this._backToList}
          >{t('Trở về')}</Button>
          <Button
            type="button"
            variant="contained"
            color="secondary"
            onClick={this.handleConfirmReorders}
            disabled={this.state.isLoadingConfirmDialog}
          >
            {this.state.isLoadingConfirmDialog ? t('Đang thiết lập lại đơn hàng...') : t('Đặt lại đơn hàng')}
          </Button>

          {
            salesOrder?.getcare_order_status?.name === "Thiếu hàng" &&
            <>
              <Button
                type="button"
                variant="contained"
                // color="secondary"
                onClick={this.handleConfirmCancel}
              >
                {t('Huỷ')}
              </Button>
              <Button
                type="button"
                variant="contained"
                color="secondary"
                onClick={this.handleConfirmBuy}
              >
                {t('Xác nhận mua')}
              </Button>
            </>
          }
        </div>

        <div className={classes.PageMain}>
          <div className={classes.InfoWrap}>
            <SalesOrderInfo />
          </div>
          <div className={classes.Content}>
            <SalesOrderProducts salesOrderStatusId={statusId} />
            <SalesOrderBonuses />
            <SalesOrderLogisticProvider deliveryLogisticList={[...deliveryLogisticList]} />
            <SalesOrderPaymentInfo salesOrderCode={salesOrder?.code} deliveryLogisticList={[...deliveryLogisticList]} />
            <SalesOrderNotes salesOrderId={salesOrder?.id} />
          </div>
          <div className={classes.Side}>
            <SalesOrderSalesInfo />
            <SalesOrderDeliveryInfo />
            <SalesOrderAmountInfo />
          </div>
        </div>
      </div>

      <FastOrderSuccessDialog
        dataOrder={this.state.dataOrder}
        // prDetails={this.props.prDetails}
        isOpen={isSuccessDialogOpen}
        onClose={this.handleCloseSuccessDialog}
      />

      {this.state.confirmDiglogCancel &&
        <SalesOrderCancelFormDialog
          isOpen={this.state.confirmDiglogCancel}
          salesOrderId={salesOrder?.id}
          onClose={this.handleCloseCancelDialog}
          onCancelSuccess={this.handleSaveSalesOrderAllInfo}
        />
      }



      <ConfirmationDialog
        isLoading={this.state.isLoadingConfirmDialog}
        isOpen={this.state.confirmDiglogOpen}
        title={this.state.confirmDiglogTitle}
        type="info"
        message={this.state.confirmDiglogMessage}
        dialogAction={
          <>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => this.submitReorders("OVERRIDE")}
            >
              {t('Thay thế')}
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => this.submitReorders("MERGE")}
            >
              {t('Gộp')}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => this.setState({ confirmDiglogOpen: false })}
            >
              {t('Hủy')}
            </Button>
          </>
        }
      />
    </>);
  }
}

const mapStateToProps = createStructuredSelector({
  salesOrder: makeSelectSalesOrder(),
  deliveryLogisticList: makeSelectDeliveryLogisticList(),
  loading: makeSelectSalesOrderLoading(),
  salesOrderLogistic: makeSelectSalesOrderLogistic(),
  salesOrderProducts: makeSelectSalesOrderProducts(),
  salesOrderListQueryString: makeSelectSalesOrderListQueryString(),
  user: makeSelectLoginUser(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getSalesOrder: (payload) => dispatch(getSalesOrder(payload)),
    confirmPurchasing: (payload) => dispatch(confirmPurchasing(payload)),
    saveSalesOrderAllInfo: (payload) => dispatch(saveSalesOrderAllInfo(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default withTranslation()(compose(withConnect, withRouter)(SalesOrder));
