import { createSelector } from 'reselect';

const selectConnectSupportDonate = (state) => {
  return state.connectSupportDonateReducer || {};
};
const makeSelectConnectSupportDonate = () =>
  createSelector(selectConnectSupportDonate, (substate) => substate.connectSupportDonate);
const makeSelectSavingConnectSupportDonate = () =>
  createSelector(selectConnectSupportDonate, (substate) => substate.savingConnectSupportDonate);
const makeSelectOpenProductInfoFormInput= () =>
  createSelector(selectConnectSupportDonate, (substate) => substate.openProductInfoFormInput);

export {
  makeSelectConnectSupportDonate,
  makeSelectSavingConnectSupportDonate,
  makeSelectOpenProductInfoFormInput,
}