import {
  SET_STATE_VENDOR,
  SET_VENDOR_LIST_PARAMS,
  GET_VENDOR_LIST,
  DEBOUNCE_GET_VENDOR_LIST,
} from 'redux/constants/vendorConstants';

// reducer
export function setStateVendor(payload) {
  return {
    type: SET_STATE_VENDOR,
    payload,
  };
}
export function setVendorListParams(payload) {
  return {
    type: SET_VENDOR_LIST_PARAMS,
    payload,
  };
}

// saga
export function getVendorList(payload) {
  return {
    type: GET_VENDOR_LIST,
    payload,
  };
}
export function debounceGetVendorList(payload) {
  return {
    type: DEBOUNCE_GET_VENDOR_LIST,
    payload,
  };
}