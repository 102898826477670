import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from "react-i18next";

import { 
  makeSelectLoginUser 
} from 'redux/selectors';

import { 
  Link,
  Box,
  Grid,
  Container,
  Typography,
} from '@material-ui/core';
import StyledPaper from 'components/Styled/Paper/Paper'

import styles from './BusinessSolution.module.scss';
import stylesLayoutMainEcom from 'assets/styles/layouts/LayoutMainEcom.module.scss';

class BusinessSolution extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      businessServices: [{
        name: 'OMS',
        fullName: 'Quản lý đơn hàng',
        color: '#ed1c24',
        href: process.env.REACT_APP_PATH_OMS,
      },{
        name: 'ECOM',
        fullName: 'Thương mại điện tử',
        color: '#60935d',
        href: process.env.REACT_APP_PATH_ECOM,
      },{
        name: 'MDM',
        fullName: 'Quản lý dữ liệu',
        color: '#b79492',
        href: process.env.REACT_APP_PATH_MDM,
      },{
        name: 'TMK',
        fullName: 'Tiếp thị thương mại',
        color: '#3d348b',
        href: process.env.REACT_APP_PATH_TMK,
      },{
        name: 'Vendor',
        fullName: 'Quản lý gian hàng',
        color: '#3e92cc',
        href: process.env.REACT_APP_PATH_VENDOR,
      },{
        name: 'PMS',
        fullName: 'Quản lý thuốc',
        color: '#fc7a57',
        href: process.env.REACT_APP_PATH_PMS,
      }],
    }
    this.refConnectSupportSupportForm = React.createRef();
  }

  render() {
    const { t } = this.props;
    return (
      <Container>
        <Typography className={stylesLayoutMainEcom.ContainerTitle} variant="h6">{t('Giải pháp kinh doanh')}</Typography>
        <Grid container spacing={2}>
          {
            this.state.businessServices.map( (item,index) => (
              <Grid item key={index} xs={12} sm={4} alignItems="stretch">
                <Link href={item.href} target="_blank" underline="none" color="inherit">
                  <StyledPaper boxShadowType={1} className={styles.box} style={{ '--color': item.color }}>
                    <Box className={styles.box__icon} >
                      {t(item.name)}
                    </Box>
                    <Box className={styles.box__title}>
                      {t(item.fullName)}
                    </Box>
                  </StyledPaper>
                </Link>
              </Grid>
            ))
          }
        </Grid>
      </Container>
    );
  }
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectLoginUser(),
});
const withConnect = connect(mapStateToProps);

export default compose(withConnect, withTranslation(['viewMainEcomBusinessSolution']))(BusinessSolution);
