import React from 'react';
import { withStyles  } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';

const styles = (theme) => {
  return {
    indicator: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
      minHeight: 3,
      '& > span': {
        width: '100%',
        backgroundColor: theme.palette.primary.main,
      },
    },
    scrollButtons: {
      position: 'absolute',
      height: '100%',
      backgroundColor: '#FFF',
      zIndex: 1,
      '&:last-child': {
        right: 0,
      }
    },
  
    root: {
      position: 'relative',
      // display: 'flex',
      // justifyContent: 'center',
      // backgroundColor: 'transparent',
      // '& > span': {
      //   maxWidth: 40,
      //   width: '100%',
      //   backgroundColor: '#635ee7',
      // },
    },
  }
};

const StyledTabs = withStyles(styles)((props) => <Tabs TabIndicatorProps={{ children: <span /> }} {...props}/>);

export default withStyles(styles)(
  React.forwardRef( (props, ref) =>
    <StyledTabs ref={ref} {...props}  />
  )
);