// reducer
export const SET_STATE_PRODUCT = 'getcare/product/SET_STATE_PRODUCT';
export const SET_PRODUCT_LIST_PARAMS = 'getcare/product/SET_PRODUCT_LIST_PARAMS';
export const SET_DEFAULT_PRODUCT_LIST_PARAMS = 'getcare/product/SET_DEFAULT_PRODUCT_LIST_PARAMS';
export const SET_PRODUCT_LIST_PARAMS_BY_FILTERS = 'getcare/product/SET_PRODUCT_LIST_PARAMS_BY_FILTERS';
export const SET_PRODUCT_FILTER_OPTIONS = 'getcare/product/SET_PRODUCT_FILTER_OPTIONS';
export const RESET_PRODUCT_FILTER = 'getcare/product/RESET_PRODUCT_FILTER';

export const SET_PRODUCT_DETAILS_STATES = 'getcare/product/SET_PRODUCT_DETAILS_STATES';
export const SET_PRODUCT_DETAILS = 'getcare/product/SET_PRODUCT_DETAILS';
export const SET_PRODUCT_DETAILS_PARAMS = 'getcare/product/SET_PRODUCT_DETAILS_PARAMS';

export const SET_PRODUCT_RATINGS = 'getcare/product/SET_PRODUCT_RATINGS';
export const SET_PRODUCT_RATINGS_PARAMS = 'getcare/product/SET_PRODUCT_RATINGS_PARAMS';

export const SET_PRODUCT_DESCRIPTIONS = 'getcare/product/SET_PRODUCT_DESCRIPTIONS';
export const SET_PRODUCT_DESCRIPTIONS_PARAMS = 'getcare/product/SET_PRODUCT_DESCRIPTIONS_PARAMS';

export const SET_PRODUCT_PRICE_WHOLESALES = 'getcare/product/SET_PRODUCT_PRICE_WHOLESALES';
export const SET_PRODUCT_PRICE_WHOLESALES_PARAMS = 'getcare/product/SET_PRODUCT_PRICE_WHOLESALES_PARAMS';

export const SET_PRODUCT_RATING_DETAIL = 'getcare/product/SET_PRODUCT_RATING_DETAIL';
export const SET_PRODUCT_RATING_DETAIL_PARAMS = 'getcare/product/SET_PRODUCT_RATING_DETAIL_PARAMS';

export const SET_PRODUCT_RELATED_LIST_PARAMS = 'getcare/product/SET_PRODUCT_RELATED_LIST_PARAMS';

export const SET_STATE_PRODUCT_STATUS = 'getcare/product/SET_STATE_PRODUCT_STATUS';

// Saga
export const GET_PRODUCT_LIST = 'getcare/product/GET_PRODUCT_LIST';
export const FETCH_PRODUCT_LIST = 'getcare/product/FETCH_PRODUCT_LIST';
export const DEBOUNCE_GET_PRODUCT_LIST = 'getcare/product/DEBOUNCE_GET_PRODUCT_LIST';
export const FETCH_PRODUCT_DETAILS = 'getcare/product/FETCH_PRODUCT_DETAILS';
export const FETCH_PRODUCT_RATINGS = 'getcare/product/FETCH_PRODUCT_RATINGS';
export const FETCH_PRODUCT_DESCRIPTIONS = 'getcare/product/FETCH_PRODUCT_DESCRIPTIONS';
export const FETCH_PRODUCT_PRICE_WHOLESALES = 'getcare/product/FETCH_PRODUCT_PRICE_WHOLESALES';
export const FETCH_PRODUCT_DETAILS_INFO = 'getcare/product/FETCH_PRODUCT_DETAILS_INFO';
export const FETCH_PRODUCT_RATING_DETAIL = 'getcare/product/FETCH_PRODUCT_RATING_DETAIL';
export const FETCH_PRODUCT_RELATED_LIST = 'getcare/product/FETCH_PRODUCT_RELATED_LIST';
