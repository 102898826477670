import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import { validDate, isArrayEmpty } from 'utils/helper';
import { dateFormat, apiDateFormat } from 'utils/constanst/dateConstants';
import { format } from 'date-fns';
import { isArray, isEqual } from 'lodash';

import TextField from '@material-ui/core/TextField';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';

import classes from './FilterField.module.scss';

class FilterField extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }
  componentDidMount() {
    if (!this._isEmpty(this.props.value)) {
      this.setState({
        isOpen: true,
      });
    }
  }
  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (!isEqual(value, prevProps.value) && !this._isEmpty(value)) {
      this.setState({
        isOpen: true,
      });
    }
  }
  _clearValue = () => {
    this.props.handleFieldChange({
      name: this.props.name,
      value: this.props.defaultValue,
    });
  }
  _isEmpty = (value) => {
    return isArray(value) ? isArrayEmpty(value) : ['', null, undefined].includes(value);
  }

  handleToggleCollapse = (e) => {
    const isSelected = this.state.isOpen;
    const { value } = this.props;
    this.setState({
      isOpen: !isSelected,
    });
    if (isSelected && !this._isEmpty(value)) {
      this._clearValue();
    }
  };
  handleInputChange = (e) => {
    this.props.handleFieldChange({
      name: this.props.name,
      value: e.target.value,
    });
  };
  handleDateChange = (date) => {
    this.props.handleFieldChange({
      name: this.props.name,
      value: validDate(date) ? format(date, apiDateFormat) : '',
    });
  };

  render() {
    const { label, value, type, placeholder, customField } = this.props;
    const { isOpen } = this.state;

    return (
      <div className={classes.FieldWrap}>
        <div className={classes.FormControl} onClick={this.handleToggleCollapse}>
          {label}
          {isOpen ? <ExpandLess className={classes.Icon} fontSize="small" /> : <ExpandMore className={classes.Icon} fontSize="small" />}
        </div>
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          {type === 'input' && (
            <TextField
              className={`${classes.Field} TextFieldOutlined`}
              variant="outlined"
              size="small"
              fullWidth
              value={value}
              placeholder={placeholder}
              autoFocus={true}
              onChange={this.handleInputChange}
            />
          )}
          {type === 'date' && (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                className={`${classes.Field} TextFieldOutlined`}
                inputVariant="outlined"
                size="small"
                fullWidth
                disableToolbar
                autoOk
                variant="inline"
                placeholder={placeholder}
                format={dateFormat}
                value={validDate(value) ? validDate(value) : null}
                onChange={this.handleDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          )}
          {type === 'custom' && customField}
        </Collapse>
      </div>
    );
  }
}

FilterField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  customField: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  type: PropTypes.oneOf(['input', 'date', 'custom']),
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  placeholder: PropTypes.string,
  handleFieldChange: PropTypes.func,
};

FilterField.defaultProps = {
  label: '',
  value: '',
  type: 'input',
  placeholder: '',
  defaultValue: '',
};

export default FilterField;
