import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { groupBy } from 'lodash';
import { getDisplayFields, currencyFormatVN, groupByVendorPrProducts } from 'utils/helper';
import {
  isSplitOrderByVendor,
} from 'utils/constanst/common';
import { isCollaborator } from 'utils/constanst/authConstants'
import {
  getAvailableLogisticProviders,
} from 'utils/constanst/ecomFastOrderConstants';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectLoginUser,
  makeSelectFastOrderProducts,
  makeSelectFastOrderDiscounts,
  makeSelectFastOrderLogistic,
  makeSelectFastOrderDetails,
  makeSelectDeliveryLogisticList,
  makeSelectFastOrderDelivery,
  makeSelectVendorDeliveryFees,
  makeSelectVendorDeliveryFeesLoading,
} from 'redux/selectors';
import { withTranslation } from "react-i18next";
import { withRouter } from 'react-router-dom';
import {
  prProductsDisplayFields,
  prProductsParamsMap,
} from 'utils/constanst/ecomFastOrderConstants';
import { removePRDraftProductItems } from 'redux/actions/ecom/fastOrderActions';

import StorefrontIcon from '@material-ui/icons/Storefront';
import Button from '@material-ui/core/Button';
import Edit from '@material-ui/icons/Edit';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import FastOrderProduct from './FastOrderProduct/FastOrderProduct';
import FastOrderDiscount from './FastOrderDiscount/FastOrderDiscount';
import FastOrderShippingFeeByVendor from './FastOrderShippingFeeByVendor/FastOrderShippingFeeByVendor';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';
import FastOrderProductsToolbar from './FastOrderProductsToolbar/FastOrderProductsToolbar';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';

import classes from './FastOrderProducts.module.scss';
import gridClasses from './FastOrderProductsGrid.module.scss';

class FastOrderProducts extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedVendors: [],
      error: false,
      isCharity: false,
      isClearCartConfirmDialogOpen: false,
      isClearCartLoading: false,
    }
  }
  componentDidMount() {
    this.setState({
      selectedVendors: [],

      isClearCartConfirmDialogOpen: false,
      isClearCartLoading: false,
    });

  }
  componentDidUpdate(prevProps, prevState) {
    const { isClearCartLoading, isClearCartConfirmDialogOpen } = this.state;
    const { error ,isCharity,vendorDeliveryFees} = this.props;
    // console.log(vendorDeliveryFees.find( vdf => vdf.vendor_id === vendorItem?.id ));
    if (error !== prevState.error && error !== undefined) {
      this.setState({
        error: this.props.error
      });
    }
    if (isCharity !== prevState.isCharity && isCharity !== undefined) {
      this.setState({
        isCharity: this.props.isCharity
      });
    }
    const { prProducts } = this.props;

    // after clear cart successful
    if (isClearCartConfirmDialogOpen && isClearCartLoading && prProducts.length === 0 && (prevProps.prProducts.length !== prProducts.length)) {
      this.setState({
        isClearCartLoading: false,
        isClearCartConfirmDialogOpen: false,
      });
    }
  }

  _getSelectedProvider = (providerId) => {
    const { deliveryLogisticList, prDetails } = this.props;

    if (!deliveryLogisticList || !providerId) return null;

    const availablesProviders = getAvailableLogisticProviders(deliveryLogisticList, prDetails?.amount_total);
    return availablesProviders.find((item) => item.id === providerId);
  };

  handleDeselectAllVendors = () => {
    this.setState({
      selectedVendors: [],
    });
  }
  handleSelectedVendorChange = (e, vendor) => {
    const isChecked = e.target.checked;
    this.setState((state) => ({
      selectedVendors: isChecked ? [...state.selectedVendors, {...vendor}] : state.selectedVendors.filter(item => item.id !== vendor.id),
    }));
  }
  handleClearCart = () => {
    this.setState({
      isClearCartLoading: true,
    }, () => {
      this.props.removePRDraftProductItems(this.props.prProducts.map(product => ({
        id: product.id,
        idStr: product.idStr,
      })));
    });
  }

  handleConfirmDialogOpen = () => {
    this.setState({
      isClearCartConfirmDialogOpen: true,
    });
  }
  handleConfirmDialogClose = () => {
    this.setState({
      isClearCartConfirmDialogOpen: false,
    });
  }

  render() {
    const { 
      prProducts, 
      isEditing, 
      prDiscounts, 
      prLogistic, 
      prDetails, 
      vendorDeliveryFees,
      vendorDeliveryFeesLoading,
      user,
      t,
    } = this.props;
    
    const _isCollaborator = isCollaborator(user);
    const displayFields = getDisplayFields(
      prProductsParamsMap,
      prProductsDisplayFields.map( item => (item.name === 'type_label' && _isCollaborator ? {
        name: 'sales_commission',
        label: 'Hoa hồng',
      } : item ))
    );
    const { selectedVendors, isClearCartConfirmDialogOpen, isClearCartLoading ,error,isCharity } = this.state;
    const selectedVendorIds = selectedVendors.map(item => item.id);
    const logisticProviderType = this._getSelectedProvider(prLogistic?.id)?.type || null;
    const isGroupType = !isEditing && isSplitOrderByVendor(logisticProviderType);
    const vendorPrProducts = isGroupType && groupByVendorPrProducts(prProducts);
    return (
      <section className={classes.Wrap}>
        <div className={classes.HeaderToolbar}>
          {isEditing ? (<>
            <h3 className="SectionTitle">{t('Danh sách sản phẩm đặt hàng')}</h3>
            { prProducts.length > 0 &&
              <Button
                size="small"
                color="secondary"
                endIcon={<DeleteOutline fontSize="small" />}
                onClick={this.handleConfirmDialogOpen}
              >
                {t('Xoá đơn hàng')}
              </Button>
            }
          </>) : (<>
            <h3 className="SectionTitle">{t('Danh sách sản phẩm')}</h3>
            {error === false && isCharity !== true? 
              <Button
              size="small"
              color="secondary"
              endIcon={<Edit fontSize="small" />}
              onClick={this.props.onEditFastOrderProducts}
            >
              {t('Sửa đơn hàng')}
            </Button>:null
            }
          </>)}
        </div>

        {isEditing && (
          <div className={classes.Toolbar}>
            <FastOrderProductsToolbar
              selectedVendors={selectedVendors}
              onDeselectAllVendors={this.handleDeselectAllVendors}
              onSelectedVendorChange={this.handleSelectedVendorChange}
            />
          </div>
        )}
        { 
          !isGroupType ? <>
            <div className={`${classes.List}`}>
              <div
                className={`${gridClasses.Row} ${
                  isEditing ? gridClasses.IsEditing : ''
                } ${gridClasses.Header}`}
              >
                {displayFields.map((item) => (
                  <ListHeaderCol
                    key={item.name}
                    {...item}
                    className={`${gridClasses.Col} ${item.className}`}
                  />
                ))}
                <ListHeaderCol className={`${gridClasses.Col}`} label="" />
              </div>
              <div className={`${classes.Body}`}>
                {prProducts.length ? (
                  <>
                    {prProducts.map((item, index) => (
                      <FastOrderProduct
                        key={`pr-product-${item.idStr || index + 1}`}
                        {...item}
                        isEditing={isEditing}
                        index={index}
                        selectedVendorIds={selectedVendorIds}
                      />
                    ))}
                    {!isEditing &&
                      prDiscounts.map((item, index) => (
                        <FastOrderDiscount
                          key={`pr-discount-${item.idStr || index + 1}`}
                          {...item}
                          index={index}
                        />
                      ))}
                  </>
                ) : (
                  <div className={classes.EmptyBox}>
                    <img
                      src={`${process.env.REACT_APP_PATH}static/icons/empty-box2.svg`}
                      width="80"
                      alt="Empty box"
                    />
                    <p className="NoData">{t('Chưa có sản phẩm')}</p>
                  </div>
                )}
              </div>
            </div>
          </> : <> 
            { 
              vendorPrProducts.map( gbvpp => {
                const { vendor_items } = prDetails;
                console.log(vendor_items);
                let vendorItem = vendor_items && vendor_items.find( vi => vi.code === gbvpp.vendor_code );
                const vendorDeliveryFee = vendorDeliveryFees.find( vdf => vdf.vendor_id === vendorItem?.id );
                console.log(vendorPrProducts);
                return (
                  <div key={`vendor-group${gbvpp.vendor_code}`} style={{ marginTop: "1rem" }}>
                    <div className={classes.HeaderVendor}> 
                      <div className={classes.HeaderVendorName}><StorefrontIcon/> {gbvpp.vendor_name}</div>
                      <div className={classes.HeaderVendorLabel}>{t('Tổng tiền')}</div>
                      <div className={classes.HeaderVendorAmountTotal}>{currencyFormatVN(gbvpp.amount_total + ( vendorDeliveryFee?.fee?.total || 0 ) )}</div>
                    </div>
                    <div className={`${classes.List}`}>
                      <div
                        className={`${gridClasses.Row} ${
                          isEditing ? gridClasses.IsEditing : ''
                        } ${gridClasses.Header}`}
                      >
                        {displayFields.map((item) => (
                          <ListHeaderCol
                            key={item.name}
                            {...item}
                            className={`${gridClasses.Col} ${item.className}`}
                          />
                        ))}
                        <ListHeaderCol className={`${gridClasses.Col}`} label="" />
                      </div>
                      <div className={`${classes.Body}`}>
                        {gbvpp.prProducts.length ? (
                          <>
                            {gbvpp.prProducts.map((item, index) => (
                              <FastOrderProduct
                                key={`pr-product-${item.idStr || index + 1}`}
                                {...item}
                                isEditing={isEditing}
                                index={index}
                                selectedVendorIds={selectedVendorIds}
                              />
                            ))}
                            <FastOrderShippingFeeByVendor
                              amount={vendorDeliveryFee?.fee?.total || 0}
                              isLoading={vendorDeliveryFeesLoading}
                            />
                            {/* {!isEditing &&
                              prDiscounts.map((item, index) => (
                                <FastOrderDiscount
                                  key={`pr-discount-${item.idStr || index + 1}`}
                                  {...item}
                                  index={index}
                                />
                              ))} */}
                          </>
                        ) : (
                          <div className={classes.EmptyBox}>
                            <img
                              src={`${process.env.REACT_APP_PATH}static/icons/empty-box2.svg`}
                              width="80"
                              alt="Empty box"
                            />
                            <p className="NoData">{t('Chưa có sản phẩm')}</p>
                          </div>
                        )}
                      </div>
                    </div>   
                  </div>
                )
              })
            }
            {!isEditing && prDiscounts.length > 0 &&
              <>
                <div style={{ marginTop: "1rem" }}>
                  <div className={classes.HeaderVendor}> 
                    <div className={classes.HeaderVendorName}>{t('Khuyến mãi')}</div>
                  </div>
                  <div className={`${classes.List}`}>
                    <div className={`${classes.DiscountBox}`}>
                      {
                        prDiscounts.map((item, index) => (
                          <FastOrderDiscount
                            key={`pr-discount-${item.idStr || index + 1}`}
                            {...item}
                            index={index}
                          />
                        ))
                      }
                    </div>
                  </div>
                </div>
              </>
            }
          </>
        }

        { isClearCartConfirmDialogOpen
          && <ConfirmationDialog
            isOpen={isClearCartConfirmDialogOpen}
            isLoading={isClearCartLoading}
            title={t('Xác nhận xoá đơn hàng')}
            message={t('Bạn có chắc xoá toàn bộ sản phẩm trong đơn hàng?')}
            onClose={this.handleConfirmDialogClose}
            onSubmit={this.handleClearCart}
          />
        }
      </section>
    );
  }
}

FastOrderProducts.propTypes = {
  prProducts: PropTypes.array,
  prDiscounts: PropTypes.array,
  prLogistic: PropTypes.object,
  isEditing: PropTypes.bool,
  step: PropTypes.number,
  onEditFastOrderProducts: PropTypes.func,
  vendorDeliveryFees: PropTypes.array,
  vendorDeliveryFeesLoading: PropTypes.bool,
};

FastOrderProducts.defaultProps = {
  prProducts: [],
  prDiscounts: [],
  prLogistic: null,
  isEditing: false,
  vendorDeliveryFees: [],
  vendorDeliveryFeesLoading: false,
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectLoginUser(),
  prProducts: makeSelectFastOrderProducts(),
  prDetails: makeSelectFastOrderDetails(),
  deliveryLogisticList: makeSelectDeliveryLogisticList(),
  prDelivery: makeSelectFastOrderDelivery(),
  prDiscounts: makeSelectFastOrderDiscounts(),
  prLogistic: makeSelectFastOrderLogistic(),
  vendorDeliveryFees: makeSelectVendorDeliveryFees(),
  vendorDeliveryFeesLoading: makeSelectVendorDeliveryFeesLoading(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    removePRDraftProductItems: (payload) => dispatch(removePRDraftProductItems(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default withTranslation()(compose(withConnect, withRouter)(FastOrderProducts));
