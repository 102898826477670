import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectFastOrderPromotionActives } from 'redux/selectors';

import PromotionActive from './PromotionActive/PromotionActive';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';
import { withTranslation } from "react-i18next";
import gridClasses from './PromotionActivesGrid.module.scss';
import classes from './PromotionActives.module.scss';

class PromotionActives extends PureComponent {
  render() {
    const { promotionActives, t} = this.props;

    return (
      <div className={`${classes.List}`}>
        <div className={`${gridClasses.Row} ${gridClasses.Header}`}>
          <ListHeaderCol
            className={gridClasses.Col}
            label={t('Sản phẩm khuyến mãi')}
          />
          <ListHeaderCol
            className={gridClasses.Col}
            label={t('Đơn vị')}
          />
          <ListHeaderCol
            className={gridClasses.Col}
            label={t('Số lượng')}
          />
          <ListHeaderCol
            className={gridClasses.Col}
            label={t('Giảm giá')}
          />
        </div>
        <div className={`${classes.Body}`}>
          {promotionActives.length ? (
            promotionActives.map((item, index) => (
              <PromotionActive
                key={`promotion-active-${item.id || item.idStr}-${index}`}
                {...item}
              />
            ))
          ) : (
            <p className="NoData">{t('Không có khuyến mãi')}</p>
          )}
        </div>
      </div>
    );
  }
}

PromotionActives.propTypes = {
};

PromotionActives.defaultProps = {
};

const mapStateToProps = createStructuredSelector({
  promotionActives: makeSelectFastOrderPromotionActives(),
});
const withConnect = connect(mapStateToProps, null);
export default withTranslation()(compose(withConnect)(PromotionActives));
