import categoryApi from 'utils/api/app/categoryApi';
import { put, call } from 'redux-saga/effects';
import { categoryActions } from 'redux/actions';
import { toast } from 'react-toastify';

export function* loadCategoryList(action) {
  try {
    const { params = {}, cancelToken } = action.payload || {};

    yield put(categoryActions.setStateCategory({ loadingCategoryEcomList: true })); 
    
    const { data: response } = yield call(categoryApi.fetchCategoryEcomList,{ params, cancelToken });
    
    if (!response?.result) {
      yield put(categoryActions.setStateCategory({ loadingCategoryEcomList: false })); 
      toast.error(response.message);
      return;
    }
    yield put(categoryActions.setStateCategory({ 
      loadingCategoryEcomList: false,
      categoryEcomList: response.data || [],
    })); 
    return response.data
  } catch (err) {
    console.log("loadCategoryList",err);
    yield put(categoryActions.setStateCategory({ loadingCategoryEcomList: false })); 
  }
}

export default {
  loadCategoryList,
}