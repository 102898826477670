import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectLoginUser } from 'redux/selectors';
import { signOut } from 'redux/actions/authActions';
import { getComponentItem, COMPONENT_LOGIN_AS } from 'utils/constanst/authConstants';
import { CUSTOMER_PAGE } from 'utils/constanst/common';
import { withTranslation } from "react-i18next";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import LoginAsDialog from 'components/LoginAsDialog/LoginAsDialog';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  user: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  avatar: {
    width: '1.4rem',
    height: '1.4rem',
    border: '1px solid #FFF',
  },
  name: {
    padding: '0 0.5rem',
    userSelect: 'none',
  },
  poper: {
    zIndex: 1,
  },
}));

function UserDropdown(props) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [isLoginAsDialogOpen, setIsLoginAsDialogOpen] = React.useState(false);

  const anchorRef = React.useRef(null);

  const handleSignOut = (event) => {
    handleClose(event);
    props.signOut();
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    setOpen(false);
  };

  const handleListKeyDown = (e) => {
    if (e.key === 'Tab') {
      e.preventDefault();
      setOpen(false);
    }
  }
  const handleClickLoginAs = (e) => {
    setOpen(false);
    handleLoginAsDialogOpen();
  }
  const handleGoToMyProfile = (e) => {
    setOpen(false);
    window.location.href = `/myprofile/personalinfo`;
  }
  const handleGoToMyOrders = (e) => {
    setOpen(false);
    window.location.href = `/so`;
  }

  const handleLoginAsDialogOpen = () => {
    setIsLoginAsDialogOpen(true);
  }
  const handleLoginAsDialogClose = () => {
    setIsLoginAsDialogOpen(false);
  }
  const handleLoginAsSuccess = (newAuthData) => {
    // refresh the app
    window.location.href = CUSTOMER_PAGE;
  }

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return (

    <div className={classes.root}>
      <div
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        className={classes.user}
        style={props.style && { ...props.style }}
        onClick={handleToggle}
      >
        { props.prefixIcon
          ? props.prefixIcon
          : <Avatar alt={ props.user?.name } src={props.user?.avatar || null} className={classes.avatar}/>
        }
        <span className={classes.name}>{ props.user?.name }</span>
        <ArrowDropDownRoundedIcon style={{ color: 'inherit' }} />
      </div>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-end"
        style={{
          minWidth: anchorRef && anchorRef.current && anchorRef.current.offsetWidth,
          marginTop: '0.25rem',
          zIndex: 3,
        }}
        className={classes.poper}
      >
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem onClick={handleGoToMyProfile}>
                    <ListItemIcon style={{minWidth: '32px'}}><PermIdentityIcon fontSize="small" /></ListItemIcon>
                    <ListItemText primary={props.t('Hồ sơ của tôi')}/>
                  </MenuItem>
                  <MenuItem onClick={handleGoToMyOrders}>
                    <ListItemIcon style={{minWidth: '32px'}}><ListAltOutlinedIcon fontSize="small" /></ListItemIcon>
                    <ListItemText  primary={props.t('Đơn hàng của tôi')}  />
                  </MenuItem>
                  <Divider />
                  { getComponentItem(COMPONENT_LOGIN_AS, props.user?.getcare_components) &&
                    <MenuItem onClick={handleClickLoginAs}>
                      <ListItemIcon style={{minWidth: '32px'}}><LockOpenIcon fontSize="small" /></ListItemIcon>
                      <ListItemText primary={props.t('Login As')}  />
                    </MenuItem>
                  }
                  <MenuItem onClick={handleSignOut}>
                    <ListItemIcon style={{minWidth: '32px'}}><PowerSettingsNewIcon fontSize="small" /></ListItemIcon>
                    <ListItemText primary={props.t('Đăng xuất')}  />
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      { isLoginAsDialogOpen &&
        <LoginAsDialog
          isOpen={isLoginAsDialogOpen}
          onClose={handleLoginAsDialogClose}
          onLoginAsSuccess={handleLoginAsSuccess}
        />
      }
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  user: makeSelectLoginUser(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    signOut: (payload) => dispatch(signOut(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default withTranslation()(compose(withConnect, withRouter)(UserDropdown));
