import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectLoginUser } from 'redux/selectors';
import { isRouteEnabled } from 'utils/constanst/authConstants';
import { withTranslation } from "react-i18next";
import { withRouter } from 'react-router-dom';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from 'react-router-dom';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

class FastOrderBreadcrumbs extends PureComponent {
  render() {
    const { user, step, selectedCustomerCode,t } = this.props;

    return (
      <Breadcrumbs
        separator={
          <NavigateNextIcon
            className="BreadscrumbSeparator"
            fontSize="small"
          />
        }
        className="Breadscrumbs"
        aria-label="breadcrumb"
      >
        { isRouteEnabled(user, 'customer') && 
          <Link color="inherit" to="/customer">
            {t('Chọn Khách Hàng')}
          </Link>
        }

        {(step === 1 || step === 2) && <span className="BreadscrumbActive">{t('Đặt hàng nhanh')}</span>}
        {(step === 1 || step === 2) && <span>{t('Xác nhận đơn hàng')}</span>}

        {step === 3 && 
          <Link
            color="inherit"
            to={`/fastorder?getcare_customer_code=${selectedCustomerCode}`}
            onClick={this.props.onReselectProducts}
          >
            {t('Đặt hàng nhanh')}
          </Link>
        }
        {step === 3 && <span className="BreadscrumbActive">{t('Xác nhận đơn hàng')}</span>}
      </Breadcrumbs>
    );
  }
}

FastOrderBreadcrumbs.propTypes = {
  step: PropTypes.number,
  onReselectProducts: PropTypes.func,
};
FastOrderBreadcrumbs.defaultProps = {
  step: 1,
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectLoginUser(),
});
const withConnect = connect(mapStateToProps, null);
export default withTranslation()(compose(withConnect, withRouter)(FastOrderBreadcrumbs));
