import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import {
  EMPTY_PICTURE_IMAGE
} from 'utils/constanst/assetConstants';

const loadImage = (src) => {
  let resolve, reject, cancelled;
  const img = new Image;
  const promise = new Promise((resolveFromPromise, rejectFromPromise) => {
    resolve = resolveFromPromise;
    reject = rejectFromPromise;
  })

  img.src = src;
  img.onload = () => {
    if (!cancelled) {
      resolve(src);
    }
  }

  return {
    promise,
    cancel: () => {
      cancelled = true;
      reject({ reason: 'cancelled' });
    }, 
  }
}

const Photo = ({ src,alt,style = {}, ...otherParams }) => {
  const [url,setUrl] = React.useState(EMPTY_PICTURE_IMAGE);
  const [loadingImg,setLoadingImg] = React.useState(true);
  const [imageStyles] = React.useState({
    objectFit: "contain",
    backgroundColor: "#fafafa"
  });
  let img = new Image();

  React.useEffect(() => {
    setLoadingImg(true);
    setUrl(EMPTY_PICTURE_IMAGE);
    const { promise, cancel } = loadImage(src)
    promise.then(() => {
      setLoadingImg(false);
      setUrl(src);
    }).catch(err => {})
    return () => {
      cancel()
    }
  },[src])

  return (<>
    <LazyLoadImage
      placeholder={<img/>}
      {...otherParams}
      src={url}
      alt={alt}
      style={loadingImg ? { ...style,...imageStyles } : style}
    >
    </LazyLoadImage>
  </>)
}

export default Photo
