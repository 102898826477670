import React, { PureComponent } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { isAfter } from 'date-fns';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from "reselect";
import {
  makeSelectAuthLoading,
  makeSelectUserProfile,
  makeSelectProvinceList,
  makeSelectDistrictList,
  makeSelectWardList,
} from 'redux/selectors';
import {
  getProvinceList,
  getDistrictList,
  getWardList,
} from 'redux/actions/locationActions';
import { updateProfile, getProfile } from 'redux/actions/authActions';

import { VN_ID } from 'utils/constanst/locationConstants';
import { userGenders } from 'utils/constanst/authConstants';
import { validDate } from 'utils/helper';
import { dateFormat } from 'utils/constanst/dateConstants';
import { withTranslation } from "react-i18next";

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import classes from './PersonalInfo.module.scss';

class PersonalInfo extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedProvinceId: props.profile?.getcare_province?.id,
      selectedDistrictId: props.profile?.getcare_district?.id,
      selectedWardId: props.profile?.getcare_ward?.id,
    };
  }

  componentDidMount() {
    this.props.getProfile();
    this.props.getProvinceList({
      params: { name: '', getcare_country_id: VN_ID },
    });
    this.props.getDistrictList({
      params: { name: '', getcare_province_id: this.props.profile?.getcare_province?.id },
    });
    this.props.getWardList({
      params: { name: '', getcare_district_id: this.props.profile?.getcare_district?.id },
    });
  }
  componentDidUpdate(prevProps, prevState) {
    const { selectedProvinceId, selectedDistrictId } = this.state;
    const { profile } = this.props;
    if (profile?.getcare_province?.id !== prevProps.profile?.getcare_province?.id) {
      this.setState({
        selectedProvinceId: profile.getcare_province?.id,
      });
    }
    if (profile?.getcare_district?.id !== prevProps.profile?.getcare_district?.id) {
      this.setState({
        selectedDistrictId: profile.getcare_district?.id,
      });
    }
    if (profile?.getcare_ward?.id !== prevProps.profile?.getcare_ward?.id) {
      this.setState({
        selectedWardId: profile.getcare_ward?.id,
      });
    }

    if (selectedProvinceId !== prevState.selectedProvinceId) {
      this.props.getDistrictList({
        params: { name: '', getcare_province_id: selectedProvinceId },
      });
    }
    if (selectedDistrictId !== prevState.selectedDistrictId) {
      this.props.getWardList({
        params: { name: '', getcare_district_id: selectedDistrictId },
      });
    }
  }

  _getValidationShape = () => {
    return {
      name: Yup.string().trim().required('Vui lòng nhập').max(55, 'Tối đa 55 ký tự'),
      birthday: Yup.mixed().test(
        'birthday',
        'Ngày sinh không hợp lệ',
        (value, context) => ['', null, undefined].includes(value) || (!!validDate(value) && !isAfter(validDate(value), new Date())),
      ),
      address: Yup.string().max(255, 'Tối đa 255 ký tự'),
    };
  }
  _getInitialValues = (params) => {
    const { profile } = this.props;
    const phoneValue = profile?.phone || '';
    return {
      name: profile?.name || '',
      gender: profile?.gender || '',
      birthday: profile?.birthday || '',
      email: profile?.email || '',
      phone: (/^(84)/).test(phoneValue) ? phoneValue.replace(/^(84)/, '+84') : phoneValue,
      getcare_province: profile?.getcare_province || '',
      getcare_province_id: profile?.getcare_province_id || '',
      getcare_district: profile?.getcare_district || '',
      getcare_district_id: profile?.getcare_district_id || '',
      getcare_ward: profile?.getcare_ward || '',
      getcare_ward_id: profile?.getcare_ward_id || '',
      address: profile?.address || '',
    }
  }

  handleSubmit = (values) => {
    this.props.updateProfile({
      params: {
        name: values.name.trim(),
        gender: values.gender || null,
        birthday: values.birthday || null,
        getcare_province_id: values.getcare_province_id || null,
        getcare_district_id: values.getcare_district_id || null,
        getcare_ward_id: values.getcare_ward_id || null,
        address: values.address,
      },
    });
  }

  render() {
    const { loading, provinceList, districtList, wardList, profile ,t } = this.props;

    return (
      <div className={`${classes.PersonalInfoWrap} ${loading ? 'OverlayLoading' : ''}`}>
        <div className={classes.PersonalInfo}>
          <h2 className="PageTitle">{t('Thông tin cá nhân')}</h2>
          <p className={classes.HelperText}>{t('Quản lý thông tin hồ sơ để bảo mật tài khoản')}</p>
          <Formik
            key={profile?.id}
            initialValues={this._getInitialValues()}
            onSubmit={this.handleSubmit}
            validationSchema={Yup.object().shape(this._getValidationShape())}
          >
            {(props) => {
              const {
                values,
                errors,
                handleChange,
                handleSubmit,
                setFieldValue,
              } = props;

              return (
                <form noValidate autoComplete="off">
                  <TextField
                    variant="filled"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    label={t('Họ và tên')}
                    className={`${classes.Field} TextFieldFilled`}
                    error={!!errors.name}
                    helperText={errors.name}
                    autoComplete="off"
                  />
                  <div className={classes.FieldControl}>
                    <label>{t('Giới tính')}</label>
                    <RadioGroup
                      name="gender"
                      value={values.gender + ``}
                      className={`${classes.RadioGroup}`}
                      onChange={handleChange}
                    >
                      { userGenders.map(gender => (
                        <FormControlLabel
                          key={gender.id}
                          value={gender.id}
                          control={<Radio size="small" style={{padding: '4px 0', marginRight: '12px'}} />}
                          fontSize="small"
                          className={classes.FormControlLabel}
                          label={gender.name}
                        />
                      )) }
                    </RadioGroup>
                  </div>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableFuture
                      disableToolbar
                      autoOk
                      variant="inline"
                      inputVariant="filled"
                      label={t('Ngày sinh')}
                      className={`${classes.Field} TextFieldFilled`}
                      format={dateFormat}
                      name="birthday"
                      value={values.birthday || null}
                      onChange={(date, newValue) => {
                        const value = validDate(date) ? date.toISOString() : newValue;
                        setFieldValue('birthday', value);
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      error={!!errors.birthday}
                      helperText={errors.birthday}
                    />
                  </MuiPickersUtilsProvider>
                  <TextField
                    disabled
                    variant="filled"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    label="Email"
                    className={`${classes.Field} TextFieldFilled`}
                    autoComplete="off"
                  />
                  <TextField
                    disabled
                    variant="filled"
                    name="phone"
                    value={values.phone}
                    onChange={handleChange}
                    label={t('Số điện thoại')}
                    className={`${classes.Field} TextFieldFilled`}
                    autoComplete="off"
                  />
                  <Autocomplete
                    name="province"
                    handleHomeEndKeys={false}
                    value={values.getcare_province || null}
                    options={provinceList || []}
                    getOptionLabel={(option) => option?.name || ''}
                    renderOption={(option) => option?.name}
                    getOptionSelected={(option, value) =>
                      option.id === value.id
                    }
                    renderInput={(params) => (
                      <TextField
                        variant="filled"
                        className={`${classes.Field} TextFieldFilled`}
                        label={t('Tỉnh/TP')}
                        {...params}
                      />
                    )}
                    onChange={(e, newValue) => {
                      this.setState({
                        selectedProvinceId: newValue?.id,
                      });
                      setFieldValue('getcare_province', newValue);
                      setFieldValue('getcare_province_id', newValue?.id || '');
                      setFieldValue('getcare_district', null);
                      setFieldValue('getcare_district_id', '');
                      setFieldValue('getcare_ward', null);
                      setFieldValue('getcare_ward_id', '');
                    }}
                  />
                  <div className={classes.FieldGroup}>
                    <Autocomplete
                      name="district"
                      handleHomeEndKeys={false}
                      value={values.getcare_district || null}
                      options={districtList || []}
                      getOptionLabel={(option) => option?.name || ''}
                      renderOption={(option) => option?.name}
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      }
                      renderInput={(params) => (
                        <TextField
                          variant="filled"
                          className={`${classes.Field} TextFieldFilled`}
                          label={t('Quận/Huyện')}
                          {...params}
                        />
                      )}
                      onChange={(e, newValue) => {
                        this.setState({
                          selectedDistrictId: newValue?.id,
                        });
                        setFieldValue('getcare_district', newValue);
                        setFieldValue('getcare_district_id', newValue?.id || '');
                        setFieldValue('getcare_ward', null);
                        setFieldValue('getcare_ward_id', '');
                      }}
                    />
                    <Autocomplete
                      name="ward"
                      handleHomeEndKeys={false}
                      value={values.getcare_ward || null}
                      options={wardList || []}
                      getOptionLabel={(option) => option?.name || ''}
                      renderOption={(option) => option?.name}
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      }
                      renderInput={(params) => (
                        <TextField
                          variant="filled"
                          className={`${classes.Field} TextFieldFilled`}
                          label={t('Phường/Xã')}
                          {...params}
                        />
                      )}
                      onChange={(e, newValue) => {
                        this.setState({
                          selectedWardId: newValue?.id,
                        });
                        setFieldValue('getcare_ward', newValue);
                        setFieldValue('getcare_ward_id', newValue?.id || '');
                      }}
                    />
                  </div>
                  <TextField
                    variant="filled"
                    name="address"
                    value={values.address}
                    onChange={handleChange}
                    label={t('Địa chỉ')}
                    className={`${classes.Field} TextFieldFilled`}
                    error={!!errors.address}
                    helperText={errors.address}
                    autoComplete="off"
                  />
                  <div className={classes.Control}>
                    <Button
                      type="button"
                      variant="contained"
                      color="secondary"
                      size="large"
                      className={classes.SubmitButton}
                      onClick={handleSubmit}
                    >{t('Cập nhập')}</Button>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  loading: makeSelectAuthLoading(),
  profile: makeSelectUserProfile(),
  provinceList: makeSelectProvinceList(),
  districtList: makeSelectDistrictList(),
  wardList: makeSelectWardList(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getProvinceList: (payload) => dispatch(getProvinceList(payload)),
    getDistrictList: (payload) => dispatch(getDistrictList(payload)),
    getWardList: (payload) => dispatch(getWardList(payload)),
    updateProfile: (payload) => dispatch(updateProfile(payload)),
    getProfile: (payload) => dispatch(getProfile(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default withTranslation()(compose(withConnect)(PersonalInfo));
