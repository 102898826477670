import React from 'react';
import { withStyles,StylesProvider  } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';

const styles = (theme) => {
  return {
    root: {
      textTransform: 'none',
      fontWeight: theme.typography.fontWeightMedium,
      padding: theme.spacing(1,2),
      '&:hover': {
        // color: theme.palette.primary.main,
        // opacity: 1,
        fontWeight: theme.typography.fontWeightBold,
      },
      '&$selected': {
        fontWeight: theme.typography.fontWeightBold,
      },
      // '&:focus': {
      //   color: theme.palette.primary.main,
      // },
    },
    selected: {}
  }
};

const StyledTab = withStyles(styles)(Tab);

// const sTab = ({ classes, innerRef, color, ...other }) => {
//   return <Tab classes={{ root: classes.root }} {...other} />
// }

// export default withStyles(styles)(sTab);

export default React.forwardRef( ({ ...other }, ref) =>
  <StyledTab ref={ref} {...other} />
)