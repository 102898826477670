import authApi from 'utils/api/authApi';
import { put } from 'redux-saga/effects';
import { authActions } from 'redux/actions';
import { toast } from 'react-toastify';

export function* checkAuthentication(payload) {
  try {
    yield put(authActions.saveCheckingAuth(true));
    yield put(authActions.saveAuth(null));
    const { data: response } = yield authApi.checkAuth();

    if (!response?.result) {
      yield put(authActions.saveAuth({}));
      yield put(authActions.saveCheckingAuth(false));
      return;
    }
    yield put(authActions.saveAuth(response.data ? {...response.data} : {}));
    yield put(authActions.saveCheckingAuth(false));
  } catch (err) {
    console.log(err);
  }
}
export function* signMeOut(payload) {
  try {
    const { data: response } = yield authApi.signOut();
    if (!response?.result) {
      return;
    }
    const { data: authResponse } = yield authApi.checkAuth();
    if (!authResponse?.result || !authResponse.data) {
      yield put(authActions.removeAuth());
      return;
    }
    yield put(authActions.saveAuth(authResponse.data));
  } catch (err) {
    console.log(err);
  }
}
export function* getProfile(payload) {
  try {
    yield put(authActions.saveAuthLoading(true));
    const { data: response } = yield authApi.getProfile();
    yield put(authActions.saveAuthLoading(false));
    if (!response?.result) {
      return;
    }
    yield put(authActions.saveProfile(response.data));
  } catch (err) {
    console.log(err);
  }
}
export function* updateProfile(payload) {
  const { params } = payload.payload;
  try {
    yield put(authActions.saveAuthLoading(true));
    const { data: response } = yield authApi.updateProfile({ params });
    yield put(authActions.saveAuthLoading(false));
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    toast.success(`Bạn đã cập nhật thông tin cá nhân thành công.`);

    yield put(authActions.saveProfile(response.data));

    const { data: authResponse } = yield authApi.checkAuth();
    if (!authResponse?.result || !authResponse.data) {
      yield put(authActions.removeAuth());
      return;
    }
    yield put(authActions.saveAuth(authResponse.data));
  } catch (err) {
    console.log(err);
  }
}

export function* loadUserProvinceList(payload) {
  try {
    const { data: response } = yield authApi.getUserProvinces();

    if (!response?.result || !response.data) {
      return;
    }
    yield put(authActions.saveUserProvinceList(response.data));
  } catch (err) {
    console.log(err);
  }
}
