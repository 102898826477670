import React, { PureComponent } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { withTranslation } from "react-i18next";

import { validNumber } from 'utils/helper'

import { 
  Grid,
  Box,
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import StyledTextField from 'components/Styled/TextField';
import FormAddress from './Address/Address';
import FormProductInfo from './ProductInfo/ProductInfo';
import FormUploadImage from './UploadImage/UploadImage';

import styles from './ConnectSupportSupportForm.module.scss';
import stylesLayoutMainEcom from 'assets/styles/layouts/LayoutMainEcom.module.scss';

class ConnectSupportSupportForm extends PureComponent {
  constructor(props,ref) {
    super(props);
    this.state = {
      datePickerOpen: false,
    }
    this.refFormik = React.createRef()
    this.refEl = React.createRef()
    this.refStyledTextFieldDeadline = React.createRef()
  }
  componentDidUpdate(prevProps) {
    const { data } = this.props;
  }

  _getInitialValues = () => {
    const { 
      id,
      name,
      deadline,
      address,
      getcare_province,
      getcare_district,
      getcare_ward,
      description,
      images,
      service_request_items,
      donate_id,
    } = this.props.data;

    return {
      // ...this.props.data,
      id: id,
      donate_id: donate_id || undefined,
      name: name || '',
      deadline: deadline || null,
      address: address || '',
      getcare_province_id: getcare_province?.id || '',
      getcare_district_id: getcare_district?.id || '',
      getcare_ward_id: getcare_ward?.id || '',
      description: description || '',
      images: images || [],
      service_request_items: service_request_items ? service_request_items.map( (item,index) => ({
        // ...item,
        id: item.item_id,
        product_name: item.name || '',
        getcare_product_id: item.id || null,
        getcare_manufacturer_id: item.getcare_manufacturer_id || null,
        manufacturer_name: item.manufacturer_name || '',
        getcare_category_ecom_id: item.getcare_category_ecom_id || null,
        category_ecom_name: item.category_ecom_name || '',
        getcare_uom_base_id: item.getcare_uom_base_id || null,
        uom_base_name: item.uom_base_name || '',
        quantity_number: validNumber(item.quantity_number),
        images: item.images || [],
        _index: index+1,
      })) : [],
    }
  }
  _getValidationSchema = () => {
    const { t } = this.props;
    return Yup.object().shape({
      name: Yup.string().required(t('Vui lòng nhập thông tin')),
      deadline: Yup.date().nullable().required(t('Vui lòng nhập thông tin')).typeError(t('Vui lòng nhập đúng định dạng Ngày/Tháng/Năm')).test({ 
        name: 'deadline',
        exclusive: true,
        test: value => {
          if (!value) return true;  
          const now = new Date();
          const deadline = new Date(value);
          return deadline > now;
        },
        message: t(`Vui lòng nhập thời gian sau ngày hôm nay`),
      }),
      address: Yup.string().required(t('Vui lòng nhập thông tin')),
      getcare_province_id: Yup.number().required(t('Vui lòng nhập thông tin')),
      getcare_district_id: Yup.number().required(t('Vui lòng nhập thông tin')),
      getcare_ward_id: Yup.number().required(t('Vui lòng nhập thông tin')),
      service_request_items: Yup.array().min(1,t('Vui lòng thêm ít nhất 1 sản phẩm')).of(
        Yup.object().shape({
          getcare_product_id: Yup.number().nullable().required(t('Vui lòng nhập thông tin sản phẩm')),
          getcare_uom_base_id: Yup.number().nullable().required(t('Vui lòng nhập đơn vị')),
          quantity_number: Yup.number().nullable().required(t('Vui lòng nhập số lượng')).min(1, t('Vui lòng nhập số lượng tối thiểu 1')),
        })
      )
    })
  }
  prepareData = (data) => {
    return {
      donate_id: data.donate_id,
      id: data.id,
      name: data.name,
      deadline: new Date(data.deadline),
      address: data.address ,
      getcare_province_id: data.getcare_province_id,
      getcare_district_id: data.getcare_district_id,
      getcare_ward_id: data.getcare_ward_id,
      description: data.description,
      service_request_items: data.service_request_items.map( item => ({
        id: item.id || undefined,
        product_name: item.product_name || '',
        getcare_product_id: item.getcare_product_id || null,
        getcare_manufacturer_id: item.getcare_manufacturer_id || null,
        manufacturer_name: item.manufacturer_name || '',
        getcare_category_ecom_id: item.getcare_category_ecom_id || null,
        category_ecom_name: item.category_ecom_name || '',
        getcare_uom_base_id: item.getcare_uom_base_id || null,
        uom_base_name: item.uom_base_name || '',
        quantity_number: validNumber(item.quantity_number),
        images: item.images || [],
      })),
      images: data.images
    }
  }

  submitForm = () => {
    const { t } = this.props;
    this.refFormik.current.validateForm().then( error => {
      if ( Object. keys(error).length === 0 ) this.refFormik.current.submitForm();
      for ( const [key,value] of Object.entries(error) ) {
        
        const el = this.refEl.current.querySelector(`input[id="support_${key}"]`);
        if ( el ) { el.focus(); break; }
        else if ( key === 'service_request_items' ) {
          if ( typeof value === 'string' ) {
            alert(value)
            break;
          }
          for ( const err of value ) {
            alert(t(`interpolation.errorServiceRequestItems`,{
              defaultValue: `{{error}} của bảng thông tin sản phẩm`,
              error: Object.values(err)[0]
            }))
            break;
          }
          break;
        }
      }
    })
  }
  handleSubmitForm = async (values) => {
    this.props.onSubmit && this.props.onSubmit(this.prepareData({...values}))
  }

  render() {
    const { t } = this.props;
    
    return (
      <Formik
        innerRef={this.refFormik}
        initialValues={this._getInitialValues()}
        // enableReinitialize
        onSubmit={this.handleSubmitForm}
        validationSchema={this._getValidationSchema()}
      >
        {
          (props) => {
            const {
              values,
              errors,
              handleChange,
              setFieldValue,
            } = props;
            
            return (
              <form ref={this.refEl} noValidate autoComplete="off">
                <Box className={stylesLayoutMainEcom.MarginB__075}>
                  <div className={stylesLayoutMainEcom.FormLabel}>{t('Tôi cần giúp đỡ')}</div>
                  <div>{t('Bạn có thể kêu gọi hỗ trợ cho bạn/gia đình hoặc cho người khác tại đây')}</div>
                </Box>
                <Box className={stylesLayoutMainEcom.MarginB__075}>
                  <Grid container spacing={2} justifyContent="space-between">
                    <Grid item xs={12} md={4}>
                      <div className={`${stylesLayoutMainEcom.FormControl} ${stylesLayoutMainEcom.Margin__0}`}>
                        <label htmlFor="support_name" className={`${!!errors.name && stylesLayoutMainEcom['FormLabel--error']}`}>{t('Tên dự án hoặc lý do cần hỗ trợ')} <span>*</span></label>
                        <StyledTextField
                          placeholder={t('Nhập tên dự án hoặc lý do cần hỗ trợ')}
                          size="medium"
                          fullWidth
                          variant="outlined"
                          id="support_name"
                          name="name"
                          error={!!errors.name}
                          helperText={errors.name}
                          value={values.name}
                          onChange={handleChange}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <div className={`${stylesLayoutMainEcom.FormControl} ${stylesLayoutMainEcom.Margin__0}`}>
                        <label htmlFor="support_deadline" className={`${!!errors.deadline && stylesLayoutMainEcom['FormLabel--error']}`}>{t('Cần trước ngày')} <span>*</span></label>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            open={this.state.datePickerOpen}
                            onClose={ () => this.setState({datePickerOpen: false}) }
                            onOpen={() => this.setState({datePickerOpen: true})}
                            disableToolbar
                            value={values.deadline}
                            onChange={(val) => {
                              setFieldValue("deadline",val)
                              this.setState({datePickerOpen: false})
                            }}
                            // autoOk 
                            error={!!errors.deadline}
                            helperText={errors.deadline}
                            fullWidth
                            size="medium"
                            id="support_deadline"
                            name="deadline"
                            format="dd/MM/yyyy"
                            variant="inline"
                            inputVariant="outlined"
                            TextFieldComponent={StyledTextField}
                            onClick={() => this.setState({datePickerOpen: true})}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    </Grid>
                  </Grid>
                </Box>
                <Box className={stylesLayoutMainEcom.MarginB__075}>
                  <div className={stylesLayoutMainEcom.FormLabel}>{t('Thông tin liên hệ')}</div>
                  <div>{t('Điền thông tin của bạn hoặc người đại diện tổ chức cần hỗ trợ ở đây')}</div>
                </Box>
                <Box className={stylesLayoutMainEcom.MarginB__075}>
                  <FormAddress
                    {...props}
                  />
                </Box>
                <Box className={stylesLayoutMainEcom.MarginB__075}>
                  <FormProductInfo
                    {...props}
                  />
                </Box>
                <Box className={stylesLayoutMainEcom.FormControl}>
                  <label htmlFor="support_description" className={stylesLayoutMainEcom.FormLabel}>{t('Chi tiết cần hỗ trợ')}</label>
                  <StyledTextField
                    placeholder={t('Nhập mô tả chi tiết')}
                    size="medium"
                    fullWidth
                    multiline
                    variant="outlined"
                    id="support_description"
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                  />
                </Box>
                <Box className={stylesLayoutMainEcom.MarginB__075}>
                  <FormUploadImage
                    {...props}
                  />
                </Box>
                <Box className={styles.Note}>
                  {t('Thông tin cần hỗ trợ của bạn sẽ được đăng trên trang "Diễn đàn". Các thông tin trên được công khai với cộng đồng cứu trợ Limart và các tổ chức uy tín nhằm giúp bạn tìm được sự hỗ trợ trong thời gian sớm nhất.')}
                </Box>
              </form>
            )
          }
        }
      </Formik>
    );
  }
};

ConnectSupportSupportForm.propTypes = {
  data: PropTypes.object,
};

ConnectSupportSupportForm.defaultProps = {
  data: {}
}

export default withTranslation(['componentConnectSupportSupportForm'],{ withRef: true })(ConnectSupportSupportForm)

