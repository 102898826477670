import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getSalesOrderStatuses } from 'redux/actions/oms/salesOrderActions';
import { createStructuredSelector } from 'reselect';
import { makeSelectSalesOrderStatuses } from 'redux/selectors';
import { isEqual } from 'lodash';

import FilterField from 'components/FilterField/FilterField';
import PriceRange from 'components/FilterField/PriceRange/PriceRange';
import DateRange from 'components/FilterField/DateRange/DateRange';
import MultiSelect from 'components/FilterField/MultiSelect';

import classes from './SalesOrderListFilters.module.scss';

class SalesOrderListFilters extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      queryParams: { ...props.queryParamsMap },
    }
  }
  componentDidMount() {
    this.props.getSalesOrderStatuses();

    const { queryParamsMap } = this.props;
    this.setState({
      queryParams: { ...queryParamsMap },
    });
  }
  componentDidUpdate(prevProps) {
    const { queryParamsMap } = this.props;
    if (!isEqual(prevProps.queryParamsMap, queryParamsMap)) {
      this.setState({
        queryParams: { ...queryParamsMap },
      });
    }
  }

  handleFieldChange = ({ name, value }) => {
    let paramsFromFilters = {};
    if (['amount_total'].includes(name)) {
      const numArr = value.map(val => val.trim() !== '' ? Number(val) : '');
      paramsFromFilters[`${name}_from`] = numArr[numArr.length - 1] && numArr[0] ? Math.min.apply(null, [...numArr]) : numArr[0];
      paramsFromFilters[`${name}_to`] = numArr[numArr.length - 1] && numArr[0] ? Math.max.apply(null, [...numArr]) : numArr[numArr.length - 1];
    } else if (['created_at'].includes(name)) {
      paramsFromFilters[`${name}_from`] = value[0];
      paramsFromFilters[`${name}_to`] = value[1];
    } else {
      paramsFromFilters[name] = value;
    }
    const newQueryParams = {
      ...this.state.queryParams,
      ...paramsFromFilters,
    }
    this.setState({
      queryParams: newQueryParams,
    });
    this.props.onFilterChange(newQueryParams, true);
  };

  render() {
    const { queryParams } = this.state;
    const { filterFields } = this.props;

    return (
      <div className={classes.Panel}>
        <div className={classes.PanelContent}>
          {filterFields.map((field) => (
            ['amount_total'].includes(field.name) ? (
              <FilterField
                key={field.name}
                {...field}
                defaultValue={['', '']}
                value={[queryParams['amount_total_from'], queryParams['amount_total_to']]}
                customField={<PriceRange
                  name={field.name}
                  value={[queryParams['amount_total_from'], queryParams['amount_total_to']]}
                  handleFieldChange={this.handleFieldChange}
                />}
                handleFieldChange={this.handleFieldChange}
              />
            ) : ['created_at'].includes(field.name) ? (
              <FilterField
                key={field.name}
                {...field}
                defaultValue={['', '']}
                value={[queryParams['created_at_from'], queryParams['created_at_to']]}
                customField={<DateRange
                  name={field.name}
                  value={[queryParams['created_at_from'], queryParams['created_at_to']]}
                  handleFieldChange={this.handleFieldChange}
                />}
                handleFieldChange={this.handleFieldChange}
              />
            ) : ['getcare_order_status_id'].includes(field.name) ? (
              <FilterField
                key={field.name}
                {...field}
                defaultValue={[]}
                value={queryParams[field.name]}
                customField={<MultiSelect
                  name={field.name}
                  value={queryParams[field.name]}
                  placeholder={field.placeholder}
                  options={this.props.salesOrderStatuses}
                  handleFieldChange={this.handleFieldChange}
                />}
                handleFieldChange={this.handleFieldChange}
              />
            ) : (<FilterField
                key={field.name}
                {...field}
                value={queryParams[field.name]}
                handleFieldChange={this.handleFieldChange}
              />)
          ))}
        </div>
      </div>
    );
  }
}

SalesOrderListFilters.propTypes = {
  filterFields: PropTypes.array,
  queryParamsMap: PropTypes.object,
  onFilterChange: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  salesOrderStatuses: makeSelectSalesOrderStatuses(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getSalesOrderStatuses: (payload) => dispatch(getSalesOrderStatuses(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(SalesOrderListFilters);
