import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import { withTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import classes from './CouponAvailables.module.scss';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import voucherApi from 'utils/api/mdm/voucherApi';
import Autocomplete from '@material-ui/lab/Autocomplete';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import Photo from 'components/Photo/Photo';

import InputAdornment from '@material-ui/core/InputAdornment';
import { isEqual, sortBy } from 'lodash';
import Panel from 'components/Panel/Panel';
import VoucherIcon from 'assets/images/icons/icon_voucher.png';

import Search from '@material-ui/icons/Search';
import * as QueryString from 'query-string';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
class CouponAvailables extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      selectedCode: [],
      isLoading: true,
      vendorOptions: [],
      isPopoverOpen: false,
      voucherList: [],
      vourcherInput: '',
      keyword: '',
      errorVoucher: props.errorVoucher,
      selectedCoupon: props.selectedCoupon,
      suggettionVoucherList: [],
      isOpen: false,
    };
    this.keywordInputRef = React.createRef();
  }
  handlePromotionLinkClick = (e, promotionItem) => {
    this.props.onPromotionLinkClick(promotionItem);
    e.stopPropagation();
    e.preventDefault();
  };

  componentDidMount() {
    const { selectedCoupon } = this.props;
    this._loadSuggetionByVendorList('');
    const dataMerge = selectedCoupon?.concat(this.state.voucherList);
    var result = dataMerge.reduce((unique, o) => {
      if (!unique.some((obj) => obj.id === o.id)) {
        unique.push(o);
      }
      return unique;
    }, []);
    console.log(dataMerge);
    this.setState({
      selectedCoupon: sortBy(selectedCoupon, 'apply').reverse(),
      voucherList: sortBy(result, 'apply').reverse(),
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { vourcherInput, isPopoverOpen, keyword, voucherList } = this.state;
    const { selectedCoupon } = this.props;

    if (vourcherInput && vourcherInput !== prevState.vourcherInput) {
      this._loadSuggetionByVendor(vourcherInput);
    }
    if (selectedCoupon && selectedCoupon !== prevProps.selectedCoupon) {
      const dataMerge = selectedCoupon.concat(this.state.voucherList);
      var result = dataMerge.reduce((unique, o) => {
        if (!unique.some((obj) => obj.id === o.id)) {
          unique.push(o);
        }
        return unique;
      }, []);
      this.setState({
        selectedCoupon: sortBy(selectedCoupon, 'apply').reverse(),
        voucherList: sortBy(result, 'apply').reverse(),
      });
    }
    if (
      (isPopoverOpen &&
        isPopoverOpen !== prevState.isPopoverOpen &&
        !voucherList.length) ||
      (isPopoverOpen && keyword !== prevState.keyword) ||
      !isEqual(sortBy(selectedCoupon), sortBy(prevProps.selectedCoupon))
    ) {
      this._loadSuggetionByVendor(keyword);
    }
  }

  _loadSuggetionByVendor = async (vourcherInput) => {
    const { location } = this.props;
    const paramsLocation = QueryString.parse(location.search);
    const params = {
      getcare_vendor_suggestion: vourcherInput,
      getcare_customer_code: paramsLocation.getcare_customer_code,
    };
    const { data: response } = await voucherApi.getVendorSuggestions({
      params,
    });
    this.setState({ isLoading: false });
    if (response?.data && response?.data.filter((x) => x.apply !== -1)) {
      this.setState({
        suggettionVoucherList:
        response.data
      });
    }
  };

  _loadSuggetionByVendorList = async (vourcherInput) => {
    const { location } = this.props;
    const paramsLocation = QueryString.parse(location.search);
    const params = {
      getcare_vendor_suggestion: vourcherInput,
      getcare_customer_code: paramsLocation.getcare_customer_code,
    };
    const { data: response } = await voucherApi.getVendorSuggestions({
      params,
    });
    if (this.state.selectedCoupon && response.data) {
      const dataMerge = response.data.concat(this.state.selectedCoupon);
      var result = dataMerge.reduce((unique, o) => {
        if (!unique.some((obj) => obj.id === o.id)) {
          unique.push(o);
        }
        return unique;
      }, []);
      if (response.data.filter((x) => x.apply !== -1)) {
        this.setState({
          voucherList: sortBy(result, 'apply').reverse() || [],
          suggettionVoucherList:
          response.data
        });
      }
    }

  };

  handleToggleVendorsPanel = (e) => {
    e.stopPropagation();
    if (!this.state.isOpen) {
      this.setState({
        isOpen: true,
      });
    }
  };

  handleSelectedChange = (e, item) => {
    this.setState({ errorVoucher: ''});

    this.props.onSelectedCouponChange(e, item);
  };

  handleSelectedChangeInput = (e, item) => {
    this.props.onSelectedCouponChange(e, item, 'input');
  };

  submit = () => {
    const { selectedCoupon } = this.state;
    const checkVoucher = selectedCoupon.map((e) => e.apply).includes(-1);
    const typeMoney = selectedCoupon.filter(x => x.getcare_voucher_program_code === 'LINH15K');
    const typeItem = selectedCoupon.filter(x => x.getcare_voucher_program_code !== 'LINH15K');

    if (selectedCoupon && checkVoucher === false && typeItem.length < 2 && typeMoney.length < 2 ) {
      this.props.onSubmitSelectedPromotion(selectedCoupon, true);
    } else {
      this.setState({ errorVoucher: this.props.t('Đã chọn đủ 2 vouchers. Quý khách phải huỷ chọn voucher hiện có để thay bằng voucher mới.')});
    }
  };
  _isCouponSelected = (coupon) => {
    return this.props.selectedCoupon.some((item) => item.id === coupon.id);
  };

  removeCoupon = () => {
    this.props.onSubmitSelectedPromotion(null);
    this.setState({ selectedCode: null, selectedVendor: null });
    this.props.onCancelPromotion();
  };

  removeCouponItem = (e, item) => {
    this.props.onRemoveCouponChange(e, item);
  };

  handleKeywordChange = (e) => {
    this.setState({
      keyword: e.target.value,
    });
  };
  handleKeywordFocus = () => {
    this.handleOpen();
  };
  handleClearKeyword = () => {
    this.setState(
      {
        keyword: '',
        isLoading: true,
        productOptions: [],
      },
      () => {
        this.keywordInputRef &&
          this.keywordInputRef.current &&
          this.keywordInputRef.current.focus();
      }
    );
  };
  handleKeywordKeyPress = (e) => {
    const { productOptions } = this.state;
    if (e.key === 'Enter' && productOptions.length === 1) {
      // auto add this product to cart
      const defaultProduct = { ...productOptions[0] };
      const defaultVendor =
        (defaultProduct?.getcare_vendors &&
          defaultProduct.getcare_vendors[0]) ||
        null;
      const defaultUom = (defaultVendor?.uom && defaultVendor.uom[0]) || null;
      this.handleAddProductToCart({
        product: defaultProduct,
        vendor: defaultVendor ? { ...defaultVendor } : null,
        uom: defaultUom ? { ...defaultUom } : null,
        quantity: 1,
      });
    }
  };
  handleOpen = () => {
    this.setState({
      isPopoverOpen: true,
    });
  };
  handleClose = () => {
    this.setState(
      {
        isPopoverOpen: false,
      },
      () => {
        this.keywordInputRef &&
          this.keywordInputRef.current &&
          this.keywordInputRef.current.blur();
      }
    );
  };

  handleClickAway = () => {
    this.setState(
      {
        isPopoverOpen: false,
      },
      () => {
        this.keywordInputRef &&
          this.keywordInputRef.current &&
          this.keywordInputRef.current.blur();
      }
    );
  };
  render() {
    const { t } = this.props;
    const {
      suggettionVoucherList,
      errorVoucher,
      selectedVendor,
      selectedCode,
      voucherList,
      isPopoverOpen,
      keyword,
      isLoading,
    } = this.state;

    return (
      <div className={`${classes.Wrap}`}>
        <div className={`${classes.List}`}>
          <Typography variant="h6" component="div">
            {t('Nhập mã voucher nếu có (tối đa 2 vouchers khác loại)')}
          </Typography>
          
          <ClickAwayListener onClickAway={this.handleClickAway}>
            <div className={classes.FilterWrap}>
              {/* <Autocomplete
                selectOnFocus
                openOnFocus
                size="small"
                disableClearable
                handleHomeEndKeys={false}
                value={selectedVendor|| ''}
                onInputChange={(e, newInputValue) => {
                  this.setState({
                    vourcherInput: newInputValue,
                  });
                }}
                name='getcare_vendor_name'
                options={suggettionVoucherList || []}
                filterOptions={(x) => x}
                getOptionLabel={(option) => option?.code || ''}
                getOptionSelected={(option, value) =>
                  option?.code
                }
                onChange={(e,value) => this.handleSelectedChangeInput(e, value)}

                renderInput={(params) => (
                  <TextField
                    {...params}
                    // error={!this._isNameVendorValid()}
                    placeholder={'-- Nhập mã khuyến mại ---'}
                  />
                )}        
              /> */}

              <TextField
                inputRef={this.keywordInputRef}
                value={keyword}
                autoComplete="off"
                name="keyword"
                variant="outlined"
                size="small"
                className={`${classes.SearchFieldFilter} ${
                  isPopoverOpen ? classes.Focus : ''
                }`}
                placeholder={t('-- Nhập mã voucher --')}
                onFocus={this.handleKeywordFocus}
                onChange={this.handleKeywordChange}
                onKeyPress={this.handleKeywordKeyPress}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {!keyword ? (
                        <Search fontSize="small" />
                      ) : (
                        <IconButton
                          size="small"
                          onClick={this.handleClearKeyword}
                        >
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                }}
              />

              {isPopoverOpen && (
                <div className={classes.ListWrap}>
                  <Panel
                    panelClassName={classes.ProductListPanel}
                    content={
                      <div className={classes.ProductList}>
                        <div className={`${classes.Body}`}>
                          {suggettionVoucherList.length > 0 ? (
                  
                            suggettionVoucherList.map((item) => (
                              <div    className={
                                item.error_message === 'voucher đã được sử dụng' ? classes.SelectionItemUsed : ''
                              }>
                              <FormControlLabel
                                key={`promotion-${item.id}`}
                                control={
                                  <Checkbox
                                    size="small"
                                    value={item.id}
                                    name="promotionId"
                                    checked={this._isCouponSelected(item)}
                                    onChange={(e) =>{
                                      this.handleSelectedChange(e, item);
                                      this.setState({keyword: ''});
                                      this.setState({ isPopoverOpen: false});
                                    }
                                    }
                                    disabled = {item.error_message === 'voucher đã được sử dụng'  ? true : false}
                                    style={{
                                      padding: '2px',
                                      marginRight: '10px',
                                      display: 'none',
                                    }}
                                  />
                                }
                                fontSize="small"
                                className={
                                  classes.SelectionItemTop 
                                }
                                label={
                                  <div className={`${classes.WrapItem}`}>
                                    <Photo  className={classes.itemImage}
                                      src={
                                        item.thumbnail
                        
                                      }
                                      alt={item.code} />
                                   
                                    <div className={`${classes.Item}`}>
                                      <p className={classes.PromotionLink}>
                                        
                                        <span>
                                          {item.getcare_voucher_program_code}
                                        </span>
                                      </p>
                                      {item.apply === -1 && item.error_message === 'voucher đã được sử dụng' ? (
                                        <span
                                          className={classes.PromotionError}
                                        >
                                          {item.error_message}
                                        </span>
                                      ) : <span
                                      className={classes.PromotionError}
                                    >
                                      {item.error_message}
                                    </span>}
                                      <p>
                                        {item.vendor_name
                                          ? item.vendor_name
                                          : ``}
                                      </p>
                                      <div   className={classes.codeVoucher}> {item.code}</div>    
                                      <p className={classes.TextExplained}>
                                        {item.note}
                                      </p>
                                    </div>
                                  </div>
                                }
                              />
                              </div>
                            ))
                          ) : (
                            <p className={classes.EmptyLine}>
                              {t('Không tìm thấy voucher')}
                            </p>
                          )}
                        </div>
                      </div>
                    }
                  />
                </div>
              )}
            </div>
          </ClickAwayListener>
           
           {
             errorVoucher ? 
             <p className={`${classes.errorMessage}`}>{errorVoucher}</p>  
             : null
           }           
                

          {voucherList.length ? (
            <FormGroup className={classes.SelectionGroup}>
              {voucherList.map((item) => (
                <FormControlLabel
                  key={`promotion-${item.id}`}
                  control={
                    <Checkbox
                      size="small"
                      value={item.id}
                      name="promotionId"
                      checked={this._isCouponSelected(item)}
                      onChange={(e) => {this.handleSelectedChange(e, item)}}
                      // disabled={item.apply === -1 && this._isCouponSelected(item) === false ? true :false}
                      style={{
                        padding: '2px',
                        marginRight: '10px',
                        display: 'none',
                      }}
                    />
                  }
                  fontSize="small"
                  className={
                    this._isCouponSelected(item) === true
                      ? classes.SelectedItem
                      : classes.SelectionItem
                  }
                  label={
                    <div className={`${classes.WrapItem}`}>
                       <Photo  className={classes.itemImage}
                                      src={
                                        item.thumbnail
                        
                                      }
                                      alt={item.code} />
                      <div className={`${classes.Item}`}>
                        <p className={classes.PromotionLink}>
                          <span>{item.getcare_voucher_program_code}</span>
                        </p>
                        {item.error_message === 'voucher đã được sử dụng' ? (
                          <span className={classes.PromotionErrorSearch}>
                            {item.error_message}
                          </span>
                        ) : null}

                        <p>{item.vendor_name ? item.vendor_name : ``}</p>
                        <p className={classes.TextExplained}>{item.note}</p>
                      </div>
                      <div   className={classes.codeVoucher}> {item.code}</div>    
                      {this._isCouponSelected(item) === true ? (
                        <CheckCircleOutline
                          fontSize="large"
                          className={classes.DialogSuccessIcon}
                        />
                      ) : null}
                    </div>
                  }
                />
              ))}
            </FormGroup>
          ) : (
            <p className="NoData">{t('Không có voucher')}</p>
          )}
        </div>

        <div></div>
        <div className={classes.Actions}>
          <div>
            <Button variant="outlined" onClick={this.removeCoupon}>
              {t('Đóng')}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              disabled={selectedCode || selectedVendor ? false : true}
              onClick={this.submit}
            >
              {t('Tiếp tục')}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
CouponAvailables.propTypes = {
  selectedVendors: PropTypes.array,
  onDeselectAll: PropTypes.func,
  onSelectedChange: PropTypes.func,
};

CouponAvailables.defaultProps = {
  selectedVendors: [],
};

export default withTranslation()(CouponAvailables);
