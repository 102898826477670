export const PRODUCT_LIST_BY_HOT_PROMOTION = 'PRODUCT_LIST_BY_HOT_PROMOTION';
export const PRODUCT_LIST_BY_TOP_SEARCH = 'PRODUCT_LIST_BY_TOP_SEARCH';
export const PRODUCT_LIST_BY_BESTSELLER = 'PRODUCT_LIST_BY_BESTSELLER';
export const PRODUCT_LIST_BY_NEW_ARRIVAL = 'PRODUCT_LIST_BY_NEW_ARRIVAL';
export const PRODUCT_LIST_BY_HIGH_PRICE = 'PRODUCT_LIST_BY_HIGH_PRICE';
export const PRODUCT_LIST_BY_LOW_PRICE = 'PRODUCT_LIST_BY_LOW_PRICE';
export const FIRST_PRODUCT_LIST = PRODUCT_LIST_BY_TOP_SEARCH;

export const productTypes = [
  PRODUCT_LIST_BY_HOT_PROMOTION,
  PRODUCT_LIST_BY_TOP_SEARCH,
  PRODUCT_LIST_BY_BESTSELLER,
  PRODUCT_LIST_BY_NEW_ARRIVAL,
  PRODUCT_LIST_BY_HIGH_PRICE,
  PRODUCT_LIST_BY_LOW_PRICE,
]

// fucntion
export function checkProductTypes(productType) {
  return productTypes.includes(productType)
}
