import axiosAuth from 'utils/axios/axiosAuth';
import axiosCommon from 'utils/axios/axiosCommon';

import { relatedProductsPageSize } from 'utils/constanst/adminProductConstants';

const ADMIN_PRODUCT_API_URL = 'ecom/product/';
const productApi = {
  get: (id, params) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    if (params.getcare_customer_code === undefined){
      return axiosCommon.get(`${ADMIN_PRODUCT_API_URL}${id}`, {
        params: queryParams,
      });
    }else{
      return axiosAuth.get(`${ADMIN_PRODUCT_API_URL}${id}`, {
        params: queryParams,
      });
    }
  },
  getRelatedProducts: (id) => {
    return axiosAuth.get(`getcare_product_related/${id}`, {
      params: {
        page_size: relatedProductsPageSize,
      },
    });
  },
  getAll: ({ params, cancelToken }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    if (params.getcare_customer_code === ''){
      return axiosCommon.get('ecom/product', {
        params: queryParams,
        cancelToken,
      });
    }else{
      return axiosAuth.get('ecom/product', {
        params: queryParams,
        cancelToken,
      });
    }
  },

  getProductBrands: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_product_brand/`, {
      params: queryParams,
    });
  },
  getProductIndustries: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_product_industry/`, {
      params: queryParams,
    });
  },
  getProductManufactures: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_manufacturer/`, {
      params: queryParams,
    });
  },
};

export default productApi;
