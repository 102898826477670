import axiosAuth from 'utils/axios/axiosAuth';

export default {
  getList: ({ params, cancelToken }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`/ecom/service_request/connect_support/support_group_by_product`, {
      params: queryParams,
      cancelToken,
    });
  },
  pushFavourite: ({ params })  => {
    return axiosAuth.post(`/ecom/service_request/connect_support/support_group_by_product_favourite`, params);
  },
  createOrUpdate: ({ params })  => {
    return axiosAuth.post(`/ecom/service_request/connect_support/support`, params);
  } 
};
