import {
  SAVE_SALES_ORDER_STATUSES,

  SAVE_LOADING,

  SAVE_SALES_ORDER_LIST,
  SAVE_SALES_ORDER_LIST_TOTAL,
  SAVE_SALES_ORDER_LIST_QUERY_STRING,
  SAVE_SALES_ORDER_LIST_ORDER_SELECTED,

  SAVE_SALES_ORDER,
  SAVE_SALES_ORDER_PRODUCTS,
  SAVE_SALES_ORDER_BONUSES,
  SAVE_SALES_ORDER_DISCOUNTS,

  SAVE_SALES_ORDER_NOTES,
  SAVE_SALES_ORDER_ALL_INFO,
  SAVE_SALES_ORDER_LOGISTIC,
  SAVE_SALES_ORDER_SALES_USER,
  SAVE_SALES_ORDER_DELIVERY,
} from 'redux/constants/oms/salesOrderConstants';

const initialState = {
  salesOrderStatuses: [],

  loading: false,

  salesOrderList: [],
  salesOrderListTotal: 0,
  salesOrderListQueryString: '',
  salesOrderListOrderSelected: [],

  salesOrder: null,
  salesOrderProducts: [],
  salesOrderBonuses: [],
  salesOrderDiscounts: [],
  salesOrderNotes: [],
  salesOrderLogistic: null,
  salesOrderSalesUser: null,
  salesOrderDelivery: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_SALES_ORDER_STATUSES: {
      return {
        ...state,
        salesOrderStatuses: action.salesOrderStatuses ? action.salesOrderStatuses : [],
      };
    }

    case SAVE_LOADING: {
      return {
        ...state,
        loading: action.loading,
      };
    }

    case SAVE_SALES_ORDER_LIST: {
      const list = action.salesOrderList;
      return {
        ...state,
        salesOrderList: list ? [...list] : [],
      };
    }
    case SAVE_SALES_ORDER_LIST_TOTAL: {
      return {
        ...state,
        salesOrderListTotal: action.salesOrderListTotal,
      };
    }
    case SAVE_SALES_ORDER_LIST_QUERY_STRING: {
      return {
        ...state,
        salesOrderListQueryString: action.salesOrderListQueryString,
      };
    }
    case SAVE_SALES_ORDER_LIST_ORDER_SELECTED: {
      return {
        ...state,
        salesOrderListOrderSelected: action.salesOrderListOrderSelected,
      };
    }

    case SAVE_SALES_ORDER: {
      return {
        ...state,
        salesOrder: action.salesOrder,
      };
    }
    case SAVE_SALES_ORDER_PRODUCTS: {
      return {
        ...state,
        salesOrderProducts: action.salesOrderProducts,
      };
    }
    case SAVE_SALES_ORDER_BONUSES: {
      return {
        ...state,
        salesOrderBonuses: action.salesOrderBonuses,
      };
    }
    case SAVE_SALES_ORDER_DISCOUNTS: {
      return {
        ...state,
        salesOrderDiscounts: action.salesOrderDiscounts,
      };
    }

    case SAVE_SALES_ORDER_NOTES: {
      return {
        ...state,
        salesOrderNotes: action.salesOrderNotes,
      };
    }
    
    case SAVE_SALES_ORDER_ALL_INFO: {
      return {
        ...state,
        salesOrder: action.salesOrderData ? {
          ...action.salesOrderData,
          getcare_order_items: undefined,
          getcare_order_bonuss: undefined,
          getcare_order_notes: undefined,
        } : null,
        salesOrderProducts: action.salesOrderData?.getcare_order_items || [],
        salesOrderBonuses: action.salesOrderData?.getcare_order_bonuss || [],
        salesOrderNotes: action.salesOrderData?.getcare_order_notes || [],
        salesOrderLogistic: action.salesOrderData?.logistic_provider || null,
        salesOrderDelivery: action.salesOrderData?.customer_delivery || null,
      };
    }

    case SAVE_SALES_ORDER_LOGISTIC: {
      return {
        ...state,
        salesOrderLogistic: action.salesOrderLogistic,
      };
    }
    case SAVE_SALES_ORDER_SALES_USER: {
      return {
        ...state,
        salesOrderSalesUser: action.salesOrderSalesUser,
      };
    }
    case SAVE_SALES_ORDER_DELIVERY: {
      return {
        ...state,
        salesOrderDelivery: action.salesOrderDelivery,
      };
    }
    default:
      return state;
  }
};

export default reducer;
