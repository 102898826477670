export const GET_DELIVERY_LOGISTIC_LIST = 'getcare/logistic/GET_DELIVERY_LOGISTIC_LIST';
export const GET_LOGISTIC_LIST = 'getcare/logistic/GET_LOGISTIC_LIST';
export const GET_VENDOR_DELIVERY_FEE = 'getcare/logistic/GET_VENDOR_DELIVERY_FEE';
export const SAVE_VENDOR_DELIVERY_FEES_LOADING = 'getcare/logistic/SAVE_VENDOR_DELIVERY_FEES_LOADING';

// mutation
export const SAVE_DELIVERY_LOGISTIC_LIST = 'getcare/logistic/SAVE_DELIVERY_LOGISTIC_LIST';
export const SAVE_SELECTED_PAYMENT = 'getcare/logistic/SAVE_SELECTED_PAYMENT';
export const SAVE_LOGISTIC_LIST = 'getcare/logistic/SAVE_LOGISTIC_LIST';
export const SAVE_LOGISTIC_LIST_LOADING = 'getcare/logistic/SAVE_LOGISTIC_LIST_LOADING';
export const SAVE_VENDOR_DELIVERY_FEES = 'getcare/logistic/SAVE_VENDOR_DELIVERY_FEES';