import React, { PureComponent } from 'react';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import { withTranslation } from "react-i18next";

import classes from './PromotionTooltip.module.scss';

class PromotionTooltip extends PureComponent {
  render() {
    const {
      t,
    } = this.props;
    return (
      <Tooltip
        placement="bottom-start"
        title={<div className={classes.Content}>
            <p>- {t('Chương trình khuyến mãi chỉ áp dụng cho sản phẩm và số lượng sản phẩm chưa được tính khuyến mãi')}</p>
            <p>- {t('Các chương trình khuyến mãi được thể hiện theo độ ưu tiên')}</p>
            <p>- {t('Khuyến mãi thể hiện ở mỗi thời điểm có thể khác nhau')}</p>
          </div>
        }
      >
        <InfoOutlinedIcon className={classes.TooltipIcon}/>
      </Tooltip>);
  }
}

export default withTranslation()(PromotionTooltip);
