import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { toast } from 'react-toastify';
import { withTranslation } from "react-i18next";

import { 
  makeSelectLoginUser,
} from 'redux/selectors';
import { 
  makeSelectConnectSupportDonate,
  makeSelectSavingConnectSupportDonate, 
  makeSelectOpenProductInfoFormInput,
} from 'redux/selectors/connectSupportDonateSelector';
import {
  saveConnectSupportDonate,
  saveSavingConnectSupportDonate,
  saveOpenProductInfoFormInput,
} from 'redux/actions/connectSupportDonateActions';
import donateApi from 'utils/api/ecom/serviceRequest/connectSupport/donate/donateApi';

import { 
  Box,
  Typography,
} from '@material-ui/core';

import ConnectSupportDonateForm from 'components/MainEcom/ConnectSupportDonateForm/ConnectSupportDonateForm';
import StyledButton from 'components/Styled/Button/Button';

import styles from './ConnectSupportDonateNew.module.scss';
import stylesLayoutMainEcom from 'assets/styles/layouts/LayoutMainEcom.module.scss';


class ConnectSupportDonateNew extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      openProductInfoFormInput: props.openProductInfoFormInput,
    }
    this.refConnectSupportDonateForm = React.createRef();
  }
  componentDidMount() {
    if ( this.props.openProductInfoFormInput ) {
      this.props.saveOpenProductInfoFormInput();
    }
  }
  componentWillUnmount() {
    this.props.saveConnectSupportDonate({})
  }

  handleSubmitConnectSupportDonateForm = async (values) => {
    const { t } = this.props;
    if ( values.items.length === 0 ) {
      alert(t('Vui lòng thêm ít nhất 1 sản phẩm đóng góp'))
      return
    } 
    this.props.saveSavingConnectSupportDonate(true);
    const { data: response } = await donateApi.createOrUpdate({ params: values });
    if (!response.result) {
      toast.error(response.message);
      this.props.saveSavingConnectSupportDonate(false);
      return;
    }
    this.props.saveSavingConnectSupportDonate(false);
    toast.success(t('Bạn đã tạo thành công'));
    this.props.history.push(`/community/connect-support`)
  }
  handleSubmitForm = () => {
    this.refConnectSupportDonateForm.current.submitForm();
  }

  render() {
    const { t } = this.props;
    return (
      <Box className={`${styles.root} ${this.props.savingConnectSupportDonate && 'OverlayLoading'}`}>
        <Typography className={stylesLayoutMainEcom.ContainerTitle} variant="h6">{t('Thêm đóng góp')}</Typography>
        <ConnectSupportDonateForm
          data={this.props.connectSupportDonate}
          ref={this.refConnectSupportDonateForm}
          onSubmit={this.handleSubmitConnectSupportDonateForm}
        />
        <Box display="flex" justifyContent="center">
          <StyledButton variant="contained" onClick={this.handleSubmitForm} className={styles.BtnSubmit} color="primary">{t('Đóng góp sản phẩm')}</StyledButton>
        </Box>
      </Box>
    );
  }
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectLoginUser(),
  connectSupportDonate: makeSelectConnectSupportDonate(),
  savingConnectSupportDonate: makeSelectSavingConnectSupportDonate(),
  openProductInfoFormInput: makeSelectOpenProductInfoFormInput(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    saveConnectSupportDonate: (payload) => dispatch(saveConnectSupportDonate(payload)),
    saveSavingConnectSupportDonate: (payload) => dispatch(saveSavingConnectSupportDonate(payload)),
    saveOpenProductInfoFormInput: (payload) => dispatch(saveOpenProductInfoFormInput(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withRouter,withTranslation(['viewMainEcomConnectSupportDonateNew']))(ConnectSupportDonateNew);
