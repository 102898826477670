import React from 'react';
import classNames from "classnames";
import PropTypes from "prop-types";

import { TextField, withStyles  } from '@material-ui/core';

const margins = ['normal','dense']

const styles = (theme) => {
  return {
    root: (props) => {
      const margins = {
        normal: {
          '&.StyledTextField-marginNormal': {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(1),
          }
        },
        dense: {
          '&.StyledTextField-marginDense': {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(.5),
          }
        },
        large: {
          '&.StyledTextField-marginLarge': {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(1.5),
          }
        }
      }
      let marginClass = {};
      if ( margins[props.margin] ) marginClass = { ...margins[props.margin] };
      else if ( ![undefined].includes(props.margin) ) {
        try {
          marginClass = {
            '&.StyledTextField-marginCustom': {
              margin: theme.spacing(props.margin)
            }
          }
        } catch (error) {}
      }
      return {
        backgroundColor: theme.palette.common.white,
        ...marginClass,
      }
    },
  }
};
const StyledTextField = React.memo(
  withStyles(styles,{ name: 'StyledTextField'})(
    React.forwardRef(({ margin, className,...otherProps },ref) => {
      const styledTextFieldClasses = classNames({
        ['StyledTextField-marginNormal']: margin === 'normal',
        ['StyledTextField-marginDense']: margin === 'dense',
        ['StyledTextField-marginLarge']: margin === 'large',
        ['StyledTextField-marginCustom']: ![undefined].includes(margin) && !margins.includes(margin),
        [className]: className !== undefined,
      });
      return <TextField 
        className={styledTextFieldClasses} 
        ref={ref} 
        {...otherProps}
      />
    })
  )
);

StyledTextField.propTypes = {
  ...TextField.Naked.propTypes,
  margin: PropTypes.any,
};

export default StyledTextField;