import axiosAuth from 'utils/axios/axiosAuth';

export default {
  fetchCategoryEcomList: ({ params, cancelToken }) => {
    return axiosAuth.get(`ecom/common/category_ecom`, {
      params,
      cancelToken
    });
  },
};
