export const CHECK_AUTH = 'getcare/sso/CHECK_AUTH';
export const SIGN_OUT = 'getcare/sso/SIGN_OUT';
export const GET_PROFILE = 'getcare/sso/GET_PROFILE';
export const UPDATE_PROFILE = 'getcare/sso/UPDATE_PROFILE';

export const GET_USER_PROVINCE_LIST = 'getcare/sso/GET_USER_PROVINCE_LIST';

// mutation
export const SAVE_AUTH_LOADING = 'getcare/sso/SAVE_AUTH_LOADING';
export const SAVE_AUTH_ERROR = 'getcare/sso/SAVE_AUTH_ERROR';
export const SAVE_AUTH = 'getcare/sso/SAVE_AUTH';
export const SAVE_AUTH_FIELDS_MAP = 'getcare/sso/SAVE_AUTH_FIELDS_MAP';
export const REMOVE_AUTH = 'getcare/sso/REMOVE_AUTH';
export const SAVE_PROFILE = 'getcare/sso/SAVE_PROFILE';
export const SAVE_CHECKING_AUTH = 'getcare/sso/SAVE_CHECKING_AUTH';

export const SAVE_USER_PROVINCE_LIST = 'getcare/sso/SAVE_USER_PROVINCE_LIST';
