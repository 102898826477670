import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { withTranslation } from "react-i18next";

import { currencyFormatVN } from 'utils/helper'
import supportApi from 'utils/api/ecom/serviceRequest/connectSupport/support/supportApi'
import { 
  makeSelectLoginUser 
} from 'redux/selectors';

import { 
  Grid,
  Avatar,
} from '@material-ui/core';
import Comments from 'components/Comments/Comments';
import Skeleton from '@material-ui/lab/Skeleton';
import { ReactComponent as CrownIcon } from 'assets/images/icons/Crown.svg';



import styles from './TabUpdate.module.scss';

class TabUpdate extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loadingLeaderboardList: true,
      topCharityGiverList: []
    }
  }

  async componentDidMount() {
    this.fetchLeaderboardListData()
  }

  fetchLeaderboardListData = async () => {
    this.setState({ loadingLeaderboardList: true })
    const { data: response } = await supportApi.getCharityLeaderboardList({ params: { project_id: this.props.values?.id }});
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    this.setState({
      loadingLeaderboardList: false,
      topCharityGiverList: response.data,
    })
  }

  render() {
    const { t } = this.props;
    return (
      <Grid container spacing={4}>
        <Grid item xs={12} sm={8}> 
          <Comments
            object_id={this.props.values?.id}
            getcare_comment_type_id={1}
          />
        </Grid>
        <Grid item xs={12} sm={4}> 
          <p className={styles.topContributorsTitle}>{t('Bảng ghi nhận')}</p>
          <div className={styles.topContributorsList}>
          {
            !this.state.loadingLeaderboardList ? (
              this.state.topCharityGiverList.length > 0 ? 
              this.state.topCharityGiverList.map( (item,index) => (
                <div key={index} className={styles.topContributorsBox}>
                  <div className={styles.topContributorsImage}>
                    <Avatar
                      className={styles.topContributorsAvatar} 
                      alt={item.name} 
                      src={item.avatar}
                    >
                      { item.name ? item.name.charAt(0) : 'G'}
                    </Avatar>
                    <span className={styles.topContributorsNumber}>{index + 1}</span>
                    { !index && <CrownIcon className={styles.topContributorsTopIcon}/> }
                  </div>
                  <div className={styles.topContributorsContent}>
                    <p className={styles.topContributorsContentName}>{item.name}</p>
                    <p className={styles.topContributorsContentPrice}>
                      {currencyFormatVN(item.amount)}
                    </p>
                  </div>
                </div>
              )) : <p className={styles.topContributorsEmpty}>
                {t('Chưa có ai đóng góp')}
              </p>
            ) : 
            <div className={styles.topContributorsBox}>
              <div className={styles.topContributorsImage}>
                <Skeleton variant="circle" width={70} height={70}/>
              </div>
              <div className={styles.topContributorsContent}>
                <Skeleton/>
                <Skeleton/>
              </div>
            </div>
          }  
          </div>
          {
            this.state.topCharityGiverList.length > 0 && 
            <div className={styles.topContributorsActions}>
              <p onClick={() => this.props.onChangeTab(3)} className={styles.topContributorsAction}>{t('Xem tất cả')} ▶</p>
            </div>
          }
        </Grid>
      </Grid>
    );
  }
};

TabUpdate.propTypes = {
  values: PropTypes.object,
};

TabUpdate.defaultProps = {
  values: {

  },
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectLoginUser(),
});
const mapDispatchToProps = (dispatch) => {
  return {

  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect,withTranslation(['viewMainEcomConnectSupportProjectDetail']))(TabUpdate);
