import {
  SAVE_CONNECT_SUPPORT_SUPPORT,
  SAVE_SAVING_CONNECT_SUPPORT_SUPPORT,
} from 'redux/constants/connectSupportSupportConstants';

const initialState = {
  connectSupportSupport: {},
  savingConnectSupportSupport: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_CONNECT_SUPPORT_SUPPORT: {
      return {
        ...state,
        connectSupportSupport: { ...action.payload },
      }
    }
    case SAVE_SAVING_CONNECT_SUPPORT_SUPPORT: {
      return {
        ...state,
        savingConnectSupportSupport: action.payload,
      }
    }
    default:
      return state;
  }
}

export default reducer;
