import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { 
  makeSelectLoginUser 
} from 'redux/selectors';
import { 
  CONNECT_SUPPORT_DONATE_PRODUCT,
} from 'utils/constanst/connectSupportConstants';
import { numberFormat } from 'utils/helper'

import { withStyles  } from '@material-ui/core/styles';
import ImageGallery from 'react-image-gallery';
import ShareIcon from '@material-ui/icons/Share';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { 
  Grid,
  Box,
  LinearProgress,
  Avatar,
  ButtonBase,
} from '@material-ui/core';
import NumberFormatCustom from 'components/NumberFormatCustom/NumberFormatCustom';
import StyledButton from 'components/Styled/Button/Button';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import Photo from 'components/Photo/Photo';

import moduleStyles from './DonateProductItem.module.scss';
import '../CardList.scss';
import stylesLayoutMainEcom from 'assets/styles/layouts/LayoutMainEcom.module.scss';

const StyledLinearProgress = withStyles((theme) => ({
  root: {
    height: 9,
    borderRadius: 4,
    // maxWidth: '66%',
  },
  colorPrimary: {
    backgroundColor: '#A0B9D2',
  },
  bar: {
    borderRadius: 4,
    backgroundColor: theme.palette.success.main,
  },
}))(LinearProgress);

class DonateProductItem extends PureComponent {
  constructor(props) {
    super(props);
    const quantity_number = props.quantity_number - props.quantity_number_assign;
    this.state = {
      quantity_number: quantity_number > 0 ? quantity_number : 1,
    }
  }

  updateQuantityNumberState = (value) => {
    this.setState({ quantity_number: Number(value) >= 1 ? Number(value) : 1 })
  }
  getLinearProgressValue = ({ value, max, min }) => {
    const MIN = min || 0;
    const result =  (value - MIN) * 100 / (max - MIN);
    return result > 100 ? 100 : result;
  }
  // goLink = (e,url) => {
  //   e.preventDefault();
  //   // console.log(e.target.classList)
  //   if ( !e.target.classList.contains('image-gallery-nav-icon') 
  //     && !e.target.classList.contains('image-gallery-bullet')
  //     && !e.target.classList.contains('image-gallery-bullets-container')
  //   ) this.props.history.push(url);
  // }
  openCharityRecipientListDialog = (e) => {
    if ( !e.target.classList.contains('image-gallery-nav-icon') 
      && !e.target.classList.contains('image-gallery-bullet')
      && !e.target.classList.contains('image-gallery-bullets-container')
    ) this.props.onSelectItem('OPEN_CHARITY_RECIPIENT_LIST_DIALOG');
  }
  stopGoToPage = (e) => {
    e.stopPropagation();
    e.preventDefault();
  }

  render() {
    const { user, t } = this.props;
    const quantityRemaining = this.props.quantity_number - this.props.quantity_number_assign > 0 ? this.props.quantity_number - this.props.quantity_number_assign : 0;
    const canTakeProduct = quantityRemaining > 0 && this.props.getcare_service_request_status?.id !== 2;
    const imageGalleryConfig = {
      showBullets: this.props.images.length > 1,
      showNav: true,
      showPlayButton: false,
      showFullscreenButton: false,
      items: this.props.images.length > 0 ? this.props.images.map( image => ({
        original: image,
        renderItem: (props) => {
          return <Photo className="image-gallery-image" src={props.original}/> 
        }
      })) : [{
        renderItem: (props) => {
          return <Photo className="image-gallery-image" src={props.original}/> 
        }
      }],
      renderLeftNav: (onClick, disabled) => (
        <ButtonBase className="image-gallery-nav-button image-gallery-nav-button-left" onClick={onClick} disabled={disabled}>
          <KeyboardArrowLeftIcon className="image-gallery-nav-icon"/>
        </ButtonBase>
      ),
      renderRightNav: (onClick, disabled) => (
        <ButtonBase className="image-gallery-nav-button image-gallery-nav-button-right" onClick={onClick} disabled={disabled}>
          <KeyboardArrowRightIcon className="image-gallery-nav-icon"/>
        </ButtonBase>
      )
    }
    
    return (
      <div 
        className={moduleStyles.Wrap}
        // onClick={(e) => this.goLink(e,`/community/connect-support/donate/${this.props.donate_id}/charity_recipients`)}
        onClick={this.openCharityRecipientListDialog} 
      >
        <div className={stylesLayoutMainEcom.MarginB__05}>
          <Grid container spacing={1}>
            <Grid item >
              <div className={`${moduleStyles.Image} custom-gallery`}>
                <ImageGallery 
                  {...imageGalleryConfig}
                />
              </div>
            </Grid>
            <Grid item xs>
              <Box 
                height="100%"
                display="flex"
                flexDirection="column"
                className={moduleStyles.Content}
              >
                <Box flexGrow="1" display="flex">
                  <Box flexGrow="1" position="relative" minHeight="107px">
                    <div className={moduleStyles.Info}>
                      <div className={moduleStyles.Info__Title}>{this.props.name}</div>
                      <div className={moduleStyles.Info__Description}>
                        <p><span>{t('Giá đưa ra')}:</span> <b className={moduleStyles.TextColorPrice}>{numberFormat(this.props.price_sales || 0)} VNĐ</b></p>
                        <p><span>{t('Đóng góp')}:</span> {this.props.quantity_number_assign }</p>
                        <p><span>{t('Số lượng đã lấy')}:</span> {this.props.quantity_number_assign}/{this.props.quantity_number}</p>
                        <StyledLinearProgress variant="determinate" value={this.getLinearProgressValue({ value: this.props.quantity_number_assign, max: this.props.quantity_number })} />
                        <p><span>{t('Số lượng còn')}:</span> <b>{quantityRemaining}</b></p>
                        {/* <p><span>Nhà sản xuất:</span> <b>{this.props.manufacturer_name}</b></p> */}
                      </div>
                    </div>
                  </Box>
                  <Box className={moduleStyles.Controls} display="flex" flexDirection="column">
                    <ButtonBase onClick={(e) => {
                      alert(t(`Tính năng này sẽ phát triển trong tương lại bạn nhé :)`,{ nsSeparator: '::' }))
                      this.stopGoToPage(e)
                    }}>
                      <ShareIcon/>
                    </ButtonBase>
                    {
                      user?.id && 
                      <ButtonBase onClick={(e) => {
                        this.stopGoToPage(e)
                        this.props.onSelectItem('FAVOURITE',{ 
                        favourite: !this.props.favourite, 
                        name: CONNECT_SUPPORT_DONATE_PRODUCT,
                        request_key: 'object_id',
                        request_value_key: 'donate_id',
                      })}
                      }>
                        {
                          this.props.favourite ? <FavoriteIcon/> : <FavoriteBorderIcon/>
                        }
                      </ButtonBase>
                    }
                  </Box>
                </Box>
                <Box className={moduleStyles.Actions} display="flex" justifyContent="flex-end">
                  <StyledButton onClick={(e) => {
                    this.stopGoToPage(e)
                    alert(t(`Tính năng này sẽ phát triển trong tương lại bạn nhé :)`,{ nsSeparator: '::' }))
                  }} variant="contained" color="secondary">
                    {t('Nhắn tin')}
                  </StyledButton>
                  <StyledButton variant="contained" disabled={!canTakeProduct} onClick={(e) => {
                    if ( !canTakeProduct ) return;
                    this.stopGoToPage(e)
                    this.props.onSelectItem('SUPPORT_NEW_FROM_DONATE',{ quantity_number: this.state.quantity_number })
                  }} color="primary">
                    {t('Đồng ý lấy')}
                  </StyledButton>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </div>
        <Box display="flex" style={{ gap: '.5rem'}}>
          <Box flexShrink={1} >
            <div className={moduleStyles.groupBtn}>
              <ButtonBase disabled={!canTakeProduct} onClick={(e) => {
                this.stopGoToPage(e)
                this.updateQuantityNumberState(this.state.quantity_number - 1 ) 
              }}>
                <RemoveIcon/>
              </ButtonBase>
              <Box className={moduleStyles.InputQuantity}>
                <NumberFormatCustom
                  style={{ width: `${ String(this.state.quantity_number).length >= 5 ? (String(this.state.quantity_number).length*4)+24 : String(this.state.quantity_number).length >= 3 ? (String(this.state.quantity_number).length*4)+24 : 24}px`}}
                  value={this.state.quantity_number}
                  onChange={(e) => {
                    this.updateQuantityNumberState(Number(e.target.value))
                  }}
                  onClick={(e) => {
                    this.stopGoToPage(e)
                  }}
                  disabled={!canTakeProduct}
                />
              </Box>
              <ButtonBase disabled={!canTakeProduct} onClick={(e) => {
                this.stopGoToPage(e)
                this.updateQuantityNumberState(this.state.quantity_number + 1 ) 
              }}>
                <AddIcon/>
              </ButtonBase>
            </div>
          </Box>
          <Box flexGrow={1}>
            <div className={moduleStyles.UserInfo}>
              <p>{t('interpolation.postDated',{
                defaultValue: `Đăng ngày {{date}} bởi {{name}}`, 
                date: moment(this.props.created_at).format('DD/MM/YYYY'),
                name: this.props.user_name,
              })}</p>
              <Avatar className={moduleStyles.UserInfo__avatar} alt={this.props.user_names} src={this.props.user_avatar}>
                {this.props.user_name ? this.props.user_name.charAt(0) : 'G'}
              </Avatar>
            </div>
          </Box>
        </Box>
      </div>
    );
  }
};

DonateProductItem.propTypes = {
  className: PropTypes.string,
};

DonateProductItem.defaultProps = {
  className: '',
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectLoginUser(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect, withTranslation(['viewMainEcomConnectSupport']))(DonateProductItem);
