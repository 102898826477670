import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { currencyFormatVN } from 'utils/helper';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { 
  makeSelectLoginUser 
} from 'redux/selectors';
import { isCollaborator } from 'utils/constanst/authConstants'
import { validNumber } from 'utils/helper'

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import QuantityInput from 'components/QuantityInput/QuantityInput';
import EmptySign from 'components/EmptySign/EmptySign';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import { withTranslation } from "react-i18next";
import Photo from 'components/Photo/Photo';

import classes from './FastOrderSelectedProduct.module.scss';
import productsGridClasses from '../FastOrderProductsToolbarGrid.module.scss';

class FastOrderSelectedProduct extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      quantityNumber: 1,
    }
  }

  _isOutOfStock = (remainingQty) => {
    return ![undefined, null].includes(remainingQty) && remainingQty <= 0;
  }

  handleQuantityInputChange = (e) => {
    this.setState({
      quantityNumber: e.target.value,
    });
  }
  handleAddProduct = () => {
    const { product } = this.props;
    const defaultVendor = (product?.getcare_vendors && product.getcare_vendors[0]) || null;
    const defaultUom = (defaultVendor?.uom && defaultVendor?.uom[0]) || null;
    this.props.onAddProductToCart({
      product: { ...product },
      vendor: defaultVendor ? { ...defaultVendor } : null,
      uom: defaultUom ? { ...defaultUom } : null,
      quantity: this.state.quantityNumber,
    });
  }

  render() {
    const { product , user, t} = this.props;
    const defaultVendor = (product.getcare_vendors && product.getcare_vendors.length && product.getcare_vendors[0]) || null;
    const defaultUom = defaultVendor?.uom && defaultVendor.uom.length && defaultVendor.uom[0];
    const isOutOfStock = this._isOutOfStock(defaultUom?.remaining_quantity);
    const priceSalesTotal = defaultUom?.price;
    const salesCommission = defaultUom?.sales_commission || 0; 
    const amountSalesCommission = defaultUom?.amount_sales_commission;

    const _isCollaborator = isCollaborator(user)

    return (<div className={`${productsGridClasses.ProductListRow} ${classes.ProductLine} ${isOutOfStock ? classes.ProductLineDisabled : ''}`}>
      <div
        onClick={isOutOfStock ? undefined : this.handleAddProduct}
        style={{cursor: isOutOfStock ? 'default' : 'pointer'}}
        className={`${productsGridClasses.ProductListCol} ${classes.ProductInfo}`}
      >
        <div className={classes.Thumb}>
        <Photo src={`${product.thumbnail}?size=120`}  alt={product.thumbnail} />
        </div>
        <div className={classes.Info}>
          <p>{product.name}</p>
          <p className={classes.SideInfo}>{product.getcare_manufacturer?.name} {`(${product.getcare_manufacturer?.getcare_country?.name})`}</p>
          { product.getcare_uom?.name &&
            <p className={classes.SideInfo}>
              <span className={classes.InfoValue}>{product.getcare_uom.name}</span>
            </p>
          }
        </div>
      </div>
      <div className={`${productsGridClasses.ProductListCol} ${classes.PriceWrap} TextCenter`}>
        {![null, undefined, ''].includes(priceSalesTotal)
          ? `${currencyFormatVN(priceSalesTotal)}/${defaultUom?.uom_base_name.toLowerCase()}`
          : <EmptySign/>
        }
      </div>
      <div className={`${productsGridClasses.ProductListCol}`}>
        {
          _isCollaborator ? (
          <Box textAlign="left" whiteSpace="nowrap">{validNumber(amountSalesCommission) && currencyFormatVN(amountSalesCommission)}</Box>
          ) : ( defaultUom?.type_label || <EmptySign/> )
        }
      </div>
      <div className={`${productsGridClasses.ProductListCol} ${classes.QuantityWrap}`}>
        <div className={classes.QuantityWrapInner}>
          <QuantityInput
            name="quantity_input"
            value={isOutOfStock ? 0 : this.state.quantityNumber}
            disabled={isOutOfStock}
            onChange={this.handleQuantityInputChange}
          />
          { isOutOfStock && <Tooltip
            title={t('Tạm ngừng cung cấp')}
            arrow
            placement="top"
          >
            <InfoIcon
              className={classes.MinQuantityIcon}
              fontSize="small"
            />
          </Tooltip> }
        </div>
        { isOutOfStock && <span className={classes.OutOfStockText}>{t('Tạm ngừng cung cấp')}</span> }
      </div>
      <div style={{paddingLeft: 0, paddingRight: 0}} className={`${productsGridClasses.ProductListCol}`}>
        <IconButton
          size="small"
          color="secondary"
          disabled={isOutOfStock}
          onClick={isOutOfStock? undefined : this.handleAddProduct}
        >
          <AddCircleIcon fontSize="large"/>
        </IconButton>
      </div>
    </div>);
  }
}

FastOrderSelectedProduct.propTypes = {
  product: PropTypes.object,
  onAddProductToCart: PropTypes.func,
};

FastOrderSelectedProduct.defaultProps = {
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectLoginUser(),
});
const withConnect = connect(mapStateToProps);
export default compose(withConnect, withTranslation())(FastOrderSelectedProduct);

