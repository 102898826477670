export const WEBSOCKET_ENDPOINT = `${process.env.REACT_APP_PATH_WS}ws`;
export const KEEP_CONNECT_MSG = 'ping';
export const KEEP_CONNECT_RES = 'pong';

export const FLUSH_CACHE_PROMOTION = 'flush_cache_promotion';
export const PMS_TENANT = 'pms_tenant';


export const EVENT_GETCARE_POLICY_PRICE = 'getcare_policy_price';
export const EVENT_GETCARE_VENDOR_PRICE = 'getcare_vendor_price';

