import React, { PureComponent } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import { withTranslation } from "react-i18next";

import { currencyFormatVN } from 'utils/helper'
import supportApi from 'utils/api/ecom/serviceRequest/connectSupport/support/supportApi'

import { withStyles  } from '@material-ui/core/styles';
import { 
  Paper,
  Box,
  Avatar,
  Grid,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { ReactComponent as EmptyIcon } from 'assets/images/icons/Empty.svg';
  
import styles from './TabContributor.module.scss';

const StyledPaper = withStyles(theme => ({
  root: {
    padding: '1rem',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    display: 'flex',
    flexDirection: 'column',
    gap: '30px'
  }
}))(Paper);

class ProjectInfo extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loadingCharityGiverList: true,
      charityGiverList: []
    }
  }
  async componentDidMount() {
    this.fetchCharityGiverListData()
  }

  fetchCharityGiverListData = async () => {
    this.setState({ loadingCharityGiverList: true })
    const { data: response } = await supportApi.getCharityGiverList({ params: { project_id: this.props.values?.id }});
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    this.setState({
      loadingCharityGiverList: false,
      charityGiverList: response.data,
    })
  }

  render() {
    const { t } = this.props; 
    return (<>
      {
        !this.state.loadingCharityGiverList ? (
        this.state.charityGiverList.length > 0 ?
        <Grid container spacing={2}> {
          this.state.charityGiverList.map( (item,index) => (
            <Grid key={index} item xs={12} sm={4}>
              <StyledPaper>
                <Box display="flex" flexWrap="nowrap">
                  <Box marginRight="1rem">
                    <Avatar
                      className={styles.avatar} 
                      alt={item.name} 
                      src={item.avatar}
                    >
                      { item.name ? item.name.charAt(0) : 'G'}
                    </Avatar>
                  </Box>
                  <Box flexGrow="1" overflow="auto">
                    <Box display="flex" flexDirection="column">
                      <div className={styles.info}>
                        <p className={styles.name}>{item.name}</p>
                        <p className={styles.content}>
                          <span className={styles.category}>{t('Tên sản phẩm')}:</span>
                          {item.product_name}
                        </p>
                        <p className={styles.content}>
                          <span className={styles.category}>{t('Đã đóng góp')}:</span>
                          {item.quantity_number}
                        </p>
                        <p className={styles.content}>
                          <span className={styles.category}>{t('Ngày đóng góp')}:</span>
                          {moment(item.created_at).format('DD/MM/YYYY')}
                        </p>
                        <p className={styles.content}>
                          <span className={styles.category}>{t('Giá hỗ trợ')}:</span> 
                          <span className={styles.price}>{currencyFormatVN(item.price_sales)}</span>/
                          {item.uom_base_name}
                        </p>
                      </div>
                    </Box>
                  </Box>
                </Box>
              </StyledPaper>
            </Grid>
          ))}
        </Grid> 
        : <div className={styles.empty}>
          <EmptyIcon className={styles.emptyIcon}/>
          <p className={styles.emptyContent}>{t('Chưa có ai đóng góp')}</p>
        </div> ) :
        <Grid container spacing={2}> {
          [...Array(3).keys()].map( (key) => (
            <Grid key={key} item xs={12} sm={4}>
              <StyledPaper>
                <Grid container spacing={1}>
                  <Grid item >
                    <Skeleton variant="circle" width={70} height={70}/>
                  </Grid>
                  <Grid item xs>
                    <Skeleton/>
                    <Skeleton/>
                    <Skeleton/>
                    <Skeleton/>
                  </Grid>
                </Grid>
              </StyledPaper>
            </Grid>
          ))}
        </Grid>
      }
    </>);
  }
};

export default withTranslation(['viewMainEcomConnectSupportProjectDetail'],{ withRef: true })(ProjectInfo);
