import  { PureComponent } from 'react';

import locationApi from 'utils/api/locationApi';

import { isFunction } from 'lodash';

class Location extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      provinceList: [],
      districtList: [],
      wardList: [],
      loadingProvinceList: false,
      loadingDistrictList: false,
      loadingWardList: false,
    }
  }
  componentDidMount() {
    const { 
      provinceId,
      districtId,
    } = this.props;
    this.getProvinceList({
      params: { name: '', getcare_country_id: 220 }
    });
    if ( provinceId ) {
      this.getDistrictList({
        params: { name: '', getcare_province_id: provinceId }
      });
    }
    if ( districtId ) {
      this.getWardList({
        params: { name: '', getcare_district_id: districtId }
      });
    }
  }
  componentDidUpdate(prevProps) {
    const { 
      provinceId,
      districtId,
    } = this.props;
    if ( provinceId !== prevProps.provinceId) {
      this.handleChangeProvinceId(provinceId);
    }
    if ( districtId !== prevProps.districtId) {
      this.handleChangeDistrictId(districtId);
    }
  }
  
  getProvince = (provinceId) => {
    return this.state.provinceList.find( item => item.id === provinceId );
  }
  getDistrict = (districtId) => {
    return this.state.districtList.find( item => item.id === districtId );
  }
  getWard = (wardId) => {
    return this.state.wardList.find( item => item.id === wardId );
  }
  getProvinceList = async ({ params }) => {
    this.setState({ loadingProvinceList: true, provinceList: [] })
    const { data: response } = await locationApi.getProvinces(params);
    if (!response?.result || !response.data) {
      return;
    }
    this.setState({ loadingProvinceList: false, provinceList: response.data || [] })
  }
  getDistrictList = async ({ params }) => {
    this.setState({ loadingDistrictList: true, districtList: [] })
    const { data: response } = await locationApi.getDistricts(params);
    if (!response?.result || !response.data) {
      return;
    }
    this.setState({ loadingDistrictList: false, districtList: response.data || [] })
  }
  getWardList = async ({ params }) => {
    this.setState({ loadingWardList: true, wardList: [] })
    const { data: response } = await locationApi.getWards(params);
    if (!response?.result || !response.data) {
      return;
    }
    this.setState({ loadingWardList: false, wardList: response.data || [] })
  }
  renderChildren = () => {
    const props = {
      ...this.state,
      getProvinceList: this.getProvinceList,
      getDistrictList: this.getDistrictList,
      getWardList: this.getWardList,
      getProvince: this.getProvince,
      getDistrict: this.getDistrict,
      getWard: this.getWard,
    };
    if ( Array.isArray(this.props.children) ) {
      return this.props.children.map( child => {
        if ( isFunction(child) ) return child(props);
        return child
      })
    }
    return this.props.children(props);
  }

  handleChangeProvinceId = async (provinceId) => {
    this.setState({ districtList: [], wardList: [] })
    this.getDistrictList({ params: { name: '', getcare_province_id: provinceId }});
  }
  handleChangeDistrictId = async (districtId) => {
    this.setState({ wardList: [] })
    this.getWardList({ params: { name: '', getcare_district_id: districtId }});
  }

  render() {
    return this.renderChildren();
  }
};

export default Location;
