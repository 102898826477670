import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from "react-i18next";
import { withStyles  } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { 
  makeSelectLoginUser 
} from 'redux/selectors';
import { 
  CONNECT_SUPPORT_PRODUCT,
} from 'utils/constanst/connectSupportConstants';

import ImageGallery from 'react-image-gallery';
import ShareIcon from '@material-ui/icons/Share';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { 
  Grid,
  Box,
  LinearProgress,
  ButtonBase,
} from '@material-ui/core';
import StyledButton from 'components/Styled/Button/Button';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import Photo from 'components/Photo/Photo';

import moduleStyles from './ProductItem.module.scss';
import '../CardList.scss';

const StyledLinearProgress = withStyles((theme) => ({
  root: {
    height: 9,
    borderRadius: 4,
    maxWidth: '66%',
  },
  colorPrimary: {
    backgroundColor: '#A0B9D2',
  },
  bar: {
    borderRadius: 4,
    backgroundColor: theme.palette.success.main,
  },
}))(LinearProgress);

class CardListProductItem extends PureComponent {

  getLinearProgressValue = ({ value, max, min }) => {
    const MIN = min || 0;
    const result =  (value - MIN) * 100 / (max - MIN);
    return result > 100 ? 100 : result;
  }

  render() {
    const { user, t } = this.props;
    const imageGalleryConfig = {
      showBullets: this.props.images.length > 1,
      showNav: true,
      showPlayButton: false,
      showFullscreenButton: false,
      items: this.props.images.length > 0 ? this.props.images.map( image => ({
        original: image,
        renderItem: (props) => {
          return <Photo className="image-gallery-image" src={props.original}/>
        }
      })) : [{
        renderItem: (props) => {
          return <Photo className="image-gallery-image"/>
        }
      }],
      renderLeftNav: (onClick, disabled) => (
        <ButtonBase className="image-gallery-nav-button image-gallery-nav-button-left" onClick={onClick} disabled={disabled}>
          <KeyboardArrowLeftIcon className="image-gallery-nav-icon"/>
        </ButtonBase>
      ),
      renderRightNav: (onClick, disabled) => (
        <ButtonBase className="image-gallery-nav-button image-gallery-nav-button-right" onClick={onClick} disabled={disabled}>
          <KeyboardArrowRightIcon className="image-gallery-nav-icon"/>
        </ButtonBase>
      )
    }
    
    return (
      <div>
        <Grid container spacing={1}>
          <Grid item >
            <div className={`${moduleStyles.Image} custom-gallery`}>
              <ImageGallery 
                {...imageGalleryConfig}
              />
              {/* <img src="https://vinmec-prod.s3.amazonaws.com/images/20200110_084244_950156_thuoc.max-800x800.png"/> */}
            </div>
          </Grid>
          <Grid item xs>
            <Box 
              height="100%"
              display="flex"
              flexDirection="column"
              className={moduleStyles.Content}
            >
              <Box flexGrow="1" display="flex">
                <Box flexGrow="1" position="relative" minHeight="107px">
                  <div className={moduleStyles.Info}>
                    <div className={moduleStyles.Info__Title}>{this.props.name}</div>
                    <div className={moduleStyles.Info__Description}>
                      <p><span>{t('Danh mục')}:</span> {this.props.category_ecom_name}</p>
                      <p><span>{t('Số người cần')}:</span> {this.props.count}</p>
                      <p><span>{t('Số lượng cần')}:</span> {this.props.quantity_number}</p>
                      <p><span>{t('Số lượng đóng góp')}:</span> <b>{this.props.quantity_number_donate}/{this.props.quantity_number}</b></p>
                    </div>
                    <StyledLinearProgress variant="determinate" value={this.getLinearProgressValue({ value: this.props.quantity_number_donate, max: this.props.quantity_number })}/>
                  </div>
                </Box>
                <Box className={moduleStyles.Controls} display="flex" flexDirection="column">
                  <ButtonBase>
                    <ShareIcon/>
                  </ButtonBase>
                  {
                    user?.id &&
                    <ButtonBase onClick={() => this.props.onSelectItem('FAVOURITE',{ 
                      favourite: !this.props.favourite, 
                      name: CONNECT_SUPPORT_PRODUCT,
                      request_key: 'object_id',
                      request_value_key: 'item_id',
                    })}>
                      {
                        this.props.favourite ? <FavoriteIcon/> : <FavoriteBorderIcon/>
                      }
                    </ButtonBase>
                  }
                </Box>
              </Box>
              <Box className={moduleStyles.Actions} display="flex" justifyContent="flex-end">
                <StyledButton onClick={() => this.props.onSelectItem('SUPPORT_NEW_FROM_PRODUCT')} variant="contained" color="secondary">
                  {t('Tôi cũng cần')}
                </StyledButton>
                <StyledButton variant="contained" onClick={() => this.props.onSelectItem('PRODUCT_DONATE_NEW')} color="primary">
                  {t('Đóng góp')}
                </StyledButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </div>
    );
  }
};

CardListProductItem.propTypes = {
  className: PropTypes.string,
};

CardListProductItem.defaultProps = {
  className: '',
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectLoginUser(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect, withTranslation(['viewMainEcomConnectSupport']))(CardListProductItem);
