import axiosAuth from 'utils/axios/axiosAuth';

export default {
  getCommentList: ({ params, cancelToken }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`ecom/comment`, {
      params: {
        ...queryParams,
      },
      cancelToken,
    });
  },
  createOrUpdateComment: ({ params }) => {
    return axiosAuth.post(`ecom/comment`, params);
  },
  getCommentMedia: () => {
    return axiosAuth.get(`ecom/comment_media`);
  },
  getCommentType: () => {
    return axiosAuth.get(`ecom/comment_type`);
  },
};
