import {
  SET_STATE_CATEGORY,
  GET_CATEGORY_LIST,
} from 'redux/constants/categoryConstants';

// reducer
export function setStateCategory(payload) {
  return {
    type: SET_STATE_CATEGORY,
    payload,
  };
}

// saga
export function getCategoryList(payload) {
  return {
    type: GET_CATEGORY_LIST,
    payload,
  };
}