import {
  SAVE_CONNECT_SUPPORT_SUPPORT,
  SAVE_SAVING_CONNECT_SUPPORT_SUPPORT,
} from 'redux/constants/connectSupportSupportConstants';

export function saveConnectSupportSupport(payload) {
  return {
    type: SAVE_CONNECT_SUPPORT_SUPPORT,
    payload
  };
}

export function saveSavingConnectSupportSupport(payload) {
  return {
    type: SAVE_SAVING_CONNECT_SUPPORT_SUPPORT,
    payload
  };
}
