import axiosAuth from 'utils/axios/axiosAuth';

export default {
  // getProductList: ({ params, cancelToken }) => {
  //   const {
  //     _product_list_by,
  //     ...otherParams
  //   } = params;
  //   const newParams = {...otherParams };
  //   const url = `mobile/`;
  //   switch (_product_list_by) {
  //     case PRODUCT_LIST_BY_BESTSELLER:
  //       url
  //       break;
    
  //     default:
  //       break;
  //   }
  //   const queryParams = Object.keys(newParams).reduce((memo, key) => {
  //     if (!!params[key]) memo[key] = params[key];
  //     return memo;
  //   }, {});
  //   return axiosAuth.get(url, {
  //     params: {
  //       ...queryParams,
  //     },
  //     cancelToken
  //   });
  // },
  fetchProductListByHotPromotion: ({ params, cancelToken }) => {
    return axiosAuth.get(`mobile/product_promotion`, {
      params,
      cancelToken
    });
  },
  fetchProductListByTopSearch: ({ params, cancelToken }) => {
    return axiosAuth.get(`mobile/product_much_search`, {
      params,
      cancelToken
    });
  },
  fetchProductListByBestseller: ({ params, cancelToken }) => {
    return axiosAuth.get(`mobile/product_selling`, {
      params,
      cancelToken
    });
  },
  fetchProductListByNewArrival: ({ params, cancelToken }) => {
    return axiosAuth.get(`mobile/product_new`, {
      params,
      cancelToken
    });
  },
  fetchProductListBySuggest: ({ params, cancelToken }) => {
    return axiosAuth.get(`mobile/product_suggest`, {
      params,
      cancelToken
    });
  },
  fetchProductDetails: ({ params: { product_id,...otherParams }, cancelToken }) => {
    const queryParams = Object.keys(otherParams).reduce((memo, key) => {
      if (!!otherParams[key]) memo[key] = otherParams[key];
      return memo;
    }, {});
    return axiosAuth.get(`mobile/product/${product_id}`, {
      params: {
        ...queryParams
      },
      cancelToken
    });
  },
  fetchProductRatingDetail: ({ params: { product_id,...otherParams }, cancelToken }) => {
    const queryParams = Object.keys(otherParams).reduce((memo, key) => {
      if (!!otherParams[key]) memo[key] = otherParams[key];
      return memo;
    }, {});
    return axiosAuth.get(`mobile/product_rating_detail/${product_id}`, {
      params: {
        ...queryParams
      },
      cancelToken
    });
  },
  fetchProductRatings: ({ params: { product_id,...otherParams }, cancelToken }) => {
    const queryParams = Object.keys(otherParams).reduce((memo, key) => {
      if (!!otherParams[key]) memo[key] = otherParams[key];
      return memo;
    }, {});
    return axiosAuth.get(`mobile/product_rating/${product_id}`, {
      params: {
        ...queryParams
      },
      cancelToken
    });
  },
  fetchProductDescriptions: ({ params: { product_id,...otherParams }, cancelToken }) => {
    const queryParams = Object.keys(otherParams).reduce((memo, key) => {
      if (!!otherParams[key]) memo[key] = otherParams[key];
      return memo;
    }, {});
    return axiosAuth.get(`mobile/product_description/${product_id}`, {
      params: {
        ...queryParams
      },
      cancelToken
    });
  },
  fetchProductPriceWholesales: ({ params: { product_id,...otherParams }, cancelToken }) => {
    const queryParams = Object.keys(otherParams).reduce((memo, key) => {
      if (!!otherParams[key]) memo[key] = otherParams[key];
      return memo;
    }, {});
    return axiosAuth.get(`mobile/product_price_wholesale/${product_id}`, {
      params: {
        ...queryParams
      },
      cancelToken
    });
  },
  fetchProductRelatedList: ({ params: { product_id,...otherParams }, cancelToken }) => {
    const queryParams = Object.keys(otherParams).reduce((memo, key) => {
      if (!!otherParams[key]) memo[key] = otherParams[key];
      return memo;
    }, {});
    return axiosAuth.get(`mobile/product_related/${product_id}`, {
      params: {
        ...queryParams
      },
      cancelToken
    });
  },
};
