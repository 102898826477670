import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { dateTimeFormat } from 'utils/constanst/dateConstants';
import { validDate } from 'utils/helper';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectSalesOrderNotes, makeSelectLoginUser } from 'redux/selectors';
import { orderBy } from 'lodash';
import { withTranslation } from "react-i18next";
import salesOrderApi from 'utils/api/oms/salesOrderApi';
import { toast } from 'react-toastify';

import classes from './SalesOrderNotes.module.scss';

class SalesOrderNotes extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      openNoteFormDialog: false,
      selectedNoteItem: null,

      isConfirmDialogOpen: false,
      isDialogLoading: false,
    }
  }
  _getOrderedSalesOrderNotes = () => {
    return orderBy(this.props.salesOrderNotes, 'created_at', 'desc');
  }
  _hasUpdateNotePermission = (userId) => {
    return this.props.user?.id === userId;
  }

  handleEditNoteItem = (noteItem) => {
    this.setState({
      selectedNoteItem: { ...noteItem },
    }, () => {
      this.handleOpenNoteFormDialog();
    });
  }
  handleOpenNoteFormDialog = () => {
    this.setState({
      openNoteFormDialog: true,
    });
  }
  handleCloseNoteFormDialog = () => {
    this.setState({
      openNoteFormDialog: false,
      selectedNoteItem: null,
    });
  }
  handleSubmitNoteSuccess = (newNoteItem) => {
    this.props.saveSalesOrderNoteItem(newNoteItem);
  }

  handleRemoveNoteItem = (noteItem) => {
    this.setState({
      selectedNoteItem: { ...noteItem },
    }, () => {
      this.handleConfirmDialogOpen();
    });
  }
  handleConfirmDialogOpen = () => {
    this.setState({
      isConfirmDialogOpen: true,
    });
  }
  handleConfirmDialogClose = () => {
    this.setState({
      isConfirmDialogOpen: false,
      selectedNoteItem: null,
    });
  }
  handleSubmitRemoveNoteItem = async () => {
    const { selectedNoteItem } = this.state;
    this.setState({
      isDialogLoading: true,
    });
    const { data: response } = await salesOrderApi.deleteSalesOrderNote({
      id: selectedNoteItem?.id,
    });
    if (!response.result) {
      toast.error(response.message);
      return;
    }
    this.setState({
      isDialogLoading: false,
    }, () => {
      this.handleRemoveNoteSuccess(selectedNoteItem);
      this.handleConfirmDialogClose();
    });
  }
  handleRemoveNoteSuccess = (noteItem) => {
    this.props.removeSalesOrderNoteItem(noteItem);
  }

  render() {
    const { t } = this.props;
    const orderedNotes = this._getOrderedSalesOrderNotes();

    return (<div className={`${classes.Wrap}`}>
      <h4 className={classes.SectionTitle}>{t('Ghi chú')}</h4>
      <div className={classes.Block}>
        { orderedNotes && orderedNotes.length > 0 ? (<>
            { orderedNotes.map(note => (
              <div key={`note-${note.id}`} className={classes.NoteItem}>
                <div className={classes.NoteInfo}>
                  <div>
                    <span className={`${classes.NoteOwner}`}>{note.user_name}</span>
                    <span className={classes.NoteDate}>{validDate(note.created_at) ? format(validDate(note.created_at), dateTimeFormat) : ''}</span>
                  </div>
                </div>
                <p className={`${classes.NoteContent} PreWrap`}>{note.note}</p>
              </div>
            )) }
          </>) : <p className={`${classes.NoData} NoData TextLeft`}>{t('Không có ghi chú nào')}</p>
        }
      </div>
    </div>)
  }
}

SalesOrderNotes.propTypes = {
  salesOrderNotes: PropTypes.array,
};

SalesOrderNotes.defaultProps = {
  salesOrderNotes: [],
};

const mapStateToProps = createStructuredSelector({
  salesOrderNotes: makeSelectSalesOrderNotes(),
  user: makeSelectLoginUser(),
});
const withConnect = connect(mapStateToProps, null);
export default withTranslation()(compose(withConnect)(SalesOrderNotes));
