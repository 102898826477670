import React, { PureComponent } from 'react';
import { currencyFormatVN } from 'utils/helper';
import gridClasses from '../FastOrderProductsGrid.module.scss';
import { withTranslation } from "react-i18next";

class FastOrderShippingFeeByVendor extends PureComponent {
  render() {
    const { amount, isLoading,t } = this.props;

    return (
      <div className={`${gridClasses.Row} ${gridClasses.DiscountRow}`}>
      <div className={gridClasses.GroupCol}>
          <div className={`${gridClasses.Col}`}>
            {t('Phí vận chuyển')}
          </div>
          <div className={`${gridClasses.Col} TextRight ${isLoading && "OverlayLoading"}`}>
            { !isLoading && currencyFormatVN(amount) }
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(FastOrderShippingFeeByVendor);
