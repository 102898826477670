import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { 
  ButtonBase,
} from '@material-ui/core';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import Photo from 'components/Photo/Photo';

import ImageGallery from 'react-image-gallery';
import './ProjectImages.scss';

class ProjectImages extends PureComponent {
  
  render() {
    const images = this.props.values?.images || [];
    const imageGalleryConfig = {
      showBullets: false,
      showNav: true,
      showPlayButton: false,
      showFullscreenButton: images.length > 0,
      items: images?.length && images.length > 0 ? images.map( image => ({
        original: image,
        thumbnail: image,
        renderItem: (props) => {
          return <Photo className="image-gallery-image"  src={props.original}/>
        }
      })) : [{
        renderItem: (props) => {
          return <Photo className="image-gallery-image"/>
        }
      }],
      renderLeftNav: (onClick, disabled) => (
        <ButtonBase className="image-gallery-nav-button image-gallery-nav-button-left" onClick={onClick} disabled={disabled}>
          <KeyboardArrowLeftIcon className="image-gallery-nav-icon"/>
        </ButtonBase>
      ),
      renderRightNav: (onClick, disabled) => (
        <ButtonBase className="image-gallery-nav-button image-gallery-nav-button-right" onClick={onClick} disabled={disabled}>
          <KeyboardArrowRightIcon className="image-gallery-nav-icon"/>
        </ButtonBase>
      )
    }

    return (
      <div className="connect-support-project-images">
        {
          <ImageGallery 
            {...imageGalleryConfig}
          />
        }
      </div>
    );
  }
};

ProjectImages.propTypes = {
  values: PropTypes.object,
};

ProjectImages.defaultProps = {
  values: {
    images: [],
  },
};

export default ProjectImages;
