import React from 'react';
import { withStyles  } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';

const styles = (theme) => {
  return {
    root: {
      '& .MuiAutocomplete-inputRoot': {
        padding: `0 14px !important`,
      },
      '& .MuiAutocomplete-input': {
        paddingTop: `15px !important`,
        paddingBottom: `15px !important`,
      }
    },
  }
};

export default withStyles(styles)(
  React.forwardRef( ({ classes, innerRef, color, ...other }, ref) =>
    <Autocomplete ref={ref} className={classes.root} {...other} />
  )
);