import fastOrderApi from 'utils/api/ecom/fastOrderApi';
import { put } from 'redux-saga/effects';
import { fastOrderActions } from 'redux/actions';
import { logisticActions } from 'redux/actions';
import salesOrderApi from 'utils/api/oms/salesOrderApi';

import { toast } from 'react-toastify';

export function* loadPRDraft(payload) {
  const { 
    params,
    resolve = () => {}, 
    reject = () => {},
  } = payload.payload;

  try {
    yield put(fastOrderActions.saveLoading(true));
    yield put(fastOrderActions.savePRDetails(null));
    yield put(fastOrderActions.saveCouponItems([]));
    yield put(fastOrderActions.savePRDraftBonuses([]));
    yield put(fastOrderActions.savePRDraftDiscounts([]));
    yield put(fastOrderActions.savePRDraftNotes([]));
    yield put(fastOrderActions.savePRDraftLogistic(null));
    yield put(fastOrderActions.savePRDraftDelivery(null));
    yield put(fastOrderActions.savePRProducts([]));
    if (params.so_code) {
      const { data: response } = yield salesOrderApi.get(params.so_code);
      yield put(fastOrderActions.saveLoading(false));

      if (!response?.result || !response?.data) {
        reject(response);
        return;
      }

      yield put(
        fastOrderActions.savePRDetails({
          ...response.data,
          getcare_purchase_request_items: undefined,
          getcare_purchase_request_bonuss: undefined,
          getcare_purchase_request_discounts: undefined,
          getcare_purchase_request_notes: undefined,
        })
      );
      yield put(fastOrderActions.saveCouponItems(response.data.getcare_vouchers || []));
      yield put(logisticActions.saveSelectedPayment(response.data.getcare_payment_method_id || 1));
      yield put(fastOrderActions.savePRDraftBonuses(response.data.getcare_purchase_request_bonuss || []));
      yield put(fastOrderActions.savePRDraftDiscounts(response.data.getcare_purchase_request_discounts || []));
      yield put(fastOrderActions.savePRDraftNotes(response.data.getcare_purchase_request_notes || []));
      yield put(fastOrderActions.savePRDraftLogistic(response.data.logistic_provider || null));
      yield put(fastOrderActions.savePRDraftDelivery(response.data.customer_delivery || null));
      yield put(fastOrderActions.savePRProducts(response.data.getcare_order_items || []));
      resolve(response);
    }else{
      
      const { data: response } = yield fastOrderApi.getPurchaseRequestDraft({
        params,
      });
      yield put(fastOrderActions.saveLoading(false));

      if (!response?.result || !response?.data) {
        reject(response);
        return;
      }
      yield put(
        fastOrderActions.savePRDetails({
          ...response.data,
          getcare_purchase_request_items: undefined,
          getcare_purchase_request_bonuss: undefined,
          getcare_purchase_request_discounts: undefined,
          getcare_purchase_request_notes: undefined,
        })
      );

      yield put(fastOrderActions.saveCouponItems(response.data.getcare_vouchers || []));
      yield put(logisticActions.saveSelectedPayment(response.data.getcare_payment_method_id || 1));
      yield put(fastOrderActions.savePRDraftBonuses(response.data.getcare_purchase_request_bonuss || []));
      yield put(fastOrderActions.savePRDraftDiscounts(response.data.getcare_purchase_request_discounts || []));
      yield put(fastOrderActions.savePRDraftNotes(response.data.getcare_purchase_request_notes || []));
      yield put(fastOrderActions.savePRDraftLogistic(response.data.logistic_provider || null));
      yield put(fastOrderActions.savePRDraftDelivery(response.data.customer_delivery || null));
      yield put(fastOrderActions.savePRProducts(response.data.getcare_purchase_request_items || []));
      resolve(response.data);
    }
  } catch (err) {
    yield put(fastOrderActions.saveLoading(false));
    toast.error(JSON.stringify(err));
    reject(err);
  }
}
export function* updatePRDraft(payload) {
  const { 
    params, cancelToken, isCharity, isWholesale, orderType,
    resolve = () => {}, 
    reject = () => {},
  } = payload.payload;
  try {
    yield put(
      fastOrderActions.saveLoading(params.willSaveLoading ? true : false)
    );

    const { data: response } = yield fastOrderApi.updatePurchaseRequestDraft({
      params: {
        ...params,
        is_charity: isCharity ? 1 : undefined,
        is_wholesale: isWholesale ? 1 : undefined,
      },
      cancelToken,
      orderType,
    });

    yield put(fastOrderActions.saveLoading(false));
        
    if (!response?.result || !response?.data) {
      toast.error(response.message);
      return;
    }

    if (params.willUpdatePRDetails) {
      yield put(
        fastOrderActions.savePRDetails({
          ...response.data,
          amount_sales_commission: undefined,
          getcare_purchase_request_bonuss: undefined,
          getcare_purchase_request_discounts: undefined,
          getcare_purchase_request_notes: undefined,
        })
      );
      yield put(logisticActions.saveSelectedPayment(response.data.getcare_payment_method_id || 1));
      yield put(fastOrderActions.savePRDraftBonuses(response.data.getcare_purchase_request_bonuss || []));
      yield put(fastOrderActions.savePRDraftDiscounts(response.data.getcare_purchase_request_discounts || []));
      yield put(fastOrderActions.savePRDraftNotes(response.data.getcare_purchase_request_notes || []));
      yield put(fastOrderActions.savePRDraftLogistic(response.data.logistic_provider || null));
      yield put(fastOrderActions.savePRDraftDelivery(response.data.customer_delivery || null));
      yield put(fastOrderActions.savePRProducts(response.data.getcare_purchase_request_items || []));
    }
    resolve(response.data)
  } catch (err) {
    yield put(fastOrderActions.saveLoading(false));
    if (JSON.stringify(err)) {
      toast.error(JSON.stringify(err));
    }
  }
}

export function* createPR(payload) {
  const { params, isCharity } = payload.payload;
  try {
    yield put(fastOrderActions.saveLoading(true));

    const { data: response } = yield fastOrderApi.createPurchaseRequest({
      params,
      isCharity,
    });

    yield put(fastOrderActions.saveLoading(false));
    if (!response?.result || !response?.data) {
      toast.error(response.message);
      return;
    }
    yield put(
      fastOrderActions.savePRDetails({
        ...response.data,
        getcare_purchase_request_items: undefined,
        getcare_purchase_request_bonuss: undefined,
        getcare_purchase_request_discounts: undefined,
        getcare_purchase_request_notes: undefined,
      })
    );
    yield put(
      fastOrderActions.saveCouponItems(response.data.getcare_vouchers || [])
    );
    yield put(
      fastOrderActions.savePRDraftBonuses(
        response.data.getcare_purchase_request_bonuss || []
      )
    );
    yield put(
      fastOrderActions.savePRDraftDiscounts(
        response.data.getcare_purchase_request_discounts || []
      )
    );
    yield put(
      fastOrderActions.savePRDraftNotes(
        response.data.getcare_purchase_request_notes || []
      )
    );
    yield put(
      fastOrderActions.savePRDraftLogistic(
        response.data.logistic_provider || null
      )
    );
    yield put(
      fastOrderActions.savePRDraftDelivery(
        response.data.customer_delivery || null
      )
    );
    yield put(
      fastOrderActions.savePRProducts(
        response.data.getcare_purchase_request_items || []
      )
    );
    yield put(fastOrderActions.saveLoading(false));
  } catch (err) {
    yield put(fastOrderActions.saveLoading(false));
    toast.error(JSON.stringify(err));
  }
}

export function* testPromotion(payload) {
  const { params } = payload.payload;
  try {
    yield put(fastOrderActions.saveActionLoading(true));

    const { data: response } = yield fastOrderApi.checkPromotion({ params });
    yield put(fastOrderActions.saveActionLoading(false));
    if (!response?.result || !response?.data) {
      toast.error(response.message);
      return;
    }
    yield put(
      fastOrderActions.savePromotionAvailables(
        response.data.promotion_availables || []
      )
    );
    yield put(
      fastOrderActions.savePromotionActives(
        response.data.promotion_actives || []
      )
    );
  } catch (err) {
    yield put(fastOrderActions.saveActionLoading(false));
    toast.error(JSON.stringify(err));
  }
}

export function* rateOrdering(payload) {
  const { params } = payload.payload;
  try {
    yield put(fastOrderActions.saveActionLoading(true));

    const { data: response } = yield fastOrderApi.rateOrdering({ params });
    yield put(fastOrderActions.saveActionLoading(false));
    if (!response?.result || !response?.data) {
      toast.error(response.message);
      return;
    }

    // clear all pr data
    yield put(fastOrderActions.saveCouponItems([]));
    yield put(fastOrderActions.savePRDetails(null));
    yield put(fastOrderActions.savePRDraftBonuses([]));
    yield put(fastOrderActions.savePRDraftDiscounts([]));
    yield put(fastOrderActions.savePRDraftNotes([]));
    yield put(fastOrderActions.savePRDraftLogistic(null));
    yield put(fastOrderActions.savePRDraftDelivery(null));
    yield put(fastOrderActions.savePRProducts([]));
  } catch (err) {
    yield put(fastOrderActions.saveActionLoading(false));
    toast.error(JSON.stringify(err));
  }
}
