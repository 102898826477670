const styles = (theme) => {
  return {
    codeInput: {
      "& input": {
        textAlign: "center",
      },
      "& .MuiFormHelperText-root": {
        textAlign: "center",
      }
    },
    btnResend: {
      marginLeft: theme.spacing(1)
    },
    dialogActions: {
      justifyContent: "center",
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    errorMessage: {
      textAlign: "center",
    },
    successIcon: {
      color: theme.palette.success.main,
      fontSize: 80,
    },
    errorIcon: {
      color: theme.palette.error.main,
      fontSize: 80,
    },  
    checkValidPhoneColor: {
      color: theme.palette.primary.main
    }
  }
}

export default styles;