import React, { PureComponent } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import axios from 'axios';
import { debounce } from 'lodash';
import { withTranslation } from "react-i18next";

import supportProjectApi from 'utils/api/ecom/serviceRequest/connectSupport/support/projectApi';
import ecomCommonApi from 'utils/api/ecom/commonApi';
import { validNumber } from 'utils/helper';

import { 
  Grid,
  Box,
  CircularProgress,
} from '@material-ui/core';

import StyledTextField from 'components/Styled/TextField';
import ContributionProductInfo from './ContributionProductInfo/ContributionProductInfo';
import StyledAutocomplete from 'components/Styled/Autocomplete/Autocomplete';
import UploadImage from './UploadImage/UploadImage';

import styles from './ConnectSupportDonateForm.module.scss'
import stylesLayoutMainEcom from 'assets/styles/layouts/LayoutMainEcom.module.scss';

const PROJECT_TYPE = 'project';
const NPO_TYPE = 'npo';
const mapOptions = (data,{ labelKey, valueKey }) => data.map( item => ({
  label: item[labelKey],
  value: item[valueKey],
  data: item,
}))
const typeConfig = {
  [PROJECT_TYPE]: {
    fetchData: supportProjectApi.getList,
    labelKey: 'name',
    valueKey: 'id',
    idKey: 'project_id',
  },
  [NPO_TYPE]: {
    fetchData: ecomCommonApi.getNPOList,
    labelKey: 'name',
    valueKey: 'id',
    idKey: 'npo_id',
  },
}

class ConnectSupportDonateForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loadingOptions: {
        [PROJECT_TYPE]: false,
        [NPO_TYPE]: false,
      },
      options: {
        [PROJECT_TYPE]: [],
        [NPO_TYPE]: [],
      },
      selectedValues: {
        [PROJECT_TYPE]: '',
        [NPO_TYPE]: '',
      },
      // projectOptions: [],
      // loadingProjectOptions: false,
      // projectValue: null,
    }
    this.source = {
      [PROJECT_TYPE]: null,
      [NPO_TYPE]: null,
    }
    this.sourceProject = null;
    this.refFormik = React.createRef()
    this.refEl = React.createRef()
  }
  componentDidMount() {
    const {
      project_id,
      project_name,
      npo_name,
      npo_id,
    } = this.props.data;
    this.setState((state) => ({
      selectedValues: { 
        ...state.selectedValues, 
        [PROJECT_TYPE]: project_id ? { label: project_name, value: project_id || '' } : null,
        [NPO_TYPE]: npo_id ? { label: npo_name, value: npo_id || '' } : null,
      }
    }))
  }

  // loadProjectOptions = async ( request = {}) => {
  //   this.sourceProject && this.sourceProject.cancel();
  //   this.setState({
  //     projectOptions: [],
  //     loadingProjectOptions: true,
  //   },() => {
  //     this.debounceFetchProjectOptions(request);
  //   })
  // }
  // debounceFetchProjectOptions = debounce(async ({ params }) => {
  //   let projectOptions = [];
  //   this.sourceProject = axios.CancelToken.source();
  //   const { data: response } = await supportProjectApi.getList({
  //     params: {
  //       page: 1,
  //       page_size: 10,
  //       ...params,
  //     },
  //     cancelToken: this.sourceProject.token
  //   });
  //   if (response?.result) {
  //     projectOptions = response.data.map( item => ({
  //       label: item.name,
  //       value: item.id,
  //       data: item,
  //     }))
  //   }
  //   this.setState({
  //     projectOptions,
  //     loadingProjectOptions: false,
  //   });
  // },250)
  loadOptions = async (type, request = {}) => {
    this.source[type] && this.source[type].cancel();
    this.setState(state => ({
      options: {
        ...state.options,
        [type]: [],
      },
      loadingOptions: {
        ...state.loadingOptions,
        [type]: true,
      }
    }),() => {
      this.debounceFetchOptions(type,request);
    })
  }
  debounceFetchOptions = debounce(async (type,{ params }) => {
    let options = [];
    this.source[type] = axios.CancelToken.source();
    const { data: response } = await typeConfig[type].fetchData({
      params: {
        page: 1,
        page_size: 20,
        ...params,
      },
      cancelToken: this.source[type].token
    });
    if (response?.result) {
      const data = typeConfig[type].mapResults ? typeConfig[type].mapResults(response.data) : response.data;
      options = data ? mapOptions(
        data,{ 
          labelKey: typeConfig[type].labelKey, 
          valueKey: typeConfig[type].valueKey,
        }
      ) : [];
    }
    this.setState(state => ({
      options: {
        ...state.options,
        [type]: options,
      },
      loadingOptions: {
        ...state.loadingOptions,
        [type]: false,
      }
    }));
  },250)
  getOptions = (type) => {
    if ( this.state.loadingOptions[type] ) return []
    return this.state.options[type]
  }

  getInitialValues = () => {
    const { 
      project_id,
      npo_id,
      images,
      items,
    } = this.props.data;

    return {
      project_id: project_id || '',
      npo_id: npo_id || '',
      images: images || [],
      items: items ? items.map( (item,index) => ({
        product_name: item.product_name || '',
        getcare_product_id: item.getcare_product_id || null,
        getcare_manufacturer_id: item.getcare_manufacturer_id || null,
        manufacturer_name: item.manufacturer_name || '',
        getcare_uom_base_id: item.getcare_uom_base_id || null,
        uom_base_name: item.uom_base_name || '',
        quantity_number: validNumber(item.quantity_number),
        images: item.images || [],
        price_sales: validNumber(item.price_sales),
        amount: validNumber(item.amount),
        product_description: item.product_description || null,
        _index: index+1,
      })) : [],
    }
  }
  getValidationSchema = () => {
    return Yup.object().shape({
      items: Yup.array().of(
        Yup.object().shape({
          quantity_number: Yup.number().nullable().required('Vui lòng nhập số lượng').min(1, 'Vui lòng nhập giá trị tối thiểu 1'),
          product_name: Yup.string().nullable().required('Vui lòng nhập thông tin sản phẩm'),
          uom_base_name: Yup.string().nullable().required('Vui lòng nhập thông tin đơn vị'),
        })
      )
    })
  }
  prepareData = (data) => {
    return {
      project_id: data.project_id || null,
      npo_id: data.npo_id || null,
      items: [...data.items.map( item => ({
        product_name: item.product_name || '',
        getcare_product_id: item.getcare_product_id || null,
        getcare_manufacturer_id: item.getcare_manufacturer_id || null,
        manufacturer_name: item.manufacturer_name || '',
        getcare_uom_base_id: item.getcare_uom_base_id || null,
        uom_base_name: item.uom_base_name || '',
        quantity_number: validNumber(item.quantity_number),
        images: item.images || [],
        price_sales: validNumber(item.price_sales),
        amount: validNumber(item.amount),
        product_description: item.product_description || null,
      }))],
      images: data.images,
    }
  }

  submitForm = () => {
    this.refFormik.current.validateForm().then( error => {
      if ( Object.keys(error).length === 0 ) this.refFormik.current.submitForm();
      for ( const [key,value] of Object.entries(error) ) {
        const el = this.refEl.current.querySelector(`input[id="support_${key}"]`);
        if ( el ) { el.focus(); break; }
        else if ( key === 'items' ) {
          for ( const err of value ) {
            alert(`${Object.values(err)[0]} của thông tin sản phẩm`)
            break;
          }
          break;
        }
      }
    })
  }
  handleSubmitForm = async (values) => {
    this.props.onSubmit && this.props.onSubmit({...this.prepareData({...values})})
  }

  render() {
    const { t } = this.props;
    return (
      <Formik
        innerRef={this.refFormik}
        initialValues={this.getInitialValues()}
        // enableReinitialize
        onSubmit={this.handleSubmitForm}
        validationSchema={this.getValidationSchema()}
      >
        {
          (props) => {
            const {
              setFieldValue,
            } = props;
            
            return (
              <form ref={this.refEl} noValidate autoComplete="off">
                <Box className={stylesLayoutMainEcom.MarginB__075} >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <div className={`${stylesLayoutMainEcom.FormControl} ${stylesLayoutMainEcom.Margin__0}`}>
                        <label htmlFor="project_id">
                          {t('Chọn dự án muốn đóng góp')}
                        </label>
                        <StyledAutocomplete
                          id="project_id"
                          fullWidth
                          autoComplete
                          filterOptions={(x) => x}
                          value={this.state.selectedValues[PROJECT_TYPE]}
                          options={this.getOptions(PROJECT_TYPE)}
                          noOptionsText={t('common:Không tìm thấy kết quả')}
                          getOptionLabel={(option) => option.label || ''}
                          getOptionSelected={(option, value) => option.value === value.value}
                          renderOption={(option) => option.value ? option.label : option.label ? `+ ${option.label}` : ''}
                          onInputChange={(event, newInputValue,reason) => {
                            this.setState((state) => ({
                              keywords: { ...state.keywords, [PROJECT_TYPE]: newInputValue },
                              selectedValues: { ...state.selectedValues, [PROJECT_TYPE]: newInputValue ? { label: newInputValue , value: '' } : null },
                            }))
                            if ( ['input','clear'].includes(reason) ) this.loadOptions(PROJECT_TYPE,{ params: { keyword: newInputValue }});
                          }}
                          loading={this.state.loadingOptions[PROJECT_TYPE]}
                          loadingText={`${t('common:Đang tìm kiếm')}...`}
                          renderInput={(params) => (
                              <StyledTextField
                                {...params}
                                placeholder={t('Chọn dự án')}
                                variant="outlined"
                                size="medium"
                                name="project_id"
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <>
                                      {this.state.loadingOptions[PROJECT_TYPE] ? <CircularProgress color="inherit" size={20} /> : null}
                                      {params.InputProps.endAdornment}
                                    </>
                                  ),
                                }}
                              />
                          )}
                          onOpen={() => this.loadOptions(PROJECT_TYPE)}
                          onChange={(e, newValue) => {
                            setFieldValue("project_id",newValue?.value || '')
                            this.setState((state) => ({
                              selectedValues: { ...state.selectedValues, [PROJECT_TYPE]: newValue },
                              keywords: { ...state.keywords, [PROJECT_TYPE]: '' },
                            }))
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <div className={`${stylesLayoutMainEcom.FormControl} ${stylesLayoutMainEcom.Margin__0}`}>
                        <label htmlFor="npo_id">
                          {t('Đơn vị từ thiện')}
                        </label>
                        <StyledAutocomplete
                          id="npo_id"
                          fullWidth
                          autoComplete
                          filterOptions={(x) => x}
                          value={this.state.selectedValues[NPO_TYPE]}
                          options={this.getOptions(NPO_TYPE)}
                          noOptionsText={t('common:Không tìm thấy kết quả')}
                          getOptionLabel={(option) => option.label || ''}
                          getOptionSelected={(option, value) => option.value === value.value}
                          renderOption={(option) => option.value ? option.label : option.label ? `+ ${option.label}` : ''}
                          onInputChange={(event, newInputValue,reason) => {
                            this.setState((state) => ({
                              keywords: { ...state.keywords, [NPO_TYPE]: newInputValue },
                              selectedValues: { ...state.selectedValues, [NPO_TYPE]: newInputValue ? { label: newInputValue , value: '' } : null },
                            }))
                            if ( ['input','clear'].includes(reason) ) this.loadOptions(NPO_TYPE,{ params: { keyword: newInputValue }});
                          }}
                          loading={this.state.loadingOptions[NPO_TYPE]}
                          loadingText={`${t('common:Đang tìm kiếm')}...`}
                          renderInput={(params) => (
                              <StyledTextField
                                {...params}
                                placeholder={t('Chọn đơn vị từ thiện')}
                                variant="outlined"
                                size="medium"
                                name="project_id"
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <>
                                      {this.state.loadingOptions[NPO_TYPE] ? <CircularProgress color="inherit" size={20} /> : null}
                                      {params.InputProps.endAdornment}
                                    </>
                                  ),
                                }}
                              />
                          )}
                          onOpen={() => this.loadOptions(NPO_TYPE)}
                          onChange={(e, newValue) => {
                            setFieldValue("npo_id",newValue?.value || '')
                            this.setState((state) => ({
                              selectedValues: { ...state.selectedValues, [NPO_TYPE]: newValue },
                              keywords: { ...state.keywords, [NPO_TYPE]: '' },
                            }))
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Box>
                <Box className={stylesLayoutMainEcom.MarginB__075}>
                  <ContributionProductInfo
                    {...props}
                  />
                  <p className={styles.Note}>{t('Lưu ý nhỏ: Nếu là sản phẩm tặng thì bạn để giá sản phẩm là 0 bạn nhé',{ nsSeparator: "::"})}</p>
                </Box>
                <Box className={stylesLayoutMainEcom.MarginB__075}>
                  <UploadImage
                    {...props}
                  />
                </Box>
              </form>
            )
          }
        }
      </Formik>
    );
  }
};

ConnectSupportDonateForm.propTypes = {
  data: PropTypes.object,
};

ConnectSupportDonateForm.defaultProps = {
  data: {}
}

export default withTranslation(['componentConnectSupportDonateForm','common'],{ withRef: true })(ConnectSupportDonateForm);
