import {
  GET_DELIVERY_LOGISTIC_LIST,
  SAVE_DELIVERY_LOGISTIC_LIST,
  GET_LOGISTIC_LIST,
  SAVE_LOGISTIC_LIST,
  SAVE_LOGISTIC_LIST_LOADING,
  GET_VENDOR_DELIVERY_FEE,
  SAVE_VENDOR_DELIVERY_FEES,
  SAVE_VENDOR_DELIVERY_FEES_LOADING,
  SAVE_SELECTED_PAYMENT,
} from 'redux/constants/logisticConstants';

export function saveVendorDeliveryFeesLoading(vendorDeliveryFeesLoading) {
  return {
    type: SAVE_VENDOR_DELIVERY_FEES_LOADING,
    vendorDeliveryFeesLoading
  };
}

export function saveVendorDeliveryFees(vendorDeliveryFees) {
  return {
    type: SAVE_VENDOR_DELIVERY_FEES,
    vendorDeliveryFees
  };
}

export function getVendorDeliveryFee(payload) {
  return {
    type: GET_VENDOR_DELIVERY_FEE,
    payload
  };
}

export function getDeliveryLogisticList(payload) {
  return {
    type: GET_DELIVERY_LOGISTIC_LIST,
    payload
  };
}
export function getLogisticList(payload) {
  return {
    type: GET_LOGISTIC_LIST,
    payload
  };
}

// mutation
export function saveDeliveryLogisticList(deliveryLogisticList) {
  return {
    type: SAVE_DELIVERY_LOGISTIC_LIST,
    deliveryLogisticList
  };
}

export function saveSelectedPayment(selectedPayment) {
  return {
    type: SAVE_SELECTED_PAYMENT,
    selectedPayment,
  };
}

export function saveLogisticList(logisticList) {
  return {
    type: SAVE_LOGISTIC_LIST,
    logisticList
  };
}
export function saveLogisticListLoading(logisticListLoading) {
  return {
    type: SAVE_LOGISTIC_LIST_LOADING,
    logisticListLoading
  };
}
