import React, { PureComponent } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectFastOrderDetails,
  makeSelectFastOrderDelivery,
  makeSelectFastOrderSelectedCustomerCode,
} from 'redux/selectors';
import { savePRDraftDelivery } from 'redux/actions/ecom/fastOrderActions';
import customerApi from 'utils/api/mdm/customerApi';
import { withTranslation } from "react-i18next";
import { withRouter } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CustomerAddressItem from 'components/CustomerAddressItem/CustomerAddressItem';
import CustomerAddressForm from './CustomerAddressForm';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';

import classes from './CustomerDeliveryFormDialog.module.scss';

class CustomerDeliveryFormDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      submitClickCount: 0,
      addressList: [],
      selectedAddress: null,
      isOtherAddress: false,
    };
    this.contentRef = null;
    this.otherAddressFormRef = null;
  }
  componentDidMount() {
    this._loadCustomerAddressBook();
  }
  componentDidUpdate(prevProps, prevState) {
    const { isOtherAddress } = this.state;
    if (isOtherAddress && isOtherAddress !== prevState.isOtherAddress) {
      this.contentRef.scrollTo({
        top: this.otherAddressFormRef.offsetTop,
      });
    }
  }

  _loadCustomerAddressBook = async () => {
    const { data: response } = await customerApi.getAddressBook({
      params: {
        getcare_customer_code: this.props.selectedCustomerCode,
      },
    });
    if (!response?.result || !response.data) {
      return;
    }
    this.setState({
      addressList: response.data,
    });
    if (response.data.length <= 0) {
      this.setState({
        isOtherAddress: true,
      });
    }
  };

  _getInitialValues  = () => {
    const {selectedAddress} = this.state;
    return {
      label: selectedAddress?.label || '',
      name: selectedAddress?.name || '',
      phone: selectedAddress?.phone || '',
      address: selectedAddress?.address || '',
      countryId: selectedAddress?.getcare_country?.id || 220,
      country: selectedAddress?.getcare_country || {
        "name":"Viet Nam",
        "id": 220
      },
      province: selectedAddress?.getcare_province || '',
      provinceId: selectedAddress?.getcare_province?.id || '',
      district: selectedAddress?.getcare_district || '',
      districtId: selectedAddress?.getcare_district?.id || '',
      ward: selectedAddress?.getcare_ward || '',
      wardId: selectedAddress?.getcare_ward?.id || '',
      default: selectedAddress?.default || false,
      save: false,
    }
  };

  handleSelectedAddressChange = (newValue) => {
    this.setState({
      selectedAddress: newValue,
      isOtherAddress: false,
    });
  };
  handleToggleOtherAddress = (e) => {
    this.setState({
      isOtherAddress: e.target.checked,
      selectedAddress: e.target.checked ? null : this.state.selectedAddress,
    });
  }
  onClose = () => {
    this.props.onClose();
  };
  onEntered = () => {};



  handleSubmit = (values) => {
    this.setState((state) => ({
      submitClickCount: state.submitClickCount + 1,
    }));

    const { isOtherAddress, selectedAddress } = this.state;
    const addressData = {
      id: selectedAddress?.id,
      name: values.name,
      phone: (/^(84)/).test(values.phone) ? values.phone.replace(/^(84)/, '+84') : values.phone,
      label: values.label,
      address: values.address,
      getcare_country_id: values.countryId ,
      getcare_province_id: values.provinceId,
      getcare_district_id: values.districtId,
      getcare_ward_id: values.wardId,
      default: values.default,
      save: isOtherAddress ? true : false,
    }
    const prDeliveryData = {
      ...this.props.prDelivery,
      ...addressData,
      getcare_country: values.country,
      getcare_district: values.district,
      getcare_province: values.province,
      getcare_ward: values.ward,
    };

    if (!isOtherAddress) {
      // update to address book for this customer
      customerApi.postAddress({
        params: {
          ...addressData,
          getcare_customer_code: this.props.selectedCustomerCode,
        },
      });
    }

    this.props.onUpdatePRDraft({
      prDeliveryData: { ...prDeliveryData },
      willSaveLoading: true,
      willUpdatePRDetails: true,
    });

    this.onClose();
  };

  render() {
    const { isOpen ,t } = this.props;
    const {
      isLoading,
      addressList,
      selectedAddress,
      isOtherAddress,
    } = this.state;

    return (
      <Dialog
        open={isOpen}
        disableBackdropClick={true}
        onClose={this.onClose}
        onEntered={this.onEntered}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.Dialog}
        maxWidth={false}
      >
        <div
          className={`${classes.DialogContentWrap} ${
            isLoading && 'OverlayLoading'
          }`}
        >
          <DialogTitle className={classes.DialogTitle}>
            {t('Địa chỉ nhận hàng')}
            <IconButton size="small" onClick={this.onClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <Formik
            initialValues={this._getInitialValues()}
            enableReinitialize
            onSubmit={(values) => {
              this.handleSubmit(values);
            }}
            validationSchema={
            
              Yup.object().shape({
                name: Yup.string().required(t('Vui lòng nhập tên người nhận')).max(255, 'Tối đa 255 ký tự'),
                label: Yup.string().required(t('Vui lòng nhập cơ sở nhận')).max(255, 'Tối đa 255 ký tự'),
                phone: Yup.string().required(t('Vui lòng nhập số điện thoại')).max(55, 'Tối đa 55 ký tự'),
                address: Yup.string().required(t('Vui lòng nhập địa chỉ')).max(255, 'Tối đa 255 ký tự'),
                countryId: Yup.string().required(t('Vui lòng chọn Quốc Gia')),
                provinceId: Yup.string().trim().when('country', { 
                  is: value => value && value.id === 220,
                  then: Yup.string().trim().required(t('Vui lòng chọn')),
                }),

                districtId: Yup.string().trim().when('country', { 
                  is: value => value && value.id === 220,
                  then: Yup.string().trim().required(t('Vui lòng chọn')),
                }),
                wardId: Yup.string().trim().when('country', { 
                  is: value => value && value.id === 220,
                  then: Yup.string().trim().required(t('Vui lòng chọn')),
                }),
              })
            }
            // validateOnChange={this.state.submitClickCount > 0}
          >
            {(props) => {
              const {
                values,
                errors,
                handleChange,
                handleSubmit,
                setFieldValue,
              } = props;
              return (
                <form noValidate autoComplete="off" className={classes.Dialog}>
                  <DialogContent ref={(ref) => { this.contentRef = ref; }} className={`${classes.DialogContent}`}>
                    { addressList
                      && addressList.length > 0
                      && addressList.map((address) => (
                        <div
                          className={`${classes.AddressMenuItem} ${selectedAddress?.id === address.id ? classes.Selected : ''}`}
                          key={`address-${address.id}`}
                          onClick={(e) => {
                            this.handleSelectedAddressChange(address);
                          }}
                        >
                          { selectedAddress?.id === address.id
                            ? (<>
                                <RadioButtonCheckedIcon className={`${classes.Icon} ${classes.Checked}`}/>
                                <CustomerAddressForm
                                  values={values}
                                  errors={errors}
                                  handleChange={handleChange}
                                  setFieldValue={setFieldValue}
                                  handleSubmit={handleSubmit}
                                />
                              </>)
                            : (<>
                              <RadioButtonUncheckedIcon className={classes.Icon}/>
                              <CustomerAddressItem
                                address={address}
                                isAllInOneLine
                              />
                            </>)
                          }
                        </div>
                      ))
                    }
                    <div className={`${classes.OtherAddressForm}`}>
                      <div className={`${classes.FieldControl} ${classes.FieldControlLabel}`}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="isOtherAddress"
                              checked={isOtherAddress}
                              onChange={this.handleToggleOtherAddress}
                            />
                          }
                          label={<strong className={classes.OtherAddressFormLabel}>{t('Địa chỉ khác')}</strong>}
                        />
                      </div>
                      {isOtherAddress && (
                        <div ref={(ref) => { this.otherAddressFormRef = ref; }} className={classes.CustomInfo}>
                          <CustomerAddressForm
                            isNewAddress
                            values={values}
                            errors={errors}
                            handleChange={handleChange}
                            setFieldValue={setFieldValue}
                            handleSubmit={handleSubmit}
                          />
                        </div>
                      )}
                    </div>
                  </DialogContent>
                </form>
              );
            }}
          </Formik>
        </div>
      </Dialog>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  prDetails: makeSelectFastOrderDetails(),
  prDelivery: makeSelectFastOrderDelivery(),
  selectedCustomerCode: makeSelectFastOrderSelectedCustomerCode(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    savePRDraftDelivery: (payload) => dispatch(savePRDraftDelivery(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default withTranslation()(compose(withConnect, withRouter)(CustomerDeliveryFormDialog));

