import React, { PureComponent } from 'react'
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as Yup from 'yup';

import { createStructuredSelector } from "reselect";
import {
  makeSelectLoginUser,
} from 'redux/selectors';
import { formatPhoneNumbers } from 'utils/helper'

import { Formik } from 'formik';
import {
  Typography,
  FormControl,
  withStyles,
} from '@material-ui/core'
import DoneIcon from '@material-ui/icons/Done';
import PhoneInput from 'components/PhoneInput/PhoneInput';
import StyledPaper from 'components/Styled/Paper/Paper';
import StyledBox from 'components/Styled/Box/Box';
import StyledButton from 'components/Styled/Button/Button';
import LoadingOverlay from 'components/LoadingOverlay/LoadingOverlay';
import OtpVerificationDialog from 'components/OtpVerificationDialog/OtpVerificationDialog';

import styles from './phoneAuthenticationStyles';

class PhoneAuthentication extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      otpVerificationDialogOpen: false,
    }
  }

  getInitialValues = () => {
    return {
      phoneCode: '+84',
      phoneNumber: '',
    }
  }
  getValidationSchema = () => {
    return Yup.object().shape({
      phoneNumber: Yup.string().required('Vui lòng nhập số điện thoại').test(
        'phoneNumber',
        'Số điện thoại chưa hợp lệ',
        (value,{ parent }) => {
          return value && parent.phoneCode.length + ( value.charAt(0) === '0' ? value.length -1 : value.length ) >= 10
        }
      ),
    })
  }

  handleOtpVerificationDialogToggle = () => {
    this.setState({ otpVerificationDialogOpen: true })
  }
  handleOtpVerificationDialogClose = () => {
    this.setState({ otpVerificationDialogOpen: false })
  }
  handleCompleteOtpVerify = () => {
    window.location.reload();
  }

  render() {
    const { classes, user, sendingOTPRequest, verifyingOTPRequest } = this.props;
    const { otpVerificationDialogOpen } = this.state;
    const validationSchema = this.getValidationSchema();
    const initialValues = this.getInitialValues();
    const loading = sendingOTPRequest || verifyingOTPRequest;

    return (
      user?.force_verify_otp ? (
        <StyledPaper boxShadowType={1} spacing={2}>
          <StyledBox display="flex" alignItems="center" flexDirection="column" gap={2}>
            <DoneIcon className={classes.doneIcon}/>
            <StyledBox>
              <Typography variant="h6" align="center" gutterBottom>
                Số điện thoại đã được xác thực
              </Typography>
              <Typography align="center">
                Nếu bạn muốn thay đổi số điện thoại, vui lòng liên hệ với chúng tôi qua số 1800 1568 để được hỗ trợ.
              </Typography>
            </StyledBox>
          </StyledBox>
        </StyledPaper>
      ) : (
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={this.handleOtpVerificationDialogToggle}
          validationSchema={validationSchema}
        >
          {(props) => {
            const {
              values,
              errors,
              handleSubmit,
              setFieldValue,
            } = props;
            
            return <>
              <StyledPaper boxShadowType={1} spacing={2}>
                <Typography className="Paper-title" variant="h6">Thiết lập xác thực qua tin nhắn</Typography>
                <LoadingOverlay loading={loading}>
                  <StyledBox marginBottom={2}>
                    <FormControl className={classes.formControl}>
                      <PhoneInput
                        label="Số điện thoại"
                        className={`${classes.textField} ${otpVerificationDialogOpen && classes.hiddenPhoneNumber}`}
                        fullWidth
                        id="PhoneAuthentication-phoneInput"
                        value={{
                          code: values.phoneCode,
                          number: values.phoneNumber
                        }}
                        onChange={(e,value) => {
                          setFieldValue("phoneNumber",value.number);
                          setFieldValue("phoneCode",value.code,false);
                        }}
                        variant="outlined"
                        error={!!errors.phoneNumber}
                        helperText={errors.phoneNumber}
                      />
                    </FormControl>
                  </StyledBox>

                  <StyledBox>
                    <StyledButton onClick={handleSubmit} size="large" color="primary" variant="contained">
                      Lấy mã OTP
                    </StyledButton>
                  </StyledBox>
                </LoadingOverlay>
              </StyledPaper>

              <OtpVerificationDialog
                open={otpVerificationDialogOpen}
                values={{
                  phone: formatPhoneNumbers({ code: values.phoneCode, number: values.phoneNumber }),
                  isRegister: false,
                }}
                onClose={this.handleOtpVerificationDialogClose}
                onComplete={this.handleCompleteOtpVerify}
              />
            </>
          }}
        </Formik>
      )
    )
  }
}

const mapStateToProps = createStructuredSelector({
  user: makeSelectLoginUser(),
});

const withConnect = connect(mapStateToProps, null);

export default compose(withConnect,withStyles(styles))(PhoneAuthentication);