import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { toast } from 'react-toastify';
import { withTranslation } from "react-i18next";

import {
  saveConnectSupportDonate,
} from 'redux/actions/connectSupportDonateActions';
import { 
  makeSelectLoginUser 
} from 'redux/selectors';
import supportProjectApi from 'utils/api/ecom/serviceRequest/connectSupport/support/projectApi';
import supportApi from 'utils/api/ecom/serviceRequest/connectSupport/support/supportApi';

import { 
  Container,
  Grid,
  Typography,
  Box,
  Slide,
  AppBar,
} from '@material-ui/core';
import ProjectImages from './ProjectImages/ProjectImages';
import ProjectInfo from './ProjectInfo/ProjectInfo';
import TabSupport from './TabSupport/TabSupport';
import TabIntroduction from './TabIntroduction/TabIntroduction';
import TabUpdate from './TabUpdate/TabUpdate';
import TabContributor from './TabContributor/TabContributor';
import StyledTabs from 'components/Styled/Tabs/Tabs';
import StyledTab from 'components/Styled/Tab/Tab';
import StyledButton from 'components/Styled/Button/Button';

import styles from './ConnectSupportProjectDetail.module.scss';
import stylesLayoutMainEcom from 'assets/styles/layouts/LayoutMainEcom.module.scss';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div {...other} >
      {value === index && children}
    </div>
  );
}

class ConnectSupportProjectDetail extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      submitingCharityOrders: false,
      tabValue: 0,
      loading: true,
      project: null,
      project_id: Number(props.match.params.project_id),
      showToolbar: false,
      windowScrollY: 0,
    }
    this.refBtnDonate = React.createRef();
    this.btnDonateElement = null
  }
  componentDidMount() {
    this.loadProjectDetail()
    window.addEventListener('scroll',this.handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll',this.handleScroll);
  }

  handleScroll = (e) => {
    const btnDonateOffsetTop = this.refBtnDonate.current?.offsetTop || null;
    const scrollingTop = this.state.windowScrollY > window.scrollY;
    this.setState({
      showToolbar: !scrollingTop && btnDonateOffsetTop && window.scrollY - btnDonateOffsetTop >= 50 ? true : false,
      windowScrollY: window.scrollY,
    })
  }
  // componentDidUpdate(prevProps,prevState) {
  //   if ( prevState.loading && !this.btnDonateElement ) {
  //     this.btnDonateElement = document.querySelector('#connectSupport-projectDetail-btnDonate');
  //     console.log(this.btnDonateElement)
  //     window.addEventListener('scroll',(e) => {
  //       console.log(this.btnDonateElement.scrollTop)
  //       // console.log(e.scrollTop)
  //     });
  //   } 
  // }

  loadProjectDetail = async () => {
    const { t } = this.props;
    this.setState({ loading: true });
    try {
      const { data: response } = await supportProjectApi.get({ params: { project_id: this.state.project_id }});
      if (!response.result) {
        this.props.history.push(`/community/connect-support`)
        toast.error(response.message);
        return;
      }
      this.setState({ loading: false, project: response.data });
    } catch (error) {
      toast.error(t("Đang xảy ra lỗi vui lòng thử lại"));
      this.props.history.push(`/community/connect-support`)
    }
  }
  // pushFavourite = debounce( async () => {
  //   const { data: response } = await supportApi.pushFavourite({ params: {
  //     object_id: this.state.project.id
  //   }})
  //   if (!response?.result) {
  //     this.setState((state) =>({
  //       project: {
  //         ...state.project,
  //         favourite: !state.project.favourite
  //       }
  //     }))
  //   }
  // },250)
  confirmLogin = () => {
    const { t } = this.props;
    let confirmLogin = true;
    if ( !this.props.user?.id ) {
      confirmLogin = window.confirm(t("common:Bạn phải đăng nhập trước khi thực hiện"));
    }
    return confirmLogin;
  }

  handleDonateProject = () => {
    if ( this.state.project?.getcare_service_request_status?.id === 2 ) return;
    this.props.saveConnectSupportDonate({
      ...this.props.connectSupportDonate,
      project_id: this.state.project.id,
      project_name: this.state.project.name
    })
    this.confirmLogin () && this.props.history.push(`/community/connect-support/donate/new`)
  }
  handleSubmitCharityOrders = async (item_ids) => {
    this.setState({ submitingCharityOrders: true });
    const { data: response } = await supportApi.createSupportOrderDraft({ params: { 
      id: this.state.project_id,
      item_ids,
    }});
    if (!response.result) {
      toast.error(response.message);
      this.setState({ submitingCharityOrders: false });
      return;
    }
    this.setState({ submitingCharityOrders: false });
    this.props.history.push(`/fastorder?getcare_customer_code=${response.data.customer_code}&is_charity=1`)
  }
  handleChangeTab = (event, newValue) => {
    this.setState({ tabValue: newValue });
  }
  
  handlePushFavourite = () => {
    this.setState((state) => ({
      project: {
        ...state.project,
        favourite: !state.project.favourite 
      }
    }),async () => {
      const { data: response } = await supportApi.pushFavourite({ params: {
        object_id: this.state.project.id
      }})
      if (!response?.result) {
        toast.error(response.message);
        this.setState((state) =>({
          project: {
            ...state.project,
            favourite: !state.project.favourite
          }
        }))
      }
    })
  }

  render() {
    const { user, t } = this.props;
    return (
      <Box className={`ConnectSupportProjectDetail`}>
        <Typography id="testtttt" className={stylesLayoutMainEcom.ContainerTitle} variant="h6">{t('Chi tiết dự án')}</Typography>
        {
          !this.state.loading &&
          <Slide appear={false} direction="down" in={this.state.showToolbar}>
            <AppBar className={styles.appBar}>
              <Container className={styles.appBarContainer}>
                <div className={styles.toolbar}>
                  <p className={styles.toolbarTitle}>{this.state.project?.name}</p>
                  <Box display="flex" style={{ gap: '.5rem' }}>
                    {
                      user?.id && <>
                        {
                          user?.getcare_customer?.code === this.state.project.customer_code &&
                          <StyledButton 
                            onClick={(e) => {
                              e.preventDefault();
                              this.props.history.push(`/community/connect-support/project/edit/${this.state.project_id}`)
                            }}
                            className={styles.btnLink} 
                            href={`/community/connect-support/project/edit/${this.state.project_id}`} 
                            variant="contained" color="secondary"
                          >
                            Sửa
                          </StyledButton>
                        }
                      </>
                    }
                    <StyledButton disabled={this.state.project?.getcare_service_request_status?.id === 2} onClick={this.handleDonateProject} variant="contained" color="primary">
                      {t('Đóng góp').toUpperCase()}
                    </StyledButton>
                  </Box>
                </div>
              </Container>
            </AppBar>
          </Slide>
        }
        {
          !this.state.loading ? <>
          {
            ( user?.id && user?.getcare_customer?.code === this.state.project.customer_code ) &&
            <Box display="flex" justifyContent="flex-end">
              <StyledButton 
                onClick={(e) => {
                  e.preventDefault();
                  this.props.history.push(`/community/connect-support/project/edit/${this.state.project_id}`)
                }}
                className={styles.btnLink} 
                href={`/community/connect-support/project/edit/${this.state.project_id}`} 
                variant="contained" color="secondary"
              >
                Sửa
              </StyledButton>
            </Box>
          }
          <Box textAlign="center" component="p" fontSize="34px" marginBottom="52px">
            <b>{this.state.project?.name}</b>
          </Box>
          <Box className={stylesLayoutMainEcom.MarginB__1}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={8}>
                <ProjectImages
                  values={this.state.project}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <ProjectInfo
                  values={this.state.project}
                  onDonateProject={this.handleDonateProject}
                  onGetRefBtnDonate={this.refBtnDonate}
                  onPushFavourite={this.handlePushFavourite}
                />
              </Grid>
            </Grid>
          </Box>
          <StyledTabs className="Tabs" value={this.state.tabValue} onChange={this.handleChangeTab} variant="scrollable" scrollButtons="on" >
            <StyledTab label={t('Giới thiệu')}/>
            <StyledTab label={t('Cần hỗ trợ')}/>
            <StyledTab label={t('Cập nhật')}/>
            <StyledTab label={t('Người đóng góp')}/>
          </StyledTabs>
          <TabPanel value={this.state.tabValue} index={0}>
            <Box paddingTop="1rem">
              <TabIntroduction
                values={this.state.project}
              />
            </Box>
          </TabPanel>
          <TabPanel value={this.state.tabValue} index={1}>
            <Box className={`${this.state.submitingCharityOrders && 'OverlayLoading'}`} paddingTop="1rem">
              <TabSupport
                values={this.state.project}
                onSubmitCharityOrders={this.handleSubmitCharityOrders}
              />
            </Box>
          </TabPanel>
          <TabPanel value={this.state.tabValue} index={2}>
            <Box paddingTop="1rem">
              <TabUpdate
                values={this.state.project}
                onChangeTab={(tab) => this.handleChangeTab(null,tab)}
              />
            </Box>
          </TabPanel>
          <TabPanel value={this.state.tabValue} index={3}>
            <Box paddingTop="1rem">
              <TabContributor
                values={this.state.project}
              />
            </Box>
          </TabPanel>
          </> : ''
        }
      </Box>
    );
  }
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectLoginUser(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    saveConnectSupportDonate: (payload) => dispatch(saveConnectSupportDonate(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withRouter, withTranslation(['viewMainEcomConnectSupportProjectDetail']))(ConnectSupportProjectDetail);