import { createSelector } from 'reselect';

const selectConnectSupportSupport = (state) => {
  return state.connectSupportSupportReducer || {};
};
const makeSelectConnectSupportSupport = () =>
  createSelector(selectConnectSupportSupport, (substate) => substate.connectSupportSupport);
const makeSelectSavingConnectSupportSupport = () =>
  createSelector(selectConnectSupportSupport, (substate) => substate.savingConnectSupportSupport);

export {
  makeSelectConnectSupportSupport,
  makeSelectSavingConnectSupportSupport,
}