import React, { PureComponent } from 'react';
import { currencyFormatVN, validNumber } from 'utils/helper';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { rateOrdering } from 'redux/actions/ecom/fastOrderActions';
import { createStructuredSelector } from "reselect";
import { makeSelectFastOrderActionLoading,makeSelectSalesOrder } from 'redux/selectors';

import Dialog from '@material-ui/core/Dialog';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import Button from '@material-ui/core/Button';
import Rating from '@material-ui/lab/Rating';
import StarOutlineSharpIcon from '@material-ui/icons/StarOutlineSharp';
import StarSharpIcon from '@material-ui/icons/StarSharp';
import { withTranslation } from "react-i18next";

import classes from './FastOrderSuccessDialog.module.scss';

class FastOrderSuccessDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      rate: 4,
      rateHover: 4,
    }
    const {t} = this.props;

    this.rateLabelsMap = {
      1: t('Rất khó'),
      2: t('Khó'),
      3: t('Bình thường'),
      4: t('Dễ'),
      5: t('Rất dễ'),
    };
  }


  componentDidUpdate(prevProps) {
    const { isOpen, actionLoading ,salesOrder } = this.props;
    if (isOpen && actionLoading && actionLoading !== prevProps.actionLoading) {
      this.props.onClose();
    }
    if (salesOrder && !prevProps.salesOrder) {
      this.setState({
        dataOrder: salesOrder
      })
    }
  }

  handleClose = () => {
    this.props.rateOrdering({
      params: {
        getcare_order_id: this.props.prDetails?.so_id,
        rating: this.state.rate,
      }
    });
  }

  onClose = () => {
    this.props.onClose();
  }

  render() {
    const { isOpen, prDetails  ,t} = this.props;
    const { rateHover, rate ,dataOrder } = this.state;

    return (
      <Dialog
        // disableBackdropClick={true}
        open={isOpen}
        onClose={this.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.Dialog}
        maxWidth={false}
      >
        <div className={`${classes.DialogContentOuter}`}>
          <div className={classes.DialogInfo}>
            <CheckCircleOutlineOutlinedIcon className={classes.DialogInfoIcon} />
            <h4 className={classes.DialogInfoTitle}>{t('Đặt hàng thành công')}</h4>
            <div className={classes.OrderInfo}>
              <div className={classes.Info}>
                <label>{t('Mã đơn hàng')}</label>
                { prDetails?.so_code ? prDetails?.so_code : '' }
                {dataOrder ? dataOrder?.code: '' }
              </div>
              <div className={classes.Info}>
                <label>{t('Tổng tiền đơn hàng')}</label>
                <span className={classes.Amount}>{validNumber(dataOrder? dataOrder?.amount_total : prDetails?.amount_total) ? currencyFormatVN(dataOrder? dataOrder?.amount_total : prDetails?.amount_total) : `0`}</span>
              </div>
            </div>
            <div className={classes.OrderRating}>
              <label>{t('Bạn có đặt hàng dễ dàng không?')}</label>
              <div className={classes.RatingBox}>
                <Rating
                  className={classes.Rating}
                  name="order_rating"
                  value={rate}
                  icon={<StarSharpIcon/>}
                  emptyIcon={<StarOutlineSharpIcon/>}
                  onChange={(event, newValue) => {
                    this.setState({ rate: newValue });
                  }}
                  onChangeActive={(event, newHover) => {
                    this.setState({ rateHover: newHover });
                  }}
                />
                {rate !== null && <span className={classes.RatingLabel}>{this.rateLabelsMap[rateHover !== -1 ? rateHover : rate]}</span>}
              </div>
            </div>
            <div className={classes.DialogInfoActions}>
              <Button variant="outlined" onClick={this.handleClose}>{t('Đóng')}</Button>
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  actionLoading: makeSelectFastOrderActionLoading(),
  salesOrder: makeSelectSalesOrder(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    rateOrdering: (payload) => dispatch(rateOrdering(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default withTranslation()(compose(withConnect)(FastOrderSuccessDialog));
