const styles = (theme) => {
  return {
    root: (props) => {
      return {
        
      }
    },
    circularProgressBackground: {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      color: theme.palette.grey[200]
    }
  }
};

export default styles;