import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { 
  makeSelectLoginUser,
} from 'redux/selectors';

import { withStyles  } from '@material-ui/core/styles';
import { 
  Paper,
  Box,
  Avatar,
  LinearProgress,
  ButtonBase,
} from '@material-ui/core';
import ShareIcon from '@material-ui/icons/Share';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ChatIcon from '@material-ui/icons/Chat';
import StyledButton from 'components/Styled/Button/Button';

import styles from './ProjectInfo.module.scss';

const StyledPaper = withStyles(theme => ({
  root: {
    padding: '30px 56px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    display: 'flex',
    flexDirection: 'column',
    gap: '30px'
  }
}))(Paper);

const StyledLinearProgress = withStyles((theme) => ({
  root: {
    height: 16,
    borderRadius: 50,
    maxWidth: '100%',
  },
  colorPrimary: {
    backgroundColor: '#A0B9D2',
  },
  bar: {
    borderRadius: 4,
    backgroundColor: theme.palette.success.main,
  },
}))(LinearProgress);

class ProjectInfo extends PureComponent {
  getLinearProgressValue = ({ value, max, min }) => {
    const MIN = min || 0;
    const result =  (value - MIN) * 100 / (max - MIN);
    return result > 100 ? 100 : result;
  }
  alertFeatured = () => {
    alert('Tính năng này sẽ phát triển trong tương lại bạn nhé :)')
  }

  render() {
    const { t, user } = this.props; 
    const { 
      customer_avatar,
      customer_name,
      deadline,
      address,
      quantity_number_donate,
      quantity_number,
      created_at,
      favourite,
      getcare_service_request_status,
    } = this.props.values || {};

    return (
      <Box display="flex" flexDirection="column" height="100%">
        <StyledPaper>
          <Box display="flex" style={{ gap: '0.75rem' }}>
            <Avatar
              className={styles.Avatar} 
              alt={customer_name} 
              src={customer_avatar}
            >
              {customer_name ? customer_name.charAt(0) : 'G'}
            </Avatar>
            <Box>
              <p><b>{customer_name}</b></p>
              <Box fontSize="14px" component="p">{t('Đã đăng')}: {moment(created_at).format('DD/MM/YYYY')}</Box>
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between" component="p" style={{ gap: '1rem' }}>
            <Box whiteSpace="nowrap" component="span">{t('Cần trước ngày')}:</Box><span>{moment(deadline).format('DD/MM/YYYY')}</span>
          </Box>
          <Box display="flex" justifyContent="space-between" component="p" style={{ gap: '1rem' }}>
            <Box whiteSpace="nowrap" component="span">{t('Địa chỉ')}:</Box> <span>{address}</span>
          </Box>
          <Box display="flex" flexDirection="column" style={{ gap: '.5rem' }}>
            <Box display="flex" justifyContent="space-between" component="p" style={{ gap: '1rem' }}>
              <Box whiteSpace="nowrap" component="span">{t('Số lượng đóng góp')}: </Box>
              <span>{quantity_number_donate}/{quantity_number}</span>
            </Box>
            <StyledLinearProgress variant="determinate" value={this.getLinearProgressValue({ value: quantity_number_donate, max: quantity_number})}/>
          </Box>
          <Box display="flex" justifyContent="space-between">
            {
              user?.id &&
              <ButtonBase onClick={this.alertFeatured} className={styles.btnIcon}>
                <ChatIcon style={{ fontSize: '40px', color: '#475667' }}/>
              </ButtonBase>
            }
            <ButtonBase onClick={this.alertFeatured} className={styles.btnIcon}>
              <ShareIcon style={{ fontSize: '40px', color: '#475667'}}/>
            </ButtonBase>
            {
              user?.id &&
              <ButtonBase onClick={() => this.props.onPushFavourite()} className={styles.btnIcon}>
                {
                  favourite ? <FavoriteIcon style={{ fontSize: '40px', color: '#475667'}}/> : <FavoriteBorderIcon style={{ fontSize: '40px', color: '#475667'}}/>
                } 
              </ButtonBase>
            }
          </Box>
          <StyledButton disabled={getcare_service_request_status?.id === 2 } ref={this.props.onGetRefBtnDonate} id="connectSupport-projectDetail-btnDonate" onClick={this.props.onDonateProject} variant="contained" color="primary" className={styles.BtnContribute}>
            {t('Đóng góp').toUpperCase()}
          </StyledButton>
          <Box display="flex" justifyContent="space-between" style={{ gap: '1rem' }}>
            <Box display="flex" flexDirection="column" style={{ gap: '.5rem' }} textAlign="center">
              <Box fontWeight="bold" >-</Box>
              <Box fontSize="14px">{t('Quan tâm')}</Box>
            </Box>
            <Box display="flex" flexDirection="column" style={{ gap: '.5rem' }} textAlign="center">
              <Box fontWeight="bold" >-</Box>
              <Box fontSize="14px">{t('Cập nhật')}</Box>
            </Box>
            <Box display="flex" flexDirection="column" style={{ gap: '.5rem' }} textAlign="center">
              <Box fontWeight="bold" >-</Box>
              <Box fontSize="14px">{t('Chia sẻ')}</Box>
            </Box>
          </Box>
        </StyledPaper>
      </Box>
    );
  }
};

ProjectInfo.propTypes = {
  values: PropTypes.object,
};

ProjectInfo.defaultProps = {
  values: {
    images: [],
  },
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectLoginUser(),
});
const withConnect = connect(mapStateToProps);

export default compose(withConnect,withTranslation(['viewMainEcomConnectSupportProjectDetail']))(ProjectInfo);
