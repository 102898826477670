import axiosAuth from 'utils/axios/axiosAuth';

export default {
  getCharityRecipientList: ({ params, cancelToken }) => {
    return axiosAuth.get(`/ecom/service_request/connect_support/charity_recipient/${params.donate_id}`, {
      cancelToken,
    });
  },
  pushFavourite: ({ params })  => {
    return axiosAuth.post(`/ecom/service_request/connect_support/donate_favourite`, params);
  },
  createOrUpdate: ({ params })  => {
    return axiosAuth.post(`/ecom/service_request/connect_support/donate`, params);
  } 
};
