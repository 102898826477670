import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { maxBy } from 'lodash';
import { withTranslation } from "react-i18next";

import commentApi from 'utils/api/commentApi'
import authApi from 'utils/api/authApi'
import { MAX_FILE_SIZE } from 'utils/constanst/common';
import { 
  makeSelectLoginUser 
} from 'redux/selectors';
import { 
  COMMENT_MEDIA_IMAGE_ID,
  COMMENT_MEDIA_VIDEO_ID,
} from 'utils/constanst/commentConstants'

import { 
  Avatar,
  Box,
  ButtonBase,
  Tooltip,
} from '@material-ui/core';
import StyledTextField from 'components/Styled/TextField';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import CancelIcon from '@material-ui/icons/Cancel';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import { ReactComponent as SendIcon } from 'assets/images/icons/Send.svg';

import styles from './CommentForm.module.scss';

class CommentForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      submitingComment: false,
      comment: '',
      files: [],
    }
    this.refInputFile = React.createRef();
    // this.refInputImageFile = React.createRef();
  }
  
  addFile = (files) => {
    const acceptedFileImage = new RegExp('image/.','i');
    const acceptedFileVideo = new RegExp('video/.','i');
    const stateFiles = [...this.state.files];
    const maxById = maxBy(stateFiles,'id');
    let id = maxById ? maxById.id + 1 : 1;

    files.forEach(file => {
      let media_id = null;
      if ( acceptedFileImage.test(file.type) ) media_id = COMMENT_MEDIA_IMAGE_ID; // hình ảnh
      if ( acceptedFileVideo.test(file.type) ) media_id = COMMENT_MEDIA_VIDEO_ID; // video 
      if ( media_id ) {
        const blob = file.slice(0, file.size,file.type); 
        const splitName = file.name.split('.');
        const newFile = new File([blob], `${file.filename}_${id}.${splitName[splitName.length - 1]}`, {type: file.type });
        stateFiles.push({
          id,
          file: newFile,
          filename: newFile.name,
          url: '',
          imageUrl: '',
          file_url: URL.createObjectURL(newFile),
          media_id,
        })
        id++;
      }
    })
    const stateFileIds = stateFiles.map( file => file.id );
    this.setState({
      files: [ ...stateFiles ]
    },async () => {
      const files = await this.uploadFiles(this.state.files.map( file => file.file));
      if ( !files ) {
        this.setState((state) => ({
          files: [...state.files.filter( file => !stateFileIds.includes(file.id) )]
        }))
        return
      }
      const stateFiles = [...this.state.files];
      files.forEach(({ filename, url }) => {
        const stateFileIndex = stateFiles.findIndex( file => file.filename === filename)
        if ( stateFileIndex > -1 ) {
          stateFiles[stateFileIndex].url = url;
        };
      })
      this.setState({ files: [...stateFiles]})
    })
  }

  getAcceptedFile = (files) => {
    const { t } = this.props;
    const acceptedFiles = [];
    files.forEach( file => {
      const isValid = file && file.size <= MAX_FILE_SIZE;
      if (!isValid) {
        toast.error(t("interpolation.errorFileLimit",{
          defaultValue: "File {{name}} vượt quá 5MB cho phép, vui lòng chọn file có kích thước nhỏ hơn 5MB",
          name: file.name
        }));
      } else {
        acceptedFiles.push(file);
      }
    })
    return acceptedFiles;
  }
  fileListToArray = (list) => {
    const array = []
    for (var i = 0; i < list.length; i++) {
      array.push(list.item(i))
    }
    return array;
  }

  uploadFiles = async (files) => {
    try {
      const { data: response } = await authApi.uploadMultipleFiles({
        params: { file: files },
      });
      if ( !response.result ) {
        toast.error(response.message);
        return;
      }
      return response.data;
    } catch(err) {
      toast.error(err);
      return 
    }
  }
  loadingBtnComment = () => {
    const checkedFile = this.state.files.every(file => file.url);
    return !checkedFile || this.state.submitingComment;
  }

  handleSubmitComment = async () => {
    const checkedFile = this.state.files.every(file => file.url);
    if ( !checkedFile ) return;
    if ( !this.state.comment ) {
      return
    }
    const { object_id, parent_id, getcare_comment_type_id } = this.props;
    this.setState({ submitingComment: true });
    const { data: response } = await commentApi.createOrUpdateComment({ params: {
      getcare_comment_type_id: getcare_comment_type_id || null,
      object_id: object_id || null,
      parent_id: parent_id || null,
      comment: this.state.comment,
      getcare_comment_attach_items: this.state.files.map( file => ({
        url: file.url,
        getcare_comment_media_id: file.media_id,
      }))
    }})
    if ( !response.result ) {
      toast.error(response.message);
      this.setState({ submitingComment: false });
      return;
    }
    this.setState({ 
      submitingComment: false,
      files: [],
      comment: '', 
    },() => {
      this.props.onComplete(response.data)
    });
  }
  handleChangeFile = (e) => {
    const files = e.target.files;
    const fileArray = this.fileListToArray(files);
    const acceptedFiles = this.getAcceptedFile(fileArray);
    if ( acceptedFiles.length > 0 ) {
      this.addFile(acceptedFiles);
    }
    
    this.refInputFile.current.value = '';
    // this.refInputImageFile.current.value = '';
  }
  handleRemoveFile = (index) => {
    const files = [...this.state.files];
    files.splice(index, 1);
    this.setState({
      files: [...files]
    })
  }
  handleOpenFile = (file) => {
    file.file_url && window.open(file.file_url)
    // const win = window.open();
    // win.document.write('<iframe src="' + file.url  + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
  }

  render() {
    const loadingBtnComment = this.loadingBtnComment();
    const { t } = this.props;

    return (
      <div className={this.props.className}>
        {
          this.props.user?.id ?
          <div className={styles.commentForm}>
            <div className={styles.commentFormAvatar}>
              <Avatar
                className={styles.avatar} 
                alt={this.props.user.name} 
                src={this.props.user.avatar}
              >
                {this.props.user.name ? this.props.user.name.charAt(0) : 'G'}
              </Avatar>
            </div>
            <div className={styles.commentFormBox}>
              <div className={styles.commentFormHead}>
                <p className={styles.commentFormName}>{this.props.user.name}</p>
              </div>
              <div className={styles.commentFormInput}>
                <StyledTextField
                  disabled={this.state.submitingComment}
                  placeholder={t('Chia sẻ cảm nhận của bạn tại đây')}
                  size="medium"
                  fullWidth
                  multiline
                  rows="3"
                  variant="outlined"
                  value={this.state.comment}
                  onChange={(e) => {
                    this.setState({ comment: e.target.value })
                  }}
                />
                <Box display="flex" justifyContent="space-between">
                  <Box display="flex" style={{ gap: '.25rem' }}>
                    <Tooltip placement="top" title={t('Gửi hình ảnh hoặc video')}>
                      <ButtonBase disabled={this.state.submitingComment} onClick={() => this.refInputFile.current.click()} className={styles.commentBtn}>
                        <input 
                          ref={this.refInputFile} 
                          type="file" 
                          accept="image/*,video/*,autio/*"
                          style={{ display: 'none'}}
                          onChange={this.handleChangeFile}
                          multiple
                        />
                        <AddAPhotoIcon/>
                      </ButtonBase>
                    </Tooltip>
                    {/* <ButtonBase onClick={() => this.refInputImageFile.current.click()} className={styles.ButtonPushComment}>
                      <input 
                        ref={this.refInputImageFile} 
                        type="file" 
                        accept="image/*,video/*,autio/*"
                        style={{ display: 'none'}}
                        onChange={this.handleChangeFile}
                        multiple
                      />
                      <InsertPhotoIcon/>
                    </ButtonBase> */}
                  </Box>
                  <div className={styles.commentGroupBtn}>
                    {
                      this.props.btnClose &&
                      <ButtonBase disabled={loadingBtnComment} onClick={() => this.props.onClose && this.props.onClose()}className={`${styles.commentBtn} ${styles.commentBtnSubmit}`}>
                        {t('common:Đóng')}
                      </ButtonBase>
                    }
                    <ButtonBase disabled={loadingBtnComment} onClick={this.handleSubmitComment}className={`${styles.commentBtn} ${styles.commentBtnSubmit}`}>
                      {t('Đăng bình luận')} <SendIcon/>
                    </ButtonBase>
                  </div>
                </Box>
                {
                  this.state.files.length > 0 && 
                    <div className={styles.commentAttachment}>
                    {
                      this.state.files.map( (file,index) => (
                        <Box key={index}>
                          <div className={styles.commentAttachmentItem}>
                            {
                              !this.state.submitingComment &&
                              <div className={styles.commentAttachmentItemAction}>
                                <ButtonBase onClick={() => this.handleRemoveFile(index)}>
                                  <CancelIcon/>
                                </ButtonBase>
                              </div>
                            }
                            {
                              file.media_id === COMMENT_MEDIA_VIDEO_ID ?
                              <div onClick={() => this.handleOpenFile(file)} className={`${styles.commentAttachmentItemContent} ${!file.url && styles.commentAttachmentItemLoading}`} >
                                <video>
                                  <source src={file.file_url}/>
                                </video>
                                {
                                  file.url &&
                                  <PlayCircleOutlineIcon className={styles.commentAttachmentItemPlayVideoIcon}/>
                                }
                                <PlayCircleOutlineIcon className={styles.commentAttachmentItemPlayVideoIcon}/>
                              </div> : 
                              <div onClick={() => this.handleOpenFile(file)} className={`${styles.commentAttachmentItemContent} ${!file.url && styles.commentAttachmentItemLoading}`} style={{ backgroundImage: `url("${file.file_url}")`}}></div>
                            }
                            {/* <div onClick={() => this.handleOpenFile(file)} className={`${styles.commentAttachmentItemContent} ${!file.url && styles.commentAttachmentItemLoading}`} style={{ backgroundImage: `url("${file.imageUrl}")`}}>
                              {
                                (file.media_id === 2 && file.url )&&
                                <PlayCircleOutlineIcon className={styles.commentAttachmentItemPlayVideoIcon}/>
                              }
                            </div> */}
                          </div>
                          { !file.url && <Box component="p" >{t('common:Đang tải')}...</Box>}
                        </Box>
                      ))
                    }
                  </div>
                }
              </div>
            </div>
          </div> : <Box fontWeight="600" fontSize="18px">
            {t('Vui lòng đăng nhập để có thể chia sẻ cảm nhận của bạn tại đây')}
          </Box>
        }
      </div>
    );
  }
};

CommentForm.propTypes = {
  user: PropTypes.object,
  parent_id: PropTypes.number,
  object_id: PropTypes.number,
  getcare_comment_type_id: PropTypes.number,
};

CommentForm.defaultProps = {
  user: {},
  parent_id: null,
  object_id: null,
  getcare_comment_type_id: null,
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectLoginUser(),
});
const mapDispatchToProps = (dispatch) => {
  return {

  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withTranslation(['componentComments','common'],{ withRef: true }))(CommentForm);
