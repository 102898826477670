import React, { PureComponent } from 'react';
import * as Yup from 'yup';
import axios from 'axios';
import { debounce } from 'lodash';
import { toast } from 'react-toastify';
import { withTranslation } from "react-i18next";

import { validNumber } from 'utils/helper';
import authApi from 'utils/api/authApi';
import ecomCommonApi from 'utils/api/ecom/commonApi';

// import { mapTreeCategoriesToList } from 'utils/constanst/ecomCategoryConstants';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import { Formik } from 'formik';
import NumberFormatCustom from 'components/NumberFormatCustom/NumberFormatCustom';
import StyledButton from 'components/Styled/Button/Button';
import StyledTextField from 'components/Styled/TextField';
import StyledAutocomplete from 'components/Styled/Autocomplete/Autocomplete';
import FileUpload from 'components/FileUpload/FileUpload';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import CloseIcon from '@material-ui/icons/Close';

import styles from './FormProductInfoDialog.module.scss';
import stylesLayoutMainEcom from 'assets/styles/layouts/LayoutMainEcom.module.scss';

const PRODUCT_TYPE = 'product';
const CATEGORY_TYPE = 'category';
const MANUFACTURE_TYPE = 'manufacture';
const UOM_BASE_TYPE = 'uom_base';
const mapOptions = (data,{ labelKey, valueKey }) => data.map( item => ({
  label: item[labelKey],
  value: item[valueKey],
  data: item,
}))
const typeConfig = {
  [PRODUCT_TYPE]: {
    fetchData: ecomCommonApi.getProductList,
    // addParams: ({getcare_manufacturer_id,
    //   getcare_category_ecom_id,
    //   getcare_uom_base_id}) => {
    //   return { getcare_uom_base_id, getcare_category_ecom_id, getcare_manufacturer_id }
    // },
    labelKey: 'name',
    valueKey: 'id',
    idKey: 'getcare_product_id',
  },
  [MANUFACTURE_TYPE]: {
    fetchData: ecomCommonApi.getManufacturerList,
    // addParams: ({getcare_product_id,
    //   getcare_category_ecom_id,
    //   getcare_uom_base_id}) => {
    //   return { getcare_uom_base_id, getcare_category_ecom_id, getcare_product_id }
    // },
    labelKey: 'name',
    valueKey: 'id',
    idKey: 'getcare_manufacturer_id',
  },
  [CATEGORY_TYPE]: {
    fetchData: ecomCommonApi.getCategoryEcomList,
    addParams: ({getcare_product_id}) => {
      return { getcare_product_id }
    },
    // mapResults: (data) => {
    //   return mapTreeCategoriesToList(data || []);
    // },
    labelKey: 'name',
    valueKey: 'id',
    idKey: 'getcare_category_ecom_id',
  }, 
  [UOM_BASE_TYPE]: {
    fetchData: ecomCommonApi.getUomBaseList,
    addParams: ({getcare_product_id}) => {
      return { getcare_product_id }
    },
    labelKey: 'name',
    valueKey: 'id',
    idKey: 'getcare_uom_base_id',
  },
}

class FormProductInfoDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loadingOptions: {
        [PRODUCT_TYPE]: false,
        [CATEGORY_TYPE]: false,
        [MANUFACTURE_TYPE]: false,
        [UOM_BASE_TYPE]: false,
      },
      options: {
        [PRODUCT_TYPE]: [],
        [CATEGORY_TYPE]: [],
        [MANUFACTURE_TYPE]: [],
        [UOM_BASE_TYPE]: [],
      },
      keywords: {
        [PRODUCT_TYPE]: '',
        [CATEGORY_TYPE]: '',
        [MANUFACTURE_TYPE]: '',
        [UOM_BASE_TYPE]: '',
      },
      selectedValues: {
        [PRODUCT_TYPE]: '',
        [CATEGORY_TYPE]: '',
        [MANUFACTURE_TYPE]: '',
        [UOM_BASE_TYPE]: '',
      },
    }
    this.source = {
      [PRODUCT_TYPE]: null,
      [CATEGORY_TYPE]: null,
      [MANUFACTURE_TYPE]: null,
      [UOM_BASE_TYPE]: null,
    }
    this.refEl = React.createRef();
    this.refProductInfoFormik = React.createRef();
  }
  componentDidMount() {
    const {
      product_name,
      getcare_product_id,
      getcare_uom_base_id,
      uom_base_name,
      getcare_category_ecom_id,
      category_ecom_name,
      getcare_manufacturer_id,
      manufacturer_name,
    } = this.props.data;
    this.setState((state) => ({
      selectedValues: { 
        ...state.selectedValues, 
        [PRODUCT_TYPE]: product_name ? { label: product_name, value: getcare_product_id || '' } : null,
        [CATEGORY_TYPE]: category_ecom_name ? { label: category_ecom_name, value: getcare_category_ecom_id || '' } : null,
        [MANUFACTURE_TYPE]: manufacturer_name ? { label: manufacturer_name, value: getcare_manufacturer_id || '' } : null,
        [UOM_BASE_TYPE]: uom_base_name ? { label: uom_base_name, value: getcare_uom_base_id || '' } : null,
      }
    }))
  }
  
  _getInitialValues = () => {
    const {
      _index,
      id,
      product_name,
      getcare_product_id,
      getcare_uom_base_id,
      uom_base_name,
      getcare_category_ecom_id,
      category_ecom_name,
      getcare_manufacturer_id,
      manufacturer_name,
      quantity_number,
      images,
    } = this.props.data;

    return {
      _index,
      id: id || undefined,
      product_name: product_name || '',
      getcare_product_id: getcare_product_id || '',
      getcare_manufacturer_id: getcare_manufacturer_id || '',
      manufacturer_name: manufacturer_name || '',
      getcare_category_ecom_id: getcare_category_ecom_id || '',
      category_ecom_name: category_ecom_name || '',
      getcare_uom_base_id: getcare_uom_base_id || '',
      uom_base_name: uom_base_name || '',
      quantity_number: quantity_number || '',
      images: images || [],
    }
  }
  _getValidationSchema = () => {
    const { t } = this.props;
    return Yup.object().shape({
      // product_name: Yup.string().required('Vui lòng nhập thông tin').max(255, 'Tối đa 255 ký tự'),
      // uom_base_name: Yup.string().required('Vui lòng nhập thông tin').max(255, 'Tối đa 255 ký tự'),
      getcare_product_id: Yup.string().required(t('Vui lòng chọn')),
      getcare_uom_base_id: Yup.string().required(t('Vui lòng chọn')),
      quantity_number: Yup.number().typeError(t('Vui lòng nhập số lượng')).required(t('Vui lòng nhập số lượng')).min(1, t('Vui lòng nhập giá trị lớn hơn 0')),
    })
  }

  loadOptions = async (type, request = {}) => {
    this.source[type] && this.source[type].cancel();
    this.setState(state => ({
      options: {
        ...state.options,
        [type]: [],
      },
      loadingOptions: {
        ...state.loadingOptions,
        [type]: true,
      }
    }),() => {
      this.debounceFetchOptions(type,request);
    })
  }
  debounceFetchOptions = debounce(async (type,{ params }) => {
    const { 
      getcare_product_id,
      getcare_manufacturer_id,
      getcare_category_ecom_id,
      getcare_uom_base_id,
    } = this.refProductInfoFormik.current.values;
    const addParams = typeConfig[type].addParams ? typeConfig[type].addParams({
        getcare_product_id,
        getcare_manufacturer_id,
        getcare_category_ecom_id,
        getcare_uom_base_id,
      }) : {};
    let options = [];
    this.source[type] = axios.CancelToken.source();
    const { data: response } = await typeConfig[type].fetchData({
      params: {
        page: 1,
        page_size: 10,
        ...addParams,
        ...params,
      },
      cancelToken: this.source[type].token
    });
    if (response?.result) {
      const data = typeConfig[type].mapResults ? typeConfig[type].mapResults(response.data) : response.data;
      options = data ? mapOptions(
        data,{ 
          labelKey: typeConfig[type].labelKey, 
          valueKey: typeConfig[type].valueKey,
        }
      ) : [];
      // matched name and update values
      const option = this.state.keywords[type].trim() && options.find(option => this.state.keywords[type].trim().toLowerCase() === option.label.toLowerCase());
      if ( option ) { 
        this.refProductInfoFormik.current.setFieldValue(typeConfig[type].idKey,option.value);
        this.state.selectedValues[type] && this.setState((state) => ({
          selectedValues: { ...state.selectedValues, [type]: {...state.selectedValues[type], value: option.value} },
        }))
      }
    }
    this.setState(state => ({
      options: {
        ...state.options,
        [type]: options,
      },
      loadingOptions: {
        ...state.loadingOptions,
        [type]: false,
      }
    }));
  },250)
  getOptions = (type) => {
    if ( this.state.loadingOptions[type] ) return []
    return this.state.options[type]
  }

  handleFilesAdded = async ({ files }) => {
    let { images } = { ...this.refProductInfoFormik.current.values };
    const setFieldValue = this.refProductInfoFormik.current.setFieldValue;
    images = [...images,...files.map( file => null )];
    setFieldValue("images",[...images])
    try {
      const { data: response } = await authApi.uploadMultipleFiles({
        params: { file: files },
      });
      if (!response?.result) {
        setFieldValue('images',
          [...images.slice(0,-files.length)]
        );
        toast.error(response.message);
      } else 
        setFieldValue('images',
          [...images.slice(0,-files.length),
          ...response.data.map(item => item.url)]
        );
    } catch(err) {
      toast.error(err);
    }
  }
  handleSubmit = (values) => {
    this.props.onSubmit({ ...values })
  }
  handleValidateForm = () => {
    this.refProductInfoFormik.current.validateForm().then( error => {
      if ( Object.keys(error).length === 0 ) this.refProductInfoFormik.current.submitForm();
      for ( const err in error ) {
        const el = this.refEl.current.querySelector(`input[name="${err}"]`);
        if ( el ) { el.focus(); break; }
      }
    })
  }
  handleCloseDialog = () => {
    this.props.onClose();
  }
  handleRemoveImage = (index) => {
    const setFieldValue = this.refProductInfoFormik.current.setFieldValue;
    let images = [...this.refProductInfoFormik.current.values.images]
    images.splice(index, 1);
    setFieldValue('images',images);
  }

  render() {
    const { open, loading, t } = this.props;

    return (
      <Dialog
        ref={this.refEl}
        open={open}
        scroll='paper'
        onClose={this.handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ root: `${loading && 'OverlayLoading'}` }}
        fullWidth
      >
        <Formik
          innerRef={this.refProductInfoFormik}
          initialValues={this._getInitialValues()}
          enableReinitialize
          onSubmit={(values) => {
            this.handleSubmit(values);
          }}
          validationSchema={this._getValidationSchema()}
        >
          {
            (props) => {
              const {
                values,
                errors,
                setFieldValue,
              } = props;
              return (
                <>
                  <DialogTitle id="scroll-dialog-title">{ values._index ? `${this.props.data?.product_name}` : t('Tạo sản phẩm')}</DialogTitle>
                  <DialogContent dividers>
                    <form autoComplete="off">
                      <div className={`${stylesLayoutMainEcom.FormControl}`}>
                        <label className={errors.product_name && stylesLayoutMainEcom['FormLabel--error']} htmlFor="product_name">
                          {t('Tên sản phẩm')} <span>*</span>
                        </label>
                        <StyledAutocomplete
                          id="getcare_product_id"
                          fullWidth
                          autoComplete
                          filterOptions={(x) => x}
                          value={this.state.selectedValues[PRODUCT_TYPE]}
                          options={this.getOptions(PRODUCT_TYPE)}
                          noOptionsText={t('common:Không tìm thấy kết quả')}
                          getOptionLabel={(option) => option.label || ''}
                          getOptionSelected={(option, value) => option.value === value.value}
                          renderOption={(option) => option.value ? option.label : option.label ? `+ ${option.label}` : ''}
                          onInputChange={(event, newInputValue,reason) => {
                            // setFieldValue("product_name",newInputValue)
                            // setFieldValue("getcare_product_id",'',false)
                            // this.setState((state) => ({
                            //   keywords: { ...state.keywords, [PRODUCT_TYPE]: newInputValue },
                            //   selectedValues: { ...state.selectedValues, [PRODUCT_TYPE]: newInputValue ? { label: newInputValue , value: '' } : null },
                            // }))
                            if ( ['input','clear'].includes(reason) ) this.loadOptions(PRODUCT_TYPE,{ params: { keyword: newInputValue }});
                          }}
                          loading={this.state.loadingOptions[PRODUCT_TYPE]}
                          loadingText={t('common:Đang tìm kiếm')}
                          renderInput={(params) => (
                              <StyledTextField
                                {...params}
                                placeholder={t('Chọn sản phẩm gợi ý')}
                                variant="outlined"
                                size="medium"
                                name="getcare_product_id"
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <>
                                      {this.state.loadingOptions[PRODUCT_TYPE] ? <CircularProgress color="inherit" size={20} /> : null}
                                      {params.InputProps.endAdornment}
                                    </>
                                  ),
                                }}
                                error={!!errors.getcare_product_id}
                                helperText={errors.getcare_product_id}
                              />
                          )}
                          onOpen={() => this.loadOptions(PRODUCT_TYPE)}
                          onChange={(e, newValue) => {
                            setFieldValue("product_name",newValue?.label || '',false)
                            setFieldValue("getcare_product_id",newValue?.value || '')
                            setFieldValue("uom_base_name",'',false)
                            setFieldValue("getcare_uom_base_id",'',false)
                            this.setState((state) => ({
                              selectedValues: { 
                                ...state.selectedValues, 
                                [PRODUCT_TYPE]: newValue,
                                [UOM_BASE_TYPE]: null,
                              },
                              keywords: { ...state.keywords, [PRODUCT_TYPE]: '' },
                            }))
                          }}
                        />
                      </div>
                      <div className={`${stylesLayoutMainEcom.FormControl}`}>
                        <label htmlFor="manufacturer_name">
                          {t('Nhà sản xuất')}
                        </label>
                        <StyledAutocomplete
                          id="manufacturer_name"
                          fullWidth
                          autoComplete
                          filterOptions={(x) => x}
                          value={this.state.selectedValues[MANUFACTURE_TYPE]}
                          options={this.getOptions(MANUFACTURE_TYPE)}
                          getOptionLabel={(option) => option.label || ''}
                          getOptionSelected={(option, value) => option.value === value.value}
                          noOptionsText={t('common:Không tìm thấy kết quả')}
                          renderOption={(option) => option.value ? option.label : option.label ? `+ ${option.label}` : ''}
                          onInputChange={(event, newInputValue,reason) => {
                            if ( ['input','clear'].includes(reason) ) {
                              setFieldValue("manufacturer_name",newInputValue)
                              setFieldValue("getcare_manufacturer_id",'',false)
                              this.setState((state) => ({
                                keywords: { ...state.keywords, [MANUFACTURE_TYPE]: newInputValue },
                                selectedValues: { ...state.selectedValues, [MANUFACTURE_TYPE]: newInputValue ? { label: newInputValue , value: '' } : null },
                              }))
                              this.loadOptions(MANUFACTURE_TYPE,{ params: { keyword: newInputValue }}) 
                            };
                          }}
                          loading={this.state.loadingOptions[MANUFACTURE_TYPE]}
                          loadingText={t('common:Đang tìm kiếm')}
                          renderInput={(params) => (
                              <StyledTextField
                                {...params}
                                placeholder={t('Chọn nhà sản xuất gợi ý hoặc nhà sản xuất bạn nhập')}
                                variant="outlined"
                                size="medium"
                                name="manufacturer_name"
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <>
                                      {this.state.loadingOptions[MANUFACTURE_TYPE] ? <CircularProgress color="inherit" size={20} /> : null}
                                      {params.InputProps.endAdornment}
                                    </>
                                  ),
                                }}
                              />
                          )}
                          onOpen={() => this.loadOptions(MANUFACTURE_TYPE)}
                          onChange={(e, newValue) => {
                            setFieldValue("manufacturer_name",newValue?.label || '', false)
                            setFieldValue("getcare_manufacturer_id",newValue?.value || '', false)
                            this.setState((state) => ({
                              selectedValues: { ...state.selectedValues, [MANUFACTURE_TYPE]: newValue },
                              keywords: { ...state.keywords, [MANUFACTURE_TYPE]: '' },
                            }))
                          }}
                        />
                      </div>
                      <div className={`${stylesLayoutMainEcom.FormControl}`}>
                        <label htmlFor="category_ecom_name">
                          {t('Danh mục')}
                        </label>
                        <StyledAutocomplete
                          id="category_ecom_name"
                          fullWidth
                          value={this.state.selectedValues[CATEGORY_TYPE]}
                          options={this.getOptions(CATEGORY_TYPE)}
                          noOptionsText={t('common:Không tìm thấy kết quả')}
                          getOptionLabel={(option) => option.label || ''}
                          getOptionSelected={(option, value) => option.value === value.value}
                          renderOption={(option) => option.value ? option.label : option.label ? `+ ${option.label}` : ''}
                          onInputChange={(event, newInputValue,reason) => {
                            if ( ['input','clear'].includes(reason) ) {
                              setFieldValue("category_ecom_name",newInputValue)
                              setFieldValue("getcare_category_ecom_id",'',false)
                              this.setState((state) => ({
                                keywords: { ...state.keywords, [CATEGORY_TYPE]: newInputValue },
                                selectedValues: { ...state.selectedValues, [CATEGORY_TYPE]: newInputValue ? { label: newInputValue , value: '' } : null },
                              }))
                              this.setState((state) => ({
                                keywords: { ...state.keywords, [CATEGORY_TYPE]: newInputValue },
                              })) 
                            };
                          }}
                          loading={this.state.loadingOptions[CATEGORY_TYPE]}
                          loadingText={t('common:Đang tìm kiếm')}
                          renderInput={(params) => (
                              <StyledTextField
                                {...params}
                                placeholder={t('Chọn danh mục gợi ý hoặc danh mục bạn nhập')}
                                variant="outlined"
                                size="medium"
                                name="category_ecom_name"
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <>
                                      {this.state.loadingOptions[CATEGORY_TYPE] ? <CircularProgress color="inherit" size={20} /> : null}
                                      {params.InputProps.endAdornment}
                                    </>
                                  ),
                                }}
                              />
                          )}
                          onOpen={() => this.loadOptions(CATEGORY_TYPE)}
                          onChange={(e, newValue) => {
                            setFieldValue("category_ecom_name",newValue?.label || '', false)
                            setFieldValue("getcare_category_ecom_id",newValue?.value || '', false)
                            this.setState((state) => ({
                              selectedValues: { ...state.selectedValues, [CATEGORY_TYPE]: newValue },
                              keywords: { ...state.keywords, [CATEGORY_TYPE]: '' },
                            }))
                          }}
                        />
                      </div>
                      <div className={`${stylesLayoutMainEcom.FormControl}`}>
                        <label className={errors.uom_base_name && stylesLayoutMainEcom['FormLabel--error']} htmlFor="uom_base_name">
                          {t('Đơn vị')} <span>*</span>
                        </label>
                        <StyledAutocomplete
                          id="getcare_uom_base_id"
                          fullWidth
                          autoComplete
                          filterOptions={(x) => x}
                          value={this.state.selectedValues[UOM_BASE_TYPE]}
                          options={this.getOptions(UOM_BASE_TYPE)}
                          noOptionsText={ !this.state.selectedValues[PRODUCT_TYPE] ? t('Vui lòng chọn sản phẩm') : t('common:Không tìm thấy kết quả')}
                          getOptionLabel={(option) => option.label || ''}
                          getOptionSelected={(option, value) => option.value === value.value}
                          renderOption={(option) => option.value ? option.label : option.label ? `+ ${option.label}` : ''}
                          onInputChange={(event, newInputValue,reason) => {
                            // setFieldValue("uom_base_name",newInputValue)
                            // setFieldValue("getcare_uom_base_id",'',false)
                            // this.setState((state) => ({
                            //   keywords: { ...state.keywords, [UOM_BASE_TYPE]: newInputValue },
                            //   selectedValues: { ...state.selectedValues, [UOM_BASE_TYPE]: newInputValue ? { label: newInputValue , value: '' } : null },
                            // }))
                            if ( ['input','clear'].includes(reason) ) this.loadOptions(UOM_BASE_TYPE,{ params: { keyword: newInputValue }});
                          }}
                          loading={this.state.loadingOptions[UOM_BASE_TYPE]}
                          loadingText={t('common:Đang tìm kiếm')}
                          renderInput={(params) => (
                              <StyledTextField
                                {...params}
                                placeholder={t('Chọn đơn vị')}
                                variant="outlined"
                                size="medium"
                                name="getcare_uom_base_id"
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <>
                                      {this.state.loadingOptions[UOM_BASE_TYPE] ? <CircularProgress color="inherit" size={20} /> : null}
                                      {params.InputProps.endAdornment}
                                    </>
                                  ),
                                }}
                                error={!!errors.getcare_uom_base_id}
                                helperText={errors.getcare_uom_base_id}
                              />
                          )}
                          onOpen={() => this.state.selectedValues[PRODUCT_TYPE] && this.loadOptions(UOM_BASE_TYPE)}
                          onChange={(e, newValue) => {
                            setFieldValue("uom_base_name",newValue?.label || '',false)
                            setFieldValue("getcare_uom_base_id",newValue?.value || '')
                            this.setState((state) => ({
                              selectedValues: { ...state.selectedValues, [UOM_BASE_TYPE]: newValue },
                              keywords: { ...state.keywords, [UOM_BASE_TYPE]: '' },
                            }))
                          }}
                        />
                      </div>
                      <div className={`${stylesLayoutMainEcom.FormControl}`}>
                        <label htmlFor="quantity_number">
                          {t('Số lượng cần')}
                        </label>
                        <StyledTextField
                          placeholder={t('Nhập số lượng cần')}
                          size="medium"
                          fullWidth
                          variant="outlined"
                          name="quantity_number"
                          id="quantity_number"
                          value={values.quantity_number}
                          error={!!errors.quantity_number}
                          helperText={errors.quantity_number}
                          onChange={(e)=> {
                            setFieldValue("quantity_number",validNumber(e.target.value))
                          }}
                          size="medium"
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                          }}
                        />
                      </div>
                      <div className={`${stylesLayoutMainEcom.FormControl} ${stylesLayoutMainEcom.Margin__0}`}>
                        <label htmlFor="image">
                          {t('Hình ảnh')}
                        </label>
                        <Grid container spacing={2}>
                          {
                            values.images.length > 0 && 
                            values.images.map( (image,index) => (
                              <Grid xs={6} sm={4} key={index} item>
                                <div className={`${styles.Image} ${!image && 'BgImage--loading'} BgImage--rounded BgImage`} style={{ backgroundImage: `url(${image})` }}> 
                                  <div className={styles.Image__actions}> 
                                    <IconButton onClick={() => this.handleRemoveImage(index)} size="small" color="primary">
                                      <CloseIcon />
                                    </IconButton>
                                  </div>
                                </div> 
                              </Grid>
                            ))
                          }
                          <Grid xs={6} sm={4} item>
                            <FileUpload
                              multiple
                              accept="image/*"
                              inputId="image"
                              className={styles.FileUpload}
                              helper={
                                <>
                                  <AddPhotoAlternateIcon style={{ fontSize: 56, marginBottom: '.5rem'}}/>
                                  <h4 style={{ textAlign: 'center', fontWeight: '600' }}>{t('Tải hình ảnh')}</h4>
                                </>
                              }
                              onFilesAdded={(files) => this.handleFilesAdded({ files })}
                            />
                          </Grid>
                        </Grid>
                      </div>
                  </form>
                </DialogContent>
              </>
              )
            }
          }
        </Formik>
        <DialogActions>
          <StyledButton
            color="default"
            onClick={() => this.handleCloseDialog()}
          >
            {t('common:Đóng')}
          </StyledButton>
          <StyledButton
            variant="contained"
            color="primary"
            onClick={() => this.handleValidateForm()}
          >
            {t('common:Lưu')}
          </StyledButton>
        </DialogActions>
      </Dialog>
    );
  }
}

FormProductInfoDialog.propTypes = {
};

FormProductInfoDialog.defaultProps = {
  data: {}
}
export default withTranslation(['componentConnectSupportSupportForm','common'],{ withRef: true })(FormProductInfoDialog);
