import {
  SET_STATE,
  LOAD_BANNERS,
} from 'redux/constants/bannerConstants';

export function setState(payload) {
  return {
    type: SET_STATE,
    payload
  };
}

export function getBanners(payload) {
  return {
    type: LOAD_BANNERS,
    payload
  };
}