import React, { PureComponent } from 'react';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import authApi from 'utils/api/authApi';
import { PMS_TENANT } from 'utils/constanst/wsConstants';
import { removeWSEvent } from 'redux/actions/wsActions';
import { withTranslation } from "react-i18next";

import Button from '@material-ui/core/Button';
import DocumentUpload from './DocumentUpload/DocumentUpload';
import TextField from '@material-ui/core/TextField';
import {
  makeSelectLoginUser,
  makeSelectWSEvents,
  makeSelectUserProfile,
} from 'redux/selectors';
import * as Yup from 'yup';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import classes from './GppRegister.module.scss';
import InputAdornment from '@material-ui/core/InputAdornment';



class GppRegister extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      domain: '',
      open: false,
      errorDomain: '',
      iconDomain: '',
      openPopup: false,
      verifyDomain: false,
      iconUserName: '',
      url_tennant: '',
      openPMS: false,
      resetUrl: '',
      signup: false,
      password:'',
      approvalCustomer: false,
      errorPassword: '',
      getData: null,
      loadingMap: {
        'business_license': false,
        'gpp': false,
        'pharmacist_file': false,
        'application_form': false,
      },
    }
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this._loadCustomerAddressBook();
    // const {open} = this.state;
  }

  handleClose = () => {
    this.setState({ open: false , isLoading :false  });
  };

  handleClosePopUpPMS = () => {
    this.setState({ openPMS: false });
  };
  handleClosePopUp= () => {
    this.setState({ openPopup: false , isLoading:false ,errorPassword: '' });
    this.formRef.current.setFieldValue("password", '');
  };

  openPopup = () => {
    this.setState({ openPopup: true });
  }

 async componentDidUpdate(prevProps, prevState) {
    const { events } = this.props;
    const { dataDomain ,signup } = this.state;
    if (events && events.length && events !== prevProps.events) {
      const getDomain = this.getDataDomain();
      if (getDomain && getDomain?.data && getDomain?.data.status === "success" ) {
        this.props.removeWSEvent({
          event: PMS_TENANT,
          indexField: 'event',
        });
        this.setState({ isLoading: false, signup:true,resetUrl : getDomain.data.tenant_reset_pwd_url, url_tennant:getDomain.data.tenant_url});
        toast.success(`Quý khách đã đăng ký thành công`);
      }
    }
    if (dataDomain !== prevState.dataDomain) {
      const { data: response } = await authApi.checkDomain(dataDomain);
      if (response.result  === false  && signup !== true  ){
        toast.error(`Domain đã được đăng ký`);
        this.setState({ errorDomain :'Domain đã được đăng ký',isLoading:false, verifyDomain: false, iconDomain:'TextFieldDomainFail',iconUserName: 'TextFieldUserName'});
        return
      }else {
        this.setState({ errorDomain :'', verifyDomain: true ,iconDomain:'TextFieldDomain',iconUserName: 'TextFieldUserName'});
      }
    }
  }


  setLoadingMap = ({ name, value }) => {
    this.setState((state) => ({
      loadingMap: {
        ...state.loadingMap,
        [name]: value,
      },
    }));
  }
  isSubmitDisabled = (values) => {
    const { loadingMap ,verifyDomain } = this.state;
    const hasValues = Object.keys(values).some(key => !!values[key]);
    const hasLoading = Object.keys(loadingMap).some(key => !!loadingMap[key]);
    return hasLoading || !hasValues || verifyDomain === false ;
  }

  getDataDomain = () => {
    return this.props.events.find(e => e.event === PMS_TENANT);
  }

  _loadCustomerAddressBook = async () => {

    try {
      const { data: response } = await authApi.getProfile();

      if (response.data.getcare_customer?.tenant_url) {
        const data = {
          tenant_url: response.data.getcare_customer.tenant_url,
        }
        this.setState({ getData: data ,signup :true});
      }else {
        this.setState({ signup :false});
      }

      if (!response?.result) {
        toast.error(response.message);
        return;
      }

    } catch(err) {
      this.setState({ isLoading :false });
      toast.error(err);
    }

  }

  vadilateDomain = async (e) => {
    var re = /^[a-z0-9-]+$/;
    if (e.target.value.length < 3 ||e.target.value.length > 256 ) {
      this.setState({ errorDomain :'Hãy sử dụng từ 3 đến 255 ký tự', verifyDomain: false, iconDomain:'TextFieldDomainFail',iconUserName: 'TextFieldUserName'});
    }else{
      if (re.test(e.target.value) === false){
        this.setState({iconDomain: 'TextFieldDomainFail'});
      }else{
        this.setState({ dataDomain :e.target.value,iconDomain: ''});
      }
    }
  }

  handleSubmit = async (values) => {
    this.setState({ isLoading: true});

    const {signup, verifyDomain} = this.state;
    if ( verifyDomain === false  && signup === false  ){
      toast.error(`Domain đã được đăng ký`);
      this.setState({ errorDomain :'Domain đã được đăng ký',isLoading:false, verifyDomain: false, iconDomain:'TextFieldDomainFail',iconUserName: 'TextFieldUserName'});
      return
    }else {
      try {
        if ( signup === true ){
          this.setState({ errorDomain :'' ,verifyDomain: true});
       const { data: response } = await authApi.updateProfile({
         params: {
           business_license_file: values.business_license_url,
           gpp_file: values.gpp_url,
           pharmacist_file: values.pharmacist_url,
           application_form_file: values.application_form_url
         }
       });
       if (!response.result) {
         toast.error(response.message);
         return;
       }
       this.setState({ isLoading :false });
       toast.success(`Quý khách đã update thành công`);

     }else {
       this.setState({ errorDomain :'', verifyDomain: true ,iconDomain:'TextFieldDomain',iconUserName: 'TextFieldUserName'});

        this.setState({openPopup:true });
        if (values.password !== '' ) {
          const { data: response } = await authApi.updateProfile({
            params: {
              business_license_file: values.business_license_url,
              gpp_file: values.gpp_url,
              pharmacist_file: values.pharmacist_url,
              application_form_file: values.application_form_url,
              tenant_url: values.domain,
              tenant_username: values.username,
              tenant_password: values.password,
            }
          });
          if (!response.result) {
            this.setState({ errorPassword :response.message});
            return;
          }
          this.setState({ openPopup: false,openPMS: true});
        }
     }
   } catch(err) {
     toast.error(err);
   }
    }
  }

  _getInitialValues =  () => {
    const {getData} = this.state;
    const {user} = this.props;

    return {
      business_license_filename: '',
              business_license_url: '',
              gpp_filename: '',
              gpp_url: '',
              pharmacist_filename: '',
              pharmacist_url: '',
              application_form_filename: '',
              application_form_url: '',
              username:  user.getcare_customer.phone?user.getcare_customer.phone : '',
              domain: getData?.tenant_url ?  getData?.tenant_url  :  '',
              password:  '',
    }
  }

  _getValidationShape = () => {
    const { signup , openPopup} = this.state;
    if (signup === false){
      if (signup === false && openPopup === false){
        return {
          domain:Yup.string().trim('Vui lòng nhập domain không có khoảng trống')
          .strict(true)
          .matches(/^[a-z0-9-]+$/, 'Vui lòng nhập domain đúng định dạng latin và không kèm kí tự đặc biệt')
          .required('*Vui lòng nhập domain').max(255, 'Tối đa 255 ký tự'),
          username: Yup.string().required('*Vui lòng nhập username').max(255, 'Tối đa 55 ký tự'),

        };
      }else {
        return {
          domain:Yup.string().trim('Vui lòng nhập domain không có khoảng trống')
          .strict(true)
          .matches(/^[a-z0-9-]+$/, 'Vui lòng nhập domain đúng định dạng latin và không kèm kí tự đặc biệt')
          .required('*Vui lòng nhập domain').max(255, 'Tối đa 255 ký tự'),
          username: Yup.string().required('*Vui lòng nhập username').max(255, 'Tối đa 55 ký tự'),
          password: Yup.string()
          .matches(/^[\w~!@#$%^&*()_+{}|:"<>?`\-=[\];',./\s]{6,32}$/,'Hãy sử dụng từ 6 đến 32 ký tự. Không sử dụng mật khẩu cho trang web khác hoặc nội dung quá rõ ràng như tên thú cưng hoặc họ tên của bạn')
          .required('*Vui lòng nhập password'),
        };
      }
    }
  }

  handleFilesAdded = async ({ name, files, setFieldValue }) => {
    this.setLoadingMap({ name, value: true });
    try {
      const { data: response } = await authApi.uploadDocument({
        params: { file: files[0] },
      });
      this.setLoadingMap({ name, value: false });

      setFieldValue(`${name}_filename`, response.data?.filename);
      setFieldValue(`${name}_url`, response.data?.url);
    } catch(err) {
      toast.error(err);
      this.setLoadingMap({ name, value: false });
    }
  }


  handleChangeDomain = async (e) => {
    var re = /^[a-z0-9-]+$/;
    if (re.test(e.target.value) && e.target.value.length < 256){
      this.formRef.current.setFieldValue("domain",e.target.value.substring(0, 255));
      this.setState({ errorDomain :'',iconDomain: ''});
    }else{
      if (e.target.value.length < 256) {
        this.formRef.current.setFieldValue("domain",e.target.value.substring(0, 255));
        this.setState({ errorDomain :'Vui lòng nhập domain đúng định dạng latin và không kèm kí tự đặc biệt', verifyDomain: false, iconDomain:'TextFieldDomainFail'});
      }else{
        this.formRef.current.setFieldValue("domain",e.target.value);
        this.setState({ errorDomain :'Vui lòng nhập tối đa 255 ký tự', verifyDomain: false, iconDomain:'TextFieldDomainFail',iconUserName: 'TextFieldUserName'});
      }
    }
  }

  handleChangeUserName = async (e) => {
    this.formRef.current.setFieldValue("username", e.target.value);
    this.setState({ iconUserName:''});
  }



  render() {
    const { isLoading, loadingMap,errorDomain,signup ,url_tennant ,resetUrl ,iconUserName,openPopup,iconDomain,errorPassword,openPMS} = this.state;
    const { t,user } = this.props;
    return (
      <div className={`${classes.GppRegisterWrap} ${isLoading ? 'OverlayLoading' : ''}`}>
        <div className={classes.GppRegister}>
          <h2 className="PageTitle">{t('Đăng ký phần mềm quản lý')}</h2>
          <p className={classes.HelperText}>{t('Quản lý thông tin hồ sơ để bảo mật tài khoản')}</p>
          <Formik
            innerRef={this.formRef}
            initialValues={this._getInitialValues()}
            enableReinitialize  ={true}
            validationSchema={Yup.object().shape(this._getValidationShape())}
            onSubmit={(values) => {
              this.handleSubmit(values);
            }}

          >
            {(props) => {
              const {
                values,
                errors,
                handleSubmit,
                handleChange,
                setFieldValue,
              } = props;
              return (
                <form noValidate autoComplete="off">
                  <DocumentUpload
                    loading={loadingMap['business_license']}
                    type="image"
                    label={t('Giấy phép kinh doanh')}
                    hint="Accepted File Types: .JPG, .JPEG, .PNG, .GIF"
                    fileName={values.business_license_filename}
                    onFilesAdded={(files) => this.handleFilesAdded({
                      name: 'business_license',
                      files,
                      setFieldValue,
                    })}
                  />
                  <DocumentUpload
                    loading={loadingMap['gpp']}
                    type="image"
                    label="GPP"
                    hint="Accepted File Types: .JPG, .JPEG, .PNG, .GIF"
                    fileName={values.gpp_filename}
                    onFilesAdded={(files) => this.handleFilesAdded({
                      name: 'gpp',
                      files,
                      setFieldValue,
                    })}
                  />
                  <DocumentUpload
                    loading={loadingMap['pharmacist']}
                    type="image"
                    label={t('Dược sĩ phụ trách')}
                    hint="Accepted File Types: .JPG, .JPEG, .PNG, .GIF"
                    fileName={values.pharmacist_filename}
                    onFilesAdded={(files) => this.handleFilesAdded({
                      name: 'pharmacist',
                      files,
                      setFieldValue,
                    })}
                  />
                  <DocumentUpload
                    loading={loadingMap['application_form']}
                    type="text"
                    label={t('Đơn đề nghị cấp tài khoản liên thông cơ sở dữ liệu dược quốc gia')}
                    hint="Accepted File Types: .doc, .docx, .pdf, .excel"
                    fileName={values.application_form_filename}
                    onFilesAdded={(files) => this.handleFilesAdded({
                      name: 'application_form',
                      files,
                      setFieldValue,
                    })}
                  />

       <TextField
        variant="filled"
        size="small"
        name="domain"
        label={t('Subdomain.limgreen.com')}
        disabled={signup !== true ? false:true }
        className={`TextFieldFull TextFieldFilled ${iconDomain} TextDomain`}
        value={ url_tennant !== '' ? url_tennant : values.domain}
        onChange={this.handleChangeDomain}
        onBlur={this.vadilateDomain}
        InputProps={{
          endAdornment: (
            <InputAdornment position="center">
              {signup !== true && process.env.REACT_APP_PATH_TENANT}
            </InputAdornment>
          )
        }}
        error={!!errors.domain || errorDomain !== ''}
        id="validation-outlined-input"
        helperText={errorDomain !== ''  ? errorDomain : errors.domain}
        autoComplete="off"
      />

        <TextField
        variant="filled"
        size="small"
        name="username"
        disabled={ values.username !== '' ? true : false }
        label="Username"
        className={`TextFieldFull TextFieldFilled ${errors.username !== '' && values.username !==  '' ? iconUserName : ''} `}
        value={values.username}
        onChange={this.handleChangeUserName}
        error={!!errors.username}
        helperText={errors.username}
        autoComplete="off"
      />

          <Dialog
        open={openPopup}
        keepMounted
        onClose={this.handleClosePopUp}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{t('Thông báo confirm password')}</DialogTitle>
        <DialogContent>
        <DialogContentText>
        {t('Vui lòng cập nhập mật khẩu bạn đã đăng ký để tiến hành tạo phần mềm quản lý.')}
          </DialogContentText>
        {signup !== true ?
      <div>
     <TextField
        variant="filled"
        size="small"
        name="password"
        fullWidth
        label={t('Mật khẩu*')}
        className={`TextFieldFull TextFieldFilled`}
        value={values.password }
        onChange={handleChange}
        error={!!errors.password  || errorPassword !== ''}
        helperText={errorPassword !== '' ? errorPassword : errors.password}
        autoComplete="off"
        type="password"
      />
      </div>
      : null
      }
        </DialogContent>
        <DialogActions>
          <Button  onClick={handleSubmit}  type="button"
                      variant="contained"
                      color="secondary"
                      size="large"
                      className="ExtraPrimaryBtn">
            {t('Đăng ký phần mềm quản lý')}
          </Button>
          <Button onClick={this.handleClosePopUp} color="primary"  size="large" type="button">
          {t('Đóng')}
          </Button>
        </DialogActions>
      </Dialog>




                {
                  signup !== false || user?.pms_status === 2 ?
                  <div className={classes.ControlGroup}>
                   <div className={classes.Control}>
                  <Button
                    type="button"
                    variant="contained"
                    color="secondary"
                    size="large"
                    className="ExtraPrimaryBtn"
                    onClick={event =>  window.open(  user?.getcare_customer?.tenant_url, '_blank')}
                  >{t('Đăng nhập')}</Button>
                  </div>
                   <div className={classes.Control}>
                   <Button
                     type="button"
                     variant="contained"
                     color="secondary"
                     size="large"
                     className="ExtraPrimaryBtn"
                     onClick={event =>  window.open( user?.getcare_customer?.tenant_url_reset_password , '_blank')}
                   >Reset Password PMS</Button>
                 </div>
                       <div className={classes.Control}>
                    <Button
                      type="button"
                      variant="contained"
                      color="secondary"
                      size="large"
                      className="ExtraPrimaryBtn"
                      onClick={handleSubmit}
                    >{t('Cập nhập thông tin')}</Button>
                 </div>
                 </div>
                :
                <div className={classes.Control}>
                    <Button
                      type="button"
                      variant="contained"
                      color="secondary"
                      size="large"
                      className="ExtraPrimaryBtn"
                      onClick={handleSubmit}
                    >{t('Đăng ký')}</Button>
                  </div>
                }

        <Dialog
        open={openPMS}
        onClose={this.handleClosePopUpPMS}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Thông báo"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {t('Cảm ơn bạn đã đăng ký Pms. Quá trình đăng ký sẽ kéo dài trong giây lát, vui lòng chờ hoặc quay lại sau khi thành công.')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>

          <Button onClick={this.handleClosePopUpPMS} color="primary" autoFocus>
          {t('Đóng')}
          </Button>
        </DialogActions>
      </Dialog>
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  user: makeSelectLoginUser(),
  events: makeSelectWSEvents(),
  profile: makeSelectUserProfile()
});
const mapDispatchToProps = (dispatch) => {
  return {
    removeWSEvent: (payload) => dispatch(removeWSEvent(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default withTranslation()(compose(withConnect)(GppRegister));
