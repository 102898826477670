import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { validNumber } from 'utils/helper';
import { debounce } from 'lodash';
import { MAXIMUM_QUANTITY_ADDED_TO_CART } from 'utils/constanst/ecomFastOrderConstants';

import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import NumberFormatCustom from 'components/NumberFormatCustom/NumberFormatCustom';

import classes from './QuantityInput.module.scss';

class QuantityInput extends PureComponent {
  resetToMinOrMaxValue = (targetValue) => {
    // reset value is min if value > min
    const { min, max } = this.props;
    const val = validNumber(targetValue);
    if (val && (val < min || val > max)) {
      this.props.onChange({
        target: {
          name: this.props.name,
          value: val < min ? min : max,
        }
      });
    }
  };
  debounceResetToMinOrMaxValue = debounce((targetValue) => {
    this.resetToMinOrMaxValue(targetValue);
  }, 1000);

  handleMinus = () => {
    const val = validNumber(this.props.value);
    this.props.onChange({
      target: {
        name: this.props.name,
        value: !!val && val > 0 ? val - 1 : 0,
      }
    });
  }
  handlePlus = () => {
    const val = validNumber(this.props.value);
    this.props.onChange({
      target: {
        name: this.props.name,
        value: !!val ? val + 1 : 1,
      }
    });
  }
  handleValueChange = (e) => {
    const val = validNumber(e.target.value);
    this.props.onChange({
      target: {
        name: this.props.name,
        value: val === null ? '' : Math.ceil(val),
      }
    });
    this.debounceResetToMinOrMaxValue(e.target.value);
  }
  handleInputBlur = () => {
    this.resetToMinOrMaxValue(this.props.value);
  }

  render() {
    const { name, value, autoFocus, disabled, min, max } = this.props;

    return (<div className={`${classes.InputWrap}`}>
      <TextField
        autoComplete="off"
        className={classes.Input}
        name={name}
        value={value}
        autoFocus={autoFocus}
        variant="outlined"
        size="small"
        disabled={disabled}
        onChange={this.handleValueChange}
        onBlur={this.handleInputBlur}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton disabled={disabled || value <= min} size="small" onClick={this.handleMinus} style={{padding: 0}}><RemoveIcon fontSize="small" /></IconButton>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton disabled={disabled || value >= max} size="small" onClick={this.handlePlus} style={{padding: 0}}><AddIcon fontSize="small" /></IconButton>
            </InputAdornment>
          ),
          inputComponent: NumberFormatCustom,
        }}
      />
    </div>);
  }
}

QuantityInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  autoFocus: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

QuantityInput.defaultProps = {
  value: 0,
  autoFocus: false,
  disabled: false,
  min: 0,
  max: MAXIMUM_QUANTITY_ADDED_TO_CART,
};

export default QuantityInput;
