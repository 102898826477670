import {
  SAVE_DELIVERY_LOGISTIC_LIST,
  SAVE_LOGISTIC_LIST,
  SAVE_LOGISTIC_LIST_LOADING,
  SAVE_VENDOR_DELIVERY_FEES,
  SAVE_VENDOR_DELIVERY_FEES_LOADING,
  SAVE_SELECTED_PAYMENT,
} from 'redux/constants/logisticConstants';

const initialState = {
  vendorDeliveryFees: [],
  deliveryLogisticList: [],
  logisticList: [],
  selectedPayment: 1,
  logisticListLoading: false,
  vendorDeliveryFeesLoading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_VENDOR_DELIVERY_FEES: {
      return {
        ...state,
        vendorDeliveryFees: action.vendorDeliveryFees,
      };
    }
    case SAVE_SELECTED_PAYMENT: {
      return {
        ...state,
        selectedPayment: action.selectedPayment,
      };
    }

    case SAVE_VENDOR_DELIVERY_FEES_LOADING: {
      return {
        ...state,
        vendorDeliveryFeesLoading: action.vendorDeliveryFeesLoading,
      };
    }
    case SAVE_DELIVERY_LOGISTIC_LIST: {
      return {
        ...state,
        deliveryLogisticList: action.deliveryLogisticList,
      };
    }
    case SAVE_LOGISTIC_LIST: {
      return {
        ...state,
        logisticList: action.logisticList,
      };
    }
    case SAVE_LOGISTIC_LIST_LOADING: {
      return {
        ...state,
        logisticListLoading: action.logisticListLoading,
      };
    }
    default:
      return state;
  }
}

export default reducer;
