import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { toast } from 'react-toastify';
import { withTranslation } from "react-i18next";

import { 
  makeSelectLoginUser,
} from 'redux/selectors';
import supportProjectApi from 'utils/api/ecom/serviceRequest/connectSupport/support/projectApi';
import supportApi from 'utils/api/ecom/serviceRequest/connectSupport/support/supportApi';

import { 
  Box,
  Typography,
} from '@material-ui/core';

import ConnectSupportSupportForm from 'components/MainEcom/ConnectSupportSupportForm/ConnectSupportSupportForm';
import StyledButton from 'components/Styled/Button/Button';

import styles from './ConnectSupportProjectEdit.module.scss';
import stylesLayoutMainEcom from 'assets/styles/layouts/LayoutMainEcom.module.scss';


class ConnectSupportProjectEdit extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loadingProject: true,
      savingProject: false,
      project: null,
      project_id: Number(props.match.params.project_id),
    }
    this.refConnectSupportDonateForm = React.createRef();
  }
  componentDidMount() {
    this.loadProjectDetail()
  }

  loadProjectDetail = async () => {
    const { t } = this.props;
    this.setState({ loading: true });
    try {
      const { data: response } = await supportProjectApi.get({ params: { project_id: this.state.project_id }});
      if (!response.result) {
        toast.error(response.message);
        this.props.history.push(`/community/connect-support`)
        return;
      }
      if ( this.props.user?.getcare_customer?.code !== response.data.customer_code ) {
        this.props.history.push(`/community/connect-support`)
      }
      this.setState({ loadingProject: false, project: response.data });
    } catch (error) {
      toast.error(t("Đang xảy ra lỗi vui lòng thử lại"));
      this.props.history.push(`/community/connect-support`)
    }
  }

  handleSubmitConnectSupportSupportForm = async (values) => {
    const { t } = this.props;
    this.setState({ savingProject: true });
    const { data: response } = await supportApi.createOrUpdate({ params: values });
    if (!response.result) {
      toast.error(response.message);
      this.setState({ savingProject: true });
      return;
    }
    this.setState({ savingProject: false });
    toast.success(t(`Bạn đã cập nhật thành công`));
    this.props.history.push(`/community/connect-support/project/detail/${this.state.project_id}`)
  }
  handleSubmitForm = () => {
    this.refConnectSupportDonateForm.current.submitForm();
  }

  render() {
    const { t } = this.props;
    return (
      <Box className={`${styles.root} ${this.state.savingProject && 'OverlayLoading'}`}>
        <Typography className={stylesLayoutMainEcom.ContainerTitle} variant="h6">{t('Sửa dự án')}</Typography>
        {
          !this.state.loadingProject && <>
            <ConnectSupportSupportForm
              data={this.state.project}
              ref={this.refConnectSupportDonateForm}
              onSubmit={this.handleSubmitConnectSupportSupportForm}
            />
            <Box display="flex" justifyContent="center">
              <StyledButton variant="contained" onClick={this.handleSubmitForm} className={styles.BtnSubmit} color="primary">{t('common:Lưu')}</StyledButton>
            </Box>
          </>
        }
      </Box>
    );
  }
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectLoginUser(),
});
const mapDispatchToProps = (dispatch) => {
  return {

  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withRouter,withTranslation(['viewMainEcomConnectSupportProjectEdit','common']))(ConnectSupportProjectEdit);
