import React from 'react';
import { withStyles  } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';

const styledBy = (property, mapping) => (props) => mapping[props[property]];

const styles = (theme) => {
  return {
    root: {
      color: styledBy('color', {
        primary: theme.palette.primary.main,
      }),  
      '&$checked': {
        color: styledBy('color', {
          primary: theme.palette.primary.main,
        }),  
      },
    },
  }
};

export default withStyles(styles)(
  React.forwardRef( ({ classes, innerRef, color, ...other }, ref) =>
    <Checkbox ref={ref} className={classes.root} {...other} />
  )
);