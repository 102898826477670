import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PromotionTooltip from 'components/ecom/FastOrder/PromotionTooltip/PromotionTooltip';
import { withTranslation } from "react-i18next";

import PromotionLevels from 'components/ecom/FastOrder/PromotionLevels/PromotionLevels';
import classes from './PromotionReselectionDialog.module.scss';

class PromotionReselectionDialog extends PureComponent {
  onClose = () => {
    this.props.onClose();
  }
  handleSubmitPromotion = () => {
    this.props.onSubmitPromotion();
    this.onClose();
  }

  render() {
    const { isOpen, promotionLevels, promotionId, promotionDetails,t } = this.props;

    return (
      <Dialog
        disableBackdropClick={true}
        open={isOpen}
        onClose={this.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.Dialog}
        maxWidth={false}
      >
        <div className={`${classes.DialogContentOuter}`}>
          <div className={classes.DialogContentWrap}>
            <DialogTitle className={classes.DialogTitle}>
              {t('Danh sách khuyến mãi')}
              <PromotionTooltip/>
            </DialogTitle>
            <DialogContent className={classes.DialogContent}>
              <PromotionLevels
                isReselecting
                promotionLevels={[...promotionLevels]}
                promotionId={promotionId}
                promotionDetails={promotionDetails}
                onSubmitPromotion={this.handleSubmitPromotion}
                onCancelSelectedPromotion={this.props.onCancelSelectedPromotion}
                onPromotionLinkClick={this.props.onPromotionLinkClick}
              />
            </DialogContent>
          </div>
        </div>
      </Dialog>
    );
  }
}

PromotionReselectionDialog.propTypes = {
  isOpen: PropTypes.bool,
  promotionLevels: PropTypes.array,
  promotionId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  promotionDetails: PropTypes.object,
  onClose: PropTypes.func,
  onSubmitPromotion: PropTypes.func,
};

PromotionReselectionDialog.defaultProps = {
  isOpen: false,
  promotionLevels: [],
  promotionId: null,
  promotionName: '',
};

export default withTranslation()(PromotionReselectionDialog);

