import React, { PureComponent } from 'react';
import { toast } from 'react-toastify';
import { withTranslation } from "react-i18next";

import authApi from 'utils/api/authApi';

import { 
  Grid,
  IconButton,
} from '@material-ui/core';
import FileUpload from 'components/FileUpload/FileUpload';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import CloseIcon from '@material-ui/icons/Close';

import stylesLayoutMainEcom from 'assets/styles/layouts/LayoutMainEcom.module.scss';
import styles from './UploadImage.module.scss';

class UploadImage extends PureComponent {
  handleFilesAdded = async (files) => {
    this.props.setFieldValue('images',[...this.props.values.images,files.map( file => null )])
    try {
      const { data: response } = await authApi.uploadMultipleFiles({
        params: { file: files },
      });
      if (!response?.result) {
        this.props.setFieldValue('images',
          [...this.props.values.images.slice(0,-files.length)]
        );
        toast.error(response.message);
      } else
        this.props.setFieldValue('images',
          [...this.props.values.images.slice(0,-files.length),
          ...response.data.map(item => item.url)]
        );
    } catch(err) {
      toast.error(err);
    }
  }
  handleRemoveImage = (index) => {
    let images = [...this.props.values.images]
    images.splice(index, 1);
    this.props.setFieldValue('images',images);
  }

  render() {
    const { 
      values,
      t,
    } = this.props;

    return (
      <>
        <div className={stylesLayoutMainEcom.FormLabel}>{t('Thêm hình ảnh')}</div>
        <p className="Margin__bottom">{t('Thêm hình ảnh để yêu cầu của bạn được giải quyết nhanh hơn bạn nhé')}</p>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={3} md={2}>
            <FileUpload
              multiple
              accept="image/*"
              className={styles.UploadImage}
              helper={<div className={styles.UploadImage__content}>
                <AddPhotoAlternateIcon className={styles.UploadImage__icon}/>
                <div className={styles.UploadImage__label}>{t('Thêm hình ảnh')}</div>
              </div>}
              onFilesAdded={this.handleFilesAdded}
            />
          </Grid> 
          {
            values.images.map( (url,index) => (
              <Grid key={index} item xs={6} sm={3} md={2}>
                <div className={`${styles.Image} ${!url && 'BgImage--loading'} BgImage--rounded BgImage`} style={{ backgroundImage: `url(${url})` }}>
                  <div className={styles.Image__actions}> 
                    <IconButton onClick={() => this.handleRemoveImage(index)} size="small" color="primary">
                      <CloseIcon />
                    </IconButton>
                  </div>
                </div>
              </Grid> 
            ))
          }
        </Grid>
      </>
    );
  }
};

export default withTranslation(['componentConnectSupportDonateForm','common'],{ withRef: true })(UploadImage);
