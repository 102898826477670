const styles = (theme) => {
  return {
    stepper: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      marginLeft: theme.spacing(-2),
      marginRight: theme.spacing(-2),
      marginBottom: theme.spacing(2),
      borderBottom: `1px solid ${theme.palette.divider}`
    },
    formControl: {
      '& label .required': {
        color: theme.palette.error.main
      },
      '& $textField': {
        marginBottom: 0,
      }
    },
    textField: {},
    phoneCodeSelect: {
      width: 'auto',
    },
    hiddenPhoneNumber: {
      "& input": {
        color: "transparent",
      }
    },
    doneIcon: {
      fontSize: 64,
      color: theme.palette.success.main
    }
  }
}

export default styles;