import React from 'react';
import { withStyles  } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { get } from 'lodash';

const styledBy = (property, mapping, props) => mapping[props[property]];

const styles = (theme) => {
  // console.log("a",styledBy('variant','contained'));
  // const root = { color: '#FFF' };
  // const backgroundColor = styledBy('color', {
  //   primary: theme.palette.background.primary,
  //   secondary: theme.palette.background.secondary,
  //   default: theme.palette.background.default,
  //   info: theme.palette.background.info,
  // });
  // console.log(backgroundColor)
  // backgroundColor
  return {
    root: (props) => {
      const root = {};
      switch (props.variant) {
        case 'contained': {
          if ( props.bgcolor ) {
            const definedColors = ["backgroundRadialGradientPrimary","backgroundRadialGradientSecondary","backgroundRadialGradientInfo","backgroundRadialGradientWarning"]

            Object.assign(root,{
              background: styledBy('bgcolor', {
                backgroundRadialGradientPrimary: theme.palette.backgroundRadialGradient.primary,
                backgroundRadialGradientSecondary: theme.palette.backgroundRadialGradient.secondary,
                backgroundRadialGradientInfo: theme.palette.backgroundRadialGradient.info,
                backgroundRadialGradientWarning: theme.palette.backgroundRadialGradient.warning,
              },props) || get(theme.palette,props.bgcolor), 
              color: definedColors.includes(props.bgcolor) ? "#FFF" : get(theme.palette,props.bgcolor) ? theme.palette.getContrastText(get(theme.palette,props.bgcolor)) : theme.palette.getContrastText(props.bgcolor),
              whiteSpace: 'nowrap',
              '&.Mui-disabled': {
                background: 'rgba(0, 0, 0, 0.12)',
              }
            })
          } else if ( props.color ) {
            Object.assign(root,{
              background: styledBy('color', {
                primary: theme.palette.backgroundRadialGradient.primary,
                secondary: theme.palette.backgroundRadialGradient.secondary,
                default: theme.palette.backgroundRadialGradient.default,
                // info: theme.palette.background.info,
              },props), 
              color: '#FFF',
              whiteSpace: 'nowrap',
              '&.Mui-disabled': {
                background: 'rgba(0, 0, 0, 0.12)',
              }
            })
          }
          break;
        }
        default: {
          break;
        }
      }
      return root;
    },
  }
};

export default withStyles(styles)(
  React.forwardRef( ({ 
    className, 
    classes, 
    innerRef, 
    color, 
    muiClasses, 
    ...other 
  }, ref) => {

    return <Button 
      ref={ref} 
      classes={muiClasses}
      color={color}
      className={`${classes.root} ${className}`} 
      {...other} 
    />
}));