import React, { PureComponent } from 'react';
import * as Yup from 'yup';
import axios from 'axios';
import { debounce, isEqual, ceil, every } from 'lodash';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { withTranslation } from "react-i18next";

import { validNumber } from 'utils/helper';
import { numberFormat, matchingKeyword } from 'utils/helper';
import supportProductApi from 'utils/api/ecom/serviceRequest/connectSupport/support/productApi';
import supportProjectApi from 'utils/api/ecom/serviceRequest/connectSupport/support/projectApi';

import { Formik } from 'formik';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  InputAdornment,
} from '@material-ui/core';
import StyledCheckbox from 'components/Styled/Checkbox/Checkbox';
import NumberFormatCustom from 'components/NumberFormatCustom/NumberFormatCustom';
import StyledTextField from 'components/Styled/TextField';
import { ReactComponent as EmptyIcon } from 'assets/images/icons/Empty.svg';
import Pagination from '@material-ui/lab/Pagination';

import styles from './TableSelect.module.scss';
import stylesLayoutMainEcom from 'assets/styles/layouts/LayoutMainEcom.module.scss';


class TableSelect extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      editedItems: [],
      loading: false,
      params: {
        page: 1,
        page_size: 10,
        keyword: '',
      },
      count: 0,
      selected: [],
      onFocusInput: null
    }
    this.source = null;
    this.refProductInfoFormik = React.createRef();
    this.refScrollTable = React.createRef();
  }
  componentDidMount() {
    this.fetchData();
    this.refScrollTable.current.addEventListener('scroll',function (e) {
      this.querySelector('thead').style.transform = 'translateY(' + this.scrollTop + 'px)';
    })
  }
  componentDidUpdate(prevProps, prevState) {
    if ( !isEqual(prevState.params,this.state.params) && this.isTableSupportProduct() ) {
      this.refProductInfoFormik.current.validateForm().then( error => {
        if ( Object.keys(error).length === 0 ) {
          this.setState({ loading: true });
          this.debounceFetchData();  
        } else {
          alert("Vui lòng nhập thông tin đầy đủ sản phẩm được chọn trước khi tìm kiếm hoặc chuyển trang")
        }
      })
      
    }
  }
  updateParamsState = (params) => {
    this.setState(state => ({
      params: {
        ...state.params,
        ...params,
      }
    }))
  }
  isTableSupportProduct = () => {
    return validNumber(this.props.params.project_id) ? false : true;
  }
  fetchData = async (params) => {
    this.setState({
      loading: true,
    })
    this.source && this.source.cancel();
    this.source = axios.CancelToken.source();
    const { data: response } = await this.loadData({ 
      params: params || {},
      cancelToken: this.source.token
    });

    if (!response?.result) {
      toast.error(response.message);
      this.setState({
        count: null,
      })
      return;
    }
    if ( !this.isTableSupportProduct() ) {
      this.setState((state) => ({
        loading: false,
        items: response.data.service_request_items || [],
        count: null,
      }))
    } else {
      this.setState((state) => ({
        loading: false,
        items: response.data,
        count: ceil(response.total_records/state.params.page_size),
      }))
    }
  }
  loadData = async ({ params, cancelToken}) => {
    if ( !this.isTableSupportProduct() ) {
      return await supportProjectApi.get({ params: { project_id: this.props.params.project_id }}); 
    }
    const { page, page_size, keyword, order } = this.state.params;
    const request = {
      params: {
        page,
        page_size,
        keyword,
        order,
        ...params
      },
      cancelToken: cancelToken,
    }
    return await supportProductApi.getList(request); 
  }
  debounceFetchData = debounce(this.fetchData,500)
  isSelected = (item) => {
    const { values } = this.refProductInfoFormik.current;
    return values.items.some( val => val.item_id === item.item_id );
  }
  checkedSelectAll = () => {
    const { values } = this.refProductInfoFormik.current;
    const itemIds = values.items.map(val => val.item_id);
    return every(this.state.items,(item) => {
      return itemIds.includes(item.item_id);
    })
  }
  indeterminateSelectAll = () => {
    const { values } = this.refProductInfoFormik.current;
    const itemIds = values.items.map(val => val.item_id);
    const filteredItems = this.state.items.filter((item) => {
      return itemIds.includes(item.item_id);
    });
    return filteredItems.length < this.state.items.length && filteredItems.length > 0;
  }
  getInitialValues = () => {
    return {
      items: [],
    }
  }
  getValidationSchema = () => {
    const { t } = this.props;
    return Yup.object().shape({
      items: Yup.array().of(
        Yup.object().shape({
          quantity_number: Yup.number().nullable().required(t('Vui lòng nhập số lượng')).min(1, t('Vui lòng nhập giá trị tối thiểu 1')),
          // price_sales: Yup.number().nullable().required('Vui lòng nhập thông tin'),
        })
      )
    })
  }

  handleSelectItem = (item) => {
    const { setFieldValue, values } = this.refProductInfoFormik.current;
    if ( values.items.some( val => val.item_id === item.item_id ) ) {
      setFieldValue('items',[...values.items.filter( val => val.item_id !== item.item_id )]);
      return
    }
    setFieldValue('items',[...values.items].concat([item]));
    // this.setState((state) => {
    //   if ( state.selected.some( val => val.id === item.id ) ) return {
    //     selected: [...state.selected.filter( val => val.id !== item.id )]
    //   }
    //   return { selected: [...state.selected].concat([item]) }
    // })
  }
  handleSelectAll = () => {
    const { setFieldValue, values } = this.refProductInfoFormik.current;
    // this.setState((state) => {
    //   let selected = [...state.selected]
    //   let newSelected = [];
    //   const itemIds = state.items.map( i => i.id );
    //   state.items.forEach( item => {
    //     const selectedItemExited = selected.some( val => val.id === item.id );
    //     !selectedItemExited && newSelected.push(item);
    //   })
    //   if ( newSelected.length === 0 ) {
    //     const newState = [...state.selected.filter( val => !itemIds.includes(val.id) )];
    //     setFieldValue('items',newState);
    //     return {
    //       selected: newState
    //     }
    //   }
    //   return {
    //     selected: [...state.selected].concat(newSelected)
    //   }
    // })
    const valueItems = [...values.items]
    let newValueItems = [];
    const itemIds = this.state.items.map( i => i.item_id );
    this.state.items.forEach( item => {
      const valueItemExited = valueItems.some( val => val.item_id === item.item_id );
      !valueItemExited && newValueItems.push(item);
    })
    if ( newValueItems.length === 0 ) {
      setFieldValue('items',[...valueItems.filter( val => !itemIds.includes(val.item_id) )]);
      return
    }
    setFieldValue('items',[...valueItems].concat(newValueItems));
  }
  handlePageChange = (e,value) => {
    this.updateParamsState({ page: value })
  }
  submitForm = () => {
    const { values } = this.refProductInfoFormik.current;
    if ( values.items.length < 1) {
      alert("Vui lòng chọn ít nhất 1 sản phẩm")
      return;
    }
    this.refProductInfoFormik.current.validateForm().then( error => {
      if ( error.items && error.items.length > 0 ) {
        alert("Vui lòng nhập thông tin")
        return
      };
      this.props.onSubmit([...values.items.map(val => ({
        product_name: val.name,
        getcare_product_id: val.id || null,
        getcare_manufacturer_id: val.getcare_manufacturer_id || null,
        manufacturer_name: val.manufacturer_name || '',
        getcare_uom_base_id: val.getcare_uom_base_id || null,
        uom_base_name: val.uom_base_name || '',
        quantity_number: validNumber(val.quantity_number),
        images: val.images || [],
        price_sales: validNumber(val.price_sales) || 0,
        amount: validNumber(val.amount) || 0,
        product_description: val.product_description || null,
      }))])
    })
    
  }

  render() {
    const { t } = this.props;

    return (
      <Formik
        innerRef={this.refProductInfoFormik}
        initialValues={this.getInitialValues()}
        enableReinitialize
        onSubmit={(values) => {
          this.handleSubmit(values);
        }}
        validationSchema={this.getValidationSchema()}
      >
        {
          (props) => {
            const {
              values,
              errors,
              setFieldValue,
            } = props;

            return (
              <div className={styles.TableContainer}>
                <div className={styles.Main}>
                  <div className={styles.Header}>
                    <StyledTextField
                      placeholder={t('common:Tìm kiếm')}
                      size="medium"
                      fullWidth
                      variant="outlined"
                      value={this.state.params.keyword}
                      onChange={(e)=> {
                        this.updateParamsState({ keyword: e.target.value })
                      }}
                    />
                  </div>
                  <div className={styles.Body}>
                    <div ref={this.refScrollTable} className={`${styles.BodyWrap}`}>
                      <TableContainer className={`${stylesLayoutMainEcom.TableContainer}`}>
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              {
                                this.state.items.length > 0 && 
                                <TableCell padding="checkbox">
                                  <StyledCheckbox
                                    indeterminate={this.indeterminateSelectAll()}
                                    checked={this.checkedSelectAll()}
                                    onChange={this.handleSelectAll}
                                  />
                                </TableCell>
                              }
                              <TableCell>{t('common:STT')}</TableCell>
                              <TableCell>{t('Tên sản phẩm')}</TableCell>
                              <TableCell>{t('Nhà sản xuất')}</TableCell>
                              <TableCell>{t('Danh mục')}</TableCell>
                              <TableCell>{t('Đơn vị')}</TableCell>
                              <TableCell>{t('Số lượng cần')}</TableCell>
                            </TableRow> 
                          </TableHead>
                          <TableBody>
                            {
                              this.state.items.length > 0 ? 
                              this.state.items.map( (item,index) => {
                                if ( !this.isTableSupportProduct() ) {
                                  const check = matchingKeyword(this.state.params.keyword,item.name);
                                  if ( !check )
                                   return null;
                                }
                                return (
                                  <>
                                    <TableRow key={index}>
                                      <TableCell padding="checkbox" rowSpan={this.isSelected(item) ? 2 : 1}>
                                        <StyledCheckbox
                                          checked={this.isSelected(item)}
                                          onChange={() => this.handleSelectItem(item)}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        {index + 1}
                                      </TableCell>
                                      <TableCell>
                                        {item.name}
                                      </TableCell>
                                      <TableCell>
                                        {item.manufacturer_name}
                                      </TableCell>
                                      <TableCell>
                                        {item.category_ecom_name}
                                      </TableCell>
                                      <TableCell>
                                        {item.uom_base_name}
                                      </TableCell>
                                      <TableCell>
                                        {numberFormat(item.quantity_number)}
                                      </TableCell>
                                    </TableRow>
                                    {
                                      this.isSelected(item) && 
                                      <TableRow key={index+1}>
                                        <TableCell colSpan={6}>
                                          {
                                            (() => {
                                              const itemValuesIndex = values.items.findIndex( obj => obj.item_id === item.item_id );
                                              const indexValues = values.items[itemValuesIndex] || {};
                                              const indexErrors = errors?.items ? errors?.items[itemValuesIndex] : {};
                                              const itemValues = values.items;
                                              return (
                                                <Grid container spacing={2}>
                                                  <Grid item xs={12} sm={4}>
                                                    <div className={`${stylesLayoutMainEcom.FormControl} ${stylesLayoutMainEcom.Margin__0}`}>
                                                      <label htmlFor={`table_quantity_number_${itemValuesIndex}`}>
                                                        {t('Số lượng đóng góp')} <span>*</span>
                                                      </label>
                                                      <StyledTextField
                                                        placeholder={t('Nhập số lượng đóng góp')}
                                                        size="medium"
                                                        fullWidth
                                                        variant="outlined"
                                                        name="quantity_number"
                                                        id={`table_quantity_number_${itemValuesIndex}`}
                                                        value={indexValues.quantity_number}
                                                        error={!!indexErrors?.quantity_number}
                                                        helperText={indexErrors?.quantity_number}
                                                        onChange={(e)=> {
                                                          const quantity_number = validNumber(e.target.value);
                                                          const price_sales = indexValues.price_sales;
                                                          const amount = price_sales ? (quantity_number || 0)*price_sales : '';
                                                          if ( this.state.onFocusInput === e.target.name ) {
                                                            itemValues[itemValuesIndex] = {
                                                              ...itemValues[itemValuesIndex], 
                                                              quantity_number, 
                                                              // amount
                                                            }
                                                            setFieldValue("items",[...itemValues])
                                                          }
                                                        }}
                                                        onFocus={(e) => this.setState({ onFocusInput: e.target.name })}
                                                        size="medium"
                                                        InputProps={{
                                                          inputComponent: NumberFormatCustom,
                                                        }}
                                                      />
                                                    </div>
                                                  </Grid>
                                                  <Grid item xs={12} sm={4}>
                                                    <div className={`${stylesLayoutMainEcom.FormControl} ${stylesLayoutMainEcom.Margin__0}`}>
                                                      <label htmlFor={`table_price_sales_${itemValuesIndex}`}>
                                                        {t('Giá bán')} <span>*</span>
                                                      </label>
                                                      <StyledTextField
                                                        placeholder={t('Nhập giá bán')}
                                                        size="medium"
                                                        fullWidth
                                                        variant="outlined"
                                                        name="price_sales"
                                                        id={`table_price_sales_${itemValuesIndex}`}
                                                        value={indexValues.price_sales}
                                                        error={!!indexErrors?.price_sales}
                                                        helperText={indexErrors?.price_sales}
                                                        onChange={(e)=> {
                                                          const price_sales = validNumber(e.target.value);
                                                          const quantity_number = indexValues.quantity_number || 0;
                                                          const amount = price_sales ? quantity_number*price_sales : '';
                                                          if ( this.state.onFocusInput === e.target.name ) {
                                                            itemValues[itemValuesIndex] = {
                                                              ...itemValues[itemValuesIndex], 
                                                              price_sales, 
                                                              // amount
                                                            }
                                                            setFieldValue("items",[...itemValues])
                                                          }
                                                        }}
                                                        onFocus={(e) => this.setState({ onFocusInput: e.target.name })}
                                                        size="medium"
                                                        InputProps={{
                                                          inputComponent: NumberFormatCustom,
                                                          endAdornment: <InputAdornment position="end">đ</InputAdornment>
                                                        }}
                                                      />
                                                    </div>
                                                  </Grid>
                                                  <Grid item xs={12} sm={4}>
                                                    <div className={`${stylesLayoutMainEcom.FormControl} ${stylesLayoutMainEcom.Margin__0}`}>
                                                      <label htmlFor={`table_amount_${itemValuesIndex}`}>
                                                        {t('Giá trị tặng')} <span>*</span>
                                                      </label>
                                                      <StyledTextField
                                                        placeholder="Nhập tên sản phẩm"
                                                        placeholder={t('Nhập giá trị tặng')}
                                                        size="medium"
                                                        fullWidth
                                                        variant="outlined"
                                                        name="amount"
                                                        id={`table_amount_${itemValuesIndex}`}
                                                        value={indexValues.amount}
                                                        error={!!indexErrors?.amount}
                                                        helperText={indexErrors?.amount}
                                                        onChange={(e)=> {
                                                          const quantity_number = indexValues.quantity_number || 0;
                                                          const amount = validNumber(e.target.value);
                                                          const price_sales = amount ? amount/quantity_number : 0;
                                                          if ( this.state.onFocusInput === e.target.name ) {
                                                            itemValues[itemValuesIndex] = {
                                                              ...itemValues[itemValuesIndex], 
                                                              amount, 
                                                              // price_sales
                                                            }
                                                            setFieldValue("items",[...itemValues])
                                                          }
                                                        }}
                                                        onFocus={(e) => this.setState({ onFocusInput: e.target.name })}
                                                        size="medium"
                                                        InputProps={{
                                                          inputComponent: NumberFormatCustom,
                                                          endAdornment: <InputAdornment position="end">đ</InputAdornment>
                                                        }}
                                                      />
                                                    </div>
                                                  </Grid>
                                                  <Grid item xs={12}>
                                                    <div className={`${stylesLayoutMainEcom.FormControl} ${stylesLayoutMainEcom.Margin__0}`} style={{ width: '100%' }}>
                                                      <label htmlFor={`table_product_description_${itemValuesIndex}`}>
                                                        {t('Mô tả sản phẩm')}
                                                      </label>
                                                      <StyledTextField
                                                        placeholder={t('Nhập mô tả sản phẩm')}
                                                        size="medium"
                                                        fullWidth
                                                        multiline
                                                        variant="outlined"
                                                        id={`table_product_description_${itemValuesIndex}`}
                                                        name="product_description"
                                                        value={indexValues.product_description}
                                                        onChange={(e) => {
                                                          itemValues[itemValuesIndex] = {
                                                            ...itemValues[itemValuesIndex], 
                                                            product_description: e.target.value,
                                                          }
                                                          setFieldValue("items",[...itemValues])
                                                        }}
                                                      />
                                                    </div>
                                                  </Grid>
                                                </Grid>
                                              )
                                            })()
                                          }
                                        </TableCell>
                                      </TableRow>
                                    }
                                    
                                  </>
                                )
                              }) :
                              <TableRow>
                                <TableCell align="center" className={stylesLayoutMainEcom.TableCellEmpty} colSpan="9">
                                  <EmptyIcon className={stylesLayoutMainEcom.TableCellEmpty__icon}/>
                                  <p>{t('Không tìm thấy sản phẩm nào')}</p>
                                </TableCell>
                              </TableRow>
                            }
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </div>
                  <div className={styles.Footer}>
                    {
                      this.state.count ? 
                      <Pagination count={this.state.count} page={this.state.params.page} color="primary" shape="rounded" 
                        onChange={this.handlePageChange}
                      /> : ''
                    }
                  </div>
                </div>
              </div>
            )
          }
        }
      </Formik>
    );
  }
}

TableSelect.propTypes = {
  data: PropTypes.object
};

TableSelect.defaultProps = {

}
export default withTranslation(['componentConnectSupportDonateForm','common'],{ withRef: true })(TableSelect);
