import React, { PureComponent } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { debounce } from 'lodash';
import { suggestionPageSize2 } from 'utils/constanst/common';
import { MODULE_ECOM } from 'utils/constanst/authConstants';

import authApi from 'utils/api/authApi';
import salesTeamApi from 'utils/api/mdm/salesTeamApi';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import classes from './LoginAsDialog.module.scss';

class LoginAsDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      availableUserList: [],
      userKeywordInput: '',

      isLoading: false,
    }
  }

  componentDidMount = () => {
    this.loadSuggestedUsers({
      params: {
        keyword: this.state.userKeywordInput,
      },
    });
  }
  componentDidUpdate = (prevProps, prevState) => {
    const { userKeywordInput } = this.state;
    if (userKeywordInput !== prevState.userKeywordInput) {
      this.loadSuggestedUsers({
        params: {
          keyword: userKeywordInput,
        },
      });
    }
  }

  loadSuggestedUsers = debounce(async ({ params }) => {
    const { data: response } = await salesTeamApi.getSalesUsers({
      params: {
        ...params,
        mode: 'login_as',
        page_size: suggestionPageSize2,
      }
    });
    if (!response?.result) return;
    this.setState({
      availableUserList: response.data,
    });
  }, 500);
  onClose = () => {
    this.props.onClose();
  };
  onEntered = () => {};
  handleSubmit = async (values) => {
    this.setState({
      isLoading: true,
    });
    const { data: response } = await authApi.siginAs({
      params: {
        code: values.selectedUser?.code,
        module: MODULE_ECOM,
      }
    });
    this.setState({
      isLoading: false,
    });
    if (!response.result || !response.data) return;
    this.onClose();
    this.props.onLoginAsSuccess(response.data);
  };

  render() {
    const { isOpen } = this.props;
    const {
      availableUserList,
      isLoading,
    } = this.state;

    return (
      <Dialog
        open={isOpen}
        onClose={this.onClose}
        onEntered={this.onEntered}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick={true}
        className={classes.Dialog}
      >
        <div className={`${classes.DialogContentWrap} ${isLoading && 'OverlayLoading'}`}>
          <DialogTitle>LOGIN AS</DialogTitle>
          <Formik
            initialValues={{
              selectedUser: null,
              selectedUserId: '',
            }}
            onSubmit={(values) => {
              this.handleSubmit(values);
            }}
            validationSchema={Yup.object().shape({
              selectedUserId: Yup.string().required('Vui lòng chọn'),
            })}
          >
            {(props) => {
              const {
                values,
                errors,
                handleSubmit,
                setValues,
              } = props;
              return (
                <form noValidate autoComplete="off" className={classes.Dialog}>
                  <DialogContent className={`${classes.DialogContent}`}>
                    <div className={classes.FieldControl}>
                      <label>Chọn tài khoản cần đăng nhập</label>
                      <Autocomplete
                        fullWidth
                        size="small"
                        disableClearable
                        name="selectedUser"
                        value={values.selectedUser}
                        options={availableUserList || []}
                        getOptionLabel={(option) => option?.id ? `${option.code} - ${option.name}` : ''}
                        renderOption={(option) => option?.id ? `${option.code} - ${option.name}` : ''}
                        getOptionSelected={(option, value) => option && option.id === value.id}
                        filterOptions={(x) => x}
                        handleHomeEndKeys={false}
                        renderInput={(params) => (
                          <TextField
                            placeholder="- Chọn -"
                            {...params}
                            error={!!errors.selectedUserId}
                            helperText={errors.selectedUserId}
                            variant="outlined"
                            autoFocus
                          />
                        )}
                        onInputChange={(e, newInputValue) => {
                          this.setState({
                            userKeywordInput: newInputValue,
                          });
                        }}
                        onChange={(e, newValue) => {
                          setValues({
                            selectedUser: newValue,
                            selectedUserId: newValue?.id,
                          });
                        }}
                      />
                    </div>
                  </DialogContent>
                  <DialogActions className={classes.DialogActions}>
                    <Button
                      type="button"
                      variant="outlined"
                      onClick={this.props.onClose}
                    >
                      Huỷ
                    </Button>
                    <Button
                      type="button"
                      variant="contained"
                      onClick={handleSubmit}
                      color="secondary"
                    >
                      Xác nhận
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          </Formik>
        </div>
      </Dialog>
    );
  }
}

export default LoginAsDialog;
