import {
  SET_STATE_COMMON,
  SET_WINDOW_SIZES,
  SET_WINDOW_SCROLL,
} from 'redux/constants/commonConstants';

const initialState = {
  windowSizes: {
    width: 0,
    height: 0,
  },
  windowScroll: {
    y: 0,
    scrollingTop: false,
  },
  isMobile: false,
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (action.type) {
    case SET_STATE_COMMON: {
      return {
        ...state,
        ...payload,
      };
    }
    case SET_WINDOW_SIZES: {
      return {
        ...state,
        windowSizes: {
          ...payload,
        }
      };
    }
    case SET_WINDOW_SCROLL: {
      return {
        ...state,
        windowScroll: {
          ...payload,
        }
      };
    }
    default:
      return state;
  }
}

export default reducer;
