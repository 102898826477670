const styles = (theme) => {
  return {
    root: {
    },
    btnPhoneCode: (props) => {
      const sizeStyles = {
        small: {
          padding: "4px 12px",
          marginLeft: -12,
        }
      }
      return {
        ... sizeStyles[props.size] ? sizeStyles[props.size] : {
          padding: 12,
          marginLeft: -12,
        },
        borderRadius: theme.shape.borderRadius,
        ...theme.typography.body1
      }
    },
    flagIcon: {
      height: 18,
    },
    arrowIcon: {
      fontSize: 16
    },
    checkIcon: {},
    dropdownPhoneCode: {
      zIndex: theme.zIndex.tooltip
    },
    phoneCodeItem: {
      display: 'flex',
      alignItems: 'center',
      '& $flagIcon': {
        marginRight: theme.spacing(2),
      },
      '& $checkIcon': {
        display: 'none',
        color: theme.palette.primary.main,
      },
    },
    phoneCodeItemActive: {
      backgroundColor: theme.palette.action.hover,
      '& $checkIcon': {
        display: 'block',
      },
      '& $phoneCodeItemLabel, $phoneCodeItemCode': {
        color: theme.palette.primary.main,
        fontWeight: 600,
      }
    },
    phoneCodeItemLabel: {
      marginRight: theme.spacing(1),
    },
    phoneCodeItemCode: {
      color: theme.palette.grey[600],
    }
  }
}

export default styles;