import { createSelector } from 'reselect';

// authentication
const selectAuthState = (state) => {
  return state.authReducer || {};
};
const makeSelectLoginUser = () =>
  createSelector(selectAuthState, (substate) => substate.user);
const makeSelectAuthLoading = () =>
  createSelector(selectAuthState, (substate) => substate.authLoading);
const makeSelectAuthError = () =>
  createSelector(selectAuthState, (substate) => substate.authError);
const makeSelectUserProfile = () =>
  createSelector(selectAuthState, (substate) => substate.profile);
const makeSelectUserProvinceList = () =>
  createSelector(selectAuthState, (substate) => substate.userProvinceList);
const makeSelectCheckingAuth = () =>
  createSelector(selectAuthState, (substate) => substate.checkingAuth);

// websocket
const selectWSState = (state) => {
  return state.wsReducer || {};
};
const makeSelectGlobalWS = () =>
  createSelector(selectWSState, (substate) => substate.ws);
const makeSelectWSEvents = () =>
  createSelector(selectWSState, (substate) => substate.events);

// logistic
const selectLogisticState = (state) => {
  return state.logisticReducer || {};
};

const makeSelectVendorDeliveryFees = () =>
  createSelector(
    selectLogisticState,
    (substate) => substate.vendorDeliveryFees
  );
const makeSelectVendorDeliveryFeesLoading = () =>
  createSelector(
    selectLogisticState,
    (substate) => substate.vendorDeliveryFeesLoading
  );
const makeSelectDeliveryLogisticList = () =>
  createSelector(
    selectLogisticState,
    (substate) => substate.deliveryLogisticList
  );
const makeSelectLogisticTotalFee = () =>
  createSelector(
    selectLogisticState,
    (substate) => substate.totalFee
  );
const makeSelectLogisticList = () =>
  createSelector(
    selectLogisticState,
    (substate) => substate.logisticList
  );
  const makeSelectFastOrderSelectedPayment = () =>
  createSelector(selectLogisticState, (substate) => substate.selectedPayment);
const makeSelectLogisticListLoading = () =>
  createSelector(
    selectLogisticState,
    (substate) => substate.logisticListLoading
  );

// location
const selectLocationState = (state) => {
  return state.locationReducer || {};
};

const makeSelectCountryList = () =>
  createSelector(selectLocationState, (substate) => substate.countryList);
const makeSelectProvinceList = () =>
  createSelector(selectLocationState, (substate) => substate.provinceList);
const makeSelectDistrictList = () =>
  createSelector(selectLocationState, (substate) => substate.districtList);
const makeSelectWardList = () =>
  createSelector(selectLocationState, (substate) => substate.wardList);

// fast order
const selectFastOrderState = (state) => {
  return state.fastOrderReducer || {};
};

const makeSelectFastOrderLoading = () =>
  createSelector(selectFastOrderState, (substate) => substate.loading);
const makeSelectFastOrderActionLoading = () =>
  createSelector(selectFastOrderState, (substate) => substate.actionLoading);
const makeSelectFastOrderDetails = () =>
  createSelector(selectFastOrderState, (substate) => substate.prDetails);
const makeSelectFastOrderProducts = () =>
  createSelector(selectFastOrderState, (substate) => substate.prProducts);
const makeSelectFastOrderBonuses = () =>
  createSelector(selectFastOrderState, (substate) => substate.prBonuses);
const makeSelectFastOrderDiscounts = () =>
  createSelector(selectFastOrderState, (substate) => substate.prDiscounts);
const makeSelectFastOrderNotes = () =>
  createSelector(selectFastOrderState, (substate) => substate.prNotes);
const makeSelectFastOrderLogistic = () =>
  createSelector(selectFastOrderState, (substate) => substate.prLogistic);
const makeSelectFastOrderDelivery = () =>
  createSelector(selectFastOrderState, (substate) => substate.prDelivery);
const makeSelectFastOrderSelectedCustomerCode = () =>
  createSelector(selectFastOrderState, (substate) => substate.selectedCustomerCode);



const makeSelectFastOrderPromotionAvailables = () =>
  createSelector(selectFastOrderState, (substate) => substate.promotionAvailables);
const makeSelectFastOrderPromotionActives = () =>
  createSelector(selectFastOrderState, (substate) => substate.promotionActives);
const makeSelectFastOrderPromotionInactives = () =>
  createSelector(selectFastOrderState, (substate) => substate.promotionInactives);
const makeSelectFastOrderUsedPromotions = () =>
  createSelector(selectFastOrderState, (substate) => substate.usedPromotions);

  // const makeSelectFastOrderUsedPromotions = () =>
  // createSelector(selectFastOrderState, (substate) => substate.usedPromotions);

// pharmacy
const selectPharmacyState = (state) => {
  return state.pharmacyReducer || {};
};

const makeSelectPharmacyLoading = () =>
  createSelector(selectPharmacyState, (substate) => substate.loading);
const makeSelectPharmacyList = () =>
  createSelector(selectPharmacyState, (substate) => substate.pharmacyList);
const makeSelectPharmacyListTotal = () =>
  createSelector(selectPharmacyState, (substate) => substate.pharmacyListTotal);
const makeSelectPharmacy = () =>
  createSelector(selectPharmacyState, (substate) => substate.pharmacyDetails);

// salesOrder
const selectSalesOrderState = (state) => {
  return state.salesOrderReducer || {};
};

const makeSelectSalesOrderStatuses = () =>
  createSelector(
    selectSalesOrderState,
    (substate) => substate.salesOrderStatuses
  );

const makeSelectSalesOrderList = () =>
  createSelector(
    selectSalesOrderState,
    (substate) => substate.salesOrderList
  );
const makeSelectSalesOrderListTotal = () =>
  createSelector(
    selectSalesOrderState,
    (substate) => substate.salesOrderListTotal
  );
const makeSelectSalesOrderListQueryString = () =>
  createSelector(
    selectSalesOrderState,
    (substate) => substate.salesOrderListQueryString
  );
const makeSelectSalesOrderListOrderSelected = () =>
  createSelector(
    selectSalesOrderState,
    (substate) => substate.salesOrderListOrderSelected
  );
const makeSelectSalesOrderLoading = () =>
  createSelector(
    selectSalesOrderState,
    (substate) => substate.loading
  );

const makeSelectSalesOrder = () =>
  createSelector(
    selectSalesOrderState,
    (substate) => substate.salesOrder
  );
const makeSelectSalesOrderProducts = () =>
  createSelector(
    selectSalesOrderState,
    (substate) => substate.salesOrderProducts
  );
const makeSelectSalesOrderBonuses = () =>
  createSelector(
    selectSalesOrderState,
    (substate) => substate.salesOrderBonuses
  );
const makeSelectSalesOrderDiscounts = () =>
  createSelector(
    selectSalesOrderState,
    (substate) => substate.salesOrderDiscounts
  );
const makeSelectSalesOrderNotes = () =>
  createSelector(
    selectSalesOrderState,
    (substate) => substate.salesOrderNotes
  );
const makeSelectSalesOrderLogistic = () =>
  createSelector(
    selectSalesOrderState,
    (substate) => substate.salesOrderLogistic
  );
const makeSelectSalesOrderSalesUser = () =>
  createSelector(
    selectSalesOrderState,
    (substate) => substate.salesOrderSalesUser
  );
const makeSelectSalesOrderDelivery = () =>
  createSelector(
    selectSalesOrderState,
    (substate) => substate.salesOrderDelivery
  );

// Coupon
const selectCouponState = (state) => {
  return state.couponReducer || {};
};

const makeSelectSelectCoupon = () =>
  createSelector(
    selectCouponState,
    (substate) => substate.selectCoupon
  );
// products
const selectAdminProductState = (state) => {
  return state.productReducer || {};
};
const makeSelectProductDetails = () =>
  createSelector(
    selectAdminProductState,
    (substate) => substate.productDetails
  );
const makeSelectProductList = () =>
  createSelector(selectAdminProductState, (substate) => substate.productList);
const makeSelectProductTotal = () =>
  createSelector(selectAdminProductState, (substate) => substate.total);
const makeSelectProductIsLoading = () =>
  createSelector(selectAdminProductState, (substate) => substate.isLoading);
const makeSelectRelatedProducts = () =>
  createSelector(
    selectAdminProductState,
    (substate) => substate.relatedProducts
  );
const makeSelectBrandList = () =>
  createSelector(selectAdminProductState, (substate) => substate.brands);
const makeSelectIndustryList = () =>
  createSelector(selectAdminProductState, (substate) => substate.industries);
const makeSelectProductManufactures = () =>
  createSelector(selectAdminProductState, (substate) => substate.productManufactures);
const makeSelectProductsFilter = () =>
  createSelector(selectAdminProductState, (substate) => substate.productsFilter);
const makeSelectProductSelectedBySearching = () =>
  createSelector(selectAdminProductState, (substate) => substate.productSelectedBySearching);
const makeSelectProductsFilterVendorSelected = () =>
  createSelector(selectAdminProductState, (substate) => substate.productsFilterVendorSelected);

export {
  makeSelectLoginUser,
  makeSelectUserProfile,
  makeSelectUserProvinceList,
  makeSelectAuthLoading,
  makeSelectAuthError,
  makeSelectGlobalWS,
  makeSelectWSEvents,
  makeSelectCheckingAuth,

  makeSelectVendorDeliveryFees,
  makeSelectVendorDeliveryFeesLoading,
  makeSelectDeliveryLogisticList,
  makeSelectLogisticTotalFee,
  makeSelectLogisticList,
  makeSelectLogisticListLoading,
  makeSelectCountryList,
  makeSelectProvinceList,
  makeSelectDistrictList,
  makeSelectWardList,
  makeSelectFastOrderLoading,
  makeSelectFastOrderActionLoading,
  makeSelectFastOrderDetails,
  makeSelectFastOrderProducts,
  makeSelectFastOrderBonuses,
  makeSelectFastOrderDiscounts,
  makeSelectFastOrderNotes,
  makeSelectFastOrderLogistic,
  makeSelectFastOrderDelivery,
  makeSelectFastOrderSelectedCustomerCode,
  makeSelectFastOrderPromotionAvailables,
  makeSelectFastOrderPromotionActives,
  makeSelectFastOrderPromotionInactives,
  makeSelectFastOrderUsedPromotions,
  makeSelectPharmacyLoading,
  makeSelectPharmacyList,
  makeSelectPharmacyListTotal,
  makeSelectPharmacy,
  makeSelectSalesOrderList,
  makeSelectSalesOrderListTotal,
  makeSelectSalesOrderListQueryString,
  makeSelectSalesOrderListOrderSelected,
  makeSelectSalesOrderLoading,
  makeSelectSalesOrderStatuses,
  makeSelectSalesOrder,
  makeSelectSalesOrderProducts,
  makeSelectSalesOrderBonuses,
  makeSelectSalesOrderDiscounts,
  makeSelectSalesOrderNotes,
  makeSelectSalesOrderLogistic,
  makeSelectSalesOrderSalesUser,
  makeSelectSalesOrderDelivery,
  makeSelectFastOrderSelectedPayment,
  makeSelectSelectCoupon,

  makeSelectProductDetails,
  makeSelectProductList,
  makeSelectProductTotal,
  makeSelectProductIsLoading,
  makeSelectRelatedProducts,
  makeSelectBrandList,
  makeSelectIndustryList,
  makeSelectProductManufactures,
  makeSelectProductsFilter,
  makeSelectProductSelectedBySearching,
  makeSelectProductsFilterVendorSelected,
};
