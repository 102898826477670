// reducer
export const SET_STATE = 'getcare/common/SET_STATE';

// saga
export const LOAD_BANNERS = 'getcare/common/LOAD_BANNERS'

export default {
  SET_STATE,
  LOAD_BANNERS,
}
