import locationApi from 'utils/api/locationApi';
import { put, call } from 'redux-saga/effects';
import { locationActions } from 'redux/actions';

export function* loadCountryList(payload) {
  const { params, resolve } = payload.payload;
  try {
    yield put(locationActions.setStateLocation({ loadingCountryList: false }));

    const { data: response } = yield locationApi.getCountries(params);
    if (!response?.result || !response.data) {
      yield put(locationActions.setStateLocation({ loadingCountryList: false }));
      return;
    }
    yield put(locationActions.saveCountryList(response.data));
    yield put(locationActions.setStateLocation({ loadingCountryList: false }));
    resolve && resolve(response)
  } catch (err) {
    console.log(err);
  }
}

export function* loadProvinceList(payload) {
  const { params, resolve } = payload.payload;
  try {
    yield put(locationActions.setStateLocation({ loadingProvinceList: true }));

    const { data: response } = yield call(locationApi.getProvinces,params);
    
    if (!response?.result || !response.data) {
      yield put(locationActions.setStateLocation({ loadingProvinceList: false }));
      return;
    }
    yield put(locationActions.saveProvinceList(response.data));
    yield put(locationActions.setStateLocation({ loadingProvinceList: false }));
    resolve && resolve(response)
  } catch (err) {
    console.log(err);
  }
}
export function* loadDistrictList(payload) {
  const { params, resolve } = payload.payload;
  try {
    yield put(locationActions.setStateLocation({ loadingDistrictList: false }));

    if (!params.getcare_province_id) return;

    const { data: response } = yield locationApi.getDistricts(params);

    if (!response?.result || !response.data) {
      yield put(locationActions.setStateLocation({ loadingDistrictList: false }));
      return;
    }
    yield put(locationActions.saveDistrictList(response.data));
    yield put(locationActions.setStateLocation({ loadingDistrictdList: false }));
    resolve && resolve(response)
  } catch (err) {
    console.log(err);
  }
}
export function* loadWardList(payload) {
  const { params, resolve } = payload.payload;
  try {
    yield put(locationActions.setStateLocation({ loadingWardList: false }));

    if (!params.getcare_district_id) return;

    const { data: response } = yield locationApi.getWards(params);

    if (!response?.result || !response.data) {
      yield put(locationActions.setStateLocation({ loadingWardList: false }));
      return;
    }
    yield put(locationActions.saveWardList(response.data));
    yield put(locationActions.setStateLocation({ loadingWardList: false }));
    resolve && resolve(response)
  } catch (err) {
    console.log(err);
  }
}
