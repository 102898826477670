import {
  SET_STATE_COMMON,
  SET_WINDOW_SIZES,
  SET_WINDOW_SCROLL,
} from 'redux/constants/commonConstants';

// reducer
export function setStateCommon(payload) {
  return {
    type: SET_STATE_COMMON,
    payload,
  };
}
export function setWindowSizes(payload) {
  return {
    type: SET_WINDOW_SIZES,
    payload,
  };
}
export function setWindowScroll(payload) {
  return {
    type: SET_WINDOW_SCROLL,
    payload,
  };
}