import {
  SET_STATE_PRODUCT,
  SET_PRODUCT_LIST_PARAMS,
  SET_PRODUCT_LIST_PARAMS_BY_FILTERS,
  SET_DEFAULT_PRODUCT_LIST_PARAMS,
  SET_PRODUCT_FILTER_OPTIONS,
  RESET_PRODUCT_FILTER,

  SET_PRODUCT_DETAILS_STATES,
  SET_PRODUCT_DETAILS,
  SET_PRODUCT_DETAILS_PARAMS,

  SET_PRODUCT_RATINGS,
  SET_PRODUCT_RATINGS_PARAMS,

  SET_PRODUCT_DESCRIPTIONS,
  SET_PRODUCT_DESCRIPTIONS_PARAMS,

  SET_PRODUCT_PRICE_WHOLESALES,
  SET_PRODUCT_PRICE_WHOLESALES_PARAMS,

  SET_PRODUCT_RATING_DETAIL,
  SET_PRODUCT_RATING_DETAIL_PARAMS,

  SET_PRODUCT_RELATED_LIST_PARAMS,

  SET_STATE_PRODUCT_STATUS,
} from 'redux/constants/productConstants';

import {
  FIRST_PRODUCT_LIST,
  checkProductTypes,
} from 'utils/constanst/productConstants';
import {
  LOAD_STATUS_IDLE,
} from 'utils/constanst/statusConstants';

const defaultProductListParams = {
  page: 1,
  page_size: 40,
  lat: null,
  lng: null,
  keyword: '',
  getcare_category_ecom_id: [],
  rating: [],
  getcare_vendor_id: [],
  price_sales_from: '',
  price_sales_to: '',
  getcare_province_id: '',
  getcare_district_id: '',
  getcare_customer_code: '',
  _product_list_by: FIRST_PRODUCT_LIST,
}

const initialState = {
  productList: [],
  productListCount: 0,
  loadingProductList: false,
  productListParams: {
    ...defaultProductListParams,
  },
  productFilterOptions: {
    categoryList: [],
    price_sales_from: '',
    price_sales_to: '',
    provinceSelected: null,
    districtSelected: null,
  },

  productDetailsParams: {
    product_id: null,
    lat: null,
    lng: null,
  },
  productDetails: null,
  productDetailsStatus: LOAD_STATUS_IDLE,
  productDetailsStates: {
    quantityAddedToCart: 1, // default 1
    vendorSelected: null,
    prProductQuantityNumber: null, // default 1
  },
  loadingProductDetails: false,

  productRatings: null,
  productRatingsParams: {
    product_id: null,
    getcare_vendor_id: null,
    getcare_uom_base_id: null,
  },
  loadingProductRatings: false,

  productDescriptions: null,
  productDescriptionsParams: {
    product_id: null,
    getcare_vendor_id: null,
    getcare_uom_base_id: null,
  },
  loadingProductDescriptions: false,

  productPriceWholesales: [],
  productPriceWholesalesParams: {
    product_id: null,
    getcare_vendor_id: null,
    getcare_uom_base_id: null,
  },
  loadingProductPriceWholesales: false,

  productRatingDetail: {
    total_rows: 0,
    items: []
  },
  productRatingDetailParams: {
    product_id: null,
    getcare_vendor_id: null,
    getcare_uom_base_id: null,
    page: 1,
    page_size: 10,
  },
  loadingProductRatingDetail: false,

  productRelatedList: [],
  loadingProductRelatedList: false,
  productRelatedListParams: {
    product_id: null,
  },
  // total: 0,
  // productDetails: null,
  // isLoading: false,
  // relatedProducts: [],
  // brands: [],
  // industries: [],
  // productManufactures: [],
  // productSelectedBySearching: null,
  // productsFilterVendorSelected: null,
  // productsFilter: {
  //   page: 1,
  //   page_size: 40,
  //   order: '',
  //   getcare_manufacturer_id: [],
  //   getcare_vendor_id: [],
  //   keyword: '',
  // },
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (action.type) {
    case SET_STATE_PRODUCT: {
      return {
        ...state,
        ...payload,
      };
    }
    
    case RESET_PRODUCT_FILTER: {
      return {
        ...state,
        productListParams: {
          ...state.productListParams,
          getcare_category_ecom_id: [],
          rating: [],
          getcare_vendor_id: [],
          price_sales_from: '',
          price_sales_to: '',
          getcare_province_id: '',
          getcare_district_id: '',
        },
        productFilterOptions: {
          ...state.productFilterOptions,
          price_sales_from: '',
          price_sales_to: '',
          provinceSelected: null,
          districtSelected: null,
        }
      };
    }
    case SET_PRODUCT_LIST_PARAMS: {
      const payloadParams = {};
      const {
        _product_list_by, page,
        ...otherPayload
      } = payload;
      _product_list_by !== undefined && 
        Object.assign(payloadParams, { _product_list_by: checkProductTypes(_product_list_by) ? _product_list_by : defaultProductListParams._product_list_by })
      page !== undefined && 
        Object.assign(payloadParams, { page: Number(page) || defaultProductListParams.page })
      return {
        ...state,
        productListParams: { 
          ...state.productListParams,
          ...otherPayload,
          ...payloadParams,
          page_size: defaultProductListParams.page_size,
          // page_size: Number(payload.page_size) || defaultProductListParams.page_size,
        },
      };
    }
    case SET_PRODUCT_LIST_PARAMS_BY_FILTERS: {
      const payloadParams = {};
      const {
        _product_list_by,
        ...otherPayload
      } = payload;
      _product_list_by !== undefined && 
        Object.assign(payloadParams, { _product_list_by: checkProductTypes(_product_list_by) ? _product_list_by : defaultProductListParams._product_list_by })
      return {
        ...state,
        productListParams: { 
          ...state.productListParams,
          ...otherPayload,
          ...payloadParams,
          page: 1,
          page_size: defaultProductListParams.page_size,
          // page_size: Number(payload.page_size) || defaultProductListParams.page_size,
        },
        productListCount: 1,
      };
    }
    case SET_DEFAULT_PRODUCT_LIST_PARAMS: {
      return {
        ...state,
        productListParams: { 
          ...defaultProductListParams,
          lat: state.productListParams.lat,
          lng: state.productListParams.lng,
          getcare_customer_code: state.productListParams.getcare_customer_code,
        },
      };
    }
    case SET_PRODUCT_FILTER_OPTIONS: {
      return {
        ...state,
        productFilterOptions: { 
          ...state.productFilterOptions,
          ...payload
        },
      };
    }

    case SET_PRODUCT_DETAILS_STATES: {
      return {
        ...state,
        productDetailsStates: { 
          ...state.productDetailsStates,
          ...payload
        },
      };
    }
    case SET_PRODUCT_DETAILS: {
      return {
        ...state,
        productDetails: { 
          ...state.productDetails,
          ...payload
        },
      };
    }
    case SET_PRODUCT_DETAILS_PARAMS: {
      return {
        ...state,
        productDetailsParams: { 
          ...state.productDetailsParams,
          ...payload
        },
      };
    }

    case SET_PRODUCT_RATINGS: {
      return {
        ...state,
        productRatings: { 
          ...state.productRatings,
          ...payload
        },
      };
    }
    case SET_PRODUCT_RATINGS_PARAMS: {
      return {
        ...state,
        productRatingsParams: { 
          ...state.productRatingsParams,
          ...payload
        },
      };
    }

    case SET_PRODUCT_DESCRIPTIONS: {
      return {
        ...state,
        productDescriptions: { 
          ...state.productDescriptions,
          ...payload
        },
      };
    }
    case SET_PRODUCT_DESCRIPTIONS_PARAMS: {
      return {
        ...state,
        productDescriptionsParams: { 
          ...state.productDescriptionsParams,
          ...payload
        },
      };
    }

    case SET_PRODUCT_PRICE_WHOLESALES: {
      return {
        ...state,
        productPriceWholesales: [ 
          ...payload
        ],
      };
    }
    case SET_PRODUCT_PRICE_WHOLESALES_PARAMS: {
      return {
        ...state,
        productPriceWholesalesParams: { 
          ...state.productPriceWholesalesParams,
          ...payload
        },
      };
    }

    case SET_PRODUCT_RATING_DETAIL: {
      return {
        ...state,
        productRatingDetail: {
          ...state.productRatingDetail,
          ...payload
        },
      };
    }
    case SET_PRODUCT_RATING_DETAIL_PARAMS: {
      return {
        ...state,
        productRatingDetailParams: { 
          ...state.productRatingDetailParams,
          ...payload
        },
      };
    }

    case SET_PRODUCT_RELATED_LIST_PARAMS: {
      return {
        ...state,
        productRelatedListParams: { 
          ...state.productRelatedListParams,
          ...payload
        },
      };
    }

    case SET_STATE_PRODUCT_STATUS: {
      return {
        ...state,
        [`${payload.scope}Status`]: payload.value,
      };
    }
    
    default:
      return state;
  }
}

export default reducer;
