import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from "react-i18next";

import { 
  Box,
} from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import PhoneIcon from '@material-ui/icons/Phone';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import styles from './TabIntroduction.module.scss';

class TabIntroduction extends PureComponent {
  render() {
    const { 
      customer_phone,
      customer_name,
      address,
      description,
    } = this.props.values || {};
    const { t } = this.props;

    return (
      <div>
        <div className={styles.Title}>{t('Chi tiết dự án')}</div>
        <div className={styles.Description}>
          {description}
        </div>
        <div className={styles.Title}>{t('Thông tin liên hệ')}</div>
        <Box display="flex" flexDirection="column" style={{ gap: '.75rem' }}>
          <Box display="flex" alignItems="center" style={{ gap: '.5rem' }}>
            <PersonIcon style={{ fontSize: '24px' }}/>
            {customer_name}
          </Box>
          <Box display="flex" alignItems="center" style={{ gap: '.5rem' }}>
            <PhoneIcon style={{ fontSize: '24px' }}/>
            {customer_phone}
          </Box>
          <Box display="flex" alignItems="center" style={{ gap: '.5rem' }}>
            <LocationOnIcon style={{ fontSize: '24px' }}/>
            {address}
          </Box>
        </Box>
      </div>
    );
  }
};

TabIntroduction.propTypes = {
  values: PropTypes.object,
};

TabIntroduction.defaultProps = {
  values: {},
};

export default withTranslation(['viewMainEcomConnectSupportProjectDetail'])(TabIntroduction);
