import {
  GET_SALES_ORDER_STATUSES,
  GET_SALES_ORDER_LIST,
  GET_SALES_ORDER,

  SAVE_LOADING,
  SAVE_SALES_ORDER_LIST,
  SAVE_SALES_ORDER_LIST_TOTAL,
  SAVE_SALES_ORDER_LIST_QUERY_STRING,
  SAVE_SALES_ORDER_STATUSES,
  SAVE_SALES_ORDER_LIST_ORDER_SELECTED,

  SAVE_SALES_ORDER,
  SAVE_SALES_ORDER_PRODUCTS,
  SAVE_SALES_ORDER_BONUSES,
  SAVE_SALES_ORDER_DISCOUNTS,
  SAVE_SALES_ORDER_ALL_INFO,
  SAVE_SALES_ORDER_NOTES,
  CONFIRM_PURCHASING,
  CONFIRM_SUCCESS,
  SAVE_SALES_ORDER_LOGISTIC,
  SAVE_SALES_ORDER_SALES_USER,
  SAVE_SALES_ORDER_DELIVERY,
} from 'redux/constants/oms/salesOrderConstants';

export function getSalesOrderStatuses(payload) {
  return {
    type: GET_SALES_ORDER_STATUSES,
    payload,
  };
}

export function getSalesOrderList(payload) {
  return {
    type: GET_SALES_ORDER_LIST,
    payload,
  };
}
export function getSalesOrder(id) {
  return {
    type: GET_SALES_ORDER,
    id,
  };
}

// mutation
export function saveSalesOrderStatuses(salesOrderStatuses) {
  return {
    type: SAVE_SALES_ORDER_STATUSES,
    salesOrderStatuses,
  };
}

export function saveSalesOrderList(salesOrderList) {
  return {
    type: SAVE_SALES_ORDER_LIST,
    salesOrderList,
  };
}
export function saveSalesOrderListTotal(salesOrderListTotal) {
  return {
    type: SAVE_SALES_ORDER_LIST_TOTAL,
    salesOrderListTotal,
  };
}
export function saveSalesOrderListQueryString(salesOrderListQueryString) {
  return {
    type: SAVE_SALES_ORDER_LIST_QUERY_STRING,
    salesOrderListQueryString,
  };
}
export function saveSalesOrderListOrderSelected(salesOrderListOrderSelected) {
  return {
    type: SAVE_SALES_ORDER_LIST_ORDER_SELECTED,
    salesOrderListOrderSelected,
  };
}

export function confirmSuccess(payload) {
  return {
    type: CONFIRM_SUCCESS,
    payload,
  };
}

export function saveLoading(loading) {
  return {
    type: SAVE_LOADING,
    loading,
  };
}

export function saveSalesOrder(salesOrder) {
  return {
    type: SAVE_SALES_ORDER,
    salesOrder,
  };
}
export function saveSalesOrderProducts(salesOrderProducts) {
  return {
    type: SAVE_SALES_ORDER_PRODUCTS,
    salesOrderProducts,
  };
}

export function saveSalesOrderBonuses(salesOrderBonuses) {
  return {
    type: SAVE_SALES_ORDER_BONUSES,
    salesOrderBonuses,
  };
}
export function saveSalesOrderDiscounts(salesOrderDiscounts) {
  return {
    type: SAVE_SALES_ORDER_DISCOUNTS,
    salesOrderDiscounts,
  };
}

export function saveSalesOrderNotes(salesOrderNotes) {
  return {
    type: SAVE_SALES_ORDER_NOTES,
    salesOrderNotes,
  };
}

export function saveSalesOrderLogistic(salesOrderLogistic) {
  return {
    type: SAVE_SALES_ORDER_LOGISTIC,
    salesOrderLogistic,
  };
}
export function saveSalesOrderSalesUser(salesOrderSalesUser) {
  return {
    type: SAVE_SALES_ORDER_SALES_USER,
    salesOrderSalesUser,
  };
}
export function saveSalesOrderDelivery(salesOrderDelivery) {
  return {
    type: SAVE_SALES_ORDER_DELIVERY,
    salesOrderDelivery,
  };
}

export function confirmPurchasing(payload) {
  return {
    type: CONFIRM_PURCHASING,
    payload,
  };
}
export function saveSalesOrderAllInfo(salesOrderData) {
  return {
    type: SAVE_SALES_ORDER_ALL_INFO,
    salesOrderData,
  };
}