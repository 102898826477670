import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { sumBy } from 'lodash';
import { currencyFormatVN, validNumber, roundCurrency } from 'utils/helper';
import { isCollaborator } from 'utils/constanst/authConstants'
import { withTranslation } from "react-i18next";
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import ChevronRight from '@material-ui/icons/ChevronRight';
import InputAdornment from '@material-ui/core/InputAdornment';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import DialogTitle from '@material-ui/core/DialogTitle';
import Panel from 'components/Panel/Panel';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import CouponAvailables from '../CouponAvailables/CouponAvailables';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectFastOrderProducts,
  makeSelectLoginUser,
  makeSelectSelectCoupon,
  makeSelectFastOrderDetails
} from 'redux/selectors';
import { saveCouponItems } from 'redux/actions/ecom/fastOrderActions';
import { withRouter } from 'react-router-dom';

import classes from './FastOrderAmount.module.scss';

class FastOrderAmount extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      errorVoucher: '',
      openConfirm : false,
      selectedCouponData: [],
      coupon: '',
    }
  }

  componentDidMount() {
    const {selectedCouponData} = this.state;
    this.setState({
      coupon: selectedCouponData.map(x => x.code).toString(),
      // selectedCoupon: selectCoupon
    });
  }


  componentDidUpdate(prevProps, prevState) {
    const {selectedCouponData} = this.state;
    const {selectCoupon} = this.props;
    if (selectedCouponData !== prevState.selectedCouponData && selectedCouponData !== undefined) {
      this.setState({
        coupon: selectedCouponData.map(x => x.code).toString(),
        selectedCoupon: selectCoupon
      });
    }
    if (selectCoupon !== prevProps.selectCoupon && selectCoupon) {
      this.setState({
        coupon: selectCoupon.map(x => x.code).toString(),
        selectedCouponData: selectCoupon
      });
    }
  }

  handleToggleVendorsPanel = () => {
    // console.log(this.props.selectCoupon);
    if (this.props.selectCoupon[0]?.code){
      this.setState({
        openConfirm: true,
      });
    }else{
      this.setState({
        open: true,
      });
    }
  }


  onClose  = () => {
    this.setState({
      openConfirm: false,
    });
  }

  onSubmit  = () => {
    this.setState({
      openConfirm: false,selectedCouponData: []
    });
    this.props.saveCouponItems([]);
     this.props.onUpdatePRDraft({
            vouchers: [],
            willUpdatePRDetails: true
          });
    this.setState({
      open: true,
    });
  }


  handleClose = () => {
    this.setState({
      open: false
    });
  }


  handleSelectedCouponChange = (e, vendor,updateVoucher) => {
    var uniq = {}
    if (updateVoucher === 'input'){
      const arrInput  = [...this.state.selectedCouponData, {...vendor}];
      if (arrInput.length > 2){
        this.setState({
          errorVoucher: 'Chọn tối đa 2 khuyến mại'
        });
      }else{
        var arrFiltered = arrInput.filter(obj => !uniq[obj.id] && (uniq[obj.id] = true));
          this.setState({
            selectedCouponData: arrFiltered
          });
          // this.props.onUpdatePRDraft({
          //   vouchers: arrFiltered,
          //   willUpdatePRDetails: true
          // });
      }
    }else{
      const isChecked = e.target.checked;

      var arr  = isChecked ? [...this.state.selectedCouponData, {...vendor}] : this.state.selectedCouponData.filter(item => item.id !== vendor.id);
      if (arr.length > 2){
        this.setState((state) => ({
          selectedCouponData: isChecked ? [...state.selectedCouponData, {...vendor}] : state.selectedCouponData.filter(item => item.id !== vendor.id),
        }));
        var arrFiltered = arr.filter(obj => !uniq[obj.id] && (uniq[obj.id] = true));
          // this.props.onUpdatePRDraft({
          //   vouchers: arrFiltered,
          //   willUpdatePRDetails: true
          // });
      }else{
        this.setState((state) => ({
          selectedCouponData: isChecked  === true ? [...state.selectedCouponData, {...vendor}] : state.selectedCouponData.filter(item => item.id !== vendor.id),
        }));

        var arrFiltered = arr.filter(obj => !uniq[obj.id] && (uniq[obj.id] = true));
          // this.props.onUpdatePRDraft({
          //   vouchers: arrFiltered,
          //   willUpdatePRDetails: true
          // });
      }
    }
  }

  onRemoveCouponChange = (e, item) => {
      const filteredCoupon = this.state.selectedCouponData.filter((x) => x.id !== item.id);
      this.setState({
        selectedCouponData: filteredCoupon
      });
      this.props.onUpdatePRDraft({
        vouchers: filteredCoupon,
      });
  }

  // handleSelectedVendorChange = (e, vendor) => {
  //   const isChecked = e.target.checked;
  //   this.setState((state) => ({
  //     selectedCouponData: isChecked ? [...state.selectedCouponData, {...vendor}] : state.selectedCouponData.filter(item => item.id !== vendor.id),
  //   }));
  // }



  handleSubmitSelectedPromotion = (coupon,closePromotion) => {
    if (coupon) {
      this.setState({
        coupon: coupon.map(x => x.code).toString(),
      });
      if (closePromotion === true) {
        this.setState({
          open: false
        });
      }
      this.props.saveCouponItems(coupon);
      this.props.onUpdatePRDraft({
        vouchers: coupon,
        willUpdatePRDetails: true
      });
    }
  }

  render() {

    const { user, prDetails, isFinalStep, subTotal, amountDiscount, amountDelivery, amountShippingCoupon, amountTotal,t,disabled } = this.props;
    const { open ,coupon,selectedPromotionId,selectedCouponData,errorVoucher ,openConfirm} = this.state;
    const _isCollaborator = isCollaborator(user)
    return (
      <Panel
        title={isFinalStep ? t('Tổng đơn hàng'): t('Hoá đơn tạm tính')}
        panelClassName={classes.Panel}
        content={
          <div className={classes.Wrap}>
            {
              isFinalStep ?
          <div className={`${classes.Info} ${classes.InfoLine}`}>
              <label>{t('Mã voucher')}</label>
              <TextField
          inputRef={this.keywordInputRef}
          value={coupon}
          autoComplete="off"
          name="name"
          disabled={isFinalStep? false :true}

          variant="outlined"
          size="small"
          // className={`${classes.SearchField} ${isOpen ? classes.Focus : ''}`}
          placeholder={t('Chọn hoặc nhập mã')}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <ChevronRight fontSize="small" />
              </InputAdornment>
            ),
          }}
          onClick={isFinalStep ? this.handleToggleVendorsPanel : null}
        />
            </div> : null
            }


            <div className={`${classes.Info} ${classes.InfoLine}`}>
              <label>{t('Tiền hàng')}</label>
              {validNumber(prDetails?.amount)
                ? currencyFormatVN(roundCurrency(prDetails?.amount + prDetails?.amount_vat))
                : `0`}
            </div>
            <div className={`${classes.Info} ${classes.InfoLine}`}>
              <label>{t('Giảm giá')}</label>
              {validNumber(prDetails?.amount_discount)
                ? `-${currencyFormatVN(
                    roundCurrency(prDetails?.amount_discount)
                  )}`
                : `0`}
            </div>
            <div className={`${classes.Info} ${classes.InfoLine}`}>
              <label>{t('Phí vận chuyển')}</label>
              {validNumber(prDetails?.amount_delivery ? prDetails?.amount_delivery : '')
                ? currencyFormatVN(roundCurrency(prDetails?.amount_delivery ? prDetails?.amount_delivery : ''))
                : `0`}
            </div>
            {prDetails?.amount_shipping_coupon > 0 && (
              <div className={`${classes.Info} ${classes.InfoLine}`}>
                <label>{t('Shipping coupon')}</label>
                {`-${currencyFormatVN(
                  roundCurrency(prDetails?.amount_shipping_coupon)
                )}`}
              </div>
            )}
            <div className={`${classes.Info} ${classes.InfoLine} ${classes.InfoTotal}`}>
              <label>{t('Tổng tiền')}</label>
              <span className={classes.Amount}>
                {validNumber(prDetails?.amount_total)
                  ? currencyFormatVN(roundCurrency(prDetails?.amount_total))
                  : `0`}
              </span>
            </div>
            <div className={`${classes.Info} ${classes.HelpLine}`}>
            {t('Đã bao gồm VAT nếu có')}
            </div>
            {
              _isCollaborator && (
                <div className={`${classes.Info} ${classes.InfoLine} ${classes.InfoTotal}`}>
                  <Box color="info.dark" >{t('Tổng tiền hoa hồng')}</Box>
                  <Box color="info.dark" component="span">
                  { currencyFormatVN(this.props.prDetails?.amount_sales_commission || 0) }
                  </Box>
                </div>
              )
            }
            <Dialog
        open={open}
        disableBackdropClick={true}
        fullWidth
        className={classes.Dialog}
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{t('Voucher')}
        <Tooltip
        placement="bottom-start"
        title={<div className={classes.Content}>
            <p>- {t('Thẻ ưu đãi (voucher) được phát hành bởi Phahub, tuân thủ các điều khoản và điều kiện do Phahub quy định.')}</p>
          </div>
        }
      >
        <InfoOutlinedIcon className={classes.TooltipIcon}/>
      </Tooltip>

        </DialogTitle>

        <DialogContent>
        <CouponAvailables
                    selectedPromotionId={selectedPromotionId}
                    onRemoveCouponChange={this.onRemoveCouponChange}
                    selectedCoupon={selectedCouponData}
                    errorVoucher={errorVoucher}
                    location={this.props.location}
                    onSelectedCouponChange={this.handleSelectedCouponChange}
                    onSubmitSelectedPromotion={this.handleSubmitSelectedPromotion}
                    onCancelPromotion={this.handleClose}
                  />
        </DialogContent>
      </Dialog>

      <Dialog
        open={openConfirm}
        disableBackdropClick={true}
        fullWidth
        className={classes.Dialog}
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{t('Voucher')}
        <Tooltip
        placement="bottom-start"
        title={<div className={classes.Content}>
            <p>- {t('Thẻ ưu đãi (voucher) được phát hành bởi Phahub, tuân thủ các điều khoản và điều kiện do Phahub quy định.')}</p>
          </div>
        }
      >
        <InfoOutlinedIcon className={classes.TooltipIcon}/>
      </Tooltip>

        </DialogTitle>

        <DialogContent className={classes.DialogContent}>
          {t('Bạn có muốn nhập lại coupon không?')}
        </DialogContent>
              <DialogActions className={classes.DialogFooter}>
                <Button onClick={this.onClose}>
                  {t('Huỷ bỏ')}
                </Button>
                <Button
                  onClick={this.onSubmit}
                  variant="contained"
                  color="secondary"
                >
                  {t('Xác nhận')}
                </Button>
                </DialogActions>
      </Dialog>

          </div>
        }
      />
    );
  }
}

FastOrderAmount.propTypes = {
  isFinalStep: PropTypes.bool,
};
FastOrderAmount.defaultProps = {
  isFinalStep: false,
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectLoginUser(),
  selectCoupon: makeSelectSelectCoupon(),
  prDetails: makeSelectFastOrderDetails(),
  prProducts: makeSelectFastOrderProducts(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    saveCouponItems: (payload) => dispatch(saveCouponItems(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default withTranslation()(compose(withConnect, withRouter)(FastOrderAmount));
