import axiosAuth from 'utils/axios/axiosAuth';

export default {
  getCharityGiverList: ({ params })  => {
    return axiosAuth.get(`/ecom/service_request/connect_support/charity_giver/${params.project_id}`);
  },
  createOrUpdate: ({ params })  => {
    return axiosAuth.post(`/ecom/service_request/connect_support/support`, params);
  },
  pushFavourite: ({ params })  => {
    return axiosAuth.post(`/ecom/service_request/connect_support/support_favourite`, params);
  },
  createSupportOrderDraft: ({ params })  => {
    return axiosAuth.post(`/ecom/service_request/connect_support/support_order_draft`, params);
  },
  getCharityLeaderboardList: ({ params })  => {
    return axiosAuth.get(`/ecom/service_request/connect_support/charity_leaderboard/${params.project_id}`, params);
  },
};
