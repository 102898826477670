import commonApi from 'utils/api/ecom/commonApi';
import { put } from 'redux-saga/effects';
import { bannerActions } from 'redux/actions';
import { toast } from 'react-toastify';

export function* loadBanners({ payload }) {
  try {
    const { params } = payload;
    yield put(bannerActions.setState({ loadingBanners: true }));
    const { data: response } = yield commonApi.getBannerList({ params });
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    yield put(bannerActions.setState({ loadingBanners: false, banners: response.data || [] }));
  } catch (err) {
    console.log("loadBanners",err);
    toast.error(err);
  }
}

export default {
  loadBanners,
}