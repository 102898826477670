// reducer
export const GET_PROVINCE_LIST = 'getcare/location/GET_PROVINCE_LIST';
export const GET_DISTRICT_LIST = 'getcare/location/GET_DISTRICT_LIST';
export const GET_WARD_LIST = 'getcare/location/GET_WARD_LIST';
export const GET_COUNTRY_LIST = 'getcare/location/GET_COUNTRY_LIST';
export const SET_STATE_LOCATION = 'getcare/location/SET_STATE_LOCATION';

// saga
export const SET_COUNTRY_LIST = 'getcare/location/SET_COUNTRY_LIST';
export const SET_PROVINCE_LIST = 'getcare/location/SET_PROVINCE_LIST';
export const SET_DISTRICT_LIST = 'getcare/location/SET_DISTRICT_LIST';
export const SET_WARD_LIST = 'getcare/location/SET_WARD_LIST';
