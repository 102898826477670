import { groupBy } from 'lodash';

/* auth */
export const CUSTOMER_PAGE = `/customer`;

export const MAX_FILE_SIZE = 4*1024*1024;

export const listPageSizes = [50, 100, 150];
export const suggestionPageSize = 250;
export const suggestionPageSize2 = 20;

export const ACTIVE = 1;
export const DEACTIVE = -1;
export const activeStatusMap = {
  [ACTIVE]: 'Đang hoạt động',
  [DEACTIVE]: 'Ngừng hoạt động',
};

/* logistic provider */

export const LOGISTIC_GETCARE_ID = 1;
export const LOGISTIC_CHANH_XE_ID = 2;
export const LOGISTIC_CLIENT_ID = 4;
export const LOGISTIC_VENDOR_ID = 5;
export const LOGISTIC_OTHER_ID = 999;

export const LOGISTIC_GETCARE_TYPE = 1;
export const LOGISTIC_CHANHXE_TYPE = 2;
export const LOGISTIC_OTHER_TYPE = 3;
export const LOGISTIC_CLIENT_TYPE = 4;
export const LOGISTIC_VENDOR_TYPE = 5;

export const IS_SPLIT_ORDER_BY_VENDOR = true;

export const logisticGroupsMap = {
  [LOGISTIC_GETCARE_TYPE]: 'Phahub giao hàng',
  [LOGISTIC_CHANHXE_TYPE]: 'Gửi xe khách hoặc nhà xe',
  [LOGISTIC_OTHER_TYPE]: 'Đơn vị vận chuyển khác',
  [LOGISTIC_CLIENT_TYPE]: 'Khách tới nhận hàng',
  [LOGISTIC_VENDOR_TYPE]: 'Nhà cung cấp giao hàng',
}

export function getProvidersGroups(providers) {
  const groupsMap = groupBy(providers, 'type');
  return Object.keys(groupsMap).map(key => ({
    typeId: key,
    typeLabel: isThirdPartyLogisticProvider(key) ? logisticGroupsMap[key] : groupsMap[key][0].name,
    providers: groupsMap[key],
    selectedProvider: undefined,
  }));
}
export function getGroup(typeId, groups) {
  return groups.find(group => [typeId, typeId + ''].includes(group.typeId));
}
export function isThirdPartyLogisticProvider(providerTypeId) {
  return [LOGISTIC_OTHER_TYPE, LOGISTIC_OTHER_TYPE + ''].includes(providerTypeId);
}
export function isChanhXeLogisticProvider(providerTypeId) {
  return [LOGISTIC_CHANHXE_TYPE, LOGISTIC_CHANHXE_TYPE + ''].includes(providerTypeId);
}
export function isGetcareLogisticProvider(providerTypeId) {
  return [LOGISTIC_GETCARE_TYPE, LOGISTIC_GETCARE_TYPE + ''].includes(providerTypeId);
}
export function isClientWillReceiveProvider(providerTypeId) {
  return [LOGISTIC_CLIENT_TYPE, LOGISTIC_CLIENT_TYPE + ''].includes(providerTypeId);
}
export function isVendorLogisticProvider(providerTypeId) {
  return [LOGISTIC_VENDOR_TYPE, LOGISTIC_VENDOR_TYPE + ''].includes(providerTypeId);
}

export function isThirdPartyLogisticProviderId(providerId) {
  return ![
    LOGISTIC_CHANH_XE_ID,
    LOGISTIC_CHANH_XE_ID + '',
    LOGISTIC_GETCARE_ID,
    LOGISTIC_GETCARE_ID + '',
    LOGISTIC_VENDOR_ID,
    LOGISTIC_VENDOR_ID + '',
    LOGISTIC_CLIENT_ID,
    LOGISTIC_CLIENT_ID + '',
  ].includes(providerId);
}
export function isChanhXeLogisticProviderId(providerId) {
  return [LOGISTIC_CHANH_XE_ID, LOGISTIC_CHANH_XE_ID + ''].includes(providerId);
}
export function isGetcareLogisticProviderId(providerId) {
  return [LOGISTIC_GETCARE_ID, LOGISTIC_GETCARE_ID + ''].includes(providerId);
}
export function isSplitOrderByVendor(providerTypeId) {
  return isThirdPartyLogisticProvider(providerTypeId) && IS_SPLIT_ORDER_BY_VENDOR
}

/* fast order */
export const FAST_ORDER_TYPE_ID = 1;
export const OPERATOR_AND = 1;
export const MAX_QUANTITY = 1000;
