import logisticApi from 'utils/api/logisticApi';
import { put } from 'redux-saga/effects';
import { logisticActions } from 'redux/actions';
import { toast } from 'react-toastify';

export function* loadVendorDeliveryFees(payload) {
  const { params } = payload.payload;
  try {
    yield put(logisticActions.saveVendorDeliveryFeesLoading(true));
    yield put(logisticActions.saveVendorDeliveryFees([]));
    const vendorDeliveryFees = [];
    for ( let item of params ) {
      const { data: response } = yield logisticApi.calculateDeliveryFee({
        params: item,
      });
      if (!response?.result || !response?.data) {
        toast.error(`Thông tin phí giao hàng xảy ra lỗi, vui lòng thử lại sau`);
        break;
      }
      vendorDeliveryFees.push({
        vendor_id: item.getcare_vendor_id,
        fee: response.data
      })
    }
    yield put(logisticActions.saveVendorDeliveryFees(vendorDeliveryFees));
    yield put(logisticActions.saveVendorDeliveryFeesLoading(false));
  } catch (err) {

  }
}

export function* loadDeliveryLogisticList(payload) {
  const { params } = payload.payload;
  try {
    yield put(logisticActions.saveLogisticListLoading(true));

    const { data: response } = yield logisticApi.getAllByDelivery({ params });

    yield put(logisticActions.saveLogisticListLoading(false));

    if (!response?.result || !response.data) {
      yield put(logisticActions.saveDeliveryLogisticList([]));
      return;
    }
    yield put(logisticActions.saveDeliveryLogisticList(response.data));
  } catch (err) {
    console.log(err);
    yield put(logisticActions.saveLogisticListLoading(false));
  }
}
export function* loadLogisticList(payload) {
  const { params } = payload.payload;
  try {
    const { data: response } = yield logisticApi.getAll({ params });

    if (!response?.result || !response.data) {
      yield put(logisticActions.saveLogisticList([]));
      return;
    }
    yield put(logisticActions.saveLogisticList(response.data));
  } catch (err) {
    console.log(err);
  }
}
