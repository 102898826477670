import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { getFullAddressStr } from 'utils/helper';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import { withTranslation } from "react-i18next";

import classes from '../SalesOrderDeliveryInfo.module.scss';

class DeliveryView extends PureComponent {
  render() {
    const { salesOrderDelivery, salesOrder, willShowVATInfo, t } = this.props;
    const hasTaxCode = !!salesOrder?.is_taxcode;

    return (<div className={classes.ViewWrap}>
      { salesOrderDelivery && (
        <>
          {!!salesOrderDelivery.customer_name && (
            <p className={classes.CustomerName}>{salesOrderDelivery.customer_name}</p>
          )}
          {(!!salesOrderDelivery.name || !!salesOrderDelivery.phone) && (
              <div className={`${classes.GroupInfo} ${classes.Info}`}>
                {!!salesOrderDelivery.name && (
                  <span className={classes.Info}>
                    <PersonOutlineIcon
                      fontSize="small"
                      className={classes.Icon}
                    />
                    {salesOrderDelivery.name}
                  </span>
                )}
                {!!salesOrderDelivery.name && !!salesOrderDelivery.phone && (
                  <span className={classes.InfoDivider}></span>
                )}
                {!!salesOrderDelivery.phone && (
                  <span className={classes.Info}>
                    <PhoneOutlinedIcon
                      fontSize="small"
                      className={classes.Icon}
                    />
                    {salesOrderDelivery.phone}
                  </span>
                )}
              </div>
            )}
            <div className={classes.Info}>
              <RoomOutlinedIcon fontSize="small" className={classes.Icon} />
              {getFullAddressStr(
                salesOrderDelivery.address,
                salesOrderDelivery.getcare_ward,
                salesOrderDelivery.getcare_district,
                salesOrderDelivery.getcare_province
              )}
            </div>
            { willShowVATInfo &&
              <div className={`${classes.Info} ${classes.VATOption}`}>
                { hasTaxCode ? <CheckBoxIcon fontSize="small" color="secondary"/> : <CheckBoxOutlineBlankIcon fontSize="small" color="secondary"/> }
                <p className={classes.VATInfo}>
                  <span>{t('Xuất hoá đơn GTGT')}</span>
                  {salesOrder?.is_taxcode && salesOrder?.tax_name ? <span className={classes.TaxInfo}>{t('Tên công ty')}<strong>{ salesOrder?.tax_name }</strong></span>: ``}
                  {salesOrder?.is_taxcode && salesOrder?.taxcode ? <span className={classes.TaxInfo}>{t('Mã số thuế')}<strong>{ salesOrder?.taxcode }</strong></span> : ``}
                  {salesOrder?.is_taxcode && salesOrder?.tax_address ? <span className={classes.TaxInfo}>{t('Địa chỉ')}<strong>{ salesOrder?.tax_address }</strong></span> : ``}
                </p>
              </div>
            }
        </>)
      }
    </div>)
  }
}

DeliveryView.propTypes = {
  salesOrderDelivery: PropTypes.object,
  salesOrder: PropTypes.object,
  willShowVATInfo: PropTypes.bool,
};
DeliveryView.defaultProps = {
  salesOrderDelivery: null,
  salesOrder: null,
  willShowVATInfo: true,
};

export default withTranslation()(DeliveryView);
