import axiosCommon from 'utils/axios/axiosCommon';
import axiosAuth from 'utils/axios/axiosAuth';

const authApi = {
  checkAuth: () => {
    return axiosCommon.post(`user/authentication/ecom`, {}, { withCredentials: true });
  },
  signOut: () => {
    return axiosCommon.post(`user/logout`, {}, { withCredentials: true });
  },
  siginAs: ({ params }) => {
    return axiosAuth.post(`user/signin_as`, params, { withCredentials: true });
  },
  getProfile: () => {
    return axiosAuth.get(`user/profile`);
  },
  updatePassword: ({ params }) => {
    return axiosAuth.post(`user/change_password`, params);
  },
  updateProfile: ({ params }) => {
    return axiosAuth.put(`user/profile`, params);
  },

  vnPayLink: ({ params }) => {
    return axiosAuth.get(`getcare_payment_method `);
  },

  getPurchaseRequestDraft: ({ params, cancelToken }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_purchase_request_draft`, {
      params: queryParams,
      cancelToken,
    });
  },

  getUserProvinces: () => {
    return axiosAuth.get(`getcare_user_province`);
  },

  getAllowedActions: ({ component }) => {
    return axiosAuth.get(`user/get_allowed_actions`, {
      params: { component },
    });
  },

  // upload document
  uploadDocument: ({ params }) => {
    const newParams = { ...params };
    const formData = new FormData();
    Object.keys(newParams).forEach((key) => {
      if (newParams[key]) formData.append(key, newParams[key]);
    });

    return axiosAuth.post(`upload/single`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  uploadMultipleFiles: ({ params }) => {
    const newParams = { ...params };
    const formData = new FormData();
    Object.keys(newParams).forEach((key) => {
      if ( Array.isArray(newParams[key]) ) {
        for (let value of newParams[key]) {
          formData.append(key,value)
        }
      } else if (newParams[key]) formData.append(key, newParams[key]);
    });
    return axiosAuth.post(`upload/multiple`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  flushPromotionsCache: () => {
    return axiosAuth.post(`flush_cache/promotion`);
  },

  checkDomain: (params) => {
    return axiosAuth.get(`user/check_duplicate_tenant_url`,{
      params: {
        tenant_url: params
      }
    });
  },

  requestOtp: ({ params }) => {
    return axiosAuth.post(`user/request_otp`,params);
  },
  verifyOtp: ({ params }) => {
    return axiosAuth.post(`user/verify_otp`,params);
  }
};

export default authApi;
