import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { 
  saveSalesOrderListOrderSelected
} from 'redux/actions/oms/salesOrderActions';
import {
  makeSelectSalesOrderListOrderSelected
} from 'redux/selectors';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
// import { withRouter } from 'react-router';
import { validDate, currencyFormatVN } from 'utils/helper';
import { dateTimeFormat } from 'utils/constanst/dateConstants';
import format from 'date-fns/format';
import { getStatusClass } from 'utils/constanst/omsSalesOrderConstants';
// import { Link } from 'react-router-dom';
import { getFullAddressStr } from 'utils/helper';

import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import Tooltip from '@material-ui/core/Tooltip';

import gridClasses from '../SalesOrderListGrid.module.scss';
import classes from './SalesOrderListItem.module.scss';

import Checkbox from '@material-ui/core/Checkbox';

class SalesOrderListItem extends PureComponent {
  goToDetails = (e) => {
    this.props.history.push(`/so/${this.props.code}`);
  }
  stopPropagation = (e) => {
    e.stopPropagation();
  };
  selectOrder = (e) => {
    e.stopPropagation();
    const { id } = this.props;
    let orderSelected = [...this.props.orderSelected];
    if ( orderSelected.includes(id) ) orderSelected = orderSelected.filter( os => os !== id );
    else orderSelected.push(id);
    this.props.saveSalesOrderListOrderSelected(orderSelected);
  }
  orderChecked = () => {
    const { id, orderSelected } = this.props;
    return orderSelected.includes(id)
  }

  render() {
    const {
      checkbox,
      code,
      getcare_order_status,
      customer_name,
      customer_code,
      created_at,
      sales_name,
      amount_total,
      logistic_provider,
      customer_delivery,
      is_taxcode,
    } = this.props;

    return <div
      // to={`/so/${code}`}
      onClick={this.goToDetails}
      className={`${classes.Item} ${gridClasses.Row}`}
    >
      <div onClick={this.selectOrder} className={gridClasses.Col}>
        <Checkbox
          style={{ padding: 0 }}
          checked={this.orderChecked()}
          // indeterminate={selectAllOrderChecked === 'indeterminate'}
        />
      </div>
      <div className={gridClasses.Col}>
        <p>{ code }</p>
        { is_taxcode && (
            <Tooltip title={`Xuất hoá đơn GTGT`} arrow placement="top">
              <LocalOfferOutlinedIcon className={classes.SecondaryInfo} fontSize="small"/>
            </Tooltip>
          ) }
      </div>
      <div className={gridClasses.Col}>
        <p>{ customer_name }</p>
        <p className={classes.SecondaryInfo}>{ customer_code }</p>
      </div>
      <div className={gridClasses.Col}>
        <p>
          { getFullAddressStr(
              customer_delivery?.address,
              customer_delivery?.getcare_ward,
              customer_delivery?.getcare_district,
              customer_delivery?.getcare_province
            )
          }
        </p>
        { customer_delivery?.name &&
          <p className={`${classes.SecondaryInfo} ${classes.FlexInfo}`}>
            <PersonOutlineIcon fontSize="small" className={classes.Icon}/>
            <span>{ customer_delivery.name }</span>
          </p>
        }
        { customer_delivery?.phone &&
          <p className={`${classes.SecondaryInfo} ${classes.FlexInfo}`}>
            <PhoneOutlinedIcon fontSize="small" className={classes.Icon}/>
            <span>{ customer_delivery.phone }</span>
          </p>
        }
      </div>
      <div className={gridClasses.Col}>{ validDate(created_at) ? format(validDate(created_at), dateTimeFormat) : '' }</div>
      <div className={`${gridClasses.Col} TextRight`}>{ ['', null, undefined].includes(amount_total) ? '' : currencyFormatVN(amount_total) }</div>
      <div className={gridClasses.Col}>{ sales_name }</div>
      <div className={gridClasses.Col}>
        <p>Phí: { ['', null, undefined].includes(logistic_provider?.total_fee) ? '-' : currencyFormatVN(logistic_provider?.total_fee) }</p>
        <p>VC: { logistic_provider?.name }</p>
       { logistic_provider?.order_code &&
         <p className={classes.orderCode}>Mã vận đơn: {logistic_provider?.order_code}
        </p>
      }
      </div>
      <div className={gridClasses.Col}>
        <span className={getStatusClass(getcare_order_status?.id)}>
          {getcare_order_status?.name}
        </span>
      </div>
    </div>;
  }
}

SalesOrderListItem.propTypes = {
  // checkbox: PropTypes.bool,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  code: PropTypes.string,
  getcare_order_type: PropTypes.object,
  getcare_purchase_request_status: PropTypes.object,
  customer_name: PropTypes.string,
  created_at: PropTypes.string,
  sales_name: PropTypes.string,
  amount_total: PropTypes.number,
  note_latest: PropTypes.string,
  orderSelected: PropTypes.array,
};

SalesOrderListItem.defaultProps = {
  orderSelected: []
};

const mapStateToProps = createStructuredSelector({
  orderSelected: makeSelectSalesOrderListOrderSelected(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    saveSalesOrderListOrderSelected: (payload) => dispatch(saveSalesOrderListOrderSelected(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withRouter)(SalesOrderListItem);
