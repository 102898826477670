import axiosAuth from 'utils/axios/axiosAuth';

const fastOrderApi = {
  getPurchaseRequestDraft: ({ params, cancelToken }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_purchase_request_draft`, {
      params: queryParams,
      cancelToken,
    });
  },

  vnPayLink: ({ params,isCharity }) => {
    if (isCharity) {
      return axiosAuth.get(`payment/vnpay_link?getcare_customer_code=${params.data}&is_charity=1`);
    }else{
      return axiosAuth.get(`payment/vnpay_link?getcare_customer_code=`+ params.data);
    }
  },

  getCareLink: ({ params,isCharity }) => {
    if (isCharity) {
      return axiosAuth.get(`payment/gcpay_link?getcare_customer_code=${params.data}&is_charity=1`);
    }else{
      return axiosAuth.get(`payment/gcpay_link?getcare_customer_code=`+ params.data);
    }
  },

  updatePurchaseRequestDraft: ({ params, cancelToken, isCharity }) => {
    if (isCharity) {
      return axiosAuth.post(`getcare_purchase_request_draft?getcare_customer_code=`+ params.getcare_customer_code +'&is_charity=1'
      , params, 
      { cancelToken: cancelToken });
    } else {
      return axiosAuth.post(`getcare_purchase_request_draft`, params, { cancelToken: cancelToken });
    }
  },
  createPurchaseRequest: ({ params,isCharity }) => {
    if (isCharity) {
      return axiosAuth.post(`getcare_purchase_request?getcare_customer_code=`+ params.getcare_customer_code +`&is_charity=1`, params);
    }else{
      return axiosAuth.post(`getcare_purchase_request`, params);
    }
  },

  checkPromotion: ({ params }) => {
    return axiosAuth.post(`getcare_purchase_request_check`, params);
  },

  rateOrdering: ({ params }) => {
    return axiosAuth.post(`getcare_order_rating`, params);
  },

  saveOrderDraft: ({ params }) => {
    return axiosAuth.post(`getcare_order_draft`, params);
  },
};

export default fastOrderApi;