import {
  SAVE_COUPON_ITEMS,
  } from 'redux/constants/ecom/fastOrderConstants';
  
  const initialState = {
    selectCoupon: [],
    loading: false,
  };
  
  const reducer = (state = initialState, action) => {
    switch (action.type) {

      case SAVE_COUPON_ITEMS: {
        return {
          ...state,
          selectCoupon: action.selectCoupon,
        };
      }
  
      default:
        return state;
    }
  };
  
  export default reducer;
  