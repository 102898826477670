import React, { PureComponent } from 'react';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withTranslation } from "react-i18next";

import classes from './PromotionAvailables.module.scss';

class PromotionAvailables extends PureComponent {
  handlePromotionLinkClick = (e, promotionItem) => {
    this.props.onPromotionLinkClick(promotionItem);
    e.stopPropagation();
    e.preventDefault();
  };
  render() {
    const { selectedPromotionId, enablePromotions, t } = this.props;

    return (
      <div className={`${classes.Wrap}`}>
        <div className={`${classes.List}`}>
          {enablePromotions.length ? (
            <FormGroup className={classes.SelectionGroup}>
              {enablePromotions.map((item) => (
                <FormControlLabel
                  key={`promotion-${item.id}`}
                  control={
                    <Checkbox
                      size="small"
                      value={item.id}
                      name="promotionId"
                      checked={item.id === selectedPromotionId}
                      onChange={(e) => {
                        this.props.onSelectPromotion(e.target.checked ? parseFloat(e.target.value) : null);
                      }}
                      style={{ padding: '2px', marginRight: '10px' }}
                    />
                  }
                  fontSize="small"
                  className={classes.SelectionItem}
                  label={
                    <div className={`${classes.Item}`}>
                      <p
                        className={classes.PromotionLink}
                        onClick={(e) => {
                          this.handlePromotionLinkClick(e, item);
                        }}
                      >
                        {item.name}
                      </p>
                      {item.vendor_name ? <p>{item.vendor_name}</p> : ``}
                      <p className={classes.TextExplained}>{`${t('Bạn sẽ nhận được')} ${item.count_total} ${t('suất khuyến mãi')}`}</p>
                    </div>
                  }
                />
              ))}
            </FormGroup>
          ) : (
            <p className="NoData">{t('Không có khuyến mãi')}</p>
          )}
        </div>
        <div className={classes.Actions}>
          <Button color="secondary" onClick={this.props.onContinuePurchasing}>
            {t('Tiếp tục mua hàng')}
          </Button>
          <div>
            <Button variant="outlined" onClick={this.props.onCancelPromotion}>
              {t('Bỏ qua khuyến mãi')}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={this.props.onSubmitSelectedPromotion}
            >
              {t('Tiếp tục')}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(PromotionAvailables);
