import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import productApi from 'utils/api/mdm/productApi';
import { debounce, isEqual, sortBy } from 'lodash';
import { currencyFormatVN, validNumber, roundCurrency } from 'utils/helper';
import { isCollaborator } from 'utils/constanst/authConstants'
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectLoginUser, makeSelectFastOrderSelectedCustomerCode,makeSelectFastOrderDetails } from 'redux/selectors';
import {
  savePRProductItem,
  removePRDraftProductItems,
} from 'redux/actions/ecom/fastOrderActions';
import { withTranslation } from "react-i18next";
import { withRouter } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import QuantityInput from 'components/QuantityInput/QuantityInput';
import Tooltip from '@material-ui/core/Tooltip';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import EmptySign from 'components/EmptySign/EmptySign';
import InfoIcon from '@material-ui/icons/Info';
import Box from '@material-ui/core/Box';

import classes from './FastOrderProduct.module.scss';
import gridClasses from '../FastOrderProductsGrid.module.scss';

class FastOrderProduct extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      vendorOptions: this._initVendorOptions(props),
      unitOptions: this._initUnitOptions(props),
      didGetFullVendorOptions: false,
    };
  }
  componentDidUpdate(prevProps, prevState) {
    const { getcare_vendor_id, getcare_uom_base_id, selectedVendorIds } = this.props;
    const { vendorOptions, unitOptions } = this.state;
    if (!isEqual(sortBy(vendorOptions), sortBy(prevState.vendorOptions))) {
      if (vendorOptions && vendorOptions.length > 0 && !getcare_vendor_id) {
        // default: select the first vendor that is in the selectedVendorIds
        // otherwise: select the first vendor in the options
        const firstMatchedVendor = vendorOptions.find(item => selectedVendorIds.includes(item.id));
        const selectedVendor = firstMatchedVendor || { ...vendorOptions[0] };
        this.handleSaveField(
          { target: { name: 'getcare_vendor_id' } },
          selectedVendor?.id || null
        );
      }
      if (vendorOptions && vendorOptions.length > 0 && getcare_vendor_id) {
        // get unit options if vendors options change and it had getcare_vendor_id
        this._setUnitOptions();
      }
    }

    if (getcare_vendor_id !== prevProps.getcare_vendor_id) {
      // vendor id change -> unit options will change
      this._setUnitOptions();
    }

    if (getcare_uom_base_id && getcare_uom_base_id !== prevProps.getcare_uom_base_id) {
      // unit id change -> price, vat will change
      const selectedUom = this._getSelectedUnit(getcare_uom_base_id);
      this.handleSaveMultiFields(selectedUom);
    }

    if (unitOptions && unitOptions.length > 0 && !isEqual(sortBy(unitOptions), sortBy(prevState.unitOptions))) {
      // unit id doesn't change but unit options change (by change vendor id)
      // -> default select the first uom option
      const selectedUom = this._getSelectedUnit(getcare_uom_base_id) || unitOptions[0];
      this.handleSaveField(
        { target: { name: 'getcare_uom_base_id' } },
        selectedUom?.id || null
      );
      if (selectedUom?.id === getcare_uom_base_id) {
        // vendor id change
        // -> unit options change
        // -> but unit id doesn't change because multi vendors have the same unit but different prices
        // -> price, vat will change
        this.handleSaveMultiFields(selectedUom);
      }
    }
  }

  _initVendorOption = (params) => {
    return {
      id: params.getcare_vendor_id,
      name: params.vendor_name,
    };
  };
  _initVendorOptions = (params) => {
    return params.getcare_vendor_id
      ? [{ ...this._initVendorOption(params) }]
      : [];
  };

  _initUnitOption = (params) => {
    return {
      id: params.getcare_uom_base_id,
      name: params.uom_base_name,
    };
  };
  _initUnitOptions = (params) => {
    return params.getcare_uom_base_id
      ? [{ ...this._initUnitOption(params) }]
      : [];
  };

  _initProductOption = (params) => {
    return {
      id: params.getcare_product_id,
      name: params.product_name,
    };
  };

  _getVendorOptions = debounce(async () => {
    if (this.state.didGetFullVendorOptions) return;

    const { getcare_product_id, rankingKeyword } = this.props;
    const { data: response } = await productApi.get(getcare_product_id, {
      getcare_customer_code: this.props.selectedCustomerCode,
      keyword: rankingKeyword || undefined,
    });
    if (!response?.result || !response.data) return;
    this.setState({
      didGetFullVendorOptions: true,
      vendorOptions: response.data.getcare_vendors
        ? response.data.getcare_vendors
        : [],
    });
  }, 10);

  _getUnitOptions = () => {
    const { getcare_vendor_id } = this.props;
    const { vendorOptions } = this.state;
    const newUnitOptions =
      !getcare_vendor_id || !vendorOptions || !vendorOptions.length
        ? []
        : this._getSelectedVendor(getcare_vendor_id)?.uom?.map((item) => ({
            ...item,
            id: item.uom_base_id,
            name: item.uom_base_name,
          }));
    return newUnitOptions;
  };
  _setUnitOptions = () => {
    const units = this._getUnitOptions();
    this.setState({
      unitOptions: units,
    });
  };

  _getInitParams = (params) => {
    return {
      idStr: params?.idStr,
      id: params?.id,

      getcare_product_id: params.getcare_product_id,
      getcare_product: params.getcare_product_id
        ? { ...this._initProductOption(params) }
        : null,

      getcare_vendor_id: params.getcare_vendor_id,
      getcare_vendor: params.getcare_vendor_id
        ? { ...this._getSelectedVendor(params.getcare_vendor_id) }
        : null,

      getcare_uom_base_id: params.getcare_uom_base_id,
      getcare_uom_base: params.getcare_uom_base_id
        ? { ...this._getSelectedUnit(params.getcare_uom_base_id) }
        : null,

      quantity_number: params.quantity_number,
      minimum_quantity: params.minimum_quantity,
      remaining_quantity: params.remaining_quantity,
      price_sales_total: params.price_sales_total,
      vat: params.vat,
      type_label: params.type_label,
    };
  };
  _getDependencyFields = (selectedUom) => {
    const { quantity_number } = this.props;
    const minOrderQty = Math.max(1, quantity_number);
    return {
      price_sales_total: validNumber(selectedUom.price),
      sales_commission: selectedUom.sales_commission,
      vat: validNumber(selectedUom.vat),
      type_label: selectedUom.type_label,
      minimum_quantity: selectedUom.minimum_quantity,
      remaining_quantity: selectedUom.remaining_quantity,
      quantity_number: this._isOutOfStock(selectedUom.remaining_quantity)
        ? 0
        : this._minimumQuantityValid(selectedUom.minimum_quantity)
          ? Math.max(selectedUom.minimum_quantity, quantity_number) : minOrderQty,
    }
  }
  _getSelectedVendor = (vendorId) => {
    return (
      this.state.vendorOptions?.find((item) => item.id === vendorId) || null
    );
  };
  _getSelectedUnit = (unitId) => {
    return this.state.unitOptions?.find((item) => item.id === unitId) || null;
  };
  _minimumQuantityValid = (min) => {
    return min && min > 1;
  }
  _isOutOfStock = (remainingQty) => {
    return ![undefined, null].includes(remainingQty) && remainingQty <= 0;
  }
  _isErrorStatus = (error_status) => {
    return ![undefined, null].includes(error_status) && error_status < 0;
  }

  handleSaveField = (e, value) => {
    const newParams = {
      ...this._getInitParams(this.props),
      [e.target.name]: e.target.type === 'number' ? validNumber(value) : value,
    };
    this.props.savePRProductItem({
      ...newParams,
      idStr: this.props.idStr,
      id: this.props.id,
    });
  };
  handleSaveMultiFields = (selectedUom) => {
    const newFields = this._getDependencyFields(selectedUom);
    const newParams = {
      ...this._getInitParams(this.props),
      ...newFields,
    };
    this.props.savePRProductItem({
      ...newParams,
      idStr: this.props.idStr,
      id: this.props.id,
    });
  };
  handleRemove = () => {
    this.props.removePRDraftProductItems([
      {
        id: this.props.id,
        idStr: this.props.idStr,
      },
    ]);
  };

  switchCaseTooltips = () => {
    const  {prDetails,getcare_product_id} = this.props;

    let result = '';
    const dataError = prDetails?.getcare_purchase_request_items ? prDetails?.getcare_purchase_request_items.find(x => x.getcare_product_id === getcare_product_id)?.error_status : null;
    const Remaining = prDetails?.getcare_purchase_request_items ? prDetails?.getcare_purchase_request_items.find(x => x.getcare_product_id === getcare_product_id)?.remaining_quantity : null;
    const MinimumQuantity = prDetails?.getcare_purchase_request_items ? prDetails?.getcare_purchase_request_items.find(x => x.getcare_product_id === getcare_product_id)?.minimum_quantity : null;
    if (!MinimumQuantity &&  Remaining &&  dataError === 0  || MinimumQuantity &&  Remaining &&  dataError !== 0 ) {
      result =  `Hiện tại có ${Remaining} sản phẩm có sẵn`
    }
    if ( Remaining === 0  ) {
      result =  'Sản phẩm đã hết hàng'
    }
    if (MinimumQuantity > 1 &&  !Remaining &&  dataError === 0  ) {
      result =  `Hiện tại có 99,999 sản phẩm có sẵn, và số lượng tối thiểu đặt là ${MinimumQuantity}`
    }
    if (!MinimumQuantity &&  !Remaining &&  dataError === 0 || MinimumQuantity === 1 &&  !Remaining &&  dataError === 0  ) {
      result =  `Hiện tại có 99,999 sản phẩm có sẵn`
    }
    if (MinimumQuantity  > 1 &&  Remaining &&  dataError === 0 || MinimumQuantity &&  Remaining == 0 &&  dataError === 0 ) {
      result =  `Hiện có ${Remaining} sản phẩm có sẵn, và số lượng tối thiểu đặt là ${MinimumQuantity}`
    }
    if (dataError === -2 || dataError === -3) {
      result = 'Tạm ngừng cung cấp' 
    }
  
    return result;
  }

  render() {
    const {
      product_name,
      price_sales_total,
      quantity_number,
      getcare_vendor_id,
      getcare_uom_base_id,
      isEditing,
      vendor_name,
      uom_base_name,
      getcare_purchase_request_item_discounts,
      vat,
      error_status,
      getcare_product_id,
      type_label,
      minimum_quantity,
      remaining_quantity,
      sales_commission,
      user,
      prDetails,
      t,
    } = this.props;
    const { unitOptions, vendorOptions } = this.state;

    const selectedUnit = this._getSelectedUnit(getcare_uom_base_id);
    const hasNoVAT = [null].includes(vat);
    const dataError = prDetails?.getcare_purchase_request_items ? prDetails?.getcare_purchase_request_items.find(x => x.getcare_product_id === getcare_product_id)?.error_message : null;
    const Remaining = prDetails?.getcare_purchase_request_items ? prDetails?.getcare_purchase_request_items.find(x => x.getcare_product_id === getcare_product_id)?.remaining_quantity : null;
    const MinimumQuantity = prDetails?.getcare_purchase_request_items ? prDetails?.getcare_purchase_request_items.find(x => x.getcare_product_id === getcare_product_id)?.minimum_quantity : null;

    const minimum_quantityItem = prDetails?.getcare_purchase_request_items ? prDetails?.getcare_purchase_request_items.find(x => x.getcare_product_id === getcare_product_id)?.minimum_quantity : null
    const minimumQuantityItemValid = this._minimumQuantityValid(minimum_quantityItem);
    const isOutOfStock = this._isOutOfStock(remaining_quantity);
    const isErrorStatus = this._isErrorStatus(error_status);
    const minimumQuantityValid = this._minimumQuantityValid(minimum_quantity);
    const _isCollaborator = isCollaborator(user)
    return (

      <>
        <div className={`${gridClasses.Row} ${isEditing ? gridClasses.IsEditing : ''} ${classes.Item}`}>
          <div className={`${gridClasses.Col}`}>
            {product_name}
            {hasNoVAT ? (
              <Tooltip
                title={t('Sản phẩm không xuất hoá đơn')}
                arrow
                placement="top"
              >
                <ReportProblemOutlinedIcon
                  className={classes.NoInvoiceIcon}
                  fontSize="small"
                />
              </Tooltip>
            ) : (
              ''
            )}
          </div>
          <div className={`${gridClasses.Col}`}>
            {isEditing ? (
              <Autocomplete
                disableClearable
                openOnFocus
                selectOnFocus
                fullWidth
                size="small"
                handleHomeEndKeys={false}
                value={this._getSelectedVendor(getcare_vendor_id)}
                options={vendorOptions || []}
                onChange={(e, newValue) =>
                  this.handleSaveField(
                    { target: { name: 'getcare_vendor_id' } },
                    newValue?.id
                  )
                }
                renderOption={(option) => `${option.name}`}
                getOptionLabel={(option) => `${option.name}`}
                getOptionSelected={(option, value) =>
                  value?.id && option ? option.id === value.id : null
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={t('- Nhà cung cấp -')}
                    variant="outlined"
                  />
                )}
                onOpen={this._getVendorOptions}
              />
            ) : (
              vendor_name
            )}
          </div>
          <div className={`${gridClasses.Col}`}>
            {isEditing ? (<div className={classes.UomWrap}>
              <Autocomplete
                disableClearable
                openOnFocus
                fullWidth
                size="small"
                handleHomeEndKeys={false}
                value={selectedUnit}
                onChange={(e, newValue) =>
                  this.handleSaveField(
                    { target: { name: 'getcare_uom_base_id' } },
                    newValue?.id
                  )
                }
                options={unitOptions || []}
                renderOption={(option) => `${option.name}`}
                getOptionLabel={(option) => (option && option.name) || ''}
                getOptionSelected={(option, value) =>
                  value?.id && option ? option.id === value.id : null
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={t('- Đơn vị -')}
                    variant="outlined"
                  />
                )}
                onOpen={this._getVendorOptions}
              />
            </div>) : (
              uom_base_name
            )}
          </div>
          <div className={`${gridClasses.Col}`}>
            {
              _isCollaborator ? (
                <Box textAlign="left" whiteSpace="nowrap">{validNumber(sales_commission) && currencyFormatVN(sales_commission)}</Box>
              ) : ( type_label || <EmptySign/> )
            }
          </div>
          <div className={`${gridClasses.Col} TextRight`}>
            {![null, undefined, ''].includes(price_sales_total)
              ? currencyFormatVN(price_sales_total)
              : ``}
          </div>
          <div className={`${gridClasses.Col} TextCenter`}>

            {isEditing ? (
            <div className={classes.UomWrap}>
              <QuantityInput
                name="quantity_number"
                autoFocus
                value={quantity_number}
                disabled={dataError }
                max={Remaining && remaining_quantity ? Remaining : remaining_quantity ? remaining_quantity  : 999999}
                min={minimum_quantityItem ? minimum_quantityItem :  minimum_quantityItem ?  minimum_quantity : 0}
                onChange={(e) => {
                  if (minimumQuantityItemValid < Remaining && Remaining) {
                    this.handleSaveField(
                      { target: { name: 'quantity_number' } },
                      e.target.value
                    );
                  }else{
                    this.handleSaveField(
                      { target: { name: 'quantity_number' } },
                      MinimumQuantity && MinimumQuantity >  remaining_quantity && remaining_quantity ? Remaining : e.target.value
                    );
                  }
                }}
              />
         
              { 
              
              <Tooltip
              title={this.switchCaseTooltips(dataError,Remaining,MinimumQuantity)}
              arrow
              placement="top"
            >
              <InfoIcon
                className={classes.MinQuantityIcon}
                fontSize="small"
              />
            </Tooltip>
            }
                </div>
            ) : (
              quantity_number
            )}

          </div>
          <div className={`${gridClasses.Col} TextRight`}>
            {[null, undefined, ''].includes(quantity_number) ||
            [null, undefined, ''].includes(price_sales_total)
              ? ``
              : currencyFormatVN(roundCurrency(price_sales_total * Number(quantity_number)))}
          </div>
          {isEditing && (
            <div className={`${gridClasses.Col} ${classes.ActionsCol}`}>
              <IconButton
                onClick={this.handleRemove}
                style={{ padding: '0' }}
                size="small"
              >
                <DeleteOutline fontSize="small" />
              </IconButton>
            </div>
          )}
        </div>
        {!isEditing &&
          getcare_purchase_request_item_discounts &&
          getcare_purchase_request_item_discounts.length > 0 &&
          getcare_purchase_request_item_discounts.map((item, index) => (
            <div
              key={`pr-discount-${index + 1}`}
              className={`${gridClasses.Row} ${gridClasses.LineDiscountRow}`}
            >
              <div className={`${gridClasses.Col}`}></div>
              <div className={gridClasses.GroupCol}>
                <div
                  className={`${gridClasses.Col}`}
                >{ `${t('Chương trình chiết khấu')} ${
                  item.promotion_name ? item.promotion_name + ` ` : ``
                }${item.promotion_code}`}</div>
                <div className={`${gridClasses.Col} TextRight`}>
                  {['', null, undefined].includes(item.amount)
                    ? ''
                    : `-${currencyFormatVN(item.amount)}`}
                </div>
              </div>
            </div>
          ))}
      </>
    );
  }
}

FastOrderProduct.propTypes = {
  isEditing: PropTypes.bool,
};

FastOrderProduct.defaultProps = {
  isEditing: false,
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectLoginUser(),
  prDetails: makeSelectFastOrderDetails(),
  selectedCustomerCode: makeSelectFastOrderSelectedCustomerCode(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    savePRProductItem: (payload) => dispatch(savePRProductItem(payload)),
    removePRDraftProductItems: (payload) => dispatch(removePRDraftProductItems(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default withTranslation()(compose(withConnect, withRouter)(FastOrderProduct));

