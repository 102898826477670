import React, { PureComponent } from 'react';

import classes from './SalesOrderBonus.module.scss';
import gridClasses from '../SalesOrderBonusesGrid.module.scss';

class SalesOrderBonus extends PureComponent {
  render() {
    const { promotion_bonus_name, promotion_name, promotion_code, uom_base_name, quantity_number } = this.props;

    return (
      <div className={`${gridClasses.Row} ${classes.Item}`}>
        <div className={`${gridClasses.Col}`}>{`${promotion_name} - ${promotion_code}`}</div>
        <div className={`${gridClasses.Col}`}>{promotion_bonus_name}</div>
        <div className={`${gridClasses.Col}`}>{uom_base_name}</div>
        <div className={`${gridClasses.Col}`}>{quantity_number}</div>
      </div>
    );
  }
}

export default SalesOrderBonus;
