import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { currencyFormatVN, numberFormat } from 'utils/helper';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectSalesOrderLogistic, makeSelectSalesOrder, makeSelectDeliveryLogisticList } from 'redux/selectors';
import { getDeliveryLogisticList } from 'redux/actions/logisticActions';
import { validDate } from 'utils/helper';
import { dateTimeFormat } from 'utils/constanst/dateConstants';
import format from 'date-fns/format';
import { withTranslation } from "react-i18next";
import { isThirdPartyLogisticProvider } from 'utils/constanst/common';
import { isBeforeDeliveryServiceSent, isAwaitingDelivery, REQUIRED_NOTE_MAP } from 'utils/constanst/omsSalesOrderConstants';

import Panel from 'components/Panel/Panel';

import classes from './SalesOrderLogisticProvider.module.scss';

class SalesOrderLogisticProvider extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
    }
    this.innerFormRef = null;
  }
  componentDidUpdate(prevProps) {
    const { salesOrder } = this.props;
    if (salesOrder && !prevProps.salesOrder) {
      this._loadDeliveryLogisticList();
    }
  }

  _loadDeliveryLogisticList = () => {
    const { salesOrder } = this.props;
    this.props.getDeliveryLogisticList({
      params: {
        getcare_customer_code: salesOrder?.customer_code,
        getcare_ward_id: salesOrder?.customer_delivery?.getcare_ward?.id,
        weight: salesOrder?.logistic_provider?.weight || undefined,
        height: salesOrder?.logistic_provider?.height || undefined,
        length: salesOrder?.logistic_provider?.length || undefined,
        width: salesOrder?.logistic_provider?.width || undefined,
        insurance_value: salesOrder?.logistic_provider?.insurance_value || undefined,
      }
    });
  }
  _getSelectedLogistic = () => {
    const { deliveryLogisticList, salesOrderLogistic } = this.props;
    if (!deliveryLogisticList || !salesOrderLogistic?.id) return null;
    const selectedItem = deliveryLogisticList.find(item => item.id === salesOrderLogistic.id);
    return {
      ...salesOrderLogistic,
      allServices: selectedItem?.services ? [...selectedItem.services] : [],
    }
  }
  _renderProviderBeforeDeliverySent = (selectedLogistic) => {
    const { salesOrderLogistic } = this.props;
    const _isThirdPartyLogisticProvider = isThirdPartyLogisticProvider(salesOrderLogistic?.id);

    return (<div className={classes.Block}>
      <h4>
        <strong>{selectedLogistic?.name}</strong>
        { !_isThirdPartyLogisticProvider && ` (${selectedLogistic.total_fee ? 'Phí dự kiến ' + currencyFormatVN(selectedLogistic.total_fee) : 'Phí vận chuyển sẽ được chỉ định sau'})` }
      </h4>
      { _isThirdPartyLogisticProvider &&
        <div className={classes.Options}>
          <p className={classes.Service}>
            <span className={classes.ServiceName}>
              { `${selectedLogistic.service_name} ${selectedLogistic.total_fee ? ' (Phí dự kiến ' + currencyFormatVN(selectedLogistic.total_fee) + ')' : ``}` }
            </span>
          </p>
        </div>
      }
      { selectedLogistic?.note && <p className={`${classes.NoteContent} PreWrap`}>{selectedLogistic.note}</p> }
    </div>)
  }
  _renderProviderRightAfterDeliverySent = (selectedLogistic) => {
    const { t } = this.props;
    return (<div className={classes.Block}>
      <div className={`${classes.WrapAfterSent}`}>
        <div className={classes.Info}>
          <label>{t('Đơn vị vận chuyển')}</label>
          <h4><strong>{selectedLogistic?.name}</strong></h4>
        </div>
        { !!selectedLogistic?.order_code &&
          <div className={classes.Info}>
            <label>{t('Mã vận đơn')}</label>
            <span className="TextSecondary">
              { selectedLogistic.tracking_url
                ? <a className="TextSecondary" href={selectedLogistic.tracking_url} rel="noopener noreferrer" target="_blank">{ selectedLogistic.order_code }</a>
                : selectedLogistic.order_code
              }
            </span>
          </div>
        }
        { !!selectedLogistic?.cod &&
          <div className={classes.Info}>
            <label>{t('Tiền thu hộ')}</label>
            {currencyFormatVN(selectedLogistic.cod)}
          </div>
        }
        { !!selectedLogistic?.total_fee &&
          <div className={classes.Info}>
            <label>{t('Phí vận chuyển')}</label>
            {currencyFormatVN(selectedLogistic.total_fee)}
          </div>
        }
        { !['', null, undefined].includes(selectedLogistic?.insurance_value) &&
          <div className={classes.Info}>
            <label>{t('Bảo hiểm hàng hoá')}</label>
            {selectedLogistic.insurance_value > 0 ? t('Có'): t('Không')}
          </div>
        }
        { !!selectedLogistic?.insurance_value &&
          <div className={classes.Info}>
            <label>{t('Giá trị bảo hiểm')}</label>
            {currencyFormatVN(selectedLogistic.insurance_value)}
          </div>
        }
        { !!selectedLogistic?.name_2 &&
          <div className={classes.Info}>
            <label>{t('Nhà xe')}</label>
            {selectedLogistic?.name_2}
          </div>
        }
        { !!selectedLogistic?.transporter_user &&
          <div className={classes.Info}>
            <label>{t('Người giao')}</label>
            {selectedLogistic.transporter_user.name}
          </div>
        }
        { !!selectedLogistic?.required_note &&
          <div className={classes.Info}>
            <label>{t('Yêu cầu khi giao')}</label>
            {REQUIRED_NOTE_MAP[selectedLogistic.required_note]}
          </div>
        }
        <div className={classes.Info}>
          <label>{t('Trọng lượng')}</label>
          {!['', null, undefined].includes(selectedLogistic?.weight) ? `${numberFormat(selectedLogistic.weight/1000)} kg` : `-`}
        </div>
        <div className={`${classes.Info} ${classes.InfoSizes}`}>
          <div className={classes.Info}>
            <label>{t('Dài')}</label>
            {!['', null, undefined].includes(selectedLogistic?.length) ? `${numberFormat(selectedLogistic.length)} cm` : `-`}
          </div>
          <div className={classes.Info}>
            <label>{t('Rộng')}</label>
            {!['', null, undefined].includes(selectedLogistic?.width) ? `${numberFormat(selectedLogistic.width)} cm` : `-`}
          </div>
          <div className={classes.Info}>
            <label>{t('Cao')}</label>
            {!['', null, undefined].includes(selectedLogistic?.height) ? `${numberFormat(selectedLogistic.height)} cm` : `-`}
          </div>
        </div>
      </div>
      { selectedLogistic?.note && <p className={`${classes.NoteContent} PreWrap`}>{selectedLogistic.note}</p> }
      { !!selectedLogistic.tracking_url &&
        <div className={classes.Actions}>
          <a className="TextSecondary" href={selectedLogistic.tracking_url} rel="noopener noreferrer" target="_blank">{t('Kiểm tra hành trình')}</a>
        </div>
      }
    </div>)
  }

  handleEdit = () => {
    this.setState({
      isEditing: true,
    });
  }
  handleCancelEdit = () => {
    this.setState({
      isEditing: false,
    });
  }
  handleSaveEdit = () => {
    this.innerFormRef.submitForm();
  }
  handleSubmitEdit = (params) => {
    this.props.updateLogisticProvider({
      params: {
        id: this.props.salesOrder?.id,
        logistic_provider: {...params},
      },
    });
    this.handleCancelEdit();
  }

  render() {
    const { salesOrder, salesOrderLogistic, t } = this.props;
    const statusId = salesOrder?.getcare_order_status?.id;
    const selectedLogistic = {...salesOrderLogistic};
    const _isBeforeDeliveryServiceSent = isBeforeDeliveryServiceSent(statusId);
    const _isAwaitingDelivery = isAwaitingDelivery(statusId);

    return (<div className={`${classes.Wrap}`}>
      <h4 className={classes.SectionTitle}>
        {t('Thông tin vận chuyển')}
        { selectedLogistic.last_changed_by
          ? <span className={classes.UpdateInfo}>
              {t('Thay đổi bởi')}{` `}
              <strong>{ selectedLogistic.last_changed_by }</strong>{` `} 
              {t('lúc')} {`${validDate(selectedLogistic.last_changed_at) ? format(validDate(selectedLogistic.last_changed_at), dateTimeFormat) : ''}`}
            </span>
          : ''
        }
      </h4>
      <Panel
        title=""
        panelClassName={classes.Panel}
        content={<>
          { selectedLogistic ? (
              (_isBeforeDeliveryServiceSent || _isAwaitingDelivery)
              ? this._renderProviderBeforeDeliverySent(selectedLogistic)
              : this._renderProviderRightAfterDeliverySent(selectedLogistic)
            ) : <p className="NoData">{t('Không có dịch vụ vận chuyển')}</p>
          }
        </>}
        isBackground
      />
    </div>)
  }
}

SalesOrderLogisticProvider.propTypes = {
  salesOrderLogistic: PropTypes.object,
};

SalesOrderLogisticProvider.defaultProps = {
  salesOrderLogistic: null,
};

const mapStateToProps = createStructuredSelector({
  salesOrderLogistic: makeSelectSalesOrderLogistic(),
  salesOrder: makeSelectSalesOrder(),
  deliveryLogisticList: makeSelectDeliveryLogisticList(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getDeliveryLogisticList: (payload) => dispatch(getDeliveryLogisticList(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default withTranslation()(compose(withConnect)(SalesOrderLogisticProvider));
