import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { toast } from 'react-toastify';
import { withTranslation } from "react-i18next";

import { 
  makeSelectConnectSupportSupport,
  makeSelectSavingConnectSupportSupport, 
} from 'redux/selectors/connectSupportSupportSelector';
import { 
  makeSelectLoginUser 
} from 'redux/selectors';
import supportApi from 'utils/api/ecom/serviceRequest/connectSupport/support/supportApi';
import {
  saveConnectSupportSupport,
  saveSavingConnectSupportSupport,
} from 'redux/actions/connectSupportSupportActions';

import { 
  Box,
  Typography,
} from '@material-ui/core';

import ConnectSupportSupportForm from 'components/MainEcom/ConnectSupportSupportForm/ConnectSupportSupportForm';
import StyledButton from 'components/Styled/Button/Button';

import styles from './ConnectSupportSupportNew.module.scss';
import stylesLayoutMainEcom from 'assets/styles/layouts/LayoutMainEcom.module.scss';

class ConnectSupportSupportNew extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    }
    this.refConnectSupportSupportForm = React.createRef();
  }
  async componentDidMount() {
    const { address, getcare_province_id, getcare_district_id, getcare_ward_id } = this.props?.user || {};
    await this.props.saveConnectSupportSupport({
      ...this.props.connectSupportSupport,
      address,
      getcare_province: {
        id: getcare_province_id,
      },
      getcare_district: {
        id: getcare_district_id
      },
      getcare_ward: {
        id: getcare_ward_id
      },
    })
    this.setState({ loading: false })
  }
  componentWillUnmount() {
    this.props.saveConnectSupportSupport({})
  }

  handleSubmitConnectSupportSupportForm = async (values) => {
    const { t } = this.props;
    this.props.saveSavingConnectSupportSupport(true);
    const { data: response } = await supportApi.createOrUpdate({ params: values });
    if (!response.result) {
      toast.error(response.message);
      this.props.saveSavingConnectSupportSupport(false);
      return;
    }
    this.props.saveSavingConnectSupportSupport(false);
    toast.success(t(`Bạn đã tạo thành công`));
    this.props.history.push(`/community/connect-support`)
  }
  handleSubmitForm = () => {
    this.refConnectSupportSupportForm.current.submitForm();
  }

  render() {
    const { t } = this.props;
    return (
      <Box className={`${styles.root} ${this.props.savingConnectSupportSupport && 'OverlayLoading'}`}>
        <Typography className={stylesLayoutMainEcom.ContainerTitle} variant="h6">{t('Thêm đề nghị hỗ trợ')}</Typography>
        {
          !this.state.loading &&
          <ConnectSupportSupportForm
            ref={this.refConnectSupportSupportForm}
            data={this.props.connectSupportSupport}
            onSubmit={this.handleSubmitConnectSupportSupportForm}
          />
        }
        <Box display="flex" justifyContent="center">
          <StyledButton variant="contained" onClick={this.handleSubmitForm} className={styles.BtnSubmit} color="primary">{t('Gửi đề nghị hỗ trợ')}</StyledButton>
        </Box>
      </Box>
    );
  }
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectLoginUser(),
  connectSupportSupport: makeSelectConnectSupportSupport(),
  savingConnectSupportSupport: makeSelectSavingConnectSupportSupport(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    saveConnectSupportSupport: (payload) => Promise.resolve(dispatch(saveConnectSupportSupport(payload))),
    saveSavingConnectSupportSupport: (payload) => dispatch(saveSavingConnectSupportSupport(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withRouter, withTranslation(['viewMainEcomConnectSupportSupportNew']))(ConnectSupportSupportNew);
